/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import {
  without,
  find,
} from 'lodash';
import Countdown from 'react-countdown-now';
import classNames from 'classnames';
import moment from 'moment-timezone';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
  TableBody,
  Dialog,
  DialogActions,
  DialogTitle,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Checkbox,
  Button,
  DialogContent,
  Typography,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

// Utils
import {
  getCompletionStatus,
  keyDistributionStepExists,
  paymentVendorProcessingFee,
  getSorting,
  stableSort,
} from '../../../../utils/functions';


// Styles
import './QuestPaymentsTable.scss';

const TableHeaderCell = withStyles(theme => ({
  head: {
    color: '#9b9b9b',
    fontWeight: '400',
    letterSpacing: '1.2px',
    fontSize: '14px',
  },
}))(TableCell);

class QuestPaymentsTable extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    const { users, quest, showApprovalModal } = props;
    const toBePaidUsers = users.filter(user => user.status === 'approved' && !quest.paymentMade)
    const completedUsers = users.filter(user => user.status === 'payFinish' || quest.paymentMade)

    this.state = {
      orderBy: 'dateAccepted',
      order: 'asc',
      showingElements: [],
      usersTab: 0,
      selected: [],
      toBePaidUsers: toBePaidUsers,
      completedUsers: completedUsers,
      isDialogOpen: false,
      totalAmount: 0,
      showApprovalModal: showApprovalModal
    };
  }

  handleClose = () => {
    this.setState({
      isDialogOpen: false
    })
  }

  //TODO: 需要加入将选中账户的钱集中打给到noiz账户中去

  submitMoney = () => {
    console.log("submitMoney called!")
    const { selected } = this.state
    const { auth: { user, token }, postApproveQuest, quest } = this.props;

    const selectedUsers = this.getTabUsers().filter(user => selected.includes(user.id))
    //封装好数据，传递给后端
    const reqToApprove = {
      adminId: user.id,
      questId: quest.id,
      users: selectedUsers.map(user => ({
        userId: user.id,
        username: user.username,
        email: user.email,
        paymentAmount: user.paymentAmount,
        paymentFee: this.rounding(paymentVendorProcessingFee(user.paymentAmount))
      }))
    };
    const data = JSON.stringify(reqToApprove);

    postApproveQuest(data, token);

    this.handleClose()
  }

  handleOpen = () => {
    const { selected } = this.state
    const selectedUsers = this.getTabUsers().filter(user => selected.includes(user.id))

    const users = selectedUsers.map(user => ({
      userId: user.id,
      paymentAmount: user.paymentAmount,
      userame: user.username,
      email: user.email,
    }));
    let total = 0
    users.forEach(user => {
      total += user.paymentAmount
    });
    this.setState({
      isDialogOpen: true,
      totalAmount: total
    })
  }

  isAllSelected = () => {
    console.log('isAllSelected called!')
    const users = this.getTabUsers()
    return this.state.selected.length === users.length
  }

  isSelected = (id) => {
    return this.state.selected.includes(id)
  }

  handleRowSelected = (id) => {
    const { usersTab } = this.state
    if (usersTab === 0) {
      this.setState((prevState) => {
        const selected = prevState.selected.includes(id)
          ? prevState.selected.filter((rowId) => rowId !== id)
          : [...prevState.selected, id]
        return { selected }
      })
    }
  }

  handleSelectAll = (event) => {
    console.log("handleSelectAll called!")
    // const isChecked = event.target.checked;
    const users = this.getTabUsers()
    this.setState((prevState) => ({
      selected: this.isAllSelected() ? [] : users.map((row) => row.id)
    }))
  }

  clearSelectAll = () => {
    this.setState((prevState) => ({
      selected: []
    }))
  }

  selectAll = () => {
    const users = this.getTabUsers()
    this.setState((prevState) => ({
      selected: users.map((row) => row.id)
    }))
  }

  // Determine sort direction for property; set state.order and state.orderBy
  handleRequestSort = (event, property) => {
    const desc = this.state.order === 'desc' && this.state.orderBy === property;
    this.setState({
      order: desc ? 'asc' : 'desc',
      orderBy: property,
    });
  }

  // Determine user's completion status; return element to display
  getCompletionElement = (user) => {
    const { userQuests, quest } = this.props;
    const userQuest = find(userQuests, o => (o.user.id === user.id));
    const {
      timeCompleted, untilDateTime, status,
    } = getCompletionStatus(quest, userQuest, user.timePlaying);

    if (status === 'C') {
      return (
        <TableCell align="center"> <span className="success">C</span></TableCell>
      );
    } else if (status === 'P') {
      return (
        <TableCell align="center"><span className="info">P</span></TableCell>
      );
    } else if (!timeCompleted) {
      return (
        <TableCell align="center">
          <Countdown
            date={untilDateTime}
            daysInHours
            renderer={({
              hours,
              minutes,
              seconds,
              completed,
            }) => {
              if (completed) {
                return (
                  <span className="error">F</span>
                );
              }
              return <span>{hours}:{minutes}:{seconds} left to stream</span>;
            }}
          />
        </TableCell>
      );
    }
    return (
      <TableCell align="center"><span className="info">F</span></TableCell>
    );
  }

  // Open requirements table for user; set state.showingElements
  toggleElementInfo = (elementId) => {
    const { showingElements } = this.state;
    if (showingElements.indexOf(elementId) === -1) {
      this.setState({
        showingElements: [
          ...showingElements,
          elementId,
        ],
      });
    } else {
      this.setState({
        showingElements: [
          ...without(showingElements, elementId),
        ],
      });
    }
  }

  getTabUsers = () => {
    return this.state.usersTab === 0 ? this.state.toBePaidUsers : this.state.completedUsers
  }
  // Sort & define user rows based on quest type & sorting state; return elements to display
  getUserRows = (users, questType, platform, keyDistribute) => {
    const { userQuests } = this.props;
    const { order, orderBy, showingElements, usersTab } = this.state;

    const sortedUsers = stableSort(users, getSorting(order, orderBy));
    // let numberRequirements = users.length;

    if (questType === 'tiered' || questType === 'tiered-one-time') {
      // numberRequirements += 1;
      return sortedUsers.map((user) => {
        let tier = '(?)';
        if (user.tier === 'viewer') {
          tier = 'V';
        } else if (user.tier === 'member') {
          tier = 'M';
        }

        const username = platform === 'twitch' ? user.twitchUsername : 'NA';
        const userQuest = find(userQuests, o => (o.user.id === user.id));
        const showUserRequirements = showingElements.indexOf(user.username) !== -1;

        return (
          <><TableRow hover tabIndex={-1} key={user.id} selected={this.isSelected(user.id)} onClick={() => this.handleRowSelected(user.id)}>
            {usersTab === 0 ? (
              <TableCell padding="checkbox">
                <Checkbox
                  checked={this.isSelected(user.id)}
                  onChange={() => this.handleRowSelected(user.id)}
                  onClick={(e) => e.stopPropagation()}
                />
              </TableCell>
            ) : ""}
            <TableCell align="center">{user.user ? user.user.username : '-'}</TableCell>
            <TableCell align="center">{username}</TableCell>
            <TableCell align="center">{user.paymentAmount ? `$${user.paymentAmount}-${tier}` : '$0'}</TableCell>
            <TableCell align="center">{user.paymentAmount ? `$${this.rounding(paymentVendorProcessingFee(user.paymentAmount))}` : '$0'}</TableCell>
            <TableCell align="center">{user.averageViewersAtJoin ? Math.floor(user.averageViewersAtJoin) : '0'}</TableCell>
            <TableCell align="center">{user.timePlaying ? user.timePlaying : '0'}</TableCell>
            {this.getCompletionElement(user)}
            {usersTab === 1 ? (
              <TableCell align="center" > <span className="pay_success">Payment has been made</span></TableCell>
            ) : ""}
          </TableRow>
            {showUserRequirements &&
              this.getUserRequirements(user, userQuest)
            }
          </>
        );
      });
    }

    return sortedUsers.map(user => {
      const userQuest = find(userQuests, o => (o.user.id === user.id));
      const showUserRequirements = showingElements.indexOf(user.username) !== -1;
      let tier = '(?)';
      if (user.tier === 'viewer') {
        tier = 'V';
      } else if (user.tier === 'member') {
        tier = 'M';
      }
      return (
        <>
          <TableRow hover tabIndex={-1} key={user.id}>
          {usersTab === 0 ? (
              <TableCell padding="checkbox">
                <Checkbox
                  checked={this.isSelected(user.id)}
                  onChange={() => this.handleRowSelected(user.id)}
                  onClick={(e) => e.stopPropagation()}
                />
              </TableCell>
            ) : ""}
            <TableCell align="center">{user.user ? user.user.username : '-'}</TableCell>
            <TableCell align="center">{user.paymentAmount ? `$${user.paymentAmount}-${tier}` : '$0'}</TableCell>
            <TableCell align="center">{user.paymentAmount ? `$${this.rounding(paymentVendorProcessingFee(user.paymentAmount))}` : '$0'}</TableCell>
            <TableCell align="center">{user.link ? <a href={user.link} target="_blank" rel="noopener noreferrer"> {user.link} </a> : '-'}</TableCell>
            {this.getCompletionElement(user)}
            <TableCell align="center">{user.dateAccepted ? moment(user.dateAccepted).format('MM/DD/YY - hh:mm a') : '-'}</TableCell>
            {usersTab === 1 ? (
              <TableCell align="center" > <span className="pay_success">Payment has been made</span></TableCell>
            ) : ""}
          </TableRow>
          {showUserRequirements &&
            this.getUserRequirements(user, userQuest)
          }
        </>
      );
    });
  }

  rounding = number => parseFloat(number).toFixed(2);

  // Define table headers based on quest type; return array of headers
  getTableHeaders = (questType, platform, keyDistribute) => {
    const { usersTab } = this.state;

    let tableHeaders = [];

    switch (questType) {
      case 'tiered':
      case 'tiered-one-time':
        tableHeaders = [
          {
            id: 'username', numeric: false, disablePadding: true, label: 'Noiz',
          },
          {
            id: `${platform}Username`, numeric: false, disablePadding: true, label: platform.charAt(0).toUpperCase() + platform.slice(1),
          },
          {
            id: 'paymentAmount', numeric: true, disablePadding: true, label: 'Payment Amount',
          },
          {
            id: 'processingFee', numeric: true, disablePadding: true, label: 'Processing Fee',
          },
          {
            id: 'averageViewers', numeric: true, disablePadding: true, label: 'Average Viewers',
          },
          {
            id: 'timePlaying', numeric: true, disablePadding: true, label: 'Time Playing',
          },
          {
            id: 'completionStatus', numeric: false, disablePadding: true, label: 'Status',
          },
        ];
        break;
      default:
        tableHeaders = [
          {
            id: 'username', numeric: false, disablePadding: false, label: 'Noiz',
          },
          {
            id: 'paymentAmount', numeric: true, disablePadding: true, label: 'Payment Amount',
          },
          {
            id: 'processingFee', numeric: true, disablePadding: true, label: 'Processing Fee',
          },
          {
            id: 'link', numeric: false, disablePadding: false, label: 'Link',
          },
          {
            id: 'completionStatus', numeric: false, disablePadding: false, label: 'Status',
          },
          {
            id: 'dateAccepted', numeric: false, disablePadding: false, label: 'Date Accepted',
          },
        ];
        break;
    }

    // if (usersTab === 0 && keyDistribute) {
    //   tableHeaders.push({
    //     id: 'gameKey', numeric: false, disablePadding: true, label: '',
    //   });
    // }

    if (usersTab === 1) {
      tableHeaders.push({
        id: 'desc', numeric: false, disablePadding: true, label: '',
      });
    }

    return tableHeaders;
  }

  // Define data for csv download; return object with headers and data

  renderFirstRow = (toStatusCMoneyUsers) => {
    const { usersTab, selected, toBePaidUsers, completedUsers } = this.state;
    // const isAllSelected = this.isAllSelected(toStatusCMoneyUsers);
    const buttonProps = {
      variant: 'contained',
      size: 'large',
      disabled: false
    };
    return (
      <div className="First_Row_Header">
        <div className="Global__subTabs" style={{ padding: '20px 30px 10px 30px', flex: 1 }}>
          <div className={classNames('Global__subTab', { active: usersTab === 0 })} onClick={() => this.setState({ usersTab: 0 })} >
            To be paid ({toBePaidUsers.length})
          </div>
          <div className={classNames('Global__subTab', { active: usersTab === 1 })} onClick={() => this.setState({ usersTab: 1 })} >
            Complete ({completedUsers.length})
          </div>
        </div>
        {usersTab === 0 ? (
          <div className="SubmissionButtons">
            <Tooltip title="Approve selected requirements" placement="top">
              <span>
                <Button {...buttonProps} color="primary" variant="contained" disabled={selected.length === 0} onClick={this.handleOpen}>Approve Selected</Button>
              </span>
            </Tooltip>
            <Tooltip title="Unselect all currently selected" placement="top">
              <span>
                <Button {...buttonProps} disabled={selected.length === 0} onClick={this.clearSelectAll}>Clear Selected</Button>
              </span>
            </Tooltip>
            <Tooltip title="Select all requirements in the current viewing window" placement="top">
              <span>
                <Button {...buttonProps} disabled={selected.length === toStatusCMoneyUsers.length} onClick={this.selectAll}>Select All</Button>
              </span>
            </Tooltip>
          </div>
        ) : ""}

      </div>
    );
  }

  openApprovalModal = () => {
    const { user } = this.props.auth;
    const isAdmin = user.role === 'admin';
    const isHelper = user.role === 'helper';

    if (isAdmin || isHelper) {
      this.setState({
        showApprovalModal: true,
      });
    }
  }

  closeApprovalModal = () => {
    this.setState({
      showApprovalModal: false,
    });
  }

  //获取失败用户列表
  getApprovalRows = () => {
    const { approvalData } = this.props;
    console.log("approvalData is:", approvalData)
    return approvalData.filter((item) => !item.status).map((item, index) => {
      return (
        <TableRow
          key={item.id || index}
          style={{
            backgroundColor: index % 2 === 0 ? '#F5F5F5' : 'transparent',
            borderBottom: '1px solid #E0E0E0',
          }}>
          <TableCell align="center" style={{ color: '#424242', padding: '12px' }}>{item.username ? item.username : '-'}</TableCell>
          <TableCell align="left" style={{ color: '#424242', padding: '12px' }}>{item.message ? item.message + item.message + item.message + item.message : '-'}</TableCell>
        </TableRow>
      );
    });
  }

  renderShowApprovalModal() {
    console.log("renderShowApprovalModal method called!")
    const tableHeaders = [{ id: 'username', numeric: false, disablePadding: true, label: 'Username' }, { id: 'message', numeric: false, disablePadding: true, label: 'Reason' }];
    console.log("tableHeaders is:", tableHeaders)
    return (
      <Dialog
        open={this.state.showApprovalModal}
        PaperProps={{
          style: {
            backgroundColor: '#FFFFFF',
            borderRadius: '8px',
            width: '100%',
            maxWidth: '600px',
            padding: '20px',
          }
        }}>

        <DialogTitle style={{
          backgroundColor: '#1976D2',
          color: '#FFF',
          padding: '16px',
          fontWeight: 'bold'
        }} >Failed Approvals</DialogTitle>
        <DialogContent style={{ padding: '16px' }}>
          <Table
            style={{ border: '1px soild #E0E0E0', borderCollapse: 'collapse', width: '100%' }}>
            <TableHead>
              <TableRow style={{ backgroundColor: '#1976D2' }}>

                {tableHeaders.map((headCell) => (
                  <TableHeaderCell
                    key={headCell.id}
                    align="center"
                    padding={headCell.disablePadding ? 'none' : 'default'}
                    style={{ fontWeight: 'bold', color: '#FFFFFF', padding: '12px' }}>{headCell.label}
                  </TableHeaderCell>))}
              </TableRow>
            </TableHead>
            <TableBody>{this.getApprovalRows()}</TableBody>
          </Table>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', padding: '16px' }}>
          <Button onClick={this.closeApprovalModal}
            style={{ fontSize: '16px', fontWeight: '600', backgroundColor: '#4CAF50', color: '#fff', borderRadius: '8px', padding: '10px 50px', boxShadow: '0px 4px 8px rgba(0,0,0,0.5)', textTransform: 'none', textAlign: 'center' }}
          >Confirm</Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const {
      questType, quest
    } = this.props;
    const { twitchAccountRequired } = quest;
    const { usersTab, selected, isDialogOpen, totalAmount } = this.state;

    let platform = 'twitch';
    if (twitchAccountRequired) {
      platform = 'twitch';
    }

    const currentTabUsers = this.getTabUsers()
    console.log("currentTabUsers", currentTabUsers)
    const isAllSelected = this.isAllSelected();

    const keyDistribute = quest.keyRequired && keyDistributionStepExists(quest);

    const createSortHandler = property => (event) => {
      this.handleRequestSort(event, property);
    };

    return (
      <section className="ToggleTable">
        <React.Fragment>
          {this.renderFirstRow(currentTabUsers)}
          {currentTabUsers.length ?
            (<Table>
              <TableHead>
                <TableRow >
                  {usersTab === 0 ? (
                    <TableCell padding="checkbox">
                      <Checkbox indeterminate={selected.length > 0 && selected.length < currentTabUsers.length}
                        checked={isAllSelected}
                        onChange={this.handleSelectAll}
                      />
                    </TableCell>
                  ) : null}
                  {this.getTableHeaders(questType, platform, keyDistribute).map(headCell => (
                    <TableHeaderCell
                      key={headCell.id}
                      align="center"
                      padding={headCell.disablePadding ? 'none' : 'default'}
                      sortDirection="asc">
                      <TableSortLabel
                        active={this.state.orderBy === headCell.id}
                        direction={this.state.order}
                        onClick={createSortHandler(headCell.id)}
                        IconComponent={ArrowDropDownIcon}
                        style={{ marginLeft: '30px' }}>
                        {headCell.label}
                        {this.state.orderBy === headCell.id ? (
                          <span style={{ display: 'none' }}>
                            {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableHeaderCell>))}
                </TableRow>
              </TableHead>
              <TableBody>{this.getUserRows(currentTabUsers, questType, platform, keyDistribute)}</TableBody>
            </Table>
            ) : (<div className="NoQuestTiered">No data yet.</div>)}
        </React.Fragment>

        <Dialog open={isDialogOpen} PaperProps={{
          style: {
            backgroundColor: '#204E78',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            width: '400px',
            padding: '10px'
          }
        }}>
          <DialogTitle><p className="custom-dialog-title">Confirmation of payment</p></DialogTitle>
          <DialogContent>
            <Typography variant='body1' component="span" style={{ fontWeight: 'bolder', fontSize: '15px' }}>You will pay a total of </Typography>
            <Typography variant='body1' component="span" style={{ fontWeight: 'bolder', fontSize: '15px', color: '#bbeb3d' }}>${totalAmount}</Typography>
            <Typography variant='body1' component="span" style={{ fontWeight: 'bolder', fontSize: '15px' }}> to the selected user to access their Noiz account. This behavior is irreversible. Confirm the payment?</Typography>
            {/* <p style={{}}>$100</p> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}
              style={{ fontSize: '16px', fontWeight: '800', backgroundColor: '#f77929', color: '#fff', borderRadius: '8px', padding: '10px 50px', boxShadow: '0px 4px 8px rgba(0,0,0,0.5)', textTransform: 'none' }}
            >Cancel</Button>
            <Button onClick={this.submitMoney}
              style={{ marginLeft: '20px', fontSize: '16px', fontWeight: '800', backgroundColor: '#76b452', color: '#fff', borderRadius: '8px', padding: '10px 50px', boxShadow: '0px 4px 8px rgba(0,0,0,0.5)', textTransform: 'none' }}
            >Confirm</Button>
          </DialogActions>
        </Dialog>
        {(this.state.showApprovalModal) && this.renderShowApprovalModal()}
      </section>
    );
  }
}

QuestPaymentsTable.propTypes = {
};

export default QuestPaymentsTable;