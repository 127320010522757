import React, { useState } from 'react';
import { Link } from 'react-router';
import { inRoles } from '../../utils/authChecker';
// import defaultAvatar from '../../images/avatars/defaultSpaceman.png';
import './AccountMenuNew.scss';

function AccountMenuNew({ auth, isBrandUser, visible }) {
  const {
    user: {
      // avatar,
      company,
      displayName,
      username,
    }
  } = auth;

  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  const toggle = () => setOpen(!open);

  if (!visible) return null;

  return (
    <>
      <div
        className={`hidden-layer ${open ? 'open' : ''}`}
        onClick={close}
        onKeyPress={close}
      />
      <button
        className="user-info"
        onClick={toggle}
        onKeyPress={toggle}
      >
          <section className="about">
            <span className="name">
              {displayName || username}
            </span>

            {isBrandUser && company ? (
              <span className="company">
                {company}
              </span>
            ) : null}
          <i className={`fa ${open ? 'fa-caret-up' : 'fa-caret-down'}`} />
        </section>
      </button>
      {open && (
        <ul
          className="account-menu"
          onClick={close}
        >
          <MenuItem
            linkTo={isBrandUser ? '/portal?component=Profile' : "/my-account-detail/wallet"}
            text="My Account"
          />
          {isBrandUser && <MenuItem
            linkTo="/portal?component=default&tab=home"
            text="Client Portal"
          />}
          {!isBrandUser && (
            <>
              <MenuItem
                linkTo="/my-quests"
                text="My Quests"
              />
              <MenuItem
                linkTo="/connect-accounts"
                text="Connect Accounts"
              />
            </>
          )}
          {inRoles(['admin', 'helper', 'finance', 'sadmin']) && (
            <MenuItem
              linkTo="/admin/command-center"
              text="Command Center"
            />
          )}
          {inRoles(['admin', 'demo', 'sadmin']) && (
            <MenuItem
              linkTo="/portal"
              text="Portal"
            />
          )}
          {!isBrandUser && (
            <MenuItem
              linkTo="/faqs"
              text="FAQs"
            />
          )}
          {inRoles(['admin', 'sadmin']) && (
            <MenuItem
              linkTo="/logs"
              text="Logs"
            />
          )}
          {inRoles(['sadmin']) && (
            <MenuItem
              linkTo="/admin/ConfigurationCenter"
              text="Configuration Center"
            />
          )}
          <MenuItem
            linkTo="/logout"
            text="Logout"
          />
        </ul>
      )}
    </>
  );
}

function MenuItem({ linkTo, text }) {
  return (
    <li>
      <Link
        activeClassName="active"
        to={linkTo}
      >
        {text}
      </Link>
    </li>
  );
}

export default AccountMenuNew;
