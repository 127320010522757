/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


class CustomDropDownGeneric extends Component {
  render() {
    const { input, meta: { touched, error } } = this.props;
    const hasError = touched && error !== undefined;
    const renderSelectOptions = option => (<option key={option} value={option}>{option}</option>);
    return (
      <div className="input-container"  style={{ flexBasis: this.props.flexBasis }}>
        <label>
          {this.props.customLabel}
        </label>
        <div className="input-wrapper">
          <select
            {...input}
            onChange={(value) => {
                if (this.props.handleChange) { this.props.handleChange(value); }
                input.onChange(value); }}
            className={classNames({
                touched, error: hasError, ok: !hasError, empty: !input.value, 'not-empty': input.value,
            })}
          >
            <option className="first-value" value="">{this.props.placeholder}</option>
            { this.props.options.map(renderSelectOptions) }
          </select>
          {(hasError && touched) &&
          <div className="error-icon">
            <i className="fa fa-exclamation-circle" />
          </div>}
          {(!hasError && touched) &&
          <div className="ok-icon">
            <i className="fa fa-check" />
          </div>}
          {hasError && <div className="error-message">{error}</div>}
        </div>
      </div>
    );
  }
}

CustomDropDownGeneric.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  // meta: PropTypes.shape({
  //   touched: PropTypes.bool,
  //   error: PropTypes.bool,
  // }),
  placeholder: PropTypes.string,
};

CustomDropDownGeneric.defaultProps = {
  placeholder: '',
  // meta: {
  //   error: false,
  //   touched: false,
  // },
};


export default CustomDropDownGeneric;
