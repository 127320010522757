import React, { useEffect } from 'react';
import { ParallaxLayer } from '../../../custom_modules/react-spring/src/renderprops/addons/index';
import useOnScreen from '../useOnScreen';
import './BrandServiceSlides.scss';
import macro from '../assetsV2/macro-influencer-new.png';
import anime from 'animejs';
import eBg from '../assetsV2/emac.png';

// Little helpers ...

const EMacroInfluencer = (props) => {
  const {
    pOffset, layoutFactor = 1, offsetAdjust = 0, navToOffset = () => null, contactNavIndex = 0,
  } = props;
  const ref = React.createRef();

  const onScreen = useOnScreen(ref, '100px', 0.5);
  const [debounce, setDebounce] = React.useState(true);

  useEffect(() => {
    if (onScreen && debounce) {
      anime({
        targets: '.cascade-fade-e',
        opacity: [1, 0],
        translateY: 100,
        duration: 800,
        direction: 'reverse',
        easing: 'linear',
        delay: anime.stagger(60, { direction: 'reverse' }),
      });
      setDebounce(false);
    }
  }, [onScreen, debounce]);

  return (

    <>
      <ParallaxLayer
        offset={pOffset + offsetAdjust}
        factor={layoutFactor}
        speed={0}
        className="slide-e-container"
        style={{ 
          backgroundImage: `url(${eBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >

        <div className="cascade-fade-e">
          <div className="brand-title">Macro Influencers</div>
          <div className="grey-brand-body-text">
            Harness the power of broad based celebrity association and reach the greatest number of viewers across a wide range of platforms with macro influencers.
          </div>
          <div>
            <button onClick={() => navToOffset(contactNavIndex - 1.8)} className="brand-white-button"> Learn More </button>
          </div>
        </div>

        <div className="cascade-fade-e brand-image-container">
          <img className="brand-micro-image" src={macro} alt="macro" />
          <div ref={ref} />
        </div>
      </ParallaxLayer>
      <ParallaxLayer offset={pOffset + offsetAdjust} factor={layoutFactor} speed={0.8} className="brand-angle-svg" />

    </>

  );
};


export default EMacroInfluencer;

