import React, { useState, useEffect } from 'react';
import { Link } from 'react-router';
import GameTooltip from './GameTooltip';
import './CDKeyItem.scss';
import {getAmountFormattedString} from "../../utils/functions";

function CDKeyItem({ quest, product,isRightEdge }) {
  const {
    cover,
    name,
    headline,
    gallery,
    id,
    releaseDate,
    categories,
  } = quest;

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const formatDate = (date) => {
    if (!date) return ''
    const dateObj = new Date(date)
    const year = dateObj.getFullYear()
    const month = String(dateObj.getMonth() + 1).padStart(2, '0')
    const day = String(dateObj.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }
  // Filter the gallery array to include only items with type 'image'
  const imageGallery = gallery?.filter(item => item.type === 'image');

  const galleryExists = imageGallery ? imageGallery.length > 0 : false;
  const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {
    if (imageGallery) {
      const interval = setInterval(() => {
        setCurrentImageIndex(prevIndex => (prevIndex + 1) % imageGallery.length);
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [imageGallery?.length]);

  return (
    <div
      className="cdkey-item-all"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="cdkey-cover-container" data-tip data-for={`tooltip-${id}`}>
        <Link to={`/game/${id}`}>
          <img src={cover} alt="Game Cover" className="cdkey-cover-image" />
        </Link>

      </div>
      <div className="red-line"></div>
      <div className='bottom'>
        <div className="bottom-price">
        <div className='price'>${getAmountFormattedString(product.salePrice)}</div>
          {product.discount>0&&<div className="section-discount">
          <div className="section-block">
            <span className="text-price">${getAmountFormattedString(product.price)}</span>
          </div>
          <span className="text-sale">-{Math.floor(product.discount.toFixed())}%</span>
        </div>}
      </div>
        <div className='remaining'>Stock Left {product&&product.availableNum>999?'999+':product?.availableNum}</div>
      </div>

      {isHovered && <GameTooltip
        isRightEdge={isRightEdge}
        coverPortrait={cover}
        cover={cover}
        errorCover={cover}
        name={name}
        imageGallery={imageGallery}
        currentImageIndex={currentImageIndex}
        galleryExists={galleryExists}
        releaseDate={formatDate(releaseDate)}
        description={headline}
        categories={categories}
      />}
    </div>
  );
}

export default CDKeyItem;
