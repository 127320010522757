import React from 'react';
import { htmlToPlainText } from '../../utils/functions';
import './Quest.scss';
import ReactHtmlParser from 'react-html-parser';


const GameSection = (props) => {
  const {
    gameDescription = '',
    developer,
    // gamePageURL,
    genres,
    // id,
    // logo,
    publisher,
    questDescription = '',
    tagline,
    gameTitle = '',
    sponsorType,
  
  } = props;



  const isAGame = sponsorType === 'game';

  const mainDesc = questDescription ? ReactHtmlParser(questDescription) : htmlToPlainText(gameDescription).trim();

  return (
    <>
      <div className="GameSection">
        <div className="gameDesc">
          <div className='GameSectionTitle'>{tagline || gameTitle}</div>
          <div className="GameSectionTitleDescription">{mainDesc}</div>
        </div>
      </div>
      <div className="GameDescription">
        {!isAGame && (
          <div className="gameItem">
            <div className="subtitle">Sponsor</div>
            <span className="subtitle-text make-visible">{gameTitle}</span>
          </div>
        )}

        {isAGame && developer && (
          <div className="gameItem">
            <div className="subtitle">Game Developer</div>
            <span className="subtitle-text make-visible">{developer}</span>
          </div>
        )}
        {isAGame && publisher && (
          <div className="gameItem">
            <div className="subtitle">Game Publisher</div>
            <span className="subtitle-text make-visible">{publisher}</span>
          </div>
        )}
        {isAGame && genres.length > 0 && (
          <div className="gameItem">
            <div className="subtitle">Game Genre</div>
            <span className="subtitle-text make-visible">{genres.map(g => g.name).join(', ')}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default GameSection;
