import React, { useState, useEffect } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './VideoWidget.scss';
import playButton from '../../global/images/icons/play_button.svg';
import { returnYoutubeId } from '../../utils/functions';
import { fade } from '../../utils/transitionIndex';
import YouTube from 'react-youtube';

const VideoWidget = (props) => {
  const {
    src = '',
    img = '',
  } = props;

  // 提取YouTube视频ID
  const videoId = returnYoutubeId(src);

  // 状态管理
  const [showVideo, setShowVideo] = useState(false); // 新状态，用于控制是否显示视频播放器
  const [isPlaying, setIsPlaying] = useState(false);

  // 重置组件逻辑
  useEffect(() => {
    // 组件卸载时确保重置所有状态
    return () => {
      setShowVideo(false);
      setIsPlaying(false);
    };
  }, []);

  // YouTube播放设置
  const opts = {
    playerVars: {
      // 视频显示后自动播放
      autoplay: 1,
      playsinline: 1,
      controls: 1,
      rel: 0,
      modestbranding: 1,
    },
  };

  // 逻辑简化：只有图片没有视频时的特殊情况
  const imageAndNoVideo = img && !src;

  // 过渡动画设置
  const elementTransition = fade(1000, 1000, false, true, false);

  // 处理播放按钮点击
  const handlePlayClick = () => {
    if (src) {
      setShowVideo(true);
    }
  };

  return (
    <>
      <TransitionGroup className="video-widget-container">
        {/* 在容器上添加播放状态类名 */}
        <div className={`video-status-indicator ${isPlaying ? '' : ''}`}>
          {isPlaying ? '' : ''}
        </div>
        {showVideo && !imageAndNoVideo && (
          <CSSTransition {...elementTransition} className="video-widget-player">
            <YouTube
              className="video-widget-iframe"
              opts={opts}
              videoId={videoId || ''}
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              onEnd={() => {
                setIsPlaying(false);
                // 视频结束后可以选择回到封面图
                setShowVideo(false);
              }}
            />
          </CSSTransition>
        )}
        {(!showVideo || imageAndNoVideo) && (
          <CSSTransition {...elementTransition} className="video-widget-image">
            <div
              className="video-widget-image"
              onClick={handlePlayClick} // 添加点击事件
              role="button"
              tabIndex={0}
              style={{ cursor: !imageAndNoVideo ? 'pointer' : 'default' }}
            >
              <div className="preview-image">
                <img className="img-man" src={img} alt="preview video" />
              </div>
              {!imageAndNoVideo && (
                <div className="after-video-image">
                  <img src={playButton} alt="play button" />
                </div>
              )}
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
};

export default VideoWidget;