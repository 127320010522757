/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/label-has-for */

import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import RichText from '../RichText';

// const rteDefaultConfig = {
//   skin: 'dark',
//   plugins: 'link lists advlist media image textcolor',
//   toolbar: 'formatselect | fontselect | fontsizeselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent  | removeformat',
//   content_style: '#tinymce{background-color:#f4f4f9 !important;color:#000 !important; font-family:Helvetica;}',
// };

class CustomInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localError: ''
    };
  }

  // 通用的 onChange 处理，针对 onlyNumbers 时进行区间和整数校验
  handleChange(event) {
    const { input, min, max, onlyNumbers } = this.props;
    const value = event.target.value;
    let error = '';

    if (onlyNumbers === 'true') {
      // 如果非空并且不匹配整数格式，提示错误
      if (value !== '' && !/^\d+$/.test(value)) {
        error = 'Value must be an integer.';
      } else if (value !== '') {
        // 转换为数字后进行范围判断
        const numeric = parseInt(value, 10);
        if (min !== undefined && numeric < min) {
          error = `Value must be greater than or equal to ${min}.`;
        } else if (max !== undefined && numeric > max) {
          error = `Value must be less than or equal to ${max}.`;
        }
      }
    }

    // 保存本地校验错误（如果有）
    this.setState({ localError: error });
    // 调用 redux-form 的 onChange，传入事件对象
    input.onChange(event);
  }

  render() {
    const { input, meta: { touched, error }, ...custom } = this.props;
    const hasError = touched && error !== undefined;
    const { copyUrl } = custom;

    return (
      <div className="input-container" style={{ flexBasis: custom.flexBasis }}>
        <label htmlFor={input.label}>
          {custom.customLabel}
        </label>
        <div className="input-wrapper">
          {custom.prefixIcon &&
            <img
              src={custom.prefixIcon}
              className="prefix-icon"
              alt="prefix"
            />
          }
          {(custom.type === 'textarea') &&
            <textarea
              autoComplete="off"
              id={custom.id}
              rows={custom.rows}
              {...input}
              type={custom.type}
              placeholder={custom.placeholder}
              onChange={(value) => { input.onChange(value); }}
              className={classNames({
                touched, error: hasError, ok: !hasError, empty: !input.value, 'not-empty': input.value, copyUrl,
              })}
            />
          }

          {custom.type === 'richtext' &&
            <RichText
              initialValue={input.value}
              callback={custom.handleChange}
            />
          }

          {custom.type !== 'textarea' && custom.type !== 'richtext' &&

            <input
              autoComplete="off"
              id={custom.id}
              {...input}
              type={custom.type}
              placeholder={custom.placeholder}
              onChange={(event) => this.handleChange(event)}
              className={classNames('placeholder-icon', {
                touched, error: hasError, ok: !hasError, empty: !input.value, 'not-empty': input.value, copyUrl,
              })}
              readOnly={custom.readOnly}
              data-placeholder-icon={custom.placeholderIcon}
              // 如果设置了onlyNumbers，可传递min和max属性到input，浏览器支持原生校验提示
              min={custom.onlyNumbers === 'true' ? custom.min : undefined}
              max={custom.onlyNumbers === 'true' ? custom.max : undefined}
            />
          }
          {custom.customLabel2}
          {hasError && touched &&
            <div className="error-icon">
              <i className="fa fa-exclamation" />
            </div>
          }
          {!hasError && touched &&
            <div className="ok-icon">
              <i className="fa fa-check" />
            </div>
          }
          {hasError &&
            // 如果 redux-form meta.error 存在则显示它，否则显示本地校验错误
            <div className="error-message">{error || this.state.localError}</div>
          }
        </div>
      </div>
    );
  }
}

CustomInput.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  customLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  prefixIcon: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  min: PropTypes.number, // 新增最小值属性
  max: PropTypes.number, // 新增最大值属性
  onlyNumbers: PropTypes.string, // 预期值为 'true'
};

CustomInput.defaultProps = {
  placeholder: '',
  // meta: {
  //   error: false,
  //   touched: false,
  // },
};

export default CustomInput;
