import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {sortBy, without} from "lodash";
import "./AdminCenter.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Box,
  IconButton,
  Typography
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close'
import ConfigurationTabs from "./ConfigurationTabs";
import Icon from "../../../global/images/icons/Icon";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import TableBody from "@material-ui/core/TableBody";
import {
  getSorting,
  stableSort
} from "../../../utils/functions";
import {withStyles} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import moment from "moment/moment";

import {simpleNotification} from "../../../utils/notifications";

const TableHeaderCell = withStyles(theme => ({
  head: {
    color: '#9b9b9b',
    fontWeight: '400',
    letterSpacing: '1.2px',
    fontSize: '14px',
  },
}))(TableCell);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paperWidthSm': {
    backgroundColor: '#322d38',
    borderRadius:'8px',
    display:'flex',
    alignItems:'center',
    width:'500px',
    padding:'10px'
  },
  '& .MuiDialogContent-dividers': {
    borderTop:'0px',
    borderBottom:'0px'
  },
  '& .MuiTypography-h6': {
    textTransform:'none'
  },
  '& .input': {
    width:'100%',
    padding:'10px',
    backgroundColor:'#1a1a1a',
    border:'1px solid #3d3d3d',
    borderRadius:'4px',
    color:'#ffffff',
  },
  '& .input_error': {
    width:'100%',
    padding:'10px',
    backgroundColor:'#1a1a1a',
    border:'2px solid #ff0000',
    borderRadius:'4px',
    color:'#ffffff',
  },
  '& select': {
    width:'100%',
    padding:'10px',
    backgroundColor:'#1a1a1a',
    border:'1px solid #3d3d3d',
    borderRadius:'4px',
    color:'#ffffff',
  },
  '& Button': {
    padding:'10px 20px',
    border:'none',
    borderRadius:'4px',
    cursor:'pointer',
    fontSize:'14px',
    transition:'background 0.3s',
    fontFamily:'',
    textTransform:'capitalize',
  },
  '& .select_error': {
    border:'2px solid #ff0000',
    borderRadius:'4px',
    color:'#ffffff',
  },
  '& .regions-container':{
    marginBottom: '12px',
    minHeight: '36px',
    border: '1px solid #333',
    borderRadius: '4px',
    padding: '8px',
    background: '#141414',
    display:'flex',
    flexWrap:'wrap',
    gap:'8px',
  },
  '& .region-tag':{
    background: '#141414',
    border: '1px solid #333',
    borderRadius: '4px',
    padding: '4px 8px',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    '& button':{
      background: 'none',
      border: 'none',
      color: '#ffffff',
      cursor: 'pointer',
      padding: '0 4px',
    }
  }

}));


class AdminCenter extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      inRoot: this.props.router.location.pathname === "/admin/command-center",
      showArchivedGames: false,
      showDisabledGames: false,
      dialogAddRegion:false,
      dialogAddCountry:false,
      dialogCountryList:false,
      dialogDeleteOpen:false,
      regionName:"",
      currencyName:"",
      timezoneName:"",
      search:"",
      reginoNameError:false,
      numPage: 1,
      filters: {
      },
      sorting: false,
      addRegions:[],
      editRegionId:0,
      editRegionName:"",
      countryName:"",
      countryError:false,
      countryConfirm:false,
      updateRegions:[],//include add and update
      allRegions:[],
      regionsSubmit:false,
      regionId:"",
      userName:"",
      userEmail:"",
      userId:"",
      userIdsSel:[],
      userRole:null,
      userRegions:[],
    };
    this.roles=[];
    this.timezones = [];
    this.RegionNameRef = React.createRef();
    //this.currencyRef = React.createRef();
    //this.timezoneRef = React.createRef();
    this.deleteRegionId=0;
    this.deleteRegionName="";
    this.regionId=0;
    this.regionName="";
    this.newRegionName="";
    this.countryList=[];
    this.regionList=[];
    this.regions=[];
    this.deleteUserId="";
    this.deleteUserName="";
    this.deleteUserType="";
  }

  componentDidMount() {
    // TODO: Get list of Games
   // this.props.getCCGameList(token, {});
    moment.tz.names().forEach((obj) => {
      let offset = Math.round(moment.tz(obj).utcOffset() / 60).toString();
      offset = (offset.includes('-') || offset === '0') ? offset : `+${offset}`;
      const timezone = obj;
      if (
          (!timezone.includes('Etc') &&
              !timezone.includes('GMT') &&
              !timezone.includes('GB') &&
              !timezone.includes('CST6') &&
              !timezone.includes('EST6') &&
              !timezone.includes('MST7') &&
              !timezone.includes('PST8') &&
              !timezone.includes('Zulu') &&
              !timezone.includes('W-') &&
              !timezone.includes('universal')) ||
          timezone === this.props.initialValue
      ) {
        if (timezone.includes(this.props.initialValue)) {
          this.timezones.push(timezone);
        } else {
          this.timezones.push(`(GMT${offset}:00) ${timezone}`);
        }
      }
      return obj;
    });
    this.timezones = sortBy(this.timezones);
    this.setState({regionName:"",currencyName:"",timezoneName:this.timezones[0]})
    this.doSearch(1);
  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if ((prevProps.admin.region.isLoading === true && this.props.admin.region.isLoading === false)) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (this.props.admin.region.errors.hasError) {
        simpleNotification({
          level: "error",
          title: this.props.admin.region.errors.message,
          message: "Region failed to add!",
        });
      }else{
        //this.handleRegionClose();
        //simpleNotification({
        //  level: "success",
        //  title: "Region creation successful.",
        //  message: "Region has been successfully created!",
       // });
        //this.doSearch(1);
        const data = {regionName:this.state.regionName,currency:this.state.currencyName,timezone:this.state.timezoneName};
        const { addRegions } = this.state;
        //addRegions.map(region=>(region.regionName === this.state.regionName));
        const region = addRegions.find(region=>region.regionName === this.state.regionName);
        if(region){
          simpleNotification({
            level: "error",
            title: "The Region with that name already exists,but not save",
            message: "Region failed to add!",
          });
          return;
        }
        addRegions.push(data);
        //this.props.createRegion(data, token);
        //this.setState({ regionName: "",currencyName:this.roles[0].code,timezoneName:this.timezones[0]});
        this.handleRegionClose();
        simpleNotification({
          level: "success",
          title: "Region add successful.",
          message: "Region has not been permanently saved yet. Please set up the application in a timely manner to save it!",
        });
      }
    }

    if ((prevProps.admin.regionBatch.isLoading === true && this.props.admin.regionBatch.isLoading === false)) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (this.props.admin.regionBatch.errors.hasError) {
        simpleNotification({
          level: "error",
          title: this.props.admin.region.errors.message,
          message: "Region failed to create!",
        });
      }else{
        //this.handleRegionClose();
        simpleNotification({
          level: "success",
          title: "Region creation successful.",
          message: "Region has been successfully created!",
         });
        this.setState({ addRegions: []});
        this.doSearch(1);
      }
    }

    if ((prevProps.admin.admin_ms.isLoading === true && this.props.admin.admin_ms.isLoading === false)) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (this.props.admin.admin_ms.errors.hasError) {
        if(this.props.admin.admin_ms.errors.code==='E_COUNTRY_EXIST_CONFIRM'){
          this.regionName=this.props.admin.admin_ms.errors.message;
          this.setState({ countryConfirm: true});
        }else {
          simpleNotification({
            level: "error",
            title: this.props.admin.admin_ms.errors.message,
            message: "Admin failed to modify!",
          });
        }
      }else{
        this.setState({ editRegionId: 0,editRegionName:""});
        //this.handleCountryClose();
        this.doSearch(1);
      }
    }
    if ((prevProps.commandCenter.findUsers.isLoading === true && this.props.commandCenter.findUsers.isLoading === false)) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (this.props.commandCenter.findUsers.errors.hasError) {
          simpleNotification({
            level: "error",
            title: this.props.commandCenter.findUsers.errors.message,
            message: "Admin failed to add!",
          });

      }else{
        //console.log(this.props.commandCenter.findUsers.data,this.props.commandCenter.findUsers.data.length);
        if(!this.props.commandCenter.findUsers.data||this.props.commandCenter.findUsers.data.length!==1){
          simpleNotification({
            level: "error",
            title: "This user is invalid",
            message: "Admin failed to add!",
          });
          return;
        }
        const user = this.props.commandCenter.findUsers.data[0];
        let message='';
        const allRegions = this.state.allRegions;
        allRegions.forEach(region=> {
          if (region.user && region.user.id === user.id){
            message = "The administrator already exists";
          }
        });
        if(message){
          simpleNotification({
            level: "error",
            title: message,
            message: "Admin failed to add!",
          });
          return;
        }
        const { userRole,userRegions } = this.state;
        allRegions.push({user:{id:user._id,name:user.username,email:user.email},role:userRole,status:true,regions:userRegions,updateStatus:true});
        this.setState({ allRegions: allRegions,regionsSubmit: true});
        this.handleRegionClose();
      }
    }
    if ((prevProps.admin.admins.isLoading === true && this.props.admin.admins.isLoading === false)) {
      if (!this.props.admin.admins.errors.hasError) {
          const datas = this.props.admin.admins.data;
        this.setState({ allRegions: datas.map(r=>{return {...r,userName:r.user.name,userEmail:r.user.email,userRole:r.role.name}})});
        this.roles=stableSort(this.props.admin.admins.roles, getSorting('desc', 'id'));
        this.regions=this.props.admin.admins.regions;
      }
    }

    if (this.state.sorting!==prevState.sorting) {
        this.doSearch(1);
    }
  }

  openDownloadModal1 = () => {
    const { token, user } = this.props.auth;
    //const isAdmin = user.role === 'admin';
    //const isHelper = user.role === 'helper';
    const isFinance = user.role === 'finance';
    if (isFinance) {
      this.props.getRollOutCsv(0,this.state.search?this.state.search:',', JSON.stringify(this.state.filters), this.state.sorting, token,0);
      this.setState({
        downloadModal: true,
      });
    }
  }

  getTableHeaders = () => {
    const tableHeaders = [
      {
        id: '', numeric: false, disablePadding: true, label: '',
      },
      {
        id: 'userName', numeric: false, disablePadding: true, label: 'USER NAME',
      },
      {
        id: 'userEmail', numeric: false, disablePadding: true, label: 'EMAIL',
      },
      {
        id: 'userRole', numeric: false, disablePadding: true, label: 'ROLE',
      },
      {
        id: 'regions', numeric: false, disablePadding: true, label: 'REGIONS',
      },
      {
        id: 'status', numeric: false, disablePadding: true, label: 'STATUS',
      },

    ];
    return tableHeaders;
  }

  getCurrey=(code)=>{
      return this.roles.find(cur=>cur.code===code);
  }

  getRegionsRows = (regions,search) => {
    if (regions.length) {
      const sortedRegions = stableSort(regions, getSorting(this.state.order, this.state.orderBy));
      return sortedRegions.filter(r=>r.user.name.indexOf(search.trim())>=0).map(region => (
          <TableRow hover tabIndex={-1} key={region.id}>
            <TableCell align="center">
              <input type="checkbox" onChange={event => this.handleChangeSel(event,region.user.id)} checked={(this.state.userIdsSel.indexOf(region.user.id) !== -1)}/>
            </TableCell>
            <TableCell align="center">
              {this.state.editRegionId!==region.id&&(<>{region.user ? region.user.name: '-'}</>)}
            </TableCell>
            <TableCell align="center">{(region.user&&region.user.email) ? region.user.email:''}</TableCell>
            <TableCell align="center">
              <select style={{cursor:"pointer"}} value={this.roles.filter(r=>r.id===region.role.id)[0].id} onChange={event => this.handleRoleChange(region.user.id,event.target.value,event.target.options[event.target.selectedIndex].text)} >
                ${this.roles.map(curry=>(<option value={curry.id}>{curry.name}</option>))}
              </select>
            </TableCell>
            <TableCell align="center">
              <p className="regions-container">
                {region.regions?.map(r=>(<span className="region-tag">
                {r.name}
                        <button onClick={this.removeRegion.bind(this,region.user.id,r.id)}>×</button>
              </span>))}
                <button
                    style={{backgroundColor:'#4a90e2',marginLeft:'10px'}}
                    onClick={this.handleCountryOpen.bind(this,region.user.id)}>
                  Add Regional
                </button>
              </p>

            </TableCell>
            <TableCell align="center">
              {region.id&&<span className={classNames('status-badge', region.status?'enabled':'disabled')} onClick={event =>this.handleDeleteOpen(event,region.user.id,region.user.name,region.status)}>{region.status?'Enabled':'Disabled'}</span>}
              {!region.id&&<span className={classNames('status-badge', 'disabled')} onClick={event =>this.handleDeleteRegionOpen(event,region.user.id,region.user.name)}>Delete</span>}
            </TableCell>
          </TableRow>
      ));
    }
  }

  updateAddRegion=(event,propName,oldValue)=>{
    const newValue = event.target.value;
    let oldAddRegions1 = {...this.state.allRegions};
    oldAddRegions1=Object.values(oldAddRegions1);
    const newAddRegions = oldAddRegions1.map(region=>{
      if(region[propName]&&region[propName]===oldValue) {
        const newRegion = {...region}
        newRegion[propName] = newValue;
        //region[propName] = newValue;
        return newRegion;
      }else{
        return region;
      }
      //return region;
    });
    this.setState({allRegions:newAddRegions});
  }

  handleRegionClose = () => {
    this.setState({ dialogAddRegion: false,userRegions: [],userName:'',userRole:{},reginoNameError:false,countryError:false});
  };

  handleRegionOpen = () => {
    this.setState({ dialogAddRegion: true,userRole:this.roles[0],reginoNameError:false,countryError:false,userRegions: [],userName:''});
  };

  handleDeleteClose = () => {
    this.setState({ dialogDeleteOpen: false});
  };

  handleDeleteOpen = (event,userId,userName,type) => {
    this.deleteUserId=userId;
    this.deleteUserName=userName;
    this.deleteUserType=!type;
    this.setState({ dialogDeleteOpen: true});
  };

  handleDeleteRegionOpen = (event,userId,userName) => {
    this.deleteUserId=userId;
    this.deleteUserName=userName;
    this.deleteUserType=undefined;
    this.setState({ dialogDeleteOpen: true});
  };

  handleCountryClose = () => {
    this.setState({ dialogAddCountry: false});
    this.regionId=0;
    //this.props.getUserWallet();
  };

  handleCountryOpen = (userId) => {
    this.setState({ dialogAddCountry: true,userId:userId,regionId:this.regions[0]?.id,regionName:this.regions[0]?.name,userRegions:[]});
  };

  submitRegion=(event)=>{
    event.preventDefault();
    const { token } = this.props.auth;
    const { allRegions } = this.state;
    //this.props.approveRollOut(this.state.withdrawOrder.orderId,1,token);
    if(!allRegions||allRegions.filter(r=>r.updateStatus).length<1){
      return;
    }
    //const data = {regionName:this.state.regionName,currency:this.state.currencyName,timezone:this.state.timezoneName};
    //this.props.createRegions(addRegions, token);
    this.props.updateAdmins(allRegions.filter(r=>r.updateStatus),token);
    //this.setState({ regionName: "",currencyName:this.roles[0].code,timezoneName:this.timezones[0]});
    //this.handleRegionClose();
  }

  addRegion=(event)=>{
    event.preventDefault();
    //this.props.approveRollOut(this.state.withdrawOrder.orderId,1,token);
    if(this.state.regionName.trim().length<1){
      this.setState({ reginoNameError: true});
      return;
    }
    const data = {regionName:this.state.regionName,currency:this.state.currencyName,timezone:this.state.timezoneName,userCount:0,countrys:[]};
    const { allRegions,updateRegions } = this.state;
    //验证region是否存在
    const r = allRegions.find(region=>region.regionName===this.state.regionName);
    if(r){
      simpleNotification({
        level: "error",
        title: "The Region with that name already exists",
        message: "Region failed to add!",
      });
      return;
    }
    allRegions.unshift(data);
    updateRegions.unshift(data);
    this.setState({ allRegions: allRegions,updateRegions:updateRegions});
    //this.props.createRegion(data,false, token);
    //this.setState({ regionName: "",currencyName:this.roles[0].code,timezoneName:this.timezones[0]});
    this.handleRegionClose();
    this.setState({ regionsSubmit: true});
    //simpleNotification({
    //  level: "success",
    //  title: "Region add successful.",
    //  message: "Region has not been permanently saved yet. Please set up the application in a timely manner to save it!",
    //});

  }

  refuseOrder=()=>{
    const { token } = this.props.auth;
    this.props.approveRollOut(this.state.withdrawOrder.orderId,2,token);
    this.setState({ approveType: 2});
    this.handleDeleteClose();
    this.handleResultOpen();
  }

  payOrder=()=>{
    const { approvalLink,isLoading} = this.props.admin.orderLink.data;
    if(!isLoading){
      if(approvalLink){
        window.open(approvalLink, "_blank");
      }
    }
    this.handleResultClose();
  }

  doSearch=(numPage)=>{
    const { token } = this.props.auth;
    if (!this.props.admin.regions.isLoading) {
      this.props.getAdmins(0,this.state.search?this.state.search:' ',  JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token, 0);
    }
  }

  handleRequestSort = (event, property) => {
    const desc = this.state.order === 'desc' && this.state.orderBy === property;
    this.setState({
      order: desc ? 'asc' : 'desc',
      orderBy: property,
      sorting:{
        order: desc ? 'asc' : 'desc',
        orderBy:property?property:false,
      }
    });
  }

  paginationRight = () => {
    const { token } = this.props.auth;
    const { pagination } = this.props.admin.regions;
    if (pagination.totalPages < this.state.numPage + 1) {
      return;
    }
    if (!this.props.admin.regions.isLoading) {
      this.props.getRegions(this.state.numPage + 1,this.state.search?this.state.search:' ', JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token,0);
    }
    this.setState({
      numPage: this.state.numPage + 1,
    });
  }

  paginationLeft = () => {
    const { token } = this.props.auth;
    if (this.state.numPage === 1) {
      return;
    }
    if (!this.props.admin.regions.isLoading) {
      this.props.getRegions(this.state.numPage - 1,this.state.search?this.state.search:' ', JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token,0);
    }
    this.setState({
      numPage: this.state.numPage - 1,
    });
  }

  handleEdit=(regionId,regionName)=>{
    this.setState({
      editRegionId: regionId,
      editRegionName:regionName,
    });
  }

  hanleSave=(event)=>{
    event.preventDefault();
    const { editRegionId,editRegionName,allRegions } = this.state;
    //this.props.updateRegion(editRegionId,editRegionName,0,'',false, token);
    const er = allRegions.find(region=>region.regionName===editRegionName&&region.id!==editRegionId);
    if(er){
      simpleNotification({
        level: "error",
        title: "The Region with that name already exists",
        message: "Region failed to edit!",
      });
      return;
    }
    allRegions.forEach(region=>{
      if(region.id===editRegionId){
        region.regionName=editRegionName;
      }
      return region;
    });
    this.setState({
      allRegions: allRegions,
      editRegionId: 0,
      editRegionName:"",
      regionsSubmit:true,
    });
  }

  handleInputChange=(event)=>{
    const newValue = event.target.value;
    this.setState({
      editRegionName: newValue,
    });
  }

  addCountry=(event)=>{
    event.preventDefault();
    const { userId,allRegions,regionId,regionName } = this.state;
    if(!regionId){
      this.setState({
        countryError: true,
      });
      return;
    }
    this.newRegionName=userId;
    //this.props.updateRegion(this.regionId,'',0,countryName,true, token);
    //local valid move country
    let message="";
    allRegions.forEach(region=> {
      if (region.user && region.user.id === userId){
        const index = region.regions.findIndex(r=>r.id===regionId);
        if (index>=0) {
          message = "This region already exists in the current user";
        }else{
          region.regions.push({id:regionId,name:regionName});
          region.updateStatus=true;
        }
    }
    });
    if(message){
      simpleNotification({
        level: "error",
        title: message,
        message: "Region failed to add!",
      });
      return;
    }
      this.setState({ allRegions: allRegions,regionsSubmit:true});
      this.handleCountryClose();

  }

  hanleDelete=(event)=>{
    event.preventDefault();
    const { allRegions } = this.state;
   // const { token } = this.props.auth;
    //this.props.updateRegion(this.deleteRegionId,'',2,'',false, token);
    const index = allRegions.findIndex(r=>r.user.id===this.deleteUserId&&r.id);
    if(index>=0) {
      allRegions.forEach(region => {
        if (region.user.id === this.deleteUserId) {
          region.status = this.deleteUserType;
          region.updateStatus=true;
        }
      });
    }else{
      const index = allRegions.findIndex(r=>r.user.id===this.deleteUserId);
      if(index>=0){
        allRegions.splice(index,1);
      }
    }
    this.setState({
      allRegions: allRegions,
      regionsSubmit:true,
    });
    this.handleDeleteClose();
  }

  handleCountryList=(countryList)=>{
    this.countryList=countryList;
    this.setState({
      dialogCountryList:true,
    });
  }

  handleCountryListClose = () => {
    this.setState({ dialogCountryList: false});
    this.countryList=[];
    //this.props.getUserWallet();
  };

  handleCountryConfirm=()=>{
    const { countryName,allRegions } = this.state;
    //this.props.updateRegion(this.regionId,'',0,countryName,false, token);
    allRegions.forEach(region=>{
      if(region.regionName===this.newRegionName){
        region.countrys.push(countryName);
      }else if(region.regionName===this.regionName){
        if(region.countrys.indexOf(countryName)>=0){
          region.countrys.splice(region.countrys.indexOf(countryName),1);
        }
      }
      return region;
    });
    this.setState({ allRegions: allRegions,regionsSubmit:true});
    this.handleCountryConfirmClose();
    this.handleCountryClose();
  }

  handleCountryConfirmClose=()=>{
    this.setState({ countryConfirm: false});
  }

  removeRegion=(userId,regionId)=>{
    const { allRegions } = this.state;
    //this.props.updateRegion(this.regionId,'',0,countryName,false, token);
    allRegions.forEach(region=> {
      if (region.user && region.user.id === userId){
        const index = region.regions.findIndex(r => r.id === regionId);
        if (index >= 0) {
          region.regions.splice(index, 1);
        }
    }
    });
    this.setState({ allRegions: allRegions,regionsSubmit:true});
  }

  handleRoleChange=(userId,roleId,roleName)=>{
    const { allRegions } = this.state;
    //this.props.updateRegion(this.regionId,'',0,countryName,false, token);
    allRegions.forEach(region=> {
      if (region.user && region.user.id === userId){
        region.role={id:roleId,name:roleName};
        region.userRole=roleName;
        region.updateStatus=true;
      }
    });
    this.setState({ allRegions: allRegions,regionsSubmit:true});
  }

  onChangeCDKeysSel(cdKeyId) {
    const { cdKeysSel } = this.state;

    if (cdKeysSel.indexOf(cdKeyId) <= -1) {
      this.setState({
        cdKeysSel: [
          ...cdKeysSel,
          cdKeyId,
        ],
      });
    } else {
      this.setState({
        cdKeysSel: [
          ...without(cdKeysSel, cdKeyId),
        ],
      });
    }
  }

  handleChangeAllSel(event) {
    const isChecked = event.target.checked;
    if(isChecked){
      const { allRegions,search } = this.state;
      const sel = allRegions.filter(r=>r.user.name.indexOf(search.trim())>=0).map(region=>region.user.id);
      this.setState({
        userIdsSel: sel,
      });
    }else{
      this.setState({
        userIdsSel: [],
      });
    }
  }

  handleChangeSel(event,userId) {
    const isChecked = event.target.checked;
    const { userIdsSel } = this.state;
    let sel;
    if(isChecked){
      if(userIdsSel.findIndex(r=>r===userId)<0){
        userIdsSel.push(userId);
      }
      sel=userIdsSel;
    }else{
      sel=userIdsSel.filter(r=>r!==userId);
    }
    this.setState({
      userIdsSel: sel,
    });
  }

  handleChangeAllStatus(type) {

    const {allRegions, userIdsSel} = this.state;
    if (!userIdsSel || userIdsSel.length <= 0) {
      return;
    }
    let isChange = false;
    allRegions.forEach(region => {
      if (userIdsSel.findIndex(r => r === region.user.id) >= 0 && region.status !== type) {
        region.status = type;
        isChange = true;
        region.updateStatus=true;
      }
    });
    if (isChange) {
    this.setState({
      allRegions: allRegions,
      regionsSubmit:true
    });
  }
  }

  addRegionNew=(event)=>{
    event.preventDefault();
    const { userName,allRegions,userRole } = this.state;
    if(!userName){
      this.setState({
        reginoNameError: true,
      });
      return;
    }
    if(!userRole){
      this.setState({
        countryError: true,
      });
      return;
    }
    let message="";
    allRegions.forEach(region=> {
      if (region.user && (region.user.name === userName||region.user.email === userName)){
        message = "The administrator already exists";
      }
    });
    if(message){
      simpleNotification({
        level: "error",
        title: message,
        message: "Admin failed to add!",
      });
      return;
    }
    this.findUser(userName);
    //this.newRegionName=userId;
    //this.props.updateRegion(this.regionId,'',0,countryName,true, token);
    //local valid move country
    /*let message="";
    let needConfirm =false;
    allRegions.forEach(region=> {
      if (region.user && region.user.id === userId){
        message = "The administrator already exists";
      }
    });
    if(message){
      simpleNotification({
        level: "error",
        title: message,
        message: "Admin failed to add!",
      });
      return;
    }*/


  }

  findUser=(userName)=>{
    const { token } = this.props.auth;
    this.props.findUsers(userName, token,false);
  }

  handleAddRegion=(event)=>{
    event.preventDefault();
    const regionId = event.target.value;
    const regionName = event.target.options[event.target.selectedIndex].text;

    const { userRegions } = this.state;
    const index = userRegions.findIndex(r=>r.id===regionId);
    if(index<0){
      userRegions.push({id:regionId,name:regionName});
      this.setState({ userRegions: userRegions});
    }

  }

  removeUserRegion=(regionId)=>{
    const { userRegions } = this.state;
    const index = userRegions.findIndex(r=>r.id===regionId);
    if(index>=0){
      userRegions.splice(index,1);
      this.setState({ userRegions: userRegions});
    }
  }

  render() {
    const {isLoading=false } = this.props.ConfigurationCenter?.games|| {};
    const { search,regionName,countryName,reginoNameError,countryError} = this.state;
    const { data: regions=[]} = this.props.admin.admins||{};
    const { dialogAddRegion,dialogDeleteOpen,dialogAddCountry,dialogCountryList ,countryConfirm,allRegions,regionId,userName,userRegions} = this.state;
    const {hasError} = this.props.admin.orderLink.errors;
    //let newRegions=[];
    //if(regions){
    //  newRegions=Object.values(regions);
   // }
    //console.log('newRegions',newRegions,typeof newRegions,addRegions);
    //if(addRegions&&addRegions.length>0){
      //console.log('regions',newRegions.length)
      //newRegions=newRegions.concat(addRegions);
      //newRegions.push(...addRegions);
    //}
    //console.log('newRegions',newRegions,typeof newRegions);
    const createSortHandler = property => (event) => {
      this.handleRequestSort(event, property);
    };


    return (
      <section className={classNames("AdminCenter", { inRoot: this.state.inRoot })}>
        <section className='AdminCenter__wrapper'>
          <ConfigurationTabs {...this.props} />
          <h2 className='AdminCenter__title'>Admin Management</h2>
          <section className='AdminCenter__container'>
          <section className="RegionalList">
            <section className="RegionalList__wrapper">
              <section className="ToggleTable">
                <section className="ToggleTable__header">
                  <div className="ToggleTable__header__left">
                    <div className="selAll">
                      <input type="checkbox" onChange={this.handleChangeAllSel.bind(this)}/>&nbsp;Select All
                      <button className="enabled" onClick={this.handleChangeAllStatus.bind(this,true)}>Enable Selected</button>
                      <button className="disabled" onClick={this.handleChangeAllStatus.bind(this,false)}>Disable Selected</button>
                    </div>
                    <div className="Search_Input">
                      <Icon color='#bfbfbf' name='search' />
                      <input
                          type="text"
                          id="search-bar-input"
                          label="SearchBarInput"
                          placeHolder="Search users..."
                          value={search}
                          onChange={event => this.setState({ search: event.target.value })}
                      />
                    </div>
                    {/*<button style={{marginLeft:'10px'}} onClick={this.refreshRollOuts.bind(this)}>
                  Refresh Payments

                </button>*/}
                  </div>
                  <div className="ToggleTable__header__right">
                    {allRegions&&allRegions.length>0&&allRegions.filter(r=>r.updateStatus).length>0&&<button
                        style={{marginLeft:'10px',backgroundColor:'#ff0000'}}
                        onClick={this.submitRegion.bind(this)}>
                      Apply Settings
                    </button>}
                    <button
                        style={{marginLeft:'10px',backgroundColor:'#4a90e2'}}
                        onClick={this.handleRegionOpen.bind(this)}>
                      Add Administrator
                    </button>
                    {/* {(pagination?pagination.totalPages:0)>1&&(<button className="regular" onClick={this.paginationLeft.bind(this)}>
                      <i className="fa fa-arrow-left" />
                    </button>)}
                    {(pagination?pagination.totalPages:0)>1&&(<button className="regular" onClick={this.paginationRight.bind(this)}>
                      <i className="fa fa-arrow-right" />
                    </button>)}*/}
                  </div>
                </section>
                <section className="ToggleTable__table-wrapper">
                  <section className="ToggleTable__table flex-table">
                    {/*<div className="row">
                  <div
                    className="table-cell headline"
                  >
                    Filters: {this.renderFiltersString(false)}
                  </div>
                </div>*/}
                    <Table>
                      <TableHead>
                        <TableRow>
                          {this.getTableHeaders().map(headCell => (
                              <TableHeaderCell
                                  key={headCell.id}
                                  align="center"
                                  padding={headCell.disablePadding ? 'none' : 'default'}
                                  sortDirection="asc"
                              >
                                <TableSortLabel
                                    active={this.state.orderBy === headCell.id}
                                    direction={this.state.order}
                                    onClick={createSortHandler(headCell.id)}
                                    IconComponent={ArrowDropDownIcon}
                                    style={{ marginLeft: '30px' ,color:'#ffffff'}}
                                >
                                  {headCell.label}
                                  {this.state.orderBy === headCell.id ? (
                                      <span style={{ display: 'none' }}>
                                {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </span>
                                  ) : null}
                                </TableSortLabel>
                              </TableHeaderCell>))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(this.getRegionsRows(allRegions,search))}
                        {/*{(this.getRegionsRows(addRegions))}
                        { (!isLoading) &&
                            this.getRegionsRows(regions)
                        }*/}
                      </TableBody>
                    </Table>
                    <div className="NoPayments">
                      { (isLoading) &&
                          <div className="PaymentList__loading">
                            <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
                          </div>
                      }
                      { (!isLoading && !regions.length) &&
                          <span> No Region Found </span>
                      }
                    </div>
                  </section>
                </section>
                {/* {(pagination?pagination.totalPages:0)>1&&(<section className="ToggleTable__footer">
                  <section className="ToggleTable__footer__left">
                    <button onClick={this.paginationLeft.bind(this)}>
                      <i className="fa fa-caret-left" />
                    </button>
                  </section>
                  <section className="ToggleTable__footer__center">
                           <div style={{height:'100%',textAlign:'center',alignContent:'center'}}>RECORDS:{pagination?pagination.totalRecords:'0'}
                      {pagination.totalPages>1&&(<div style={{display:'inline',marginLeft:'10px'}}>PAGE:{pagination?pagination.pageNumber:'-'}/{pagination?pagination.totalPages:'-'}</div>)}
                             </div>
                </section>
                  <section className="ToggleTable__footer__right">
                    <button onClick={this.paginationRight.bind(this)}>
                      <i className="fa fa-caret-right" />
                    </button>
                  </section>
                </section>)}*/}
              </section>
            </section>

            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={!!dialogAddRegion}
            >
              <form onSubmit={this.addRegion.bind(this)} style={{width:'100%'}}>
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" style={{color:'#ff0000',alignSelf:'flex-start'}}>
                Add Administrator
              </DialogTitle>
              <DialogContent dividers>
                <div style={{display:'flex',justifyContent:'left'}}>
                  {}<Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',color:'#a0a0a0'}}>User Name/Email</Typography>
                </div>
                <div style={{display:'flex',justifyContent:'left'}}>
                  <input type="text" id="region-name-bar-input" className={reginoNameError?'input_error':'input'} label="RegionBarInput" value={userName} placeholder="Enter user name or email" onChange={event => this.setState({ userName: event.target.value,reginoNameError: false})} ref={this.RegionNameRef}/>
                </div>
                <div style={{display:'flex',justifyContent:'left',marginTop:'20px'}}>
                  <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',color:'#a0a0a0'}}>Role</Typography>
                </div>
                <div style={{display:'flex',justifyContent:'left'}}>
                  <select onChange={event => this.setState({ userRole: {id:event.target.value,name:event.target.options[event.target.selectedIndex].text}})} className={countryError?'select_error':'select'}>
                    ${this.roles.map(curry=>(<option value={curry.id}>{curry.name}</option>))}
                  </select>
                </div>
                <div style={{display:'flex',justifyContent:'left',marginTop:'20px'}}>
                  <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',color:'#a0a0a0'}}>Managed Regions</Typography>
                </div>
                <div style={{display:'flex',justifyContent:'left',flexDirection:'column'}}>
                  <div id="selectedRegions" className="regions-container">
                    {userRegions&&userRegions.map(r=>{return (<span className="region-tag">
                      {r.name}
                      <button onClick={this.removeUserRegion.bind(this,r.id)}>×</button>
                    </span>)})}
                  </div>
                  <div>
                  <select onChange={event => this.handleAddRegion(event)}>
                    ${this.regions.map(curry=>(<option value={curry.id}>{curry.name}</option>))}
                  </select>
                  </div>
                </div>
              </DialogContent>
              <DialogActions style={{alignSelf:'flex-end'}}>
                <Button autoFocus style={{background:'#ffffff',color:'#000000'}} onClick={this.handleRegionClose.bind(this)}>
                  Cancel
                </Button>
                <Button style={{background:'#4a90e2',color:'#ffffff'}} onClick={this.addRegionNew.bind(this)}>
                  Confirm
                </Button>
              </DialogActions>
              </form>
            </BootstrapDialog>

            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={!!dialogAddCountry}
            >
              <form onSubmit={this.addCountry.bind(this)} style={{width:'100%'}}>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" style={{color:'#ff0000',alignSelf:'flex-start'}}>
                  Add Region
                </DialogTitle>
                <DialogContent dividers>
                  <div style={{display:'flex',justifyContent:'left',marginTop:'20px'}}>
                    <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',color:'#a0a0a0'}}>Select Region</Typography>
                  </div>
                  <div style={{display:'flex',justifyContent:'left'}} className={countryError?'select_error':''}>
                    <select value={regionId} onChange={event => {this.setState({ regionId: event.target.value,regionName:event.target.options[event.target.selectedIndex].text })}}>
                      ${this.regions.map(curry=>(<option value={curry.id}>{curry.name}</option>))}
                    </select>
                  </div>
                </DialogContent>
                <DialogActions style={{alignSelf:'flex-end'}}>
                  <Button autoFocus style={{background:'#ffffff',color:'#000000'}} onClick={this.handleCountryClose.bind(this)}>
                    Cancel
                  </Button>
                  <Button style={{background:'#4a90e2',color:'#ffffff'}} onClick={this.addCountry.bind(this)}>
                    Confirm
                  </Button>
                </DialogActions>
              </form>
            </BootstrapDialog>

            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={!!dialogDeleteOpen}
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" style={{color:hasError?'#ff0000':''}}>
                {this.deleteUserType!==undefined&&(<p>Are you sure to  {this.deleteUserType?'make management permissions effective':'invalidate the management permissions'} of user <p style={{color:'#E7F328',display:'inline'}}>{this.deleteUserName}</p>?</p>)}
                {this.deleteUserType===undefined&&(<p>Are you sure to  delete user <p style={{color:'#E7F328',display:'inline'}}>{this.deleteUserName}</p>?</p>)}
              </DialogTitle>
              <DialogContent dividers>
                <div style={{display:'flex',justifyContent:'center'}}>
                  <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',color:hasError?'#ff0000':''}}>

                  </Typography>
                </div>
              </DialogContent>
              <DialogActions>
                <Button autoFocus style={{fontSize:'16px',fontWeight:'800',backgroundColor:'#CAC1C4',color:'#fff',borderRadius:'8px',padding:'10px 50px',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.handleDeleteClose.bind(this)}>
                  Cancel
                </Button>
                <Button style={{marginLeft:'20px',fontSize:'16px',fontWeight:'800',backgroundColor:'#76b452',color:'#fff',borderRadius:'8px',padding:'10px 50px',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.hanleDelete.bind(this)}>
                  Confirm
                </Button>
              </DialogActions>
            </BootstrapDialog>

            {<BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={!!dialogCountryList}
                PaperProps={{
                  style: {
                    width: '300px',
                    maxWidth: 'none',
                    maxHeight: '80%',
                    height: 'auto',
                  },
                }}
            >

              <DialogTitle  style={{width:'100%'}} id="customized-dialog-title">
                <Box display="flex" alignItems="left" justifyContent="space-between">
                  <Typography variant="h6"  style={{flex:'4'}}>Country List</Typography>
                <IconButton
                    edge="end"
                    aria-label="close"
                    onClick={this.handleCountryListClose.bind(this)}
                    style={{flex:'1'}}
                >
                  <CloseIcon />
                </IconButton>
                </Box>
              </DialogTitle>
              <DialogContent dividers style={{width:'90%'}}>
                {this.countryList.map(country=>(
                <div style={{display:'flex',justifyContent:'left',borderBottom:'solid 1px #3d3d3d'}}>
                  <Typography variant='body1' component="div"  gutterBottom style={{marginTop:'10px',minWidth:'150px'}}>
                    {country}
                  </Typography>
                </div>))}
              </DialogContent>
              <DialogActions>
              </DialogActions>
            </BootstrapDialog>}

            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={!!countryConfirm}
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" style={{color:hasError?'#ff0000':''}}>
                Will the <p style={{color:'#E7F328',display:'inline'}}>{countryName}</p> be moved from <p style={{color:'#E7F328',display:'inline'}}>{this.regionName}</p> to <p style={{color:'#E7F328',display:'inline'}}>{regionName}</p>?
              </DialogTitle>
              <DialogContent dividers>
                <div style={{display:'flex',justifyContent:'center'}}>
                  <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',color:hasError?'#ff0000':''}}>

                  </Typography>
                </div>
              </DialogContent>
              <DialogActions>
                <Button autoFocus style={{fontSize:'16px',fontWeight:'800',backgroundColor:'#CAC1C4',color:'#fff',borderRadius:'8px',padding:'10px 50px',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.handleCountryConfirmClose.bind(this)}>
                  Cancel
                </Button>
                <Button style={{marginLeft:'20px',fontSize:'16px',fontWeight:'800',backgroundColor:'#76b452',color:'#fff',borderRadius:'8px',padding:'10px 50px',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.handleCountryConfirm.bind(this)}>
                  Confirm
                </Button>
              </DialogActions>
            </BootstrapDialog>
            {/*{ (this.state.filtersModal) && this.renderFiltersModal() }*/}
            { (this.state.downloadModal) && this.renderDownloadModal() }
          </section>
          </section>
        </section>
      </section>
    );
  }
}

AdminCenter.propTypes = {
  router: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  // auth: PropTypes.shape({
  //   token: PropTypes.string.isRequired,
  //   user: PropTypes.shape({
  //
  //   }).isRequired,
  // }).isRequired,
  // user: PropTypes.shape({
  //   currentAgreement: PropTypes.shape({
  //     data: PropTypes.shape({
  //
  //     }).isRequired,
  //     isLoading: PropTypes.bool.isRequired,
  //     hasError: PropTypes.bool.isRequired,
  //     code: PropTypes.string.isRequired,
  //   }).isRequired,
  //   currentScheduledBroadcast: PropTypes.shape({
  //     data: PropTypes.shape({
  //
  //     }).isRequired,
  //     isLoading: PropTypes.bool.isRequired,
  //     hasError: PropTypes.bool.isRequired,
  //     code: PropTypes.string.isRequired,
  //   }).isRequired,
  // }).isRequired,
  // logout: PropTypes.func.isRequired,
  // children: PropTypes.element.isRequired,
};

export default AdminCenter;
