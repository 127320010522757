/* eslint-disable */
import React from 'react';
// import PropTypes from 'prop-types'
import './static.css';

class PrivacyPolicy extends React.Component {
    render() {
        return (
            <section className="PrivacyPolicy static">
                <h1 dir="ltr">
                    Noiz Service Agreement Update
                </h1>
                Effective Date: [2025-04-07]
                <br />
                <br />

                <p dir="ltr">
                    Noiz is updating its payment system and content usage policies. By continuing to use Noiz, users must review and agree to the updated terms outlined in this notice. This agreement is legally binding and sets clear limits on the use of Noiz services, including the Noiz Wallet, content contributions, and prize pool campaigns.
                </p>

                <h2 dir="ltr">
                    1. NOIZ WALLET FUNCTIONALITY
                </h2>
                <p dir="ltr">
                    The Noiz Wallet serves as a temporary holding account where user payments are stored. Funds in the Noiz Wallet do not accrue interest and remain accessible for withdrawal. Users must manually request a withdrawal to transfer funds from their Noiz Wallet to their PayPal account. Noiz does not provide direct bank transfers, and PayPal will handle all currency conversions and transaction processes.
                </p>

                <h2 dir="ltr">
                    2. DEPOSITS & WITHDRAWALS
                </h2>
                <p dir="ltr">
                    Users must actively request a withdrawal from their Noiz Wallet to access their funds. Withdrawal requests will be processed within up to fifteen (15) business days from the date of submission. Payments will be transferred exclusively through PayPal, which is responsible for currency conversion and applicable transaction policies. Noiz does not impose any additional transaction fees, but PayPal's fees may apply. Users are encouraged to review PayPal's terms before proceeding with withdrawals.
                </p>

                <h2 dir="ltr">
                    3. OWNERSHIP & UNCLAIMED BALANCES
                </h2>
                <p dir="ltr">
                    Funds in the Noiz Wallet remain the property of the user until withdrawn. However, if a user fails to withdraw their funds for a continuous period of three (3) years, Noiz reserves the right to remove the balance from the account. Users are responsible for ensuring they manage their funds within this timeframe to avoid forfeiture.
                </p>

                <h2 dir="ltr">
                    4. REFUND & CHARGEBACK POLICY
                </h2>
                <p dir="ltr">
                    Once funds have been withdrawn from the Noiz Wallet, they cannot be refunded under any circumstances. Users should verify all withdrawal details before confirming their request. Any disputes over transactions must be handled directly through PayPal's dispute resolution process.
                </p>

                <h2 dir="ltr">
                    5. OWNERSHIP OF DELIVERABLES
                </h2>
                <p dir="ltr">
                    Subject to Noiz's or its clients' rights, users will retain ownership of their contributions to the Deliverables unless otherwise specified. However, by submitting content to Noiz, users grant Noiz and its clients a worldwide, perpetual, fully paid-up, royalty-free, irrevocable, and fully sublicensable right and license to share, use, copy, process, adapt, translate, display, perform, reproduce, republish, and distribute their submitted materials, or any portion of them, in all forms of media, including Internet and other electronic communications, television, radio, and print media for a period of three (3) months.
                </p>
                <p dir="ltr">
                    This license allows Noiz to reproduce, reuse, or repost curator content freely within the agreed-upon timeframe. However, Noiz will not use user-submitted content for paid media or advertising without obtaining direct permission and acquiring the original file from the creator. If Noiz wishes to use the content beyond this license period or for commercial purposes, Noiz will contact the creator to secure explicit authorization.
                </p>

                <h2 dir="ltr">
                    6. PRIZE POOL CAMPAIGN TERMS
                </h2>
                <p dir="ltr">
                    Each prize pool campaign hosted by Noiz will have an individual upper limit determined by the unique campaign parameters. These limits will be clearly stated within the specific campaign details. Participants must be at least eighteen (18) years old, or meet the age requirement specified by the individual campaign, to be eligible for participation.
                </p>
                <p dir="ltr">
                    To enter the prize pool campaign, participants must complete a CDKey purchase exclusively through the Noiz Wallet. No other payment methods will be accepted. In the event of any refund requests or technical issues related to the prize pool campaign, users must contact Noiz Support at support@noiz.gg.
                </p>
                <p dir="ltr">
                    Noiz reserves the right to cancel or modify prize pool campaigns at its sole discretion. In cases of fraudulent activity or violation of campaign rules, Noiz may disqualify participants and forfeit any associated winnings.
                </p>

                <h2 dir="ltr">
                    7. AGREEMENT & CONTINUED USE
                </h2>
                <p dir="ltr">
                    By continuing to use Noiz, users acknowledge and accept these updated terms. Users who do not agree with the updates may discontinue their use of Noiz before the effective date. For further inquiries or assistance, please contact support@noiz.gg.
                </p>
            </section>
        );
    }
}

export default PrivacyPolicy;
