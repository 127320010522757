import React, { useState, useEffect } from 'react';
import { Link } from 'react-router';
import errorCover from "../images/errorCover.jpg";
import GameTooltip from './GameTooltip';

import './GameItem.scss';

function GameItem({ quest, isRightEdge }) {
  const {
    coverPortrait,
    cover,
    name,
    gallery,
    id,
    releaseDate,
    description,
    categories,
    createdAt,
    updatedAt
  } = quest;

  const [isHovered, setIsHovered] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  // Filter the gallery array to include only items with type 'image'
  const imageGallery = gallery.filter(item => item.type === 'image');

  const formatDate = (date) => {
    if (!date) return ''
    const dateObj = new Date(date)
    const year = dateObj.getFullYear()
    const month = String(dateObj.getMonth() + 1).padStart(2, '0')
    const day = String(dateObj.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const getDisplayDate = () => {
    const tryFormat = (dateValue) => {
      const dateObj = new Date(dateValue);
      return isNaN(dateObj.getTime()) ? null : formatDate(dateValue);
    };
    let dateString = releaseDate || createdAt || updatedAt
    return tryFormat(dateString) || 'unknown';
  };
  const releaseDateDisplay = getDisplayDate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % imageGallery.length);
    }, 2000);
    return () => clearInterval(interval);
  }, [imageGallery.length]);

  return (
    <div
      className={`game-cover ${isHovered ? 'hovered' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Link to={`/game/${id}`}>
        <img
          src={coverPortrait || cover}
          alt="Game Cover"
          className="game-cover"
          onError={(e) => {
            e.target.src = errorCover;
          }}
        />
      </Link>
      {isHovered && (
        <GameTooltip
          isRightEdge={isRightEdge}
          coverPortrait={coverPortrait}
          cover={cover}
          errorCover={errorCover}
          name={name}
          imageGallery={imageGallery}
          currentImageIndex={currentImageIndex}
          description={description}
          categories={categories}
          releaseDate={releaseDateDisplay}
        />
      )}
    </div>
  );
}

export default GameItem;
