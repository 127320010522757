import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';

import DevConsole from '../dev-console/DevConsole';
import { konami } from '../../../../utils/util';

import './AdminTabs.scss';

function AdminTabs(props) {
  const [, setKeypresses] = useState([]);
  const [modal, setModal] = useState(false);
  const { pagination } = props.admin.rollOutsAll;
  const { role } = props.auth.user;
  const isFinance = role === 'finance';

  const onKeyPress = e => {
    setKeypresses(prevState => {
      const newPresses = [...prevState, e.key];
      if (newPresses.length > konami.length) {
        newPresses.shift();
      }

      if (newPresses.length === konami.length) {
        const code = newPresses.reduce((acc, curr, index) => {
          if (!acc) { return acc; }
          return curr === konami[index];
        }, true);

        if (code) {
          setModal(true);
          document.removeEventListener('keydown', onKeyPress, false);
        }
      }

      return newPresses;
    });
  };

  useEffect(() => {
    queryWithdrawTip();
    document.addEventListener('keydown', onKeyPress, false);
    return () => document.removeEventListener('keydown', onKeyPress, false);
  }, []);

 const queryWithdrawTip = () =>{
   const { token } = props.auth;
   if (!props.admin.rollOutsAll.isLoading) {
     props.getRollOutAll(0,',', '{"tip":true,"approveStatus":"0"}', false, token,0);
   }
  };

  return (
    <section className="ADMIN_tabs">
      {modal && (
        <DevConsole
          modal={modal}
          toggleModal={val => setModal(val)}
          {...props}
        />
      )}
      <ul>
        <li>
          <Link
            to="/admin/command-center"
            activeClassName="active"
            href="/admin/command-center">
            Command Center
          </Link>
        </li>
        <li>
          <Link
            to="/admin/users"
            activeClassName="active"
            href="/admin/users">
            Users
          </Link>
        </li>
        <li>
          <Link
            to="/admin/games"
            activeClassName="active"
            href="/admin/games">
            Games
          </Link>
        </li>
        <li>
          <Link
            to="/admin/payments"
            activeClassName="active"
            href="/admin/payments">
            Payments{isFinance&&(pagination?'('+pagination.totalUsers+')':'' )}
          </Link>
        </li>
        <li>
          <Link
            to="/admin/insights"
            activeClassName="active"
            href="/admin/insights">
            Insights
          </Link>
        </li>
        <li>
          <Link
            to="/admin/contacts"
            activeClassName="active"
            href="/admin/contacts">
            Contacts
          </Link>
        </li>
        {/* <li>
          <Link
            to="/admin/arbitration"
            activeClassName="active"
            href="/admin/arbitration">
            Arbitration
          </Link>
        </li> */}
      </ul>
    </section>
  )
}

export default AdminTabs;
