import React from 'react';
import GameItem from './GameItem';
import './GameList.scss';

class GameList extends React.Component {
  constructor(props) {
    super(props);
    const { games, current, total } = props;

    this.state = {
      // 初始化时保存传入的游戏数据
      gameList: games || [],
      currentPage: parseInt(current, 10) || 1,
      isLoadingMore: false,
      hasMore: (parseInt(current, 10) || 1) < parseInt(total, 10),
    };

    // 创建对底部加载指示器的引用
    this.loadMoreRef = React.createRef();
  }

  componentDidMount() {
    // 创建Intersection Observer来监测底部加载指示器
    this.observer = new IntersectionObserver(this.handleObserver, {
      root: null, // 使用viewport作为观察区域
      rootMargin: '0px',
      threshold: 0.1, // 当10%的元素可见时触发
    });

    // 开始监测底部加载指示器
    if (this.loadMoreRef.current) {
      this.observer.observe(this.loadMoreRef.current);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.games !== this.props.games && this.props.listStyle === 'past') {
      // 检查是否是初始加载还是分页加载
      if (this.props.current > 1) {
        // 分页加载 - 正确合并数据
        this.setState(prevState => ({
          gameList: [...prevState.gameList, ...this.props.games],
          hasMore: parseInt(this.props.current, 10) < parseInt(this.props.total, 10)
        }));
      } else {
        // 初始加载 - 替换数据
        this.setState({
          gameList: this.props.games,
          hasMore: parseInt(this.props.current, 10) < parseInt(this.props.total, 10)
        });
      }
    } else if (prevProps.games !== this.props.games) {
      // 非分页列表，直接替换
      this.setState({
        gameList: this.props.games,
        hasMore: false
      });
    }
  }

  componentWillUnmount() {
    // 组件卸载时，取消观察者
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  handleObserver = (entries) => {
    const entry = entries[0];
    // 当加载指示器可见且有更多数据可加载时，加载更多
    if (entry.isIntersecting && this.state.hasMore && !this.state.isLoadingMore && this.props.listStyle === 'past') {
      this.loadMore();
    }
  };

  loadMore = () => {
    const nextPage = parseInt(this.state.currentPage, 10) + 1;
    const keyword = (this.props.keyword.trim() === "") ? "" : this.props.keyword;
    // 修正参数顺序，与 handleLoadMore 期望的一致
    this.props.getUserGameList(nextPage, 'all', keyword);
    this.setState({ currentPage: nextPage });
  };

  render() {
    const { user, listStyle } = this.props;
    const { gameList, hasMore } = this.state;

    return (
      <div className="GQL__Wrapper">
        <div className="game-quest-list">
          <div className="game-grid">
            {gameList.map((game, index) => (
              <GameItem
                key={`${game.id}-${index}`}
                quest={game}
                user={user}
                isRightEdge={((index + 1) % 6 === 0)}
              />
            ))}
          </div>
          {listStyle === 'past' && hasMore && (
            <div
              ref={this.loadMoreRef}
              className="load-more-indicator"
              style={{ height: '20px' }} // 减小高度，只作为observer触发点
            >

            </div>
          )}
        </div>
      </div>
    );
  }
}

export default GameList;