/* eslint-disable import/prefer-default-export */
import { createTheme } from '@material-ui/core/styles';


export const noiz = createTheme({

  palette: {
    type: 'dark',
    primary: { main: '#47b8d4' },
    secondary: {
      main: '#FFFFFF',
      light: '#47b8d4',
    },
    error: {
      main: '#EF3937',
    },

    white: {
      main: '#FFFFFF',
    },
    tonalOffset: 0.2,
  },
  typography: {
    button: {
      color: '#FFFFFF',
    },
  },
  root: {
    colorPrimary: '#FFFFFF',
  },

  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule

      // backgroundColor: 'transparent',
      text: {
        // Some CSS


      },
    },
  },

});

