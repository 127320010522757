import React, { useEffect } from 'react';
import { Link } from 'react-router';

import './ConfigurationTabs.scss';

function ConfigurationTabs(props) {

  useEffect(() => {
  }, []);

  return (
    <section className="Configuration_tabs">
      <ul>
        <li>
          <Link
            to="/admin/ConfigurationCenter"
            activeClassName="active"
            href="/admin/ConfigurationCenter">
            Regional Settings
          </Link>
        </li>
        <li>
          <Link
            to="/admin/PermissionCenter"
            activeClassName="active"
            href="/admin/PermissionCenter">
            Permission Settings
          </Link>
        </li>
        <li>
          <Link
            to="/admin/AdminCenter"
            activeClassName="active"
            href="/admin/AdminCenter">
            Member Management
          </Link>
        </li>
      </ul>
    </section>
  )
}

export default ConfigurationTabs;
