import React, { useEffect, useState } from "react";
import { Link } from "react-router";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import GameCard from "./GameCard";
import GameList from "./GameList";
import CDKeyList from "./CDKeyList";
import { isAuthenticated } from "../../utils/authChecker";
import searchIcon from "../../images/search.png"
import Loading from '../loading/Loading';

import "./QuestIndexNew.scss";

function QuestIndexNew(props) {
  const {
    auth: { token, user },
    config: {
      userGames: { featured: userFeaturedGames, past: userAllGames, progress: userProgressGames, isLoading: isLoadingUserGames, current: page_current, total: page_total }
    }
  } = props;

  const [activeTab, setActiveTab] = useState("all");
  const [searchKeyword, setSearchKeyword] = useState("");
  // 添加新的状态用于跟踪是否是标签切换导致的加载
  const [isTabChange, setIsTabChange] = useState(false);
  // 添加状态用于跟踪是否是分页加载
  const [isPaging, setIsPaging] = useState(false);

  useEffect(() => {
    if (isAuthenticated()) runPostAuthChecks(props);
  }, []);

  // 修改监控加载状态的useEffect
  useEffect(() => {
    if (isLoadingUserGames) {
      // 正在加载时，不做任何状态改变
    } else {
      if (isTabChange) {
        setIsTabChange(false);
      }
      console.log('isPaging', isPaging);
      // if (isPaging) {
      //   setIsPaging(false); // 重置分页加载标志
      // }
    }
  }, [isLoadingUserGames]);

  // 修改tab切换逻辑，标记这是由tab切换触发的加载
  const handleTabChange = (tab) => {
    if (tab !== activeTab) {
      setIsTabChange(true); // 设置标签切换标志
      setActiveTab(tab);
    }
  };

  // 根据 activeTab 变化调用 getUserGameList
  useEffect(() => {
    const keyword = searchKeyword;
    if (activeTab === "all") {
      props.getUserGameList('all', 1, token, keyword)
    } else if (activeTab === "live") {
      props.getUserGameList("live", 1, token, keyword);
    } else if (activeTab === "cdkey") {
      // 添加这个条件分支处理 cdkey 类型
      props.getUserGameList("cdkey", 1, token, keyword);
    }
  }, [activeTab, token]);

  // 当在搜索输入框按下 Enter 键时触发搜索
  const handleSearchKeyDown = (event) => {
    if (event.key === "Enter") {
      const keyword = searchKeyword;
      setIsTabChange(true); // 搜索也被视为重新加载，应显示加载动画

      // 根据当前激活的 Tab 调用对应的接口
      if (activeTab === "all") {
        props.getUserGameList("all", 1, token, keyword);
      } else if (activeTab === "live") {
        props.getUserGameList("live", 1, token, keyword);
      } else if (activeTab === "cdkey") {
        props.getUserGameList("cdkey", 1, token, keyword);
      }
    }
  };

  // 创建自定义加载方法，供GameList组件使用
  const handleLoadMore = (page, type, keyword) => {
    // 设置分页加载标志
    setIsPaging(true);
    // 调用API并传递isPaging=true
    props.getUserGameList(type, page, token, keyword, true);
    // 重置分页标志
    setTimeout(() => setIsPaging(false), 500);
  };

  return (
    <div className="quest-index">
      {/* Add Loading component at the top */}
      <Loading isLoading={isLoadingUserGames} />

      <div className="featured-games">
        <Carousel // This carousel has issues w/ showing pixels to the left/right that shouldn't be shown
          autoPlay
          infiniteLoop
          interval={10000}
          showArrows={true}
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
          width='100%'>
          {/*
            Brand Games are currently filtered out on the backend
            Search BRAND FILTER to find the line of code that does this
          */}
          {userFeaturedGames && userFeaturedGames.map(game => <GameCard data={game} key={game.id} />)}
        </Carousel>
      </div>

      <div className='tab-bar-new'>
        <div className='left'>
          <span
            className={`tab-span ${activeTab === "all" ? "active" : ""}`}
            onClick={() => handleTabChange("all")}>
            ALL
          </span>
          <span
            className={`tab-span ${activeTab === "live" ? "active" : ""}`}
            onClick={() => handleTabChange("live")}>
            LIVE STREAMING
          </span>
          {/* <span
            className={`tab-span ${activeTab === "second" ? "active" : ""}`}
            onClick={() => setActiveTab("second")}>
            SECONDARY CREATION
          </span> */}
          <span
            className={`tab-span ${activeTab === "cdkey" ? "active" : ""}`}
            onClick={() => handleTabChange("cdkey")}>
            BUY CD-KEY
          </span>
        </div>
        <div className="search-bar">
          <input
            type="text"
            className="search-input"
            placeholder="SEARCH FOR GAMES"
            value={searchKeyword}
            onChange={e => setSearchKeyword(e.target.value)}
            onKeyDown={handleSearchKeyDown}
          />
          <img src={searchIcon} alt="Search" className="search-icon" />
        </div>
      </div>

      <div className='tab-line'></div>

      {/* CD-KEY标签内容 - 切换时显示LoadingSpinner */}
      {activeTab === "cdkey" && (
        <div className="cdkey_list_content">
          {/* 在标签切换加载中时显示LoadingSpinner */}
          {(isTabChange && isLoadingUserGames) ? (
            <div style={{ height: 300, position: "relative" }}>
              <LoadingSpinner />
            </div>
          ) : (
            <CDKeyList
              {...props}
              searchKeyword={searchKeyword}
            />
          )}
        </div>
      )}

      {/* 其他标签内容 - 切换时显示LoadingSpinner */}
      {activeTab !== "cdkey" && (
        <div className="quest_list_content">
          {/* 在标签切换加载中时整体显示LoadingSpinner */}
          {(isTabChange && isLoadingUserGames) ? (
            <div style={{ height: 500, position: "relative" }}>
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <div className="in_progress">
                <div className="content_dots"></div>
                <div className="content_text">The GAMES IN PROGRESS</div>
                {(!userProgressGames || userProgressGames.length <= 0) ? (
                  <div className='no-quests'>
                    There are currently no active quests, please watch your email for announcements and join
                    us on{" "}
                    <Link rel='noopener noreferrer' target='_blank' to='https://discord.gg/Hwb2Rrg'>
                      Discord
                    </Link>
                    .
                  </div>
                ) : (
                  <GameList
                    user={user}
                    games={userProgressGames}
                    keyword={searchKeyword}
                  />
                )}
              </div>

              <div className="past">
                <div className="content_dots"></div>
                <div className="content_text">GAMES OF The Past</div>
                {(!userAllGames || userAllGames.length <= 0) ? (
                  <div className='no-quests'>
                    There are currently no active quests, please watch your email for announcements and join
                    us on{" "}
                    <Link rel='noopener noreferrer' target='_blank' to='https://discord.gg/Hwb2Rrg'>
                      Discord
                    </Link>
                    .
                  </div>
                ) : (
                  <GameList
                    user={user}
                    games={userAllGames}
                    listStyle="past"
                    getUserGameList={handleLoadMore}
                    token={token}
                    total={page_total}
                    current={page_current}
                    keyword={searchKeyword}
                  />
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

const LoadingSpinner = () => (
  <div className='loading-spinner'>
    <i className='fa fa-spinner fa-spin fa-3x fa-fw' />
  </div>
);

const runPostAuthChecks = (props) => {
  const {
    auth: { token, user },
    getInitialInfo,
    getOptimizedAllQuests,
    getQuest,
    getUserReferralsActivity,
    getUserTodayActivity,
    router,
  } = props;

  if (!(user.firstName && user.lastName && user.country)) {
    router.replace("/complete-registration");
  }

  getInitialInfo(token);
  getUserTodayActivity(token);
  getUserReferralsActivity(token);
  getOptimizedAllQuests("all", 1, token);

  if (localStorage.getItem("justLoggedIn")) {
    localStorage.removeItem("justLoggedIn");
  }

  if (localStorage.getItem("showWelcome")) {
    router.replace("/welcome");
    localStorage.removeItem("showWelcome");
    return;
  }

  if (localStorage.getItem("redirectAfterAuth")) {
    router.replace(localStorage.getItem("redirectAfterAuth"));
    localStorage.removeItem("redirectAfterAuth");
    localStorage.setItem("justLoggedIn", true);
    return;
  }

  if (localStorage.getItem("questId")) {
    getQuest(localStorage.getItem("questId"), true, token);
    localStorage.removeItem("questId");
  }
};

export default QuestIndexNew;
