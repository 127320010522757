import React from "react";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import Axios from "axios";

import defaultAvatar from "../../images/avatars/defaultSpaceman.png";
import ConnectPlatforms from "../connect-platforms/ConnectPlatforms";
// import Payment from "../../global/components/Payment/Payment";
import ProfileInfoForm from "../forms/ProfileInfoForm";

import { simpleNotification } from "../../utils/notifications";
// import { getHeadersFromS3Url } from "../../utils/functions";

import "./Profile.scss";
import { Link } from "react-router";
import Orders from "./Orders";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      dropzoneActive: false,
      activeModal: 0,
      isVerificationCode: false,
    };
    this.days1 = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
    ];
    this.days2 = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
    ];
    this.days3 = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
    ];
    this.days4 = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
    ];
  }

  componentDidMount() {
    const { user } = this.props.auth;
    // const { token } = this.props.auth;
    const userInfo = {
      firstName: user.firstName ? user.firstName : "",
      lastName: user.lastName ? user.lastName : "",
      username: user.displayName || "",
      displayName: user.displayName || "",
      email: user.email ? user.email : "",
      paymentEmail: user.paymentEmail ? user.paymentEmail : "",
      birthDate: user.birthDate ? user.birthDate : "",
      country: user.country ? user.country : "United States",
      password: "••••••••",
      gender: user.gender ? user.gender : "",
      twitter: user.twitter ? user.twitter : "",
      discord: user.discord ? user.discord : "",
      avatar: user.avatar ? user.avatar : "",
      texType: user.taxType ? user.taxType : "",
      year: "",
    };
    const month = userInfo.birthDate ? userInfo.birthDate.substring(5, 7) : "01";
    userInfo.month = this.formatMonth(month);
    this.props.assignProfileInfoForm(userInfo);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Updating things when twitch finish the request loading.
    if (this.props.auth.isLoading === true && nextProps.auth.isLoading === false) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (!nextProps.auth.errors.hasError) {
        simpleNotification({
          level: "success",
          title: "Account Info Updated.",
          message: "The account info was successfully updated!",
        });
      }
      if (nextProps.auth.errors.hasError) {
        if (nextProps.auth.errors.code === "E_OLDPASSWORD_DOES_NOT_MATCH") {
          simpleNotification({
            level: "error",
            title: "Warning, Old password incorrect.",
            message: "The account info wasn't updated!",
          });
        } else {
          simpleNotification({
            level: "error",
            title: "Warning",
            message: "Error has occurred updating account info. The account info wasn't updated!",
          });
        }
      }

    }
    if (
      this.props.auth.youtube.isDisconnecting === true &&
      nextProps.auth.youtube.isDisconnecting === false
    ) {
      if (nextProps.auth.youtube.errors.hasError) {
        simpleNotification({
          level: "error",
          title: "Youtube disconnect error",
          message: nextProps.auth.youtube.errors.message,
          autoDismiss: 0,
          position: "tl",
        });
      } else {
        simpleNotification({
          level: "warning",
          title: "Disconnected",
          message: "Youtube account disconnected",
          autoDismiss: 0,
          position: "tl",
        });
      }
    }
    /*if (this.props.auth.isSending === true && nextProps.auth.isSending === false) {
        if (!nextProps.auth.errors.hasError) {
          simpleNotification({
            level: "success",
            title: "Email sending success.",
            message: "Please check your email for more information!",
          });
        }
        if (nextProps.auth.errors.hasError) {
          simpleNotification({
            level: "error",
            title: "Email sending failed",
            message: "Please confirm if the email you provided is correct!",
          });

        }

    }*/
  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if ((prevProps.auth.isLoading === true && this.props.auth.isLoading === false)) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (!this.props.auth.errors.hasError) {
        simpleNotification({
          level: "success",
          title: "Account Info Updated.",
          message: "The account info was successfully updated!",
        });
        const { user } = this.props.auth;
        const userInfo = {
          firstName: user.firstName ? user.firstName : "",
          lastName: user.lastName ? user.lastName : "",
          username: user.displayName || "",
          displayName: user.displayName || "",
          email: user.email ? user.email : "",
          paymentEmail: user.paymentEmail ? user.paymentEmail : "",
          birthDate: user.birthDate ? user.birthDate : "",
          country: user.country ? user.country : "United States",
          password: "••••••••",
          gender: user.gender ? user.gender : "",
          twitter: user.twitter ? user.twitter : "",
          discord: user.discord ? user.discord : "",
          avatar: user.avatar ? user.avatar : "",
          taxType: user.taxType ? user.taxType : "",
          taxNumber: user.taxNumber ? user.taxNumber : "",
        };
        const month = userInfo.birthDate ? userInfo.birthDate.substring(5, 7) : "01";
        userInfo.month = this.formatMonth(month);
        this.props.assignProfileInfoForm(userInfo);
      }
    }
  }

  onSubmit = userInfo => {
    const { token } = this.props.auth;
    const info = {
      ...userInfo,
    };
    this.setState({ isVerificationCode: false });
    this.props.updateProfileInfo(info, token);
  };

  onDragEnter() {
    this.setState({ dropzoneActive: true });
  }

  onDragLeave() {
    this.setState({ dropzoneActive: false });
  }

  onDrop(files, e) {
    const final = files && files.length ? files[0] : e[0];
    this.setState({ dropzoneActive: false });
    this.uploadToS3(final);
  }

  formatMonth = month => {
    switch (month) {
      case "01":
        return "January";
      case "02":
        return "February";
      case "03":
        return "March";
      case "04":
        return "April";
      case "05":
        return "May";
      case "06":
        return "June";
      case "07":
        return "July";
      case "08":
        return "August";
      case "09":
        return "September";
      case "10":
        return "October";
      case "11":
        return "November";
      case "12":
        return "December";
      default:
        return "Month";
    }
  };

  addZeroes = param => {
    const num = param.toString();
    let value = Number(num);
    const res = num.split(".");
    if (res.length === 1 || res[1].length < 3) {
      value = value.toFixed(2);
    }
    return value;
  };

  padWithZeroes = (number, length) => {
    let myString = number.toString();
    while (myString.length < length) {
      myString = `0${myString}`;
    }
    return myString;
  };

  changeStep = nStep => {
    this.setState({
      activeModal: nStep,
    });
  };

  handleSelectedFile = event => {
    this.uploadToS3(event.target.files[0]);
  };

  handleProgress = event => {
    console.log("profile", event);
  };

  handleLoad = (event, url) => {
    if (event.target.status === 200) {
      const { responseURL } = event.target;
      const toWrite = responseURL.split('?')[0];
      const { token, user } = this.props.auth;

      this.props.updateProfileInfo({ ...user, avatar: toWrite }, token);
    } else {
      // create somethign went wrong simple notification
      simpleNotification({
        level: "error",
        title: "Error",
        message: "Error uploading image",
        autoDismiss: 5,
        position: "tl",
      });
    }

    this.setState({
      activeModal: 0,
    });
  };

  disconnectYoutube = () => {
    const { token, user } = this.props.auth;
    this.props.disconnectYoutube(token, user);
  };

  uploadToS3 = file => {
    const { name, type } = file;
    const { token } = this.props.auth;

    const ext = name.split(".").pop();
    Axios.post(`/api/v1/user/request-update-avatar-url`, { type, format: ext }, {
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then(res => {
        const { preSignedUrl } = res.data.data;
        // console.log('presigned', preSignedUrl)
        // const headerObject = getHeadersFromS3Url(preSignedUrl);
        // console.log(headerObject, 'headerObject');

        const xhr = new XMLHttpRequest();
        xhr.open('PUT', preSignedUrl);
        xhr.setRequestHeader('Content-Type', file.type);
        xhr.addEventListener('progress', this.handleProgress);
        xhr.addEventListener('load', this.handleLoad);
        xhr.send(file);
      })
      .catch(err => {
        console.log(err, "err");
      });
  };

  openActiveModal = () => {
    this.setState({ activeModal: 1 });
  };

  onSendVCode = userInfo => {
    const { token } = this.props.auth;
    this.props.sendVerificationCode(token, 'profileInfo', true);
    this.setState({ isVerificationCode: true });
  };

  render() {
    const { user } = this.props.auth;
    const { activeModal } = this.state;
    const { form } = this.props;
    //const isUser = user.role === 'user';
    //const {switchType} = this.props.location.state || {};
    //const switchType = window.location.pathname.includes("my-account-detail")
    let switchType = 1;
    const pathName = window.location.pathname;
    if (pathName.includes("my-account-detail")) {
      switchType = 1;
    } else if (pathName.includes("my-account-cdk")) {
      switchType = 2;
    } else {
      switchType = 3;
    }

    // const ConnectPayment = () => (
    //   <div className='Profile__connect'>
    //     <div className='Profile__connect__title'>Connect Payment Account</div>
    //     <div className='Profile__connect__message'>
    //       We use Stripe to make payments seamless.  Connect your bank account and get paid directly!
    //       <br /> Need more information? Please contact <a href='mailto:support@noiz.gg'>support@noiz.gg</a>.
    //     </div>
    //     <div className='Profile__connect__content'>
    //       <Payment streamer={true} />
    //     </div>
    //   </div>
    // );

    let days = [];
    if (
      form &&
      form.profileInfoForm &&
      form.profileInfoForm.values &&
      form.profileInfoForm.values.month
    ) {
      const y = form.profileInfoForm.values.year;
      switch (form.profileInfoForm.values.month) {
        case "January":
          days = this.days1;
          break;
        case "February":
          if (0 === y % 4 && (0 !== y % 100 || 0 === y % 400)) {
            days = this.days3;
          } else {
            days = this.days4;
          }
          break;
        case "March":
          days = this.days1;
          break;
        case "April":
          days = this.days2;
          break;
        case "May":
          days = this.days1;
          break;
        case "June":
          days = this.days2;
          break;
        case "July":
          days = this.days1;
          break;
        case "August":
          days = this.days1;
          break;
        case "September":
          days = this.days2;
          break;
        case "October":
          days = this.days1;
          break;
        case "November":
          days = this.days2;
          break;
        default:
          days = this.days1;
          break;
      }
    }

    return (
      <section className='Profile'>
        <section className='Profile_list'>
          <section className='GameList__wrapper'>
            {/* {isUser&&(*/}
            <section>
              <ul className='GameList__items'>
                <li className={classNames("GameList__GameItem", {
                  active: switchType === 1,
                })}>
                  <Link
                    to={{ pathname: `/my-account-detail/wallet` }}
                    activeClassName='active'
                    href={`/my-account-detail/wallet`}>
                    Account details
                  </Link>
                </li>
              </ul>
              <ul className='GameList__items'>
                <li className={classNames("GameList__GameItem", {
                  active: switchType === 2,
                })}>
                  <Link
                    to={{ pathname: `/my-account-cdk` }}
                    activeClassName='active'
                    href={`/my-account-cdk`}>
                    My Orders
                  </Link>
                </li>
              </ul>
              <ul className='GameList__items'>
                <li className={classNames("GameList__GameItem", {
                  active: switchType === 3,
                })}>
                  <Link
                    to={{ pathname: `/my-account` }}
                    activeClassName='active'
                    href={`/my-account`}>
                    My profile
                  </Link>
                </li>
              </ul>
            </section>
            {/*)}*/}
          </section>
          <section className='Profile__container'>
            <div className='Profile__top'> My Account</div>
            {switchType === 3 && (<div className='Profile__info'>
              <div className='Profile__info__content'>
                <div className='Profile__info__left'>
                  <div className='UserInfo__profile--container'>
                    <img
                      className='UserInfo__profile--container-avatar'
                      src={user?.avatar || defaultAvatar}
                      alt={user?.displayName || user?.username}
                      onClick={this.openActiveModal}
                    />
                  </div>
                  <div className='UserInfo__profile__button' onClick={this.openActiveModal}>
                    Edit <i className="fa fa-pencil add-hover-glow ml-4" />
                  </div>
                </div>
                <div className='Profile__info__right'>
                  <ProfileInfoForm
                    profileInfoForm={this.props.form.profileInfoForm}
                    enableReinitialize
                    initialValues={this.props.auth.user}
                    onSubmit={this.onSubmit.bind(this)}
                    //onSendVCode={this.onSendVCode.bind(this)}
                    daysValues={days}
                    auth={this.props.auth}
                    sendVerificationCode={this.props.sendVerificationCode}
                    validateVerificationCode={this.props.validateVerificationCode}
                  />
                </div>
              </div>
            </div>)}

            {switchType === 1 && (<section className='CommandCenter__child'>
              {/*{isUser && (*/}
              <section className='CommandCenter__navigation'>
                {/* <Link
                    onlyActiveOnIndex
                    to={`/admin/command-center/${gameId}`}
                    activeClassName="active"
                    href={`/admin/command-center/${gameId}`}
                  >
                    Game Summary
                  </Link> */}

                <Link
                  to={`/my-account-detail/wallet`}
                  activeClassName='active'
                  href={`/my-account-detail/wallet`}>
                  Wallet
                </Link>

                <Link
                  to={`/my-account-detail/withdraw`}
                  activeClassName='active'
                  href={`/my-account-detail/withdraw`}>
                  Withdraw balance
                </Link>
                <Link
                  className={classNames({ active: window.location.pathname.includes("quests") })}
                  to={`/my-account-detail/transaction-record`}
                  activeClassName='active'
                  href={`/my-account-detail/transaction-record`}>
                  Transaction History
                </Link>
              </section>
              {/*)}*/}
              {this.props.children && this.props.children.props.children
                ? React.cloneElement(this.props.children.props.children, this.props)
                : "Select a Game in the left bar to show its report"}
            </section>)}

            {switchType === 2 && (<section className='CommandCenter__child'>
              {/*{isUser && (*/}
              <div className='Profile__info__right'>
                <Orders {...this.props} />
              </div>
              {/*)}*/}

            </section>)}

            {/* <ConnectPayment /> */}

            <div  className="Profile_connect_container">
              <div 
              className='Profile__connect'
              style={{
                backgroundColor: switchType === 3 ? "" : "#2C2733",
              }}
              >
                <div className='title_wrap'><span className='Profile__connect__title'>Connect Account</span></div>
                <div className='Profile__connect__message'>
                  Connect or disconnect your accounts below. If you want to change the connected account
                  information, please contact <a href='mailto:support@noiz.gg'>support@noiz.gg</a>.
                </div>
                <ConnectPlatforms
                  config={this.props.config}
                  auth={this.props.auth}
                  disconnectTwitch={this.props.disconnectTwitch}
                  disconnectYoutube={this.props.disconnectYoutube}
                />
              </div>
            </div>

          </section>
        </section>
        <section className='ProfileUpdateImage'>
          <section className='ModalMaster'>
            <section
              className={classNames("Modal Modal__updateavatar Modal__updateavatar__one", {
                active: activeModal === 1,
              })}>
              <div
                onClick={this.changeStep.bind(this, 0)}
                className='Modal__layer'
                role='button'
                aria-hidden
              />
              <section className='Modal__wrapper'>
                {this.props.user.preSignedUrl.isLoading && (
                  <i className='fa fa-spinner fa-spin fa-3x fa-fw' />
                )}
                {!this.props.user.preSignedUrl.isLoading && (
                  <React.Fragment>
                    <div
                      className={classNames("Modal__updateavatar__border", {
                        active: this.state.dropzoneActive,
                      })}
                    />
                    <div
                      className='Modal__close-btn'
                      style={{ display: this.state.dropzoneActive ? "none" : "block" }}
                      onClick={this.changeStep.bind(this, 0)}
                      role='button'
                      aria-hidden
                    />
                    <Dropzone
                      ref={node => {
                        this.dropzoneRef = node;
                      }}
                      disableClick={this.state.disableClick}
                      className='Modal__updateavatar__content'
                      onDrop={(e, event) => this.onDrop(event, e)}
                      onDragEnter={this.onDragEnter.bind(this)}
                      onDragLeave={this.onDragLeave.bind(this)}
                      disablePreview={false}
                      multiple={false}
                      accept={[".jpg", ".png"]}
                      inputProps={{ id: "file" }}>
                      <div className='Modal__updateavatar__image' />
                      <div className='Modal__updateavatar__title'>
                        Drag and drop your avatar here
                      </div>
                      <div className='Modal__updateavatar__or'>Or</div>
                      <div className='Modal__updateavatar__action'>
                        <input
                          type='file'
                          name='file'
                          id='file'
                          className='inputFile'
                          onChange={this.handleSelectedFile}
                        />
                        <button className='cta__button'>Choose a file</button>
                      </div>
                    </Dropzone>
                  </React.Fragment>
                )}
              </section>
            </section>
            <section
              className={classNames("Modal Modal__updateavatar Modal__updateavatar__two", {
                active: activeModal === 2,
              })}>
              <div
                onClick={this.changeStep.bind(this, 0)}
                className='Modal__layer'
                role='button'
                aria-hidden
              />
              <section className='Modal__wrapper'>
                <div className='Modal__updateavatar__border' />
                <div
                  onClick={this.changeStep.bind(this, 0)}
                  className='Modal__close-btn'
                  role='button'
                  aria-hidden
                />
                <section className='Modal__updateavatar__content'>
                  <div className='Modal__updateavatar__image' />
                  <div className='Modal__updateavatar__title'>Uploading your avatar</div>
                  <div className='Modal__updateavatar__loading'>
                    <div className='Modal__updateavatar__loading__progress' />
                    <div className='Modal__updateavatar__loading__percentage'>42%</div>
                    <div className='Modal__updateavatar__loading__fileinfo'>
                      <div className='Modal__updateavatar__loading__top'>filename.jpg</div>
                      <div className='Modal__updateavatar__loading__bottom'>320kb</div>
                    </div>
                  </div>
                </section>
              </section>
            </section>
            <section
              className={classNames("Modal Modal__updateavatar Modal__updateavatar__three", {
                active: activeModal === 3,
              })}>
              <div
                onClick={this.changeStep.bind(this, 0)}
                className='Modal__layer'
                role='button'
                aria-hidden
              />
              <section className='Modal__wrapper'>
                <div
                  onClick={this.changeStep.bind(this, 0)}
                  className='Modal__close-btn'
                  role='button'
                  aria-hidden
                />
                <section className='Modal__updateavatar__content'>
                  <section className='Modal__updateavatar__avatar'>
                    <div className='Modal__updateavatar__avatar--container' aria-hidden>
                      <figure
                        className='Modal__updateavatar__avatar--container-avatar'
                        style={{ backgroundImage: `url(${user.avatar})` }}
                      />
                    </div>
                  </section>
                  <div className='Modal__updateavatar__title2'>Upload completed</div>
                  <div
                    onClick={this.changeStep.bind(this, 1)}
                    className='Modal__updateavatar__button'
                    aria-hidden>
                    Change Image
                  </div>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

Profile.propTypes = {
  // router: PropTypes.shape({
  //  replace: PropTypes.func.isRequired,
  //  location: PropTypes.shape({
  //    pathname: PropTypes.string.isRequired,
  //  }).isRequired,
  // }).isRequired,
  // auth: PropTypes.shape({
  //   token: PropTypes.string.isRequired,
  //   user: PropTypes.shape({
  //
  //   }).isRequired,
  // }).isRequired,
  // user: PropTypes.shape({
  //   currentAgreement: PropTypes.shape({
  //     data: PropTypes.shape({
  //
  //     }).isRequired,
  //     isLoading: PropTypes.bool.isRequired,
  //     hasError: PropTypes.bool.isRequired,
  //     code: PropTypes.string.isRequired,
  //   }).isRequired,
  //   currentScheduledBroadcast: PropTypes.shape({
  //     data: PropTypes.shape({
  //
  //     }).isRequired,
  //     isLoading: PropTypes.bool.isRequired,
  //     hasError: PropTypes.bool.isRequired,
  //     code: PropTypes.string.isRequired,
  //   }).isRequired,
  // }).isRequired,
  // logout: PropTypes.func.isRequired,
  // children: PropTypes.element.isRequired,
};

export default Profile;
