import React from 'react';
import './UserDailyRankingData.scss';
import defaultImage from '../../images/avatars/spaceman5.png'

const UserDailyRankingData = ({ current }) => {
    // twitchData 例如：{ totalWatchTime: '30%', streamRevenue: '40%', effectiveStream: '50%' }
    // 处理排行榜项点击事件
    const handleRankingItemClick = (twitchUsername, item) => {
        console.log('点击了排行榜项目', item);
        console.log('Twitch用户名:', twitchUsername);
        
        if (twitchUsername) {
            console.log('正在打开Twitch页面:', `https://www.twitch.tv/${twitchUsername}`);
            // 使用try-catch捕获可能的错误
            try {
                window.open(`https://www.twitch.tv/${twitchUsername}`, '_blank');
            } catch (error) {
                console.error('打开Twitch页面时出错:', error);
                
                // 备用方法：创建并触发一个链接
                const link = document.createElement('a');
                link.href = `https://www.twitch.tv/${twitchUsername}`;
                link.target = '_blank';
                link.rel = 'noopener noreferrer';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } else {
            console.warn('未找到有效的Twitch用户名，无法打开链接');
        }
    };

    console.log('当前排行榜数据:', current?.latestLeaderboard);

    return (
        <div className="twitch-rankings">
            <div className="rankings-title">
                Live Twitch Streamer Rankings
            </div>
            <div className="rankings-summary">

                <div className="summary-title">
                    <span className="label">Ranking based on composite score.</span>
                </div>
                <div className="summary-item">
                    <span className="label break-lines">The ranking will be calculated based on the comprehensive score of the live streaming viewing duration, live streaming revenue, and the number of live streaming days.</span>
                    {/* <span className="value">{totalWatchTime}</span> */}
                </div>

                <div className="summary-bottom">
                    <span className="label ">Rankings update daily</span>
                </div>
            </div>
            <div className="rankings-slider">
                {current && current.latestLeaderboard && current.latestLeaderboard.length > 0 ? (current.latestLeaderboard.map((item, index) => {
                    // 获取Twitch用户名
                    const twitchUsername = item?.user?.connectedAccounts?.twitch?.username;

                    return (
                        <div
                            key={index}
                            className="ranking-item"
                            onClick={(e) => {
                                e.stopPropagation(); // 阻止事件进一步传播
                                console.log('排行项被点击');
                                handleRankingItemClick(twitchUsername, item);
                            }}
                            style={{ 
                                cursor: 'pointer',
                                position: 'relative', // 确保z-index生效
                                zIndex: 5 // 提高z-index确保元素可点击
                             }}  // 添加鼠标悬停指针样式
                            title={`Visit ${twitchUsername}'s Twitch channel`}  // 添加提示文本
                        >
                            <div className="rank">#{item.rank}</div>
                            <img
                                src={item?.user?.connectedAccounts?.twitch?.profile_image_url}
                                alt={twitchUsername}
                                className="avatar"
                                onError={(e) => { e.target.onerror = null; e.target.src = defaultImage; }}
                            />
                            <div className="user-info">
                                <div className="name">
                                    {twitchUsername}
                                </div>
                                <div className="fans">
                                    {item?.user?.connectedAccounts?.twitch?.followers} Fans
                                </div>
                            </div>
                        </div>
                    );
                }))
                    : (
                        <div className="no-data">No data available</div>
                    )}
            </div>
        </div>
    );
};

export default UserDailyRankingData;