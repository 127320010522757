/* eslint-disable */
import React from 'react';
// import PropTypes from 'prop-types'
import './static.css';

class PrivacyPolicy extends React.Component {
    render() {
        return (
            <section className="PrivacyPolicy static">
                <h1 dir="ltr">
                    Noiz提现说明
                </h1>
                <h1 dir="ltr">
                    Noiz提现
                </h1>
                <br />
                <br />

                <p dir="ltr">
                    Welcome to Eminence Corporation (together with its subdomains, Content,
                    Marks, App and services, the “Site”) headquartered at 4972 Warner Ave Suite
                    201, Huntington Beach, CA 92649 and accessible online at<a href="http://www.eminence.gg" rel="nofollow noopener noreferrer"> www.eminence.gg</a> and    <a href="http://www.noiz.gg"> www.noiz.gg</a>. Please read the following
                    Privacy Policy carefully before installing and/or using the Eminence
                    Corporation web application (the "App") using this Site. This privacy
                    notice applies solely to information collected by this website. It will
                    notify you of the following:
                </p>

                <ol>
                    <li dir="ltr">
                        <p dir="ltr">
                            The personally identifiable information collected from you through
                            the website, how it is used and with whom it may be shared.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            The choices available to you regarding the use of your data.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            The security procedures in place to protect the misuse of your
                            information.
                        </p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">
                            How you can correct any inaccuracies in the information.
                        </p>
                    </li>
                </ol>
                <h2 dir="ltr">
                    INFORMATION, COLLECTION, USE AND SHARING
                </h2>
                <p dir="ltr">
                    We are the sole owners of the information collected on this Site. We only
                    have access to/collect information that you voluntarily give us via Twitch
                    or other direct contact from you.
                </p>

                <p dir="ltr">
                    We will use your information to respond to you, regarding the reason you
                    contacted us. We will not share your information with any third party
                    outside of our organization, other than as necessary to fulfill your
                    request, e.g. to ship an order.
                </p>

                <p dir="ltr">
                    Unless you ask us not to, we may contact you via email in the future to
                    tell you about new products or services, or changes to this privacy policy.
                </p>

                <p dir="ltr">
                    The Site collects two types of information from users of the Site: (1)
                    non-personal information collected, for example, as you interact with our
                    services, that cannot be used to specifically identify or contact an
                    individual (“Non-Personal Information”); and (2) personal information that
                    can be used to specifically identify or contact an individual (“Personal
                    Information”).
                </p>

                <h2 dir="ltr">
                    NON-PERSONAL INFORMATION
                </h2>
                <p dir="ltr">
                    When you use the Site, our web server and or applications, automatically
                    recognizes and collects certain Non-Personal Information about your visit,
                    such as your Internet Protocol (IP) Address and domain name.
                </p>

                <p dir="ltr">
                    The Site also collects clickstream data, which includes a trail of
                    electronic information left at each website you visit, game you play or
                    application that you use. The data tell us, for example, the type of
                    computer or device and browsing software you are using and the address of
                    the web Site, if applicable, from which you linked to the Site. We may use
                    this clickstream data to determine how much time visitors spend on each
                    page of our sites or applications, how visitors navigate throughout those
                    sites and application, and how we may tailor our services to better meet
                    the needs of visitors. Collection or use of clickstream data will be
                    anonymous, aggregated, and will not contain any Personal Information.
                </p>

                <p dir="ltr">
                    The Site also uses cookies, which are small pieces of data stored by your
                    web browser that contain data regarding your settings and preferences or
                    other data about your visits to our Web Sites or Games. We use this
                    information to, for example, recognize you and your computer or device when
                    you return to the Site, provide you with access to account information you
                    may have entered on the Site, compile statistics about traffic to the Site,
                    and for other purposes consistent with this Privacy Policy. We do not use
                    cookies to retrieve personal information about you from your computer.
                </p>

                <p dir="ltr">
                    The Site may also use Web beacon technology to help track usage and viewing
                    of the Site and certain Site applications and communications (such as
                    emails), and usage of and access to the Site services. Web beacons, also
                    sometimes called single pixel gifs or action tags, allow the Site to track
                    page and message viewing counts and patterns, which we use to focus and
                    tailor future content development and otherwise measure and improve the
                    performance of our services.
                </p>
                <p dir="ltr">
                    Any or all of the above-described activities may be performed on our behalf
                    by third party service providers of the Site.
                </p>
                <h2 dir="ltr">
                    PERSONAL INFORMATION
                </h2>
                <p dir="ltr">
                    When you use the Site, you may be asked to provide or otherwise choose to
                    provide Personal Information, which may include, but is not limited to your
                    name and e-mail address. We may also request information about your likes,
                    demographic information, your social networking preferences, and other
                    interests you have. We collect or request Personal Information for many
                    reasons and on different occasions during your visits to our Sites and
                    application. For example, you may provide certain Personal Information when
                    you sign up for the Site.
                </p>
                <p dir="ltr">
                    You may be able to send information about our services to your friends and
                    family members by clicking on a “Send-to-a-Friend” or similar button within
                    the application, or in an e-mail that we have sent you. In doing so, you
                    may provide to us the name and e-mail address of your friend or family
                    member. We will treat this information in accordance with this Privacy
                    Policy and applicable law.
                </p>
                <p dir="ltr">
                    The above are only examples of the types of Personal Information we may
                    collect. The actual types of Personal Information collected depend on the
                    specific product or service you request, the types of transactions you
                    conduct within the application and the other various activities in which
                    you participate.
                </p>
                <h2 dir="ltr">
                    LOCATION INFORMATION
                </h2>
                <p dir="ltr">
                    If you use location-enabled products or devices to access the Site, you may
                    be sending us location information, including information about the
                    real-time geographic location of your device. You may also choose to note
                    your location in your profile or otherwise voluntarily send us information
                    about your location.
                </p>
                <h2 dir="ltr">
                    INFORMATION OBTAINED FROM THIRD PARTIES
                </h2>
                <p dir="ltr">
                    We may supplement the information collected from you with information that
                    is received or otherwise obtained from third parties. For example, this
                    information may be received or obtained from our partners in sponsored
                    content sections or our affiliates with whom you have previously done
                    business. Such supplemental information could include, for example, user
                    contact information (like name, e-mail address and telephone number), user
                    preferences (like the types of products you buy or request information
                    about) and user demographic information (like zip code, age or income
                    level).
                </p>

                <p dir="ltr">
                    We receive information about consumers from third parties to enable
                    targeted advertising on the website(s) and application(s) that we support.
                    The collection and use of that data is governed by the privacy policy and
                    legal terms of the data collector and the website using the data; it is not
                    governed by the Site.
                </p>
                <h2 dir="ltr">
                    HOW DOES THE SITE USE MY INFORMATION?
                </h2>
                <p dir="ltr">
                    We will use information we collect from and about you to provide services
                    to you, process and respond to your requests, help you connect with other
                    users of our application, develop new features and offerings, improve our
                    service offerings and protect our rights and property as well as that of
                    third parties. We may also use your information to maintain our internal
                    record keeping, to show you a history of your activity, to provide you with
                    statistics about your use of the Site or to enhance your user experience.
                    We may analyze user behavior as a measure of interest in, and use of our
                    services, e-mails and other messaging campaigns both on an individual basis
                    and in the aggregate. We may match information collected from you through
                    different means or at different times, including both Personal Information
                    and Non-Personal Information, and use such information along with
                    supplemental information obtained from other sources.
                </p>
                <p dir="ltr">
                    In addition, we may use your information to send you notices (in the form
                    of e-mails, text messages, SMS communications, notifications, mailings, and
                    the like) as part of the services or our other offerings, or to inform you
                    about products, services, companies and events that we think might interest
                    you. You may opt out of receiving certain notices from us by following the
                    instructions in the Opt Out section below.
                </p>
                <h2 dir="ltr">
                    OPT OUT
                </h2>
                <p dir="ltr">
                    You may “opt out” or unsubscribe from our communications and
                    non-transactional related emails by following the unsubscribe instructions
                    in any email you receive from us. You may also update your opt-out
                    preferences by contacting the Site.
                </p>

                <p dir="ltr">
                    Please understand that if you opt out of receiving promotional
                    correspondence from us, we may still contact you in connection with your
                    relationship, activities, transactions and communications with us.
                </p>

                <p dir="ltr">
                    Please note that your choice to change your information or opt out may
                    affect the Site’s ability to provide you with the requested the Site
                    products or services.
                </p>
                <h2 dir="ltr">
                    WHAT INFORMATION DOES THE SITE SHARE WITH OTHERS?
                </h2>
                <p dir="ltr">
                    The Site may share your information (including both Personal Information
                    and Non-Personal Information) with third parties in certain circumstances,
                    including the following:
                </p>

                <p dir="ltr">
                    Public Forums: When you contribute to a public area or feature of the Site,
                    such as a chat room, bulletin board, list serve, blog, wiki or other
                    communication or community tool or area, the information that you submit or
                    post will be made available to the general public. For this reason, we
                    recommend that you do not submit any sensitive information, including your
                    full name, home address, phone number, other information that would enable
                    other users to locate you, or financial information on these areas.
                    Instead, make up a username that does not disclose your personal identity.
                    In addition to any information you choose to post using public areas or
                    features available on our Web Sites, we may display statistics and other
                    information about your participation to other users or the general public.
                </p>

                <p dir="ltr">
                    Third-Party Offers: We may provide you with an opportunity to receive
                    offers from other entities that have services, products and offers that we
                    think might be of interest to you. Unless you elect not to receive such
                    offers by following the instructions in the Opt Out section above, we may
                    from time to time provide your Personal Information, to these entities so
                    that they may send you these offers.
                </p>

                <p dir="ltr">
                    Service Providers: We may use third-party partners to help operate and
                    deliver our products and services, and may share your information with our
                    service providers and other third parties that provide products or services
                    for our business (such as web Site or database hosting companies, address
                    list hosting companies, e-mail service providers, analytics companies,
                    distribution companies, and fulfillment companies).
                </p>

                <p dir="ltr">
                    Non-Personal Information: We may disclose Non-Personal Information
                    regarding our users and user behavior as a measure of interest in, and use
                    of, our products and services and e-mails to third parties, including, for
                    example, aggregate data, such as overall patterns or demographic reports
                    that do not describe or identify any individual user.
                </p>

                <p dir="ltr">
                    Legal Disclosures: We may disclose user information to government
                    authorities, and to other third parties when compelled to do so by
                    government authorities, at our discretion, or otherwise as required or
                    permitted by law, including but not limited to in response to court orders
                    and subpoenas. We also may disclose user information when we have reason to
                    believe that someone is causing injury to or interference with our rights
                    or property, other users of the Site, or anyone else that could be harmed
                    by such activities.
                </p>

                <p dir="ltr">
                    Business Transfer: In the event that the Site, or substantially all of its
                    assets, is acquired by one or more third parties as a result of an
                    acquisition, merger, sale, reorganization, consolidation or liquidation,
                    Personal Information may be one of the transferred assets.
                </p>

                <p dir="ltr">
                    YouTube API Services: In order to operate the Site and provide related
                    functionality, we use the <a href="https://developers.google.com/youtube/terms/developer-policies#definition-youtube-api-services" target="_blank" rel="nofollow noopener noreferrer">YouTube API Services</a>. YouTube API Services are
                    governed by Google’s Privacy Policy, <a href="https://policies.google.com/privacy" target="_blank" rel="nofollow noopener noreferrer">which you can access here</a>.
                    When you connect your YouTube account, we collect and store the following
                    information: User Name, View Count, Comment Count, Subscriber Count, and
                    Video Count. This data will be updated every 30 days unless and until you
                    disconnect your YouTube account. If you join a quest on the Site that makes
                    use of the YouTube platform, we will collect and store certain information
                    about your YouTube channel for up to 30 days. That information includes whether
                    you are streaming live, time and viewer count data about the live streams on
                    your channel, likes and dislikes for your stream, and your channel's subscriber
                    information. After 30 days, this information will be deleted.
                </p>

                <p dir="ltr">
                    If you’d like to view and control our access to your YouTube data, you can do this
                    through Google’s security settings page located at <a href="https://security.google.com/settings/security/permissions" target="_blank" rel="nofollow noopener noreferrer">https://security.google.com/settings/security/permissions</a>.
                </p>

                <h2 dir="ltr">
                    THIRD-PARTY AD SERVERS
                </h2>
                <p dir="ltr">
                    We may use third-party advertising companies to serve ads when you visit
                    the Site. If so, a list of these ad serving companies will be made
                    available upon request. These companies may use information (generally not
                    including your name, address, e-mail address or telephone number) about
                    your visits to this and other web Sites in order to provide advertisements
                    about goods and services of interest to you. These advertisements may
                    appear on the Site or on other the Site web Sites. These companies may
                    employ cookies and clear GIFs to cause relevant ads to be displayed to you.
                    Any information that these third parties collect via cookies and clear GIFs
                    is generally not personally identifiable (unless, for example, you provide
                    personally identifiable information to them through an ad or e-mail
                    message). We encourage you to read these businesses’ privacy policies if
                    you should have any concerns about how they will care for your Non-Personal
                    Information or Personal Information.
                </p>
                <h2 dir="ltr">
                    WIRELESS ADDRESSES
                </h2>
                <p dir="ltr">
                    If the e-mail address you provide to us is a wireless e-mail address, you
                    agree to receive messages at such address from the Site (unless and until
                    you have elected not to receive such messages by following the instructions
                    in the Opt Out section above). You understand that your wireless carrier’s
                    standard rates apply to these messages, and that you may change your mind
                    at any time by following the instructions in the Opt Out section above. You
                    represent that you are the owner or authorized user of the wireless device
                    on which messages will be received, and that you are authorized to approve
                    the applicable charges.
                </p>
                <h2 dir="ltr">
                    LINKS TO OTHER WEB SITES
                </h2>
                <p dir="ltr">
                    The Site may contain or provide links, banners, widgets or advertisements
                    that lead to other Web Sites. When you click on these types of third-party
                    content, the privacy policies of the Web Sites to which you are linked will
                    govern the collection and use of your information. the Site does not have
                    access to or control over any technologies or practices that may be used on
                    linked to Sites, and we are not responsible for the privacy practices of
                    them.
                </p>
                <h2 dir="ltr">
                    CONSENT TO PROCESSING
                </h2>
                <p dir="ltr">
                    By providing Personal Information through the Site application or related
                    Web Sites or Games, you consent to the collection, maintenance, processing
                    and transfer of such information in and to the United States and other
                    countries and territories, pursuant to the laws of the United States, which
                    may provide lesser privacy protections than the laws of other countries.
                </p>
                <h2 dir="ltr">
                    SECURITY
                </h2>
                <p dir="ltr">
                    We take precautions to protect your information. When you submit sensitive
                    information via the website, your information is protected both online and
                    offline.
                </p>

                <p dir="ltr">
                    Wherever we collect sensitive information (such as credit card data), that
                    information is encrypted and transmitted to us in a secure way. You can
                    verify this by looking for a lock icon in the address bar and looking for
                    "https" at the beginning of the address of the Web page.
                </p>

                <p dir="ltr">
                    While we use encryption to protect sensitive information transmitted
                    online, we also protect your information offline. Only employees who need
                    the information to perform a specific job (for example, billing or customer
                    service) are granted access to personally identifiable information. The
                    computers/servers in which we store personally identifiable information are
                    kept in a secure environment.
                </p>


                <h2 dir="ltr">
                    QUESTIONS?
                </h2>
                <p dir="ltr">
                    If you have any questions about this Privacy Policy or how we collect and process
                    personal information, you can contact us at <a href="mailto:support@noiz.gg" rel="nofollow noopener noreferrer">support@noiz.gg</a>.
                </p>

            </section>
        );
    }
}

export default PrivacyPolicy;
