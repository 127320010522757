/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import CustomInput from '../../utils/redux-forms-custom-fields/CustomInput';
import email from './img/91.png';
class RecoverPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  submit = (userFormInfo) => {
    // Passing this params a level up on submit parent function.
    this.props.onSubmit(userFormInfo);
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <form className="form-container signup" autoComplete="off" onSubmit={handleSubmit(this.submit.bind(this))}>
        <Field
          type="password"
          name="password"
          id="password"
          placeholder="Password"
          prefixIcon={email}
          customLabel2={(<label data-label="New Password" data-label-small="Your new password here" />)}
          component={CustomInput}
        />
        <Field
          type="password"
          name="rePassword"
          id="rePassword"
          placeholder="Confirm Password"
          customLabel2={(<label data-label="Confirm New Password" data-label-small="Your new password confirmation here" />)}
          component={CustomInput}
        />
        <div className="form__message">
          Create a new password.
        </div>
        <div className="form__footer">
          <div className="form__footer__left">
            <button className="cta-button">Save Password</button>
          </div>
        </div>
      </form>
    );
  }
}

const validate = values => {
  const { password, rePassword } = values;
  const errors = {};
  if (!password || password.trim() === '') {
    errors.password = 'This field is required';
  }

  if (password) {
    if (!/[*@!#%&()^~{}$]+/.test(password)) {
      errors.password = 'Password must have one of the following: !@#$%^&*()';
    }
  
    if (!/[A-Z]+/.test(password)) {
      errors.password = 'Password must have at least one upper case letter';
    }
  
    if (!/[a-z]+/.test(password)) {
      errors.password = 'Password must have at least one lower case letter';
    }
  
    if (password.length > 30 || password.length < 8) {
      errors.password = 'Password must be between 8 and 30 characters';
    }
  }

  if (!rePassword || rePassword.trim() === '') {
    errors.rePassword = 'This field is required';
  }

  if (password !== rePassword) {
    errors.rePassword = 'Passwords don\'t match.';
  }

  return errors;
};


RecoverPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'recoverPasswordForm',
  validate,
})(RecoverPasswordForm);
