/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import CustomInput from '../../utils/redux-forms-custom-fields/CustomInput2';
import { dayjs } from '../../utils/dateTime';

import password from './img/92.png';
import person from './img/93.png';

class SignInForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  submit = (userFormInfo) => {
    const { role, onSubmit } = this.props;

    onSubmit({
      browserTimezone: dayjs.tz.guess(),
      identity: userFormInfo.identity.toLowerCase(),
      password: userFormInfo.password,
      ...(role ? { role } : {}),
    });
  }

  render() {
    const { handleSubmit } = this.props;
    const isClientPortal = !!window.location.pathname.includes('portal');

    if (isClientPortal) {
      return (
        <form className="form-container signup client-portal" autoComplete="off" onSubmit={handleSubmit(this.submit.bind(this))}>
          <span style={{ fontSize: '12px', fontWeight: 'bold', margin: '0 0 10px 0' }}> EMAIL OR USERNAME </span>
          <Field
            type="text"
            name="identity"
            id="identity"
            component={CustomInput}
          />
          <span style={{ fontSize: '12px', fontWeight: 'bold', margin: '0 0 10px 0' }}> EMAIL </span>
          <Field
            type="password"
            name="password"
            id="password"
            component={CustomInput}
          />
          <div className="form__footer form__footer__2" style={{ margin: '40px 0 0 0' }}>
            {this.props.auth.isLoading && (<i className="fa fa-spinner fa-spin fa-3x fa-fw" />)}
            {!this.props.auth.isLoading && (
              <div className="form__footer__left">
                <button style={{ width: '120px', borderRadius: '25px' }} className="CTAButton__purple">Login</button>
                <span style={{ margin: '0 0 0 20px' }}>
                  {'Forgot your password?  '}
                  <Link
                    className="Modal__tab"
                    to="/recover"
                    href="/recover"
                    role="button"
                    aria-hidden
                  >
                    Click here
                  </Link>
                </span>
              </div>)}
          </div>
        </form>
      );
    }
    return (
      <form className="form-container signup" autoComplete="off" onSubmit={handleSubmit(this.submit.bind(this))}>
        <Field
          type="text"
          name="identity"
          id="identity"
          prefixIcon={person}
          placeholder="Your email or username here"
          component={CustomInput}
        />
        <Field
          type="password"
          name="password"
          id="password"
          prefixIcon={password}
          placeholder="Password"
          component={CustomInput}
        />
        <div className="Modal__forgot">
          <Link
            className="Modal__tab"
            to="/recover"
            href="/recover"
            role="button"
            aria-hidden
          >
            Forgot your password?
          </Link>
        </div>
        <div className="form__footer">
          {this.props.auth.isLoading ?
            (<i className="fa fa-spinner fa-spin fa-3x fa-fw" />) :
            (<div className="form__footer__left"><button className="cta-button">CONTINUE</button></div>)
          }
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.identity || values.identity.trim() === '') {
    errors.identity = 'This field is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address or username';
  }
  if (!values.password || values.password.trim() === '') {
    errors.password = 'This field is required';
  }

  return errors;
};


SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'signInForm',
  validate,
})(SignInForm);
