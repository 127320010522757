import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {sortBy} from "lodash";
import { Link } from "react-router";
import "./ConfigurationCenter.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Box,
  IconButton,
  Typography
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close'
import ConfigurationTabs from "./ConfigurationTabs";
import Icon from "../../../global/images/icons/Icon";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import TableBody from "@material-ui/core/TableBody";
import {
  getAmountFormattedString,
  getSorting,
  stableSort
} from "../../../utils/functions";
import {withStyles} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import moment from "moment/moment";

import {simpleNotification} from "../../../utils/notifications";
import {CountryDropdown} from "react-country-region-selector";

const TableHeaderCell = withStyles(theme => ({
  head: {
    color: '#9b9b9b',
    fontWeight: '400',
    letterSpacing: '1.2px',
    fontSize: '14px',
  },
}))(TableCell);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paperWidthSm': {
    backgroundColor: '#322d38',
    borderRadius:'8px',
    display:'flex',
    alignItems:'center',
    width:'500px',
    padding:'10px'
  },
  '& .MuiDialogContent-dividers': {
    borderTop:'0px',
    borderBottom:'0px'
  },
  '& .MuiTypography-h6': {
    textTransform:'none'
  },
  '& .input': {
    width:'100%',
    padding:'10px',
    backgroundColor:'#1a1a1a',
    border:'1px solid #3d3d3d',
    borderRadius:'4px',
    color:'#ffffff',
  },
  '& .input_error': {
    width:'100%',
    padding:'10px',
    backgroundColor:'#1a1a1a',
    border:'2px solid #ff0000',
    borderRadius:'4px',
    color:'#ffffff',
  },
  '& select': {
    width:'100%',
    padding:'10px',
    backgroundColor:'#1a1a1a',
    border:'1px solid #3d3d3d',
    borderRadius:'4px',
    color:'#ffffff',
  },
  '& Button': {
    padding:'10px 20px',
    border:'none',
    borderRadius:'4px',
    cursor:'pointer',
    fontSize:'14px',
    transition:'background 0.3s',
    fontFamily:'',
    textTransform:'capitalize',
  },
  '& .select_error': {
    border:'2px solid #ff0000',
    borderRadius:'4px',
    color:'#ffffff',
  },
}));


class ConfigurationCenter extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      inRoot: this.props.router.location.pathname === "/admin/command-center",
      showArchivedGames: false,
      showDisabledGames: false,
      dialogAddRegion:false,
      dialogAddCountry:false,
      dialogCountryList:false,
      dialogDeleteOpen:false,
      regionName:"",
      currencyName:"",
      timezoneName:"",
      search:"",
      reginoNameError:false,
      numPage: 1,
      filters: {
      },
      sorting: false,
      addRegions:[],
      editRegionId:0,
      editRegionName:"",
      countryName:"",
      countryError:false,
      countryConfirm:false,
      updateRegions:[],//include add and update
      allRegions:[],
      regionsSubmit:false,
    };
    this.currys=[
        { code: 'USD', name: 'United States Dollar' },
      { code: 'EUR', name: 'Euro' },
      { code: 'GBP', name: 'British Pound Sterling' },
      { code: 'JPY', name: 'Japanese Yen' },
      { code: 'CNY', name: 'Chinese Yuan' },
      { code: 'CAD', name: 'Canadian Dollar' },
      { code: 'AUD', name: 'Australian Dollar' },
      { code: 'INR', name: 'Indian Rupee' },
      { code: 'BRL', name: 'Brazilian Real' },
      { code: 'RUB', name: 'Russian Ruble' },
    ];
    this.timezones = [];
    this.RegionNameRef = React.createRef();
    //this.currencyRef = React.createRef();
    //this.timezoneRef = React.createRef();
    this.deleteRegionId=0;
    this.deleteRegionName="";
    this.regionId=0;
    this.regionName="";
    this.newRegionName="";
    this.countryList=[];
    this.regionList=[];
  }

  componentDidMount() {
    // TODO: Get list of Games
   // this.props.getCCGameList(token, {});
    moment.tz.names().forEach((obj) => {
      let offset = Math.round(moment.tz(obj).utcOffset() / 60).toString();
      offset = (offset.includes('-') || offset === '0') ? offset : `+${offset}`;
      const timezone = obj;
      if (
          (!timezone.includes('Etc') &&
              !timezone.includes('GMT') &&
              !timezone.includes('GB') &&
              !timezone.includes('CST6') &&
              !timezone.includes('EST6') &&
              !timezone.includes('MST7') &&
              !timezone.includes('PST8') &&
              !timezone.includes('Zulu') &&
              !timezone.includes('W-') &&
              !timezone.includes('universal')) ||
          timezone === this.props.initialValue
      ) {
        if (timezone.includes(this.props.initialValue)) {
          this.timezones.push(timezone);
        } else {
          this.timezones.push(`(GMT${offset}:00) ${timezone}`);
        }
      }
    });
    this.timezones = sortBy(this.timezones);
    this.setState({regionName:"",currencyName:this.currys[0].code,timezoneName:this.timezones[0]})
    this.doSearch(1);
  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if ((prevProps.admin.region.isLoading === true && this.props.admin.region.isLoading === false)) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (this.props.admin.region.errors.hasError) {
        simpleNotification({
          level: "error",
          title: this.props.admin.region.errors.message,
          message: "Region failed to add!",
        });
      }else{
        //this.handleRegionClose();
        //simpleNotification({
        //  level: "success",
        //  title: "Region creation successful.",
        //  message: "Region has been successfully created!",
       // });
        //this.doSearch(1);
        const data = {regionName:this.state.regionName,currency:this.state.currencyName,timezone:this.state.timezoneName};
        const { addRegions } = this.state;
        //addRegions.map(region=>(region.regionName === this.state.regionName));
        const region = addRegions.find(region=>region.regionName === this.state.regionName);
        if(region){
          simpleNotification({
            level: "error",
            title: "The Region with that name already exists,but not save",
            message: "Region failed to add!",
          });
          return;
        }
        addRegions.push(data);
        //this.props.createRegion(data, token);
        //this.setState({ regionName: "",currencyName:this.currys[0].code,timezoneName:this.timezones[0]});
        this.handleRegionClose();
        simpleNotification({
          level: "success",
          title: "Region add successful.",
          message: "Region has not been permanently saved yet. Please set up the application in a timely manner to save it!",
        });
      }
    }

    if ((prevProps.admin.regionBatch.isLoading === true && this.props.admin.regionBatch.isLoading === false)) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (this.props.admin.regionBatch.errors.hasError) {
        simpleNotification({
          level: "error",
          title: this.props.admin.region.errors.message,
          message: "Region failed to create!",
        });
      }else{
        //this.handleRegionClose();
        simpleNotification({
          level: "success",
          title: "Region creation successful.",
          message: "Region has been successfully created!",
         });
        this.setState({ addRegions: []});
        this.doSearch(1);
      }
    }

    if ((prevProps.admin.region_m.isLoading === true && this.props.admin.region_m.isLoading === false)) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (this.props.admin.region_m.errors.hasError) {
        if(this.props.admin.region_m.errors.code==='E_COUNTRY_EXIST_CONFIRM'){
          this.regionName=this.props.admin.region_m.errors.message;
          this.setState({ countryConfirm: true});
        }else {
          simpleNotification({
            level: "error",
            title: this.props.admin.region_m.errors.message,
            message: "Region failed to modify!",
          });
        }
      }else{
        this.setState({ editRegionId: 0,editRegionName:""});
        this.handleCountryClose();
        this.doSearch(1);
      }
    }
    if ((prevProps.admin.region_ms.isLoading === true && this.props.admin.region_ms.isLoading === false)) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (this.props.admin.region_ms.errors.hasError) {
          simpleNotification({
            level: "error",
            title: this.props.admin.region_m.errors.message,
            message: "Region failed to modify!",
          });

      }else{
        this.setState({ regionsSubmit: false});
        this.doSearch(1);
      }
    }
    if ((prevProps.admin.regions.isLoading === true && this.props.admin.regions.isLoading === false)) {
      if (!this.props.admin.regions.errors.hasError) {
          const datas = this.props.admin.regions.data;
        this.setState({ allRegions: datas});
      }
    }

    if (this.state.sorting!==prevState.sorting) {
        this.doSearch(1);
    }
  }

  openDownloadModal1 = () => {
    const { token, user } = this.props.auth;
    //const isAdmin = user.role === 'admin';
    //const isHelper = user.role === 'helper';
    const isFinance = user.role === 'finance';
    //console.log('isFinance',isFinance);
    if (isFinance) {
      this.props.getRollOutCsv(0,this.state.search?this.state.search:',', JSON.stringify(this.state.filters), this.state.sorting, token,0);
      this.setState({
        downloadModal: true,
      });
    }
  }

  getTableHeaders = () => {
    const tableHeaders = [
      {
        id: 'regionName', numeric: false, disablePadding: true, label: 'REGION NAME',
      },
      {
        id: 'registeredusers', numeric: false, disablePadding: true, label: 'REGISTERED USERS',
      },
      {
        id: 'currency', numeric: false, disablePadding: true, label: 'CURRENCY',
      },
      {
        id: 'timezone', numeric: false, disablePadding: true, label: 'TIMEZONE',
      },
      {
        id: 'countryList', numeric: false, disablePadding: true, label: 'COUNTRY LIST',
      },
      {
        id: 'actions', numeric: false, disablePadding: true, label: 'ACTIONS',
      },

    ];
    return tableHeaders;
  }

  getCurrey=(code)=>{
      return this.currys.find(cur=>cur.code===code);
  }

  getRegionsRows = (regions) => {
    if (regions.length) {
      const sortedRegions = stableSort(regions, getSorting(this.state.order, this.state.orderBy));
      return sortedRegions.map(region => region.active!==false&&(
          <TableRow hover tabIndex={-1} key={region.id}>
            <TableCell align="center">
              {region.id&&this.state.editRegionId===region.id&&(<><input type='text' value={this.state.editRegionName} onChange={event => this.handleInputChange(event)} style={{width:"80%"}}/><button onClick={event=>this.hanleSave(event)} className="fa fa-save"/></>)}

              {region.id&&this.state.editRegionId!==region.id&&(<>{region.regionName ? region.regionName: '-'}{region.regionName!=='Global Others'&&<button
                className="table-cell-cta" onClick={event =>this.handleEdit(region.id,region.regionName)}
                >
                <i className="fa fa-edit" />
                </button>}</>)}
              {!region.id&&(<input type='text' value={region.regionName ? region.regionName: ''} onChange={event => this.updateAddRegion(event,'regionName',region.regionName)}/>)}
            </TableCell>
            <TableCell align="center">{region.userCount!==undefined ? getAmountFormattedString(region.userCount):0}</TableCell>
            <TableCell align="center">
              {region.id&&<select value={region.currency} disabled>
                ${this.currys.map(curry=>(<option value={curry.code}>{curry.code} - {curry.name}</option>))}
              </select>}
              {!region.id&&<select value={region.currency} style={{cursor:"pointer"}} onChange={event => this.setState({ currency: event.target.value })} >
                ${this.currys.map(curry=>(<option value={curry.code}>{curry.code} - {curry.name}</option>))}
              </select>}
            </TableCell>
            <TableCell align="center">
              {region.id&&<select value={region.timezone} disabled>
                ${this.timezones.map(option=>(<option value={option}>{option}</option>))}
              </select>}
              {!region.id&&<select value={region.timezone} style={{cursor:"pointer"}} onChange={event => this.setState({ timezone: event.target.value })} >
                ${this.timezones.map(option=>(<option value={option}>{option}</option>))}
              </select>}
            </TableCell>
            <TableCell align="center">
              <p style={{display:'flex'}}>
              <p className="long_text">{region.countrys?.map((country,index)=>(index===0?country:(','+country)))}</p>
              {region.countrys&&region.countrys.length>1&&<Link style={{alignSelf:'center'}} onClick={this.handleCountryList.bind(this,region.countrys)}>View more</Link>}
              </p>
            </TableCell>
            <TableCell align="left">
              <button
                  style={{backgroundColor:'#4a90e2'}}
                  onClick={this.handleCountryOpen.bind(this,region.id,region.regionName)}>
                Add Country
              </button>
              {region.regionName!=='Global Others'&&<button
                  className="table-cell-cta" onClick={event =>this.handleDeleteOpen(event,region.id,region.regionName,region.countrys,region.userCount)}
                  style={{marginLeft:'10px'}}
              >
                <i className="fa fa-trash" />
              </button>}
            </TableCell>
          </TableRow>
      ));
    }
  }

  updateAddRegion=(event,propName,oldValue)=>{
    const newValue = event.target.value;
    let oldAddRegions1 = {...this.state.allRegions};
    oldAddRegions1=Object.values(oldAddRegions1);
    const newAddRegions = oldAddRegions1.map(region=>{
      if(region[propName]&&region[propName]===oldValue) {
        const newRegion = {...region}
        newRegion[propName] = newValue;
        //region[propName] = newValue;
        return newRegion;
      }else{
        return region;
      }
    });
    this.setState({allRegions:newAddRegions});
  }

  handleRegionClose = () => {
    this.setState({ dialogAddRegion: false,regionName: "",currencyName:this.currys[0].code,timezoneName:this.timezones[0]});
  };

  handleRegionOpen = () => {
    this.setState({ dialogAddRegion: true});
  };

  handleDeleteClose = () => {
    this.setState({ dialogDeleteOpen: false});
  };

  handleDeleteOpen = (event,regionId,regionName,countrys,userCount) => {
    if(userCount&&userCount>0){
      simpleNotification({
        level: "error",
        title: "fail to delete",
        message: "It can be deleted only when the REGISTERED USERS is 0!",
      });
      return;
    }
    this.deleteRegionName=regionName;
    this.deleteRegionId=regionId;
    this.setState({ dialogDeleteOpen: true});
  };

  handleCountryClose = () => {
    this.setState({ dialogAddCountry: false});
    this.regionId=0;
    //this.props.getUserWallet();
  };

  handleCountryOpen = (regionId,regionName) => {
    this.setState({ dialogAddCountry: true,regionName:regionName});
    this.regionId=regionId;
  };

  submitRegion=(event)=>{
    event.preventDefault();
    const { token } = this.props.auth;
    const { allRegions } = this.state;
    //this.props.approveRollOut(this.state.withdrawOrder.orderId,1,token);
    if(!allRegions||allRegions.length<1){
      return;
    }
    //const data = {regionName:this.state.regionName,currency:this.state.currencyName,timezone:this.state.timezoneName};
    //this.props.createRegions(addRegions, token);
    this.props.updateRegions(allRegions,token);
    //this.setState({ regionName: "",currencyName:this.currys[0].code,timezoneName:this.timezones[0]});
    //this.handleRegionClose();
  }

  addRegion=(event)=>{
    event.preventDefault();
    //this.props.approveRollOut(this.state.withdrawOrder.orderId,1,token);
    if(this.state.regionName.trim().length<1){
      this.setState({ reginoNameError: true});
      return;
    }
    const data = {regionName:this.state.regionName,currency:this.state.currencyName,timezone:this.state.timezoneName,userCount:0,countrys:[]};
    const { allRegions,updateRegions } = this.state;
    //验证region是否存在
    const r = allRegions.find(region=>region.regionName===this.state.regionName);
    if(r){
      simpleNotification({
        level: "error",
        title: "The Region with that name already exists",
        message: "Region failed to add!",
      });
      return;
    }
    allRegions.unshift(data);
    updateRegions.unshift(data);
    this.setState({ allRegions: allRegions,updateRegions:updateRegions});
    //this.props.createRegion(data,false, token);
    //this.setState({ regionName: "",currencyName:this.currys[0].code,timezoneName:this.timezones[0]});
    this.handleRegionClose();
    this.setState({ regionsSubmit: true});
    //simpleNotification({
    //  level: "success",
    //  title: "Region add successful.",
    //  message: "Region has not been permanently saved yet. Please set up the application in a timely manner to save it!",
    //});

  }

  refuseOrder=()=>{
    const { token } = this.props.auth;
    this.props.approveRollOut(this.state.withdrawOrder.orderId,2,token);
    this.setState({ approveType: 2});
    this.handleDeleteClose();
    this.handleResultOpen();
  }

  payOrder=()=>{
    const { approvalLink,isLoading} = this.props.admin.orderLink.data;
    if(!isLoading){
      if(approvalLink){
        window.open(approvalLink, "_blank");
      }
    }
    this.handleResultClose();
  }

  doSearch=(numPage)=>{
    const { token } = this.props.auth;
    if (!this.props.admin.regions.isLoading) {
      //this.props.getRegions(numPage?numPage:this.state.numPage,this.state.search?this.state.search:' ',  JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token, 0);
      //not paging
      this.props.getRegions(0,this.state.search?this.state.search:' ',  JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token, 0);
    }
  }

  handleRequestSort = (event, property) => {
    const desc = this.state.order === 'desc' && this.state.orderBy === property;
    this.setState({
      order: desc ? 'asc' : 'desc',
      orderBy: property,
      sorting:{
        order: desc ? 'asc' : 'desc',
        orderBy:property?property:false,
      }
    });
  }

  paginationRight = () => {
    const { token } = this.props.auth;
    const { pagination } = this.props.admin.regions;
    if (pagination.totalPages < this.state.numPage + 1) {
      return;
    }
    if (!this.props.admin.regions.isLoading) {
      this.props.getRegions(this.state.numPage + 1,this.state.search?this.state.search:' ', JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token,0);
    }
    this.setState({
      numPage: this.state.numPage + 1,
    });
  }

  paginationLeft = () => {
    const { token } = this.props.auth;
    if (this.state.numPage === 1) {
      return;
    }
    if (!this.props.admin.regions.isLoading) {
      this.props.getRegions(this.state.numPage - 1,this.state.search?this.state.search:' ', JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token,0);
    }
    this.setState({
      numPage: this.state.numPage - 1,
    });
  }

  handleEdit=(regionId,regionName)=>{
    this.setState({
      editRegionId: regionId,
      editRegionName:regionName,
    });
  }

  hanleSave=(event)=>{
    event.preventDefault();
    const { editRegionId,editRegionName,allRegions } = this.state;
    //this.props.updateRegion(editRegionId,editRegionName,0,'',false, token);
    const er = allRegions.find(region=>region.regionName===editRegionName&&region.id!==editRegionId);
    if(er){
      simpleNotification({
        level: "error",
        title: "The Region with that name already exists",
        message: "Region failed to edit!",
      });
      return;
    }
    allRegions.forEach(region=>{
      if(region.id===editRegionId){
        region.regionName=editRegionName;
      }
    });
    this.setState({
      allRegions: allRegions,
      editRegionId: 0,
      editRegionName:"",
      regionsSubmit:true,
    });
  }

  handleInputChange=(event)=>{
    const newValue = event.target.value;
    this.setState({
      editRegionName: newValue,
    });
  }

  addCountry=(event)=>{
    event.preventDefault();
    const { countryName,allRegions,regionName } = this.state;
    if(!countryName){
      this.setState({
        countryError: true,
      });
      return;
    }

    this.newRegionName=regionName;
    //this.props.updateRegion(this.regionId,'',0,countryName,true, token);
    //local valid move country
    let message="";
    let needConfirm =false;
    allRegions.forEach(region=>{
        if(region.regionName===regionName&&region.countrys.find(country=>country===countryName)){
          message = "This country already exists in the current region";
          return;
        }
        if(region.regionName!==regionName&&region.countrys.find(country=>country===countryName)){
          needConfirm=true;
          this.regionName=region.regionName;
          this.setState({ countryConfirm: true});
          return;
        }
    });
    if(message){
      simpleNotification({
        level: "error",
        title: message,
        message: "Region failed to add!",
      });
      return;
    }
    if(!needConfirm){
      allRegions.forEach(region=>{
        if(region.regionName===regionName){
          region.countrys.push(countryName);
        }
      });
      this.setState({ allRegions: allRegions,regionsSubmit:true});
      this.handleCountryClose();
    }
  }

  hanleDelete=(event)=>{
    event.preventDefault();
    const { allRegions } = this.state;
    if(!this.deleteRegionId){
       const newRegions = allRegions.filter(region => region.regionName !== this.deleteRegionName);

      if(newRegions){
        this.setState({
          allRegions: newRegions,
          regionsSubmit:true,
        });
      }
      this.handleDeleteClose();
      return;
    }
   // const { token } = this.props.auth;
    //this.props.updateRegion(this.deleteRegionId,'',2,'',false, token);
    allRegions.forEach(region=>{
      if(region.id === this.deleteRegionId){
        region.active=false;
      }
    });

    this.setState({
      allRegions: allRegions,
      regionsSubmit:true,
    });
    this.handleDeleteClose();
  }

  handleCountryList=(countryList)=>{
    this.countryList=countryList;
    this.setState({
      dialogCountryList:true,
    });
  }

  handleCountryListClose = () => {
    this.setState({ dialogCountryList: false});
    this.countryList=[];
    //this.props.getUserWallet();
  };

  handleCountryConfirm=()=>{
    const { countryName,allRegions } = this.state;
    //this.props.updateRegion(this.regionId,'',0,countryName,false, token);
    allRegions.forEach(region=>{
      if(region.regionName===this.newRegionName){
        region.countrys.push(countryName);
      }else if(region.regionName===this.regionName){
        if(region.countrys.indexOf(countryName)>=0){
          region.countrys.splice(region.countrys.indexOf(countryName),1);
        }
      }
    });
    this.setState({ allRegions: allRegions,regionsSubmit:true});
    this.handleCountryConfirmClose();
    this.handleCountryClose();
  }

  handleCountryConfirmClose=()=>{
    this.setState({ countryConfirm: false});
  }

  render() {
    const {isLoading=false } = this.props.ConfigurationCenter?.games|| {};
    const { search,regionName,currencyName,timezoneName,countryName,reginoNameError,countryError,regionsSubmit} = this.state;
    const { data: regions=[]} = this.props.admin.regions||{};
    const { dialogAddRegion,dialogDeleteOpen,dialogAddCountry,dialogCountryList ,countryConfirm,allRegions} = this.state;
    const {hasError} = this.props.admin.orderLink.errors;
    //let newRegions=[];
    //if(regions){
    //  newRegions=Object.values(regions);
   // }
    //console.log('newRegions',newRegions,typeof newRegions,addRegions);
    //if(addRegions&&addRegions.length>0){
      //console.log('regions',newRegions.length)
      //newRegions=newRegions.concat(addRegions);
      //newRegions.push(...addRegions);
    //}
    //console.log('newRegions',newRegions,typeof newRegions);
    const createSortHandler = property => (event) => {
      this.handleRequestSort(event, property);
    };


    return (
      <section className={classNames("ConfigurationCenter", { inRoot: this.state.inRoot })}>
        <section className='ConfigurationCenter__wrapper'>
          <ConfigurationTabs {...this.props} />
          <h2 className='ConfigurationCenter__title'>REGIONAL SETTINGS</h2>
          <section className='ConfigurationCenter__container'>
          <section className="RegionalList">
            <section className="RegionalList__wrapper">
              <section className="ToggleTable">
                <section className="ToggleTable__header">
                  <div className="ToggleTable__header__left">

                    <div className="Search_Input">
                      <Icon color='#bfbfbf' name='search' />
                      <input
                          type="text"
                          id="search-bar-input"
                          label="SearchBarInput"
                          placeHolder="Search countries..."
                          value={search}
                          onChange={event => this.setState({ search: event.target.value })}
                          onKeyDown={(e) => e.key === 'Enter' && this.doSearch(1)}
                      />
                    </div>
                    {/*<button style={{marginLeft:'10px'}} onClick={this.refreshRollOuts.bind(this)}>
                  Refresh Payments

                </button>*/}
                  </div>
                  <div className="ToggleTable__header__right">
                    {/* <button
                  className={classNames('')}
                  onClick={this.openFiltersModal.bind(this)}
                >
                  Filters
                </button> */}
                    {regionsSubmit&&<button
                        style={{marginLeft:'10px',backgroundColor:'#ff0000'}}
                        onClick={this.submitRegion.bind(this)}>
                      Apply Settings
                    </button>}
                    <button
                        style={{marginLeft:'10px',backgroundColor:'#4a90e2'}}
                        onClick={this.handleRegionOpen.bind(this)}>
                      Add Region
                    </button>
                    {/* {(pagination?pagination.totalPages:0)>1&&(<button className="regular" onClick={this.paginationLeft.bind(this)}>
                      <i className="fa fa-arrow-left" />
                    </button>)}
                    {(pagination?pagination.totalPages:0)>1&&(<button className="regular" onClick={this.paginationRight.bind(this)}>
                      <i className="fa fa-arrow-right" />
                    </button>)}*/}
                  </div>
                </section>
                <section className="ToggleTable__table-wrapper">
                  <section className="ToggleTable__table flex-table">
                    {/*<div className="row">
                  <div
                    className="table-cell headline"
                  >
                    Filters: {this.renderFiltersString(false)}
                  </div>
                </div>*/}
                    <Table>
                      <TableHead>
                        <TableRow>
                          {this.getTableHeaders().map(headCell => (
                              <TableHeaderCell
                                  key={headCell.id}
                                  align="center"
                                  padding={headCell.disablePadding ? 'none' : 'default'}
                                  sortDirection="asc"
                              >
                                <TableSortLabel
                                    active={this.state.orderBy === headCell.id}
                                    direction={this.state.order}
                                    onClick={createSortHandler(headCell.id)}
                                    IconComponent={ArrowDropDownIcon}
                                    style={{ marginLeft: '30px' ,color:'#ffffff'}}
                                >
                                  {headCell.label}
                                  {this.state.orderBy === headCell.id ? (
                                      <span style={{ display: 'none' }}>
                                {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </span>
                                  ) : null}
                                </TableSortLabel>
                              </TableHeaderCell>))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(this.getRegionsRows(allRegions))}
                        {/*{(this.getRegionsRows(addRegions))}
                        { (!isLoading) &&
                            this.getRegionsRows(regions)
                        }*/}
                      </TableBody>
                    </Table>
                    <div className="NoPayments">
                      { (isLoading) &&
                          <div className="PaymentList__loading">
                            <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
                          </div>
                      }
                      { (!isLoading && !regions.length) &&
                          <span> No Region Found </span>
                      }
                    </div>
                  </section>
                </section>
                {/* {(pagination?pagination.totalPages:0)>1&&(<section className="ToggleTable__footer">
                  <section className="ToggleTable__footer__left">
                    <button onClick={this.paginationLeft.bind(this)}>
                      <i className="fa fa-caret-left" />
                    </button>
                  </section>
                  <section className="ToggleTable__footer__center">
                           <div style={{height:'100%',textAlign:'center',alignContent:'center'}}>RECORDS:{pagination?pagination.totalRecords:'0'}
                      {pagination.totalPages>1&&(<div style={{display:'inline',marginLeft:'10px'}}>PAGE:{pagination?pagination.pageNumber:'-'}/{pagination?pagination.totalPages:'-'}</div>)}
                             </div>
                </section>
                  <section className="ToggleTable__footer__right">
                    <button onClick={this.paginationRight.bind(this)}>
                      <i className="fa fa-caret-right" />
                    </button>
                  </section>
                </section>)}*/}
              </section>
            </section>

            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={!!dialogAddRegion}
            >
              <form onSubmit={this.addRegion.bind(this)}>
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" style={{color:'#ff0000',alignSelf:'flex-start'}}>
                Add New Region
              </DialogTitle>
              <DialogContent dividers>
                <div style={{display:'flex',justifyContent:'left'}}>
                  {}<Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',color:'#a0a0a0'}}>Region Name</Typography>
                </div>
                <div style={{display:'flex',justifyContent:'left'}}>
                  <input type="text" id="region-name-bar-input" className={reginoNameError?'input_error':'input'} label="RegionBarInput" value={regionName} placeholder="Enter region name" onChange={event => this.setState({ regionName: event.target.value,reginoNameError: false})} ref={this.RegionNameRef}/>
                </div>
                <div style={{display:'flex',justifyContent:'left',marginTop:'20px'}}>
                  <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',color:'#a0a0a0'}}>Currency</Typography>
                </div>
                <div style={{display:'flex',justifyContent:'left'}}>
                  <select value={currencyName} onChange={event => this.setState({ currencyName: event.target.value })}>
                    ${this.currys.map(curry=>(<option value={curry.code}>{curry.code} - {curry.name}</option>))}
                  </select>
                </div>
                <div style={{display:'flex',justifyContent:'left',marginTop:'20px'}}>
                  <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',color:'#a0a0a0'}}>Timezone</Typography>
                </div>
                <div style={{display:'flex',justifyContent:'left'}}>
                  <select value={timezoneName} onChange={event => this.setState({ timezoneName: event.target.value })}>
                  { this.timezones.map(option => (<option key={option} value={option}>{option}</option>)) }
                  </select>
                </div>
              </DialogContent>
              <DialogActions style={{alignSelf:'flex-end'}}>
                <Button autoFocus style={{background:'#ffffff',color:'#000000'}} onClick={this.handleRegionClose.bind(this)}>
                  Cancel
                </Button>
                <Button style={{background:'#4a90e2',color:'#ffffff'}} onClick={this.addRegion.bind(this)}>
                  Confirm
                </Button>
              </DialogActions>
              </form>
            </BootstrapDialog>

            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={!!dialogAddCountry}
            >
              <form onSubmit={this.addCountry.bind(this)}>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" style={{color:'#ff0000',alignSelf:'flex-start'}}>
                  Add New Country
                </DialogTitle>
                <DialogContent dividers>
                  <div style={{display:'flex',justifyContent:'left',marginTop:'20px'}}>
                    <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',color:'#a0a0a0'}}>Select Country</Typography>
                  </div>
                  <div style={{display:'flex',justifyContent:'left'}} className={countryError?'select_error':''}>
                    <CountryDropdown
                        id=''
                        value={countryName}
                        name=''
                        defaultOptionLabel={'Please select or search for a country'}
                        onChange={
                          (value) => {this.setState({ countryName: value,countryError:false })}
                        }
                    />
                  </div>
                </DialogContent>
                <DialogActions style={{alignSelf:'flex-end'}}>
                  <Button autoFocus style={{background:'#ffffff',color:'#000000'}} onClick={this.handleCountryClose.bind(this)}>
                    Cancel
                  </Button>
                  <Button style={{background:'#4a90e2',color:'#ffffff'}} onClick={this.addCountry.bind(this)}>
                    Confirm
                  </Button>
                </DialogActions>
              </form>
            </BootstrapDialog>

            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={!!dialogDeleteOpen}
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" style={{color:hasError?'#ff0000':''}}>
                Confirm to delete the region of <p style={{color:'#E7F328',display:'inline'}}>{this.deleteRegionName}</p>?
              </DialogTitle>
              <DialogContent dividers>
                <div style={{display:'flex',justifyContent:'center'}}>
                  <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',color:hasError?'#ff0000':''}}>

                  </Typography>
                </div>
              </DialogContent>
              <DialogActions>
                <Button autoFocus style={{fontSize:'16px',fontWeight:'800',backgroundColor:'#CAC1C4',color:'#fff',borderRadius:'8px',padding:'10px 50px',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.handleDeleteClose.bind(this)}>
                  Cancel
                </Button>
                <Button style={{marginLeft:'20px',fontSize:'16px',fontWeight:'800',backgroundColor:'#76b452',color:'#fff',borderRadius:'8px',padding:'10px 50px',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.hanleDelete.bind(this)}>
                  Confirm
                </Button>
              </DialogActions>
            </BootstrapDialog>

            {<BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={!!dialogCountryList}
                PaperProps={{
                  style: {
                    width: '300px',
                    maxWidth: 'none',
                    maxHeight: '80%',
                    height: 'auto',
                  },
                }}
            >

              <DialogTitle  style={{width:'100%'}} id="customized-dialog-title">
                <Box display="flex" alignItems="left" justifyContent="space-between">
                  <Typography variant="h6"  style={{flex:'4'}}>Country List</Typography>
                <IconButton
                    edge="end"
                    aria-label="close"
                    onClick={this.handleCountryListClose.bind(this)}
                    style={{flex:'1'}}
                >
                  <CloseIcon />
                </IconButton>
                </Box>
              </DialogTitle>
              <DialogContent dividers style={{width:'90%'}}>
                {this.countryList.map(country=>(
                <div style={{display:'flex',justifyContent:'left',borderBottom:'solid 1px #3d3d3d'}}>
                  <Typography variant='body1' component="div"  gutterBottom style={{marginTop:'10px',minWidth:'150px'}}>
                    {country}
                  </Typography>
                </div>))}
              </DialogContent>
              <DialogActions>
              </DialogActions>
            </BootstrapDialog>}

            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={!!countryConfirm}
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" style={{color:hasError?'#ff0000':''}}>
                Will the <p style={{color:'#E7F328',display:'inline'}}>{countryName}</p> be moved from <p style={{color:'#E7F328',display:'inline'}}>{this.regionName}</p> to <p style={{color:'#E7F328',display:'inline'}}>{regionName}</p>?
              </DialogTitle>
              <DialogContent dividers>
                <div style={{display:'flex',justifyContent:'center'}}>
                  <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',color:hasError?'#ff0000':''}}>

                  </Typography>
                </div>
              </DialogContent>
              <DialogActions>
                <Button autoFocus style={{fontSize:'16px',fontWeight:'800',backgroundColor:'#CAC1C4',color:'#fff',borderRadius:'8px',padding:'10px 50px',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.handleCountryConfirmClose.bind(this)}>
                  Cancel
                </Button>
                <Button style={{marginLeft:'20px',fontSize:'16px',fontWeight:'800',backgroundColor:'#76b452',color:'#fff',borderRadius:'8px',padding:'10px 50px',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.handleCountryConfirm.bind(this)}>
                  Confirm
                </Button>
              </DialogActions>
            </BootstrapDialog>
            {/*{ (this.state.filtersModal) && this.renderFiltersModal() }*/}
            { (this.state.downloadModal) && this.renderDownloadModal() }
          </section>
          </section>
        </section>
      </section>
    );
  }
}

ConfigurationCenter.propTypes = {
  router: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  // auth: PropTypes.shape({
  //   token: PropTypes.string.isRequired,
  //   user: PropTypes.shape({
  //
  //   }).isRequired,
  // }).isRequired,
  // user: PropTypes.shape({
  //   currentAgreement: PropTypes.shape({
  //     data: PropTypes.shape({
  //
  //     }).isRequired,
  //     isLoading: PropTypes.bool.isRequired,
  //     hasError: PropTypes.bool.isRequired,
  //     code: PropTypes.string.isRequired,
  //   }).isRequired,
  //   currentScheduledBroadcast: PropTypes.shape({
  //     data: PropTypes.shape({
  //
  //     }).isRequired,
  //     isLoading: PropTypes.bool.isRequired,
  //     hasError: PropTypes.bool.isRequired,
  //     code: PropTypes.string.isRequired,
  //   }).isRequired,
  // }).isRequired,
  // logout: PropTypes.func.isRequired,
  // children: PropTypes.element.isRequired,
};

export default ConfigurationCenter;
