import * as React from "react";

import './QuestCampaignToggle.scss';

export default function QuestCampaignToggle({ isCampaigns = true, toReturn = () => null }) {

  return (
    <div className="QuestCampaignToggle_Wrapper">
      <input 
        type='radio' 
        id="campaigns"
        name='campaignOrQuest' 
        value='campaign' 
        onClick={() => toReturn(true)} 
        defaultChecked={isCampaigns} 
      />
      <label htmlFor="campaigns">CAMPAIGNS</label>
      <input 
        type='radio' 
        id="quests"
        name='campaignOrQuest' 
        value='quest' 
        onClick={() => toReturn(false)} 
        defaultChecked={!isCampaigns} 
      />
      <label htmlFor="quests">QUESTS</label>
    </div>
  );
};
