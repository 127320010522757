import api from '../utils/api';
import { getErrorResponse } from '../utils/apiErrorHandler';


// export const getLastAgreement = action$ =>
//   action$.ofType('GET_LAST_AGREEMENT')
//     .mergeMap(action =>
//       api.getLastAgreement(action.payload.token)
//         .map(response => ({ type: 'GET_LAST_AGREEMENT_SUCCESS', payload: response }))
//         .takeUntil(action$.ofType('GET_LAST_AGREEMENT_ABORTED'))
//         .defaultIfEmpty({ type: 'GET_LAST_AGREEMENT_CANCELED' })
//         .catch(error => getErrorResponse({ type: 'GET_LAST_AGREEMENT_ERROR', error }))
//         .startWith({ type: 'GET_LAST_AGREEMENT_PENDING', payload: action.payload }));
//
// export const scheduleABroadcast = action$ =>
//   action$.ofType('CREATE_SCHEDULED_BROADCAST')
//     .mergeMap(action =>
//       api.scheduleABroadcast(action.payload.scheduledBroadcastInfo, action.payload.agreementId, action.payload.token)
//         .map(response => ({ type: 'CREATE_SCHEDULED_BROADCAST_SUCCESS', payload: response }))
//         .takeUntil(action$.ofType('CREATE_SCHEDULED_BROADCAST_ABORTED'))
//         .defaultIfEmpty({ type: 'CREATE_SCHEDULED_BROADCAST_CANCELED' })
//         .catch(error => getErrorResponse({ type: 'CREATE_SCHEDULED_BROADCAST_ERROR', error }))
//         .startWith({ type: 'CREATE_SCHEDULED_BROADCAST_PENDING', payload: action.payload }));
//
//
// export const deleteScheduledBroadcast = action$ =>
//   action$.ofType('DELETE_SCHEDULED_BROADCAST')
//     .mergeMap(action =>
//       api.deleteScheduledBroadcast(action.payload.scheduledBroadcastId, action.payload.token)
//         .map(response => ({ type: 'DELETE_SCHEDULED_BROADCAST_SUCCESS', payload: response }))
//         .takeUntil(action$.ofType('DELETE_SCHEDULED_BROADCAST_ABORTED'))
//         .defaultIfEmpty({ type: 'DELETE_SCHEDULED_BROADCAST_CANCELED' })
//         .catch(error => getErrorResponse({ type: 'DELETE_SCHEDULED_BROADCAST_ERROR', error }))
//         .startWith({ type: 'DELETE_SCHEDULED_BROADCAST_PENDING', payload: action.payload }));

// <--------------- START NOIZ V2 EPICS --------------->


export const getInitialInfo = action$ =>
  action$.ofType('GET_INITIAL_INFO')
    .mergeMap(action =>
      api.getInitialInfo(action.payload.token)
        .map(response => ({ type: 'GET_INITIAL_INFO_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_INITIAL_INFO_ABORTED'))
        .defaultIfEmpty({ type: 'GET_INITIAL_INFO_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_INITIAL_INFO_ERROR', error }))
        .startWith({ type: 'GET_INITIAL_INFO_PENDING', payload: action.payload }));


export const getTwitchLanguage = action$ =>
  action$.ofType('GET_TWITCH_LANGUAGE')
    .mergeMap(action =>
      api.getTwitchLanguage(action.payload.token)
        .map(response => ({ type: 'GET_TWITCH_LANGUAGE_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_TWITCH_LANGUAGE_ABORTED'))
        .defaultIfEmpty({ type: 'GET_TWITCH_LANGUAGE_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_TWITCH_LANGUAGE_ERROR', error }))
        .startWith({ type: 'GET_TWITCH_LANGUAGE_PENDING', payload: action.payload }));


export const getBroadcast = action$ =>
  action$.ofType('GET_BROADCAST')
    .mergeMap(action =>
      api.getBroadcast(action.payload.broadcast, action.payload.token)
        .map(response => ({ type: 'GET_BROADCAST_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_BROADCAST_ABORTED'))
        .defaultIfEmpty({ type: 'GET_BROADCAST_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_BROADCAST_ERROR', error }))
        .startWith({ type: 'GET_BROADCAST_PENDING', payload: action.payload }));

export const getGameList = action$ =>
  action$.ofType('GET_GAME_LIST')
    .mergeMap(action =>
      api.getGameList(action.payload.token)
        .map(response => ({ type: 'GET_GAME_LIST_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_GAME_LIST_ABORTED'))
        .defaultIfEmpty({ type: 'GET_GAME_LIST_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_GAME_LIST_ERROR', error }))
        .startWith({ type: 'GET_GAME_LIST_PENDING', payload: action.payload }));

export const getUserGameList = action$ =>
  action$.ofType('GET_USER_GAME_LIST')
    .mergeMap(action =>
      api.getUserGameList(action.payload.type, action.payload.page, action.payload.token, action.payload.keyword)
        .map(response => ({ type: 'GET_USER_GAME_LIST_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_USER_GAME_LIST_ABORTED'))
        .defaultIfEmpty({ type: 'GET_USER_GAME_LIST_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_USER_GAME_LIST_ERROR', error }))
        .startWith({ type: 'GET_USER_GAME_LIST_PENDING', payload: action.payload }));

export const getGame = action$ =>
  action$.ofType('GET_GAME')
    .mergeMap(action =>
      api.getGame(action.payload.gameId, action.payload.token)
        .map(response => ({ type: 'GET_GAME_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_GAME_ABORTED'))
        .defaultIfEmpty({ type: 'GET_GAME_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_GAME_ERROR', error }))
        .startWith({ type: 'GET_GAME_PENDING', payload: action.payload }));

export const requestGameKey = action$ =>
  action$.ofType('REQUEST_GAME_KEY')
    .mergeMap(action =>
      api.requestGameKey(action.payload.gameId, action.payload.token)
        .map(response => ({ type: 'REQUEST_GAME_KEY_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('REQUEST_GAME_KEY_ABORTED'))
        .defaultIfEmpty({ type: 'REQUEST_GAME_KEY_CANCELED' })
        .catch(error => getErrorResponse({ type: 'REQUEST_GAME_KEY_ERROR', error }))
        .startWith({ type: 'REQUEST_GAME_KEY_PENDING', payload: action.payload }));

export const joinToBounty = action$ =>
  action$.ofType('JOIN_TO_BOUNTY')
    .mergeMap(action =>
      api.joinToBounty(action.payload.bountyId, action.payload.paymentEmail, action.payload.token)
        .map(response => ({ type: 'JOIN_TO_BOUNTY_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('JOIN_TO_BOUNTY_ABORTED'))
        .defaultIfEmpty({ type: 'JOIN_TO_BOUNTY_CANCELED' })
        .catch(error => getErrorResponse({ type: 'JOIN_TO_BOUNTY_ERROR', error }))
        .startWith({ type: 'JOIN_TO_BOUNTY_PENDING', payload: action.payload }));

export const leaveBounty = action$ =>
  action$.ofType('LEAVE_BOUNTY')
    .mergeMap(action =>
      api.leaveBounty(action.payload.bountyId, action.payload.token)
        .map(response => ({ type: 'LEAVE_BOUNTY_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('LEAVE_BOUNTY_ABORTED'))
        .defaultIfEmpty({ type: 'LEAVE_BOUNTY_CANCELED' })
        .catch(error => getErrorResponse({ type: 'LEAVE_BOUNTY_ERROR', error }))
        .startWith({ type: 'LEAVE_BOUNTY_PENDING', payload: action.payload }));

export const getUserPayments = action$ =>
  action$.ofType('GET_USER_PAYMENTS')
    .mergeMap(action =>
      api.getUserPayments(action.payload.token)
        .map(response => ({ type: 'GET_USER_PAYMENTS_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_USER_PAYMENTS_ABORTED'))
        .defaultIfEmpty({ type: 'GET_USER_PAYMENTS_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_USER_PAYMENTS_ERROR', error }))
        .startWith({ type: 'GET_USER_PAYMENTS_PENDING', payload: action.payload }));

export const getUserTodayActivity = action$ =>
  action$.ofType('GET_USER_TODAY_ACTIVITY')
    .mergeMap(action =>
      api.getUserTodayActivity(action.payload.token)
        .map(response => ({ type: 'GET_USER_TODAY_ACTIVITY_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_USER_TODAY_ACTIVITY_ABORTED'))
        .defaultIfEmpty({ type: 'GET_USER_TODAY_ACTIVITY_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_USER_TODAY_ACTIVITY_ERROR', error }))
        .startWith({ type: 'GET_USER_TODAY_ACTIVITY_PENDING', payload: action.payload }));

export const getUserReferralsActivity = action$ =>
  action$.ofType('GET_USER_REFERRALS_ACTIVITY')
    .mergeMap(action =>
      api.getUserReferralsActivity(action.payload.token)
        .map(response => ({ type: 'GET_USER_REFERRALS_ACTIVITY_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_USER_REFERRALS_ACTIVITY_ABORTED'))
        .defaultIfEmpty({ type: 'GET_USER_REFERRALS_ACTIVITY_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_USER_REFERRALS_ACTIVITY_ERROR', error }))
        .startWith({ type: 'GET_USER_REFERRALS_ACTIVITY_PENDING', payload: action.payload }));

export const getUserTimedActivity = action$ =>
  action$.ofType('GET_USER_TIMED_ACTIVITY')
    .mergeMap(action =>
      api.getUserTimedActivity(action.payload.token)
        .map(response => ({ type: 'GET_USER_TIMED_ACTIVITY_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_USER_TIMED_ACTIVITY_ABORTED'))
        .defaultIfEmpty({ type: 'GET_USER_TIMED_ACTIVITY_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_USER_TIMED_ACTIVITY_ERROR', error }))
        .startWith({ type: 'GET_USER_TIMED_ACTIVITY_PENDING', payload: action.payload }));

export const submitLink = action$ =>
  action$.ofType('SUBMIT_LINK')
    .mergeMap(action =>
      api.submitLink(action.payload.link, action.payload.questId, action.payload.token)
        .map(response => ({ type: 'SUBMIT_LINK_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('SUBMIT_LINK_ABORTED'))
        .defaultIfEmpty({ type: 'SUBMIT_LINK_CANCELED' })
        .catch(error => getErrorResponse({ type: 'SUBMIT_LINK_ERROR', error }))
        .startWith({ type: 'SUBMIT_LINK_PENDING', payload: action.payload }));

export const submitAffiliateData = action$ =>
  action$.ofType('SUBMIT_AFFILIATE_DATA')
    .mergeMap(action =>
      api.submitAffiliateData(
        action.payload.data,
        action.payload.stepType,
        action.payload.stepTitle,
        action.payload.questId,
        action.payload.kind,
        action.payload.token,
      )
        .map(response => ({ type: 'SUBMIT_AFFILIATE_DATA_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('SUBMIT_AFFILIATE_DATA_ABORTED'))
        .defaultIfEmpty({ type: 'SUBMIT_AFFILIATE_DATA_CANCELED' })
        .catch(error => getErrorResponse({ type: 'SUBMIT_AFFILIATE_DATA_ERROR', error }))
        .startWith({ type: 'SUBMIT_AFFILIATE_DATA_PENDING', payload: action.payload }));

export const submitRequirementData = action$ =>
  action$.ofType('SUBMIT_REQUIREMENT_DATA')
    .mergeMap(action =>
      api.submitRequirementData(
        action.payload.data,
        action.payload.token,
      )
        .map(response => ({ type: 'SUBMIT_REQUIREMENT_DATA_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('SUBMIT_REQUIREMENT_DATA_ABORTED'))
        .defaultIfEmpty({ type: 'SUBMIT_REQUIREMENT_DATA_CANCELED' })
        .catch(error => getErrorResponse({ type: 'SUBMIT_REQUIREMENT_DATA_ERROR', error }))
        .startWith({ type: 'SUBMIT_REQUIREMENT_DATA_PENDING', payload: action.payload }));


export const checkRequirementData = action$ =>
  action$.ofType('CHECK_REQUIREMENT_DATA')
    .mergeMap(action =>
      api.checkRequirementData(
        action.payload.reqId,
        action.payload.checked,
        action.payload.type,
        action.payload.title,
        action.payload.questId,
        action.payload.approvalRequired,
        action.payload.token,
      )
        .map(response => ({ type: 'CHECK_REQUIREMENT_DATA_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('CHECK_REQUIREMENT_DATA_ABORTED'))
        .defaultIfEmpty({ type: 'CHECK_REQUIREMENT_DATA_CANCELED' })
        .catch(error => getErrorResponse({ type: 'CHECK_REQUIREMENT_DATA_ERROR', error }))
        .startWith({ type: 'CHECK_REQUIREMENT_DATA_PENDING', payload: action.payload }));

export const getMyQuestsPageData = action$ =>
  action$.ofType('GET_MY_QUESTS_PAGE_DATA')
    .mergeMap(action =>
      api.getMyQuestsPageData(action.payload.token)
        .map(response => ({ type: 'GET_MY_QUESTS_PAGE_DATA_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_MY_QUESTS_PAGE_DATA_ABORTED'))
        .defaultIfEmpty({ type: 'GET_MY_QUESTS_PAGE_DATA_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_MY_QUESTS_PAGE_DATA_ERROR', error }))
        .startWith({ type: 'GET_MY_QUESTS_PAGE_DATA_PENDING', payload: action.payload }));


export const getUserTickets = action$ =>
  action$.ofType('GET_USER_TICKETS')
    .mergeMap(action =>
      api.getUserTickets(action.payload.token)
        .map(response => ({ type: 'GET_USER_TICKETS_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_USER_TICKETS_ABORTED'))
        .defaultIfEmpty({ type: 'GET_USER_TICKETS_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_USER_TICKETS_ERROR', error }))
        .startWith({ type: 'GET_USER_TICKETS_PENDING', payload: action.payload }));



export const requestUpdateAvatarUrl = action$ =>
  action$.ofType('REQUEST_UPDATE_AVATAR_URL')
    .mergeMap(action =>
      api.requestUpdateAvatarUrl(action.payload.token)
        .map(response => ({ type: 'REQUEST_UPDATE_AVATAR_URL_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('REQUEST_UPDATE_AVATAR_URL_ABORTED'))
        .defaultIfEmpty({ type: 'REQUEST_UPDATE_AVATAR_URL_CANCELED' })
        .catch(error => getErrorResponse({ type: 'REQUEST_UPDATE_AVATAR_URL_ERROR', error }))
        .startWith({ type: 'REQUEST_UPDATE_AVATAR_URL_PENDING', payload: action.payload }));

export const getUserWallet = action$ =>
  action$.ofType('GET_USER_WALLET')
    .mergeMap(action =>
      api.getUserWallet(action.payload.token)
        .map(response => ({ type: 'GET_USER_WALLET_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_USER_WALLET_ABORTED'))
        .defaultIfEmpty({ type: 'GET_USER_WALLET_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_USER_WALLET_ERROR', error }))
        .startWith({ type: 'GET_USER_WALLET_PENDING', payload: action.payload }));

export const withdrawWallet = action$ =>
  action$.ofType('WITHDRAW_USER_WALLET')
    .mergeMap(action =>
      api.withdrawWallet(action.payload.amount, action.payload.token)
        .map(response => ({ type: 'WITHDRAW_USER_WALLET_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('WITHDRAW_USER_WALLET_ABORTED'))
        .defaultIfEmpty({ type: 'WITHDRAW_USER_WALLET_CANCELED' })
        .catch(error => getErrorResponse({ type: 'WITHDRAW_USER_WALLET_ERROR', error }))
        .startWith({ type: 'WITHDRAW_USER_WALLET_PENDING', payload: action.payload }));

export const getWalletRollOutRecord = action$ =>
  action$.ofType('GET_USER_WALLET_ROLL_OUT_LIST')
    .mergeMap(action =>
      api.getWalletRollOutRecord(action.payload.pageNum, action.payload.filter, action.payload.sorting, action.payload.token, action.payload.exactMatch)
        .map(response => ({ type: 'GET_USER_WALLET_ROLL_OUT_LIST_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_USER_WALLET_ROLL_OUT_LIST_ABORTED'))
        .defaultIfEmpty({ type: 'GET_USER_WALLET_ROLL_OUT_LIST_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_USER_WALLET_ROLL_OUT_LIST_ERROR', error }))
        .startWith({ type: 'GET_USER_WALLET_ROLL_OUT_LIST_PENDING', payload: action.payload }));

export const getWalletRollOutCsv = action$ =>
  action$.ofType('GET_USER_WALLET_ROLL_OUT_CSV')
    .mergeMap(action =>
      api.getWalletRollOutRecord(action.payload.pageNum, action.payload.filter, action.payload.sorting, action.payload.token, action.payload.exactMatch)
        .map(response => ({ type: 'GET_USER_WALLET_ROLL_OUT_CSV_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_USER_WALLET_ROLL_OUT_CSV_ABORTED'))
        .defaultIfEmpty({ type: 'GET_USER_WALLET_ROLL_OUT_CSV_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_USER_WALLET_ROLL_OUT_CSV_ERROR', error }))
        .startWith({ type: 'GET_USER_WALLET_ROLL_OUT_CSV_PENDING', payload: action.payload }));

export const getWalletRollInRecord = action$ =>
  action$.ofType('GET_USER_WALLET_ROLL_IN_LIST')
    .mergeMap(action =>
      api.getWalletRollInRecord(action.payload.pageNum, action.payload.filter, action.payload.sorting, action.payload.token, action.payload.exactMatch)
        .map(response => ({ type: 'GET_USER_WALLET_ROLL_IN_LIST_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_USER_WALLET_ROLL_IN_LIST_ABORTED'))
        .defaultIfEmpty({ type: 'GET_USER_WALLET_ROLL_IN_LIST_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_USER_WALLET_ROLL_IN_LIST_ERROR', error }))
        .startWith({ type: 'GET_USER_WALLET_ROLL_IN_LIST_PENDING', payload: action.payload }));

export const getWalletRollInCsv = action$ =>
  action$.ofType('GET_USER_WALLET_ROLL_IN_CSV')
    .mergeMap(action =>
      api.getWalletRollInRecord(action.payload.pageNum, action.payload.filter, action.payload.sorting, action.payload.token, action.payload.exactMatch)
        .map(response => ({ type: 'GET_USER_WALLET_ROLL_IN_CSV_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('GET_USER_WALLET_ROLL_IN_CSV_ABORTED'))
        .defaultIfEmpty({ type: 'GET_USER_WALLET_ROLL_IN_CSV_CANCELED' })
        .catch(error => getErrorResponse({ type: 'GET_USER_WALLET_ROLL_IN_CSV_ERROR', error }))
        .startWith({ type: 'GET_USER_WALLET_ROLL_IN_CSV_PENDING', payload: action.payload }));

export const addRollInOrder = action$ =>
action$.ofType('ADD_ROLL_IN_ORDER')
    .mergeMap(action =>
      api.addRollInOrder(action.payload.amount, action.payload.questAmount, action.payload.fee, action.payload.questId, action.payload.token)
        .map(response => ({ type: 'WITHDRAW_USER_WALLET_SUCCESS', payload: response }))
        .takeUntil(action$.ofType('WITHDRAW_USER_WALLET_ABORTED'))
        .defaultIfEmpty({ type: 'WITHDRAW_USER_WALLET_CANCELED' })
        .catch(error => getErrorResponse({ type: 'WITHDRAW_USER_WALLET_ERROR', error }))
        .startWith({ type: 'WITHDRAW_USER_WALLET_PENDING', payload: action.payload }));

export const buyCDK = action$ =>
    action$.ofType('BUY_CDK')
        .mergeMap(action =>
            api.buyCDK(action.payload.productId,action.payload.price,action.payload.amount,action.payload.payType,action.payload.purchaseType,action.payload.doneeEmail,action.payload.token)
                .map(response => ({ type: 'BUY_CDK_SUCCESS', payload: response }))
                .takeUntil(action$.ofType('BUY_CDK_ABORTED'))
                .defaultIfEmpty({ type: 'BUY_CDK_CANCELED' })
                .catch(error => getErrorResponse({ type: 'BUY_CDK_ERROR', error }))
                .startWith({ type: 'BUY_CDK_PENDING', payload: action.payload }));

export const getCDKOrders = action$ =>
    action$.ofType('GET_USER_CDK_ORDER_LIST')
        .mergeMap(action =>
            api.getCDKOrders(action.payload.pageNum, action.payload.filter, action.payload.sorting, action.payload.token, action.payload.exactMatch)
                .map(response => ({ type: 'GET_USER_CDK_ORDER_LIST_SUCCESS', payload: response }))
                .takeUntil(action$.ofType('GET_USER_CDK_ORDER_LIST_ABORTED'))
                .defaultIfEmpty({ type: 'GET_USER_CDK_ORDER_LIST_CANCELED' })
                .catch(error => getErrorResponse({ type: 'GET_USER_CDK_ORDER_LIST_ERROR', error }))
                .startWith({ type: 'GET_USER_CDK_ORDER_LIST_PENDING', payload: action.payload }));

export const cancelCDKOrder = action$ =>
    action$.ofType('CANCEL_CDK_ORDER')
        .mergeMap(action =>
            api.cancelCDKOrder(action.payload.orderId,action.payload.token)
                .map(response => ({ type: 'CANCEL_CDK_ORDER_SUCCESS', payload: response }))
                .takeUntil(action$.ofType('CANCEL_CDK_ORDER_ABORTED'))
                .defaultIfEmpty({ type: 'CANCEL_CDK_ORDER_CANCELED' })
                .catch(error => getErrorResponse({ type: 'CANCEL_CDK_ORDER_ERROR', error }))
                .startWith({ type: 'CANCEL_CDK_ORDER_PENDING', payload: action.payload }));

export const payCDKOrder = action$ =>
    action$.ofType('PAY_CDK_ORDER')
        .mergeMap(action =>
            api.payCDKOrder(action.payload.orderId,action.payload.payType,action.payload.token)
                .map(response => ({ type: 'PAY_CDK_ORDER_SUCCESS', payload: response }))
                .takeUntil(action$.ofType('PAY_CDK_ORDER_ABORTED'))
                .defaultIfEmpty({ type: 'BUY_CDK_CANCELED' })
                .catch(error => getErrorResponse({ type: 'PAY_CDK_ORDER_ERROR', error }))
                .startWith({ type: 'PAY_CDK_ORDER_PENDING', payload: action.payload }));

export const showCDKPay = action$ =>
    action$.ofType('GET_CDK_PAY_ORDER')
        .mergeMap(action =>
            api.showCDKPay(action.payload.orderId, action.payload.token)
                .map(response => ({ type: 'GET_CDK_PAY_ORDER_SUCCESS', payload: response }))
                .takeUntil(action$.ofType('GET_CDK_PAY_ORDER_ABORTED'))
                .defaultIfEmpty({ type: 'GET_CDK_PAY_ORDER_CANCELED' })
                .catch(error => getErrorResponse({ type: 'GET_CDK_PAY_ORDER_ERROR', error }))
                .startWith({ type: 'GET_CDK_PAY_ORDER_PENDING', payload: action.payload }));

export const getCDKOrderRecord = action$ =>
    action$.ofType('GET_USER_CDK_ORDER_RECORD')
        .mergeMap(action =>
            api.getCDKOrderRecord(action.payload.pageNum, action.payload.filter, action.payload.sorting, action.payload.token, action.payload.exactMatch)
                .map(response => ({ type: 'GET_USER_CDK_ORDER_RECORD_SUCCESS', payload: response }))
                .takeUntil(action$.ofType('GET_USER_CDK_ORDER_RECORD_ABORTED'))
                .defaultIfEmpty({ type: 'GET_USER_CDK_ORDER_RECORD_CANCELED' })
                .catch(error => getErrorResponse({ type: 'GET_USER_CDK_ORDER_RECORD_ERROR', error }))
                .startWith({ type: 'GET_USER_CDK_ORDER_RECORD_PENDING', payload: action.payload }));

export const getCDKOrderRecordCsv = action$ =>
    action$.ofType('GET_USER_CDK_ORDER_RECORD_CSV')
        .mergeMap(action =>
            api.getCDKOrderRecordCsv(action.payload.pageNum, action.payload.filter, action.payload.sorting, action.payload.token, action.payload.exactMatch)
                .map(response => ({ type: 'GET_USER_CDK_ORDER_RECORD_CSV_SUCCESS', payload: response }))
                .takeUntil(action$.ofType('GET_USER_CDK_ORDER_RECORD_CSV_ABORTED'))
                .defaultIfEmpty({ type: 'GET_USER_CDK_ORDER_RECORD_CSV_CANCELED' })
                .catch(error => getErrorResponse({ type: 'GET_USER_CDK_ORDER_RECORD_CSV_ERROR', error }))
                .startWith({ type: 'GET_USER_CDK_ORDER_RECORD_CSV_PENDING', payload: action.payload }));

export const getCDKProducts = action$ =>
    action$.ofType('GET_USER_CDK_PRODUCTS')
        .mergeMap(action =>
            api.getCDKProducts(action.payload.gameId, action.payload.pageNum, action.payload.filter, action.payload.sorting, action.payload.token, action.payload.exactMatch)
                .map(response => ({ type: 'GET_USER_CDK_PRODUCTS_SUCCESS', payload: response }))
                .takeUntil(action$.ofType('GET_USER_CDK_PRODUCTS_ABORTED'))
                .defaultIfEmpty({ type: 'GET_USER_CDK_PRODUCTS_CANCELED' })
                .catch(error => getErrorResponse({ type: 'GET_USER_CDK_PRODUCTS_ERROR', error }))
                .startWith({ type: 'GET_USER_CDK_PRODUCTS_PENDING', payload: action.payload }));
