import React, { useEffect } from 'react';
import { ParallaxLayer } from '../../../custom_modules/react-spring/src/renderprops/addons/index';
import useOnScreen from '../useOnScreen';
import brandList from '../assetsV2/brandsIndex1';
import anime from 'animejs';
import ourbrand from '../assetsV2/ourbrand-bg.jpg';

import './BrandServiceSlides.scss';

const HOurBrands = (props) => {
  const {
    pOffset,
    layoutFactor = 1,
    offsetAdjust = 0,
    // isMobile = false, 
    // isTablet = false,
  } = props;
  const ref = React.createRef();
  const onScreen = useOnScreen(ref, '100px', 0.5);
  const [debounce, setDebounce] = React.useState(true);
  // const coverLayerOffset = isMobile ? 1.2 : isTablet ? 1 : 0.6;

  useEffect(() => {
    if (onScreen && debounce) {
      anime({
        targets: '.cascade-fade-h',
        opacity: [1, 0],
        translateY: 100,
        duration: 1000,
        direction: 'reverse',
        easing: 'linear',
        delay: anime.stagger(60, { direction: 'reverse' }),
      });
      setDebounce(false);
    }
  }, [onScreen, debounce]);

  return (
    <>
      <ParallaxLayer
        className="slide-h-container"
        factor={layoutFactor}
        offset={pOffset + offsetAdjust}
        style={{ backgroundImage: `url(${ourbrand})` }}
        speed={0}>
        <div>
          <div className="brand-title cascade-fade-h fade-up-prep">Brands we’ve worked with</div>
        </div>
        <div ref={ref} />
        <div className="brand-logo-grid" >
          {brandList.map((logo, i) => (
            <img
              className="cascade-fade-h fade-up-prep"
              key={i}
              alt="logos"
              src={logo}
            />
          ))}
        </div>
      </ParallaxLayer>
    </>
  );
};

export default HOurBrands;
