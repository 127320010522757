/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CountryDropdown } from 'react-country-region-selector';

class CustomDropDown extends Component {
  render() {
    const { input, meta: { touched, error }, ...custom } = this.props;
    const hasError = touched && error !== undefined;
    return (
      <div className="input-container">
        {custom.customLabel && <label htmlFor={input.label}>
          {custom.customLabel}
        </label>
        }
        <div className="input-wrapper input-wrapper-country-dropdown">
          <CountryDropdown
            {...input}
            id={custom.id}
            value={input.value}
            name={input.name}
            defaultOptionLabel={custom.defaultOptionLabel}
            onChange={
              (value) => {
                if (custom.handleChange) {
                  custom.handleChange(value);
                }
                input.onChange(value);
              }
            }
            classes={classNames(custom.classes, 'country-dropdown', {
              touched, error: hasError, ok: !hasError, empty: !input.value, 'not-empty': input.value,
            })}
          />
          {custom.customLabel2}
          {(hasError && touched) &&
            <div className="error-icon">
              <i className="fa fa-exclamation-circle" />
            </div>
          }
          {(!hasError && touched) &&
            <div className="ok-icon">
              <i className="fa fa-check" />
            </div>
          }
          {custom.countryOk &&
            <div className="ok-icon">
              <i className="fa fa-check" />
            </div>
          }
        </div>
        {!hasError && custom.errorMessage && <div className="error-message">{custom.errorMessage}</div>}
        {hasError && <div className="error-message">{error}</div>}
      </div>
    );
  }
}

CustomDropDown.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  // meta: PropTypes.shape({
  //   touched: PropTypes.bool,
  //   error: PropTypes.bool,
  // }),
  placeholder: PropTypes.string,
};

CustomDropDown.defaultProps = {
  placeholder: '',
  // meta: {
  //   error: false,
  //   touched: false,
  // },
};

export default CustomDropDown;
