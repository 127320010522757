import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import itpedia from '../assetsV2/itpedia.mp4';
import kratzy76 from '../assetsV2/kratzy76au.mp4';
import loserfruit from '../assetsV2/loserfruit.mp4';
import mrMEOLA from '../assetsV2/MrMEOLA.mp4';
import porta7511 from '../assetsV2/porta7511.mp4';
import raeDancing from '../assetsV2/rae-dancing.mp4';
import theRabble from '../assetsV2/therabblewrangler.mp4';
import droopy1943 from '../assetsV2/droopy1943.mp4';
import topictech from '../assetsV2/topictech.mp4';

// 静态配置项
const config = {
  speed: 0.001,
  radius: 45,
  height: 30,
  cols: 4,
  rows: 3,
  gapX: 100,
  gapY: 100,
  loopGap: 100,
  uvScale: 0.65,
  images: [
    // image1, image2, image3, image4, // 第一行图片
    loserfruit, raeDancing, porta7511, mrMEOLA,
    theRabble, raeDancing, itpedia, kratzy76,// 第二行图片
    topictech, droopy1943, porta7511, raeDancing, // 第三行图片
  ]
};

const getResponsiveConfig = () => {
  const width = window.innerWidth;
  const minWidth = 375;  // 最小屏幕宽度

  // 线性插值函数
  const lerp = (min, max, t) => {
    const ratio = (width - minWidth) / (1920 - minWidth);
    // 当屏幕宽度超过1920时，保持1920px时的参数值
    return width <= 1920
      ? min + (max - min) * Math.max(0, Math.min(1, ratio))
      : max * (1 + (width - 1920) / 1920 * 0.5);
  };

  return {
    ...config,
    radius: lerp(25, 45),
    height: lerp(20, 30),
    gapX: lerp(50, 100),
    gapY: lerp(50, 100),
    loopGap: lerp(50, 100)
  };
};

const CylinderScroll = () => {
  const containerRef = useRef();
  const scene = useRef(new THREE.Scene());
  const camera = useRef(null);
  const renderer = useRef(null);
  const texture = useRef(null);
  const animateId = useRef(0);
  const videoElements = useRef([]);
  const imgElements = useRef([]);
  const texturesLoaded = useRef(0);
  const isAnimating = useRef(false);
  const savedOffset = useRef(0);
  const resizeTimer = useRef(null);
  // 创建纹理
  const createTexture = () => {
    const { cols, rows, gapX, gapY, loopGap, images } = config;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d', { alpha: true }); // 启用 alpha 通道

    const imgWidth = 402;
    const imgHeight = 376;
    const canvasWidth = cols * imgWidth + (cols - 1) * gapX + loopGap;
    const canvasHeight = rows * imgHeight + (rows - 1) * gapY;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    // 加载图片和视频
    images.forEach((src, index) => {
      if (typeof src === 'string' && src.endsWith('.mp4')) {
        const video = document.createElement('video');
        video.src = src;
        video.loop = true;
        video.muted = true;
        video.autoplay = true;
        video.playsInline = true;
        video.oncanplaythrough = () => {
          video.play();
          texturesLoaded.current++;
          videoElements.current[index] = video;
          checkTexturesLoaded(ctx, canvas);
        };
        video.load();
      } else {
        const img = new Image();
        img.src = src;
        img.onload = () => {
          texturesLoaded.current++;
          imgElements.current[index] = img;
          checkTexturesLoaded(ctx, canvas);
        };
      }
    });
  };
  // 检查所有资源是否加载完成
  const checkTexturesLoaded = (ctx, canvas) => {
    if (texturesLoaded.current === config.images.length) {
      animateCanvas(ctx, canvas);
    }
  };
  // 画布动画
  const animateCanvas = (ctx, canvas) => {
    const renderFrame = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      const { cols, rows, gapX, gapY, loopGap } = config;
      const imgWidth = (canvas.width - (cols - 1) * gapX - loopGap) / cols + 10;
      const imgHeight = (canvas.height - (rows - 1) * gapY) / rows + 10;

      for (let row = 0; row < rows; row++) {
        for (let col = 0; col < cols; col++) {
          const imgIndex = row * cols + col;
          const x = col * (imgWidth + gapX);
          const y = row * (imgHeight + gapY);

          if (videoElements.current[imgIndex]) {
            ctx.drawImage(videoElements.current[imgIndex], x, y, imgWidth, imgHeight);
          } else if (imgElements.current[imgIndex]) {
            ctx.drawImage(imgElements.current[imgIndex], x, y, imgWidth, imgHeight);
          }
        }
      }
      // loopGap 区域保持透明，不需要额外绘制

      if (texture.current) {
        texture.current.needsUpdate = true;
      }
      animateId.current = requestAnimationFrame(renderFrame);
    };

    if (!texture.current) {
      texture.current = new THREE.CanvasTexture(canvas);
      texture.current.wrapS = THREE.RepeatWrapping;
      texture.current.wrapT = THREE.RepeatWrapping;
      texture.current.repeat.set(config.cols, 1);
      texture.current.minFilter = THREE.LinearFilter;
      texture.current.magFilter = THREE.LinearFilter;
      texture.current.anisotropy = Math.min(4, renderer.current.capabilities.getMaxAnisotropy());
      createCylinder();
    }

    renderFrame();
  };
  const initThree = () => {
    const containerWidth = window.innerWidth;
    const containerHeight = Math.min(window.innerHeight, 1000);
    // 相机配置
    camera.current = new THREE.PerspectiveCamera(45,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.current.position.set(0, 0, 45);
    // 渲染器配置
    renderer.current = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
      powerPreference: 'high-performance', // 添加性能优先选项
      precision: 'mediump'
    });
    // 移除 outputColorSpace 设置
    renderer.current.setPixelRatio(window.devicePixelRatio); // 优化像素比
    renderer.current.setSize(containerWidth, containerHeight);
    containerRef.current.appendChild(renderer.current.domElement);
    createTexture();
    startAnimation();
  };
  // 在组件内添加统一的视频控制方法
  const handleVideoPlayback = () => {
    videoElements.current.forEach(video => video?.play());
  };
  useEffect(() => {
    initThree();
    // 添加 resize 事件监听
    window.addEventListener('resize', handleResize);
    // 延迟开始动画
    setTimeout(() => {
      isAnimating.current = true;
      handleVideoPlayback(); // 改用统一方法
    }, 2000);

    return () => {
      clearTimeout(resizeTimer.current);
      cancelAnimationFrame(animateId.current);
      window.removeEventListener('resize', handleResize);
      videoElements.current.forEach(video => video?.pause());
      if (renderer.current) {
        renderer.current.dispose();
        scene.current.clear();
        texture.current?.dispose();
        containerRef.current?.removeChild(renderer.current.domElement);
      }
    };
  }, []); // 保持空依赖数组

  
  // 创建圆柱体
  const createCylinder = (currentConfig = getResponsiveConfig()) => {
    const geometry = new THREE.CylinderGeometry(
      currentConfig.radius,
      currentConfig.radius,
      currentConfig.height,
      64,
      1,
      true,
      Math.PI / 2,
      Math.PI
    );

    // UV坐标修正
    const uvArray = geometry.attributes.uv.array;
    for (let i = 0; i < uvArray.length; i += 2) {
      uvArray[i] *= 0.65;
    }
    geometry.attributes.uv.needsUpdate = true;

    // 材质设置
    const material = new THREE.MeshBasicMaterial({
      map: texture.current,
      side: THREE.BackSide,
    });

    const cylinder = new THREE.Mesh(geometry, material);
    scene.current.add(cylinder);
  };
  // 动画循环
  const startAnimation = () => {
    const animate = () => {
      if (!containerRef.current) return; // 组件卸载时停止
      animateId.current = requestAnimationFrame(animate);
      if (texture.current) {
        texture.current.offset.x -= config.speed;
        if (texture.current.offset.x <= -1) texture.current.offset.x = 0;
      }
      renderer.current.render(scene.current, camera.current);
    };
    animate();
  };
  // 窗口尺寸调整
  const handleResize = () => {
    // 保存当前纹理偏移量
    if (texture.current) {
      texture.current.dispose();
    }
    // 防抖处理
    clearTimeout(resizeTimer.current);
    resizeTimer.current = setTimeout(() => {
      const currentConfig = getResponsiveConfig();
      const containerWidth = window.innerWidth;
      const containerHeight = Math.min(window.innerHeight, 1000);
      renderer.current.setSize(containerWidth, containerHeight);
      // 更新相机参数
      camera.current.aspect = window.innerWidth / window.innerHeight;
      renderer.current.setPixelRatio(window.devicePixelRatio);
      camera.current.position.z = currentConfig.radius * 1.1;
      camera.current.updateProjectionMatrix();
      // 更新渲染器尺寸
      // 重建圆柱体时保留动画状态
      scene.current.clear();
      createCylinder(currentConfig);
      // 恢复纹理偏移
      if (texture.current) {
        texture.current.offset.x = savedOffset.current;
      }
      // 重启视频播放
      handleVideoPlayback();
      // 重启动画循环
      if (!animateId.current) {
        startAnimation();
      }
    }, 200); // 200毫秒防抖间隔
  };
  // 保持原有渲染逻辑不变
  return (
    <div
      ref={containerRef}
    />
  );
};

export default CylinderScroll;