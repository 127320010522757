import React from 'react';
import classNames from 'classnames';
import { localeURI } from '../../utils/util';
import welcome1 from '../images/modal_welcome_1.png';
import twitter_logo from '../images/white_twitter.png';
import './ConnectPlatforms.scss';
import check_logo from './check_logo.png';


class ConnectPlatforms extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      connectTwitch: 0,
      connectYoutube: 0,
      connectTwitter: 0,
    };
  }

  connectTwitch = () => {
    const { user } = this.props.auth;
    if (user.connectedAccounts.twitch.id || user.connectedAccounts.twitch.username) {
      return;
    }
    window.location.href = localeURI('/api/v1/auth/twitch');
    if (this.state.connectTwitch === 2) {
      this.setState({
        connectTwitch: 0,
      });
    }
  }

  connectTwitter = () => {
    const { user } = this.props.auth;
    if (user.connectedAccounts.twitter && (user.connectedAccounts.twitter.id || user.connectedAccounts.twitter.username)) {
      return;
    }
    window.location.href = localeURI('/api/v1/auth/twitter');
    if (this.state.connectTwitter === 2) {
      this.setState({
        connectTwitch: 0,
      });
    }
  }

  disconnectTwitch = () => {
    const { token, user } = this.props.auth;
    this.props.disconnectTwitch(token, user);
  }

  connectYoutube = () => {
    const { user } = this.props.auth;
    if (user.connectedAccounts.youtube.id || user.connectedAccounts.youtube.username) {
      return;
    }
    window.location.href = localeURI('/api/v1/auth/youtube');
    if (this.state.connectYoutube === 2) {
      this.setState({
        connectYoutube: 0,
      });
    }
  }

  disconnectYoutube = () => {
    const { token, user } = this.props.auth;
    this.props.disconnectYoutube(token, user);
  }

  render() {
    const { user } = this.props.auth;
    const twitterUser = user.connectedAccounts.twitter ? user.connectedAccounts.twitter.username : null;
    const twitterId = user.connectedAccounts.twitter ? user.connectedAccounts.twitter.id : null;
    const hasTwitter = !!user.connectedAccounts.twitter;

    return (
      <div className="ConnectPlatforms">
        <div className="ConnectPlatforms--squares">
          <div
            onClick={this.connectTwitch.bind(this)}
            className={classNames(
              'ConnectPlatforms--square ConnectPlatforms--square1',
              { not_connected: (this.state.connectTwitch === 0) },
              { connecting: (this.state.connectTwitch === 1) },
              { connected: (user.connectedAccounts.twitch.id || user.connectedAccounts.twitch.username) },
            )}
            onMouseOver={() => {
              this.setState({ connected_hovered: true });
            }}
            onMouseLeave={() => {
              this.setState({ connected_hovered: false });
            }}
            onFocus={() => { }}
            aria-hidden
          >
            <div className="ConnectPlatforms--border" />
            {!this.state.connected_hovered &&
              <div className="ConnectPlatforms--check" >
                <img className="check_icon" src={check_logo} alt="Welcome 1" />
              </div>
            }
            <div className="ConnectPlatforms--inner">
              <div className="ConnectPlatforms--inner1">
                <img className="responsive" src={welcome1} alt="Welcome 1" />
              </div>
              <div className="ConnectPlatforms--inner2">
                <button className="ConnectPlatforms--disconnected">Connect</button>
                <button
                  className="ConnectPlatforms--connected desktop"
                >
                  {(this.state.connected_hovered) ? `Connected as ${user.connectedAccounts.twitch.username}` : 'Connected'}
                </button>
                <button
                  className={classNames('ConnectPlatforms--connected', 'mobile',
                    { connected: (user.connectedAccounts.twitch.id || user.connectedAccounts.twitch.username) })}
                >
                  Connected as {user.connectedAccounts.twitch.username}
                </button>
              </div>
            </div>
          </div>
          <div
            onClick={this.connectTwitter.bind(this)}
            className={classNames(
              'ConnectPlatforms--square ConnectPlatforms--square2',
              { not_connected: (this.state.connectTwitter === 0) },
              { connecting: (this.state.connectTwitter === 1) },
              { connected: hasTwitter && (twitterId || twitterUser) },
            )}
            onMouseOver={() => {
              this.setState({ connected_hovered_twitter: true });
            }}
            onMouseLeave={() => {
              this.setState({ connected_hovered_twitter: false });
            }}
            onFocus={() => { }}
            aria-hidden
          >
            <div className="ConnectPlatforms--border" />
            {!this.state.connected_hovered_twitter &&
              <div className="ConnectPlatforms--check" >
                <img className="check_icon" src={check_logo} alt="Welcome 1" />
              </div>
            }
            <div className="ConnectPlatforms--inner">
              <div className="ConnectPlatforms--inner1">
                <img className="responsive" style={{ maxHeight: '87px', maxWidth: '300px' }} src={twitter_logo} alt="Twitter Logo" />
              </div>
              <div className="ConnectPlatforms--inner2">
                <button className="ConnectPlatforms--disconnected">Connect</button>
                <button className="ConnectPlatforms--connected desktop" >
                  {(this.state.connected_hovered_twitter) ? `Connected as ${hasTwitter && twitterUser}` : 'Connected'}
                </button>
                <button
                  className={classNames('ConnectPlatforms--connected', 'mobile',
                    { connected: hasTwitter && (twitterId || twitterUser) })}
                >
                  Connected as {hasTwitter && twitterUser}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ConnectPlatforms.propTypes = {
};

export default ConnectPlatforms;
