/* eslint max-len: ["error", { "code": 800 }] */
import React from 'react';
import './AboutUs.scss';
import IconParticles from '../icon-particles/IconParticles';
import brandList from '../brand-service/assetsV2/brandsIndex1';

function AboutUs() {
  return (
    <section className="AboutUs">
      <section className="AboutUs__container">
        <section className="Hero">
          <IconParticles />
        </section>
        <section className="Intro">
          <section className="Intro__content">
            <p className="Intro__content--title">About Us</p>
            <div className="Intro__content--details">
              Noiz was conceptualized by a few passionate gamers seeing a gap in the gaming industry. Our goal is to support and provide tools for passionate streamers while amplifying marketing campaigns targeted at the video game audience.
              <br />
              <br />
              Our team is comprised of esports and gaming industry veterans- who have worked with companies like Major League Gaming, Riot Games, Activision, THQ, Fnatic, and more.
              <br />
              <br />
              We aim to help both aspiring game streamers and game companies alike reach a wider audience, diversify marketing spend and income streams, and build their own brands easily and professionally at large scale. If you want to revolutionize game influencer marketing by making it more fun and accessible to all streamers and game companies, check out our jobs page!
            </div>
          </section>
        </section>
        <section className="Brands">
          <section className="Brands__content">
            <h3 className="Brands__content--title">BRANDS WE'VE WORKED WITH</h3>
            <section className="Brands__logos-wrapper">
              {brandList.map((logo, i) => (
                <section className="Brands__logos-wrapper--logo" key={i}>
                  <img alt="brand logo" src={logo} />
                </section>
              ))}
            </section>
          </section>
        </section>
      </section>
    </section>
  );
}

export default AboutUs;