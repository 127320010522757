import React from 'react';
import { Link } from 'react-router';
import logo from './noiz-logo.png';
import logoBlack from './noiz-logo-black.png';

const styles = {
  link: {
    display: 'flex',
  },
};

function Logo({ black }) {
  return (
    <Link
      href="/"
      style={styles.link}
      to="/"
    >
      <div
        className="logo"
        style={{ backgroundImage: `url(${black ? logoBlack : logo})`}}
      />
    </Link>
  );
}

export default Logo;
