/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link } from 'react-router';
import {orderBy, find, without} from 'lodash';
import moment from 'moment-timezone';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  styled,
  Tooltip,
  Typography
} from '@material-ui/core';
import { simpleNotification } from '../../../../utils/notifications';
// import { Field, FieldArray, reduxForm } from 'redux-form';
// import CustomDropDownArray from '../../../../utils/redux-forms-custom-fields/CustomDropDownArray';
import './CDKeys.scss';
import Select from 'react-select';
import {arrayDropDownColors} from "../../../../utils/constants";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {withStyles} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {getCurryFormattedString} from "../../../../utils/functions";
import Dropzone from "react-dropzone";
import CloseIcon from '@material-ui/icons/Close'
import PromptDialog from "../../../dialog/PromptDialog";
import wcIcon from '../../../../images/dialog/wc.png';

const quickWidth = {};
// const quickWidth = {maxWidth: '10%'}

const TableHeaderCell = withStyles(theme => ({
  head: {
    backgroundColor: '#141414',
  fontWeight: '500',
  whiteSpace: 'nowrap',
  borderColor: '#323232',
  },
}))(TableCell);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    overflowY:'hidden',
    overflowX:'hidden'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paperWidthSm': {
    backgroundColor: '#322d38',
    borderRadius:'8px',
    display:'flex',
    alignItems:'center',
    width:'600px',
    padding:'0px'
  },
  '& .MuiDialogContent-dividers': {
    borderTop:'0px',
    borderBottom:'0px',
    backgroundColor:'none',
    width:'100%'
  },
  '& .MuiTypography-h6': {
    textTransform:'none'
  },
  '& .input': {
    width:'100%',
    padding:'10px',
    backgroundColor:'#000000',
    border:'1px solid #3d3d3d',
    borderRadius:'4px',
    color:'#ffffff',
  },
  '& .input:focus': {
    outline:'1px solid #0d6efd',
  },
  '& .input_error': {
    width:'100%',
    padding:'10px',
    backgroundColor:'#1a1a1a',
    border:'2px solid #ff0000',
    borderRadius:'4px',
    color:'#ffffff',
  },
  '& .input_readonly': {
    width:'100%',
    padding:'10px',
    backgroundColor:'#000000',
    border:'2px solid #3d3d3d',
    borderRadius:'4px',
    color:'#6b5c5c',
  },
  '& select': {

  },
  '& Button': {
    padding:'10px 20px',
    border:'none',
    borderRadius:'4px',
    cursor:'pointer',
    fontSize:'14px',
    transition:'background 0.3s',
    fontFamily:'',
    textTransform:'capitalize',
  },
  '& .select_error': {
    border:'2px solid #ff0000',
    borderRadius:'4px',
    color:'#ffffff',
  },
  '& .css-1pcexqc-container': {
    width:'100%'
  },
  '& .css-ga0n51-control':{
    backgroundColor:'#000000'
  },
  '& .MuiDialogTitle-root':{
    backgroundColor:'none'
  },
  '& .block_5': {
  width: '537px',
  height: '95px',
  margin: '30px 0 0 29px'
},
  '& .flex-col': {
    display: 'flex',
    flexDirection: 'column'
  },
     '& .flex-row': {
  display: 'flex',
  flexDirection: 'row'
},
    '& .justify-between': {
  display: 'flex',
  justifyContent: 'space-between'
},
   '& .text-wrapper_7': {
  height: '95px',
  background:` url(${wcIcon}) 100% no-repeat`,
  backgroundSize: '100% 100%',
  width: '95px',
     marginTop:'15px'
},
  '& .text-wrapper_8': {
  width: '412px',
  height: '88px',
  marginTop: '3px'
},
  '& .text_11': {
  width: '10px',
  height: '46px',
  overflowWrap:'break-word',
  color: 'rgba(239, 211, 55, 1)',
  fontSize: '57px',
  fontFamily: 'Adobe Heiti Std R',
  fontWeight: 'NaN',
  textAlign: 'left',
  whiteSpace: 'nowrap',
  lineHeight: '67px',
  margin: '17px 0 0 40px'
},
  '& .text_12': {
  width: '49px',
  height: '24px',
  overflowWrap: 'break-word',
  color: 'rgba(255, 255, 255, 1)',
  fontSize: '24px',
  fontFamily: 'MicrosoftYaHei-Bold',
  fontWeight: '700',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  lineHeight: '18px'
},
  '& .paragraph_1': {
  width: '412px',
  height: '40px',
  overflowWrap: 'break-word',
  color: 'rgba(205, 204, 208, 1)',
  fontSize: '16px',
  fontFamily: 'MicrosoftYaHei',
  fontWeight: 'NaN',
  textAlign: 'left',
  lineHeight: '24px',
  marginTop: '24px'
},
  '& .block_6': {
  width: '542px',
  height: '154px',
  margin: '59px 0 0 29px'
},
  '& .text-wrapper_9': {
  backgroundColor: 'rgba(33, 28, 39, 1)',
  borderRadius: '7px',
  height: '154px',
  width: '542px',
    overflowY:'auto'
},
  '& .block_7': {
  width: '250px',
  height: '48px',
  margin: '0px 0 0px 0px'
},
  '& .paragraph_2': {
  width: '500px',
  height: '82px',
  overflowWrap: 'break-word',
  color: 'rgba(205, 204, 208, 1)',
  fontSize: '16px',
  fontFamily: 'MicrosoftYaHei',
  fontWeight: 'NaN',
  textAlign: 'left',
  lineHeight: '34px',
  margin: '0 0 0 24px'
},
  '& .text-wrapper_10': {
  height: '48px',
  backgroundSize: '100% 100%',
  width: '250px',
    backgroundColor:'#ff0000',
    borderRadius:'100px',
    cursor:'pointer',
    justifyContent:'center'
},
'& .text_13': {
  width: '100%',
  height: '24px',
  overflowWrap: 'break-word',
  color: 'rgba(255, 255, 255, 1)',
  fontSize: '18px',
  fontFamily: 'MicrosoftYaHei',
  fontWeight: 'NaN',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  lineHeight: '24px',
  margin: '0px 0 0 0px'
},


}));

class CDKeys extends React.Component {
  constructor(props) {
    super(props);
    this.idCopy = React.createRef();
    this.props = props;
    this.dropzoneRef = null;
    this.state = {
      copyModal: false,
      questCopyId: null,
      selectGame: null,
      tab: 1,
      dialogAddProduct:false,
      productNameError:false,
      productPrice:0,
      productDiscount:0,
      productDiscountPrice:0,
      productPlatform:null,
      productPlatformError:false,
      productName:"",
      productPriceError:false,
      productDiscountError:false,
      game:null,
      productPlatforms:[],
      search:"",
      numPage: 1,
      filters: {
        searchAll: true,
        searchSubject: false,
        searchNoizEmail: false,
        searchNoiz: false,
        paypalStatusAll: true,
        paypalStatusSuccess: false,
        paypalStatusDenied: false,
        paypalStatusPending: false,
        paypalStatusRefunded: false,
        paypalStatusPartiallyRefunded: false,
        searchValue: '',
        searchPlatform:"",
      },
      filtersClear: {
        searchAll: true,
        searchSubject: false,
        searchNoizEmail: false,
        searchNoiz: false,
        paypalStatusAll: true,
        paypalStatusSuccess: false,
        paypalStatusDenied: false,
        paypalStatusPending: false,
        paypalStatusRefunded: false,
        paypalStatusPartiallyRefunded: false,
        searchValue: '',
        searchPlatform:"",
      },
      sorting: false,
      editProductId:0,
      editProductName:"",
      editProductType:"",
      editProductPrice:"",
      editProductPlatform:null,
      uploadProductId:"",
      file:null,
      dropzoneActive:false,
      saleoutProductPlatformName:"",
      dialogProductOpen:false,
      editProductStatus:0,
      dialogCDKeysOpen:false,
      cdKeys:[],
      productNameTip:"",
      productPlatformTip:"",
      cdKeyTip:"",
      cdKeysSel:[],
      dialogCDKeysDelConfirmOpen:false,
      delCDKeys:[],
      searchPlatform:null,
      editProductDiscount:"",
      dialogCDKeysRepeatConfirmOpen:false,
      dialogCDKeysRepeatOpen:false,
      invalidKeys:[],
      repeatKeys:[],
      repeatPlatform:"",
      dialogSuccessOpen:false,
      uploadSuccess:{enter:0,repeat:0,platform:''},
    };
    //this.productPlatforms = [];
  }
  componentDidMount() {
    if (this.props.commandCenter.games.data.length > 0) {
      this.setState({productPlatforms: this.platformOptions()});
    }
    this.doSearch(1);
    this.doSearchSailRecords(1);
    //console.log(this.productPlatforms);
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.commandCenter.cdKeyProduct.isLoading === true && this.props.commandCenter.cdKeyProduct.isLoading === false)) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (this.props.commandCenter.cdKeyProduct.errors.hasError) {
        simpleNotification({
          level: "error",
          title: this.props.commandCenter.cdKeyProduct.errors.message,
          message: "Product failed to create!",
        });
      }else{
        //this.handleRegionClose();
        simpleNotification({
          level: "success",
          title: "Product creation successful.",
          message: "Product has been successfully created!",
        });
        this.handleProductClose();
        this.doSearch(1);
      }
    }
    if ((prevProps.commandCenter.cdKeyProduct_m.isLoading === true && this.props.commandCenter.cdKeyProduct_m.isLoading === false)) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (this.props.commandCenter.cdKeyProduct_m.errors.hasError) {
        simpleNotification({
          level: "error",
          title: this.props.commandCenter.cdKeyProduct_m.errors.message,
          message: "Product failed to operate!",
        });
      }else{
        //this.handleRegionClose();
        simpleNotification({
          level: "success",
          title: "Product operate successful.",
          message: "Product has been successfully operate!",
        });
        this.setState({editProductId:0,editProductName:""});
        this.doSearch(1);
      }
      this.setState({editProductStatus:0});
    }
    if ((prevProps.commandCenter.uploadCDKeys.isLoading === true && this.props.commandCenter.uploadCDKeys.isLoading === false)) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (this.props.commandCenter.uploadCDKeys.errors.hasError) {
       // console.log(this.props.commandCenter.uploadCDKeys.errors.code);
        //if(this.props.commandCenter.uploadCDKeys.errors.code==='E_NO_VALID_CDKEYS'){
//console.log(this.props.commandCenter.uploadCDKeys.data,this.props.commandCenter.uploadCDKeys.errors.data,this.props.commandCenter.uploadCDKeys.errors.all,this.props.commandCenter.uploadCDKeys);
         // this.setState({dialogCDKeysRepeatConfirmOpen:true,invalidKeys:this.props.commandCenter.uploadCDKeys.data?.repeatedKeys});
        //}else {
          simpleNotification({
            level: "error",
            title: this.props.commandCenter.uploadCDKeys.errors.message,
            message: "CDKEY failed to upload!",
          });
       // }
      }else{
        //this.handleRegionClose();
        if(this.props.commandCenter.uploadCDKeys.code==='E_NO_VALID_CDKEYS'){
          //console.log(this.props.commandCenter.uploadCDKeys.data,this.props.commandCenter.uploadCDKeys.errors.data,this.props.commandCenter.uploadCDKeys.errors.all,this.props.commandCenter.uploadCDKeys);
          this.setState({dialogCDKeysRepeatConfirmOpen:true,invalidKeys:this.props.commandCenter.uploadCDKeys.data?.repeatedKeys,repeatPlatform:this.props.commandCenter.uploadCDKeys.data.platform});
        }else if(this.props.commandCenter.uploadCDKeys.code==='E_FINDING_DUPLICATED_KEY'){
          //console.log(this.props.commandCenter.uploadCDKeys.data,this.props.commandCenter.uploadCDKeys.errors.data,this.props.commandCenter.uploadCDKeys.errors.all,this.props.commandCenter.uploadCDKeys);
          this.setState({dialogCDKeysRepeatOpen: true,repeatKeys:this.props.commandCenter.uploadCDKeys.data.repeatedKeys,repeatPlatform:this.props.commandCenter.uploadCDKeys.data.platform});
        }else {
         // simpleNotification({
          //  level: "success",
          //  title: "CDKEY upload successful.uploaded:" + this.props.commandCenter.uploadCDKeys.data?.createdTotal + ",repeated:" + this.props.commandCenter.uploadCDKeys.data?.repeatedTotal,
         //   message: "CDKEY has been successfully upload!",
         // });
          //if(this.props.commandCenter.uploadCDKeys.data.repeatedKeys&&this.props.commandCenter.uploadCDKeys.data.repeatedKeys.length>0){
          //  this.setState({dialogCDKeysRepeatOpen: true,repeatKeys:this.props.commandCenter.uploadCDKeys.data.repeatedKeys});
         // }else{
            this.setState({dialogSuccessOpen: true,uploadSuccess:{enter:this.props.commandCenter.uploadCDKeys.data?.createdTotal,platform:this.props.commandCenter.uploadCDKeys.data?.platform}});
          //}
          this.setState({uploadProductId: 0});
          this.doSearch(1);
        }
      }
    }
    if ((prevProps.commandCenter.CDKeys_d.isLoading === true && this.props.commandCenter.CDKeys_d.isLoading === false)) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (this.props.commandCenter.CDKeys_d.errors.hasError) {
        simpleNotification({
          level: "error",
          title: this.props.commandCenter.CDKeys_d.errors.message,
          message: "CDKEY failed to delete!",
        });
      }else{
        //this.handleRegionClose();
        //console.log('data',this.props.commandCenter.CDKeys_d.data);
        simpleNotification({
          level: "success",
          title: "CDKEY delete successful.uploaded:"+this.props.commandCenter.CDKeys_d.data?.createdTotal+",repeated:"+this.props.commandCenter.CDKeys_d.data?.repeatedTotal,
          message: "CDKEY has been successfully delete!",
        });
        let cdKeys=this.state.cdKeys.filter(cdKey=>(this.props.commandCenter.CDKeys_d.data.indexOf(cdKey.id)<=-1&&cdKey.status==='active'));
        this.setState({cdKeysSel:[],delCDKeys:this.props.commandCenter.CDKeys_d.data,cdKeys:cdKeys});
      }
    }
    if ((prevProps.commandCenter.games.isLoading === true && this.props.commandCenter.games.isLoading === false)) {
      if (this.props.commandCenter.games.data.length > 0) {
        this.setState({productPlatforms: this.platformOptions()});
      }
    }
  }

  doSearch=(numPage)=>{
    const { token } = this.props.auth;
    const { gameId } = this.props.router.params;
    if (!this.props.commandCenter.cdKeyProduct.isLoading) {
      //this.props.getRegions(numPage?numPage:this.state.numPage,this.state.search?this.state.search:' ',  JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token, 0);
      //not paging
      this.props.getCDKeyProducts(gameId,0,' ', "{}", false, token, 0);
    }
  }

  doSearchSailRecords=(numPage)=>{
    const { token } = this.props.auth;
    const { gameId } = this.props.router.params;
    if (!this.props.commandCenter.CDKRecords.isLoading) {
      //this.props.getRegions(numPage?numPage:this.state.numPage,this.state.search?this.state.search:' ',  JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token, 0);
      //not paging
      this.props.getCDKRecords(gameId,0,this.state.search?this.state.search:',',  JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token, 0);
    }
  }

  copyText = (event) => {
    const text = event.target.innerHTML;
    if(navigator&&navigator.clipboard) {
      navigator.clipboard.writeText(text);
      simpleNotification({
        level: 'success',
        title: 'Successfully Copied',
        message: `${text}`,
        autoDismiss: 2,
      });
    }
  }

  generateRowsHTML = (quests, gameId) => {
    const today = moment().tz('America/Los_Angeles');
    const hoursOffset = -today.utcOffset() / 60;

    if (quests.length >= 1) {
      return quests.map((quest, index) => (
        <div className="row" key={quest.id}>
          <div className="table-cell">{quest.title}</div>
          <div
            className="table-cell"
            ref={this.idCopy}
            style={{ cursor: 'pointer', ...quickWidth }}
            onClick={e => this.copyText(e)}
            aria-hidden
          >
            {quest.id}
          </div>
          <div className="table-cell">{quest.startDateTime ? moment(quest.startDateTime).tz('America/Los_Angeles').add(hoursOffset, 'hours').format('MM-DD-YY hh:mm A') : '-'}</div>
          <div className="table-cell">{quest.endDateTime ? moment(quest.endDateTime).tz('America/Los_Angeles').add(hoursOffset, 'hours').format('MM-DD-YY hh:mm A') : '-' }</div>
          <div className="table-cell">
            { (quest.slug)
                &&
                <Link
                  to={`/quests/${quest.slug}`}
                  activeClassName="active"
                  href={`/quests/${quest.slug}`}
                  className="remove-key"
                  target="_blank"
                >
                  <i className="fa fa-external-link" />
                </Link> }
            { !(quest.slug)
                &&
                <span className="not-available">
                  Not Available
                </span>
              }
          </div>
          <div className="table-cell">
            {quest.needsReview}
          </div>
          <div className="table-cell">
            <Tooltip title="Edit" placement="top">
              <Link
                to={`/admin/command-center/${gameId}/quests/${quest.id}/edit`}
                activeClassName="active"
                href={`/admin/command-center/${gameId}/quests/${quest.id}/edit`}
                className="remove-key"
              >
                <i className="fa fa-pencil" />
              </Link>
            </Tooltip>
            { (quest.requirePreapproval) &&
              <Tooltip title="Preapproval" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/preapproval`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/preapproval`}
                  className="remove-key"
                >
                  <i className="fa fa-check-square-o" />
                </Link>
              </Tooltip>
              }
            { (quest.type === 'paid-bounty' || quest.type === 'paid bounty') &&
              <Tooltip title="Details" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/activity`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/activity`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
              }
            { (quest.type === 'referral') &&
              <Tooltip title="Details" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/sales`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/sales`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
              }
            { (quest.type === 'timed') &&
              <Tooltip title="Details" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/timed`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/timed`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
              }
            { (quest.type === 'general' || quest.type === 'default' || quest.type === 'basic') &&
              <Tooltip title="Details" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/${'general'}`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/${'general'}`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
              }
            { (quest.type === 'affiliate') &&
              <Tooltip title="Details" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/affiliate`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/affiliate`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
              }
            { ((quest.type === 'tiered' || quest.type === 'tiered-one-time') && quest.frequency === 'one-time') &&
              <Tooltip title="Details" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/${quest.type}`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/${quest.type}`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
              }
            { ((quest.type === 'tiered' || quest.type === 'tiered-multi-days') && quest.frequency === 'daily') &&
              <Tooltip title="Details" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/tiered-multiple-days`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/tiered-multiple-days`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
              }
            { moment(quest.startDateTime).diff(moment(Date.now())) > 0 &&
              <Tooltip title="Delete" placement="top">
                <button className="remove-key" onClick={() => { this.deleteQuest(quest, index); }}>
                  <i className="fa fa-trash" />
                </button>
              </Tooltip>
            }
            <Tooltip title="Duplicate" placement="top">
              <button className="remove-key" onClick={() => { this.setState({ copyModal: true, questCopyId: quest.id, selectGame: gameId }); }}>
                <i className="fa fa-copy" />
              </button>
            </Tooltip>
          </div>
        </div>
      ));
    }
    return (<div className="NoQuests">No Quests for this game</div>);
  }

  deleteQuest(quest, index) {
    if (window.confirm('This will delete the selected Quest, proceed?')) {
      const { token } = this.props.auth;
      fetch(`/api/v1/admin/quest/${quest.id}`, {
        method: 'delete',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        this.props.commandCenter.quests.data.splice(index, 1);
        this.setState({ quests: this.props.commandCenter.quests.data });
      });
    }
  }

  copyCCQuest() {
    if (window.confirm('Are you sure about copy this Quest?')) {
      const { token } = this.props.auth;
      this.props.copyCCQuest(this.state.questCopyId, this.state.selectGame, token);
      this.setState({ copyModal: false });
    }
  }

  closeCopyModal = () => {
    this.setState({
      copyModal: false,
    });
  }

  renderCopyModal() {
    const { data: games } = this.props.commandCenter.games;
    // const { gameId } = this.props.router.params;
    let configGames = games.map(game => ({
      value: game.id,
      label: game.name,
    }));
    configGames = orderBy(configGames, ['label'], ['asc']);
    const renderSelectOptions = option => (<option key={option.value} value={option.value}>{option.label}</option>);
    return (
      <section className="ModalMaster active">
        <section className="Modal active">
          <button onClick={this.closeCopyModal.bind(this)} className="Modal__layer" aria-hidden />
          <section className="Modal__wrapper Copy">
            <div className="Modal__copy__header">
              Select the game
            </div>
            <div className="Modal__copy__body">
              <select
                onChange={event => this.setState({ selectGame: event.target.value })}
                className=""
                value={this.state.selectGame}
              >
                { configGames.map(renderSelectOptions) }
              </select>
            </div>
            <div className="Modal__copy__footer">
              <button
                onClick={this.closeCopyModal.bind(this)}
              >
                <i className="fa fa-times" /> Cancel
              </button>
              <button
                onClick={this.copyCCQuest.bind(this)}
              >
                <i className="fa fa-check" /> Copy
              </button>
            </div>
          </section>
        </section>
      </section>
    );
  }

  handleProductClose = () => {
    this.setState({ dialogAddProduct: false,productName: "",productPlatform:null,productPrice:0});
  };

  handleProductOpen = () => {
    this.setState({ dialogAddProduct: true});
  };

  addProduct=(event)=>{
    event.preventDefault();
    //this.props.approveRollOut(this.state.withdrawOrder.orderId,1,token);
    if(!this.state.productName||this.state.productName.trim().length<1){
      this.setState({ productNameError: true});
      return;
    }

    if(!this.state.productPlatform||!this.state.productPlatform.value||this.state.productPlatform.value.trim().length<1){
      this.setState({ productPlatformError: true});
      return;
    }

    const priceRegex = /^(0|[1-9]\d*)(\.\d{1,2})?$/;
    if(!this.state.productPrice||this.state.productPrice.toString().length<1||!priceRegex.test(this.state.productPrice.toString())){
      this.setState({ productPriceError: true});
      return;
    }
    if(this.state.productDiscount===undefined||this.state.productDiscount.toString().length<1||!priceRegex.test(this.state.productDiscount.toString())){
      this.setState({ productDiscountError: true});
      return;
    }
    const salePrice = Number((this.state.productPrice*(100-this.state.productDiscount)/100).toFixed(2));
    if(salePrice<=0){
      this.setState({ productDiscountError: true});
      simpleNotification({
        level: "error",
        title: "The discount cannot be too large",
        message: "CDKEY product failed to modify!",
      });
      return;
    }
   // const data = {productName:this.state.productName,productPlatform:this.state.productPlatform,productPrice:this.state.productPrice};
    const { gameId } = this.props.router.params;
    const { token } = this.props.auth;
    this.props.createCDKeyProduct(gameId,this.state.productName,this.state.productPlatform?.value,this.state.productPrice,this.state.productDiscount,this.state.productDiscountPrice, token);
    //this.setState({ regionName: "",currencyName:this.currys[0].code,timezoneName:this.timezones[0]});
    //this.handleRegionClose();
    //this.setState({ regionsSubmit: true});
    //simpleNotification({
    //  level: "success",
    //  title: "Region add successful.",
    //  message: "Region has not been permanently saved yet. Please set up the application in a timely manner to save it!",
    //});

  }

  platformOptions = () => {
    const { gameId } = this.props.router.params;
    const game = find(this.props.commandCenter.games.data, game => game.id === gameId);
    if (game) {
      this.setState({
        game,
      },()=>{
        const grouped = game.platforms.reduce((acc, pl) => {
          if (!acc[pl.baseService]) {
            acc[pl.baseService] = [];
          }
          acc[pl.baseService].push(pl);
          return acc;
        }, {});
        const productPlatforms = Object.keys(grouped).map(group => ({
          label: group,
          options: grouped[group].map(({ id, platform }) => ({
            value: id,
            label: <span style={{ zIndex: '1000' }}>{platform}</span>,
          })),
        }));
        this.setState({productPlatforms:productPlatforms});
      });
    }

  }

  handleEditProductName=(productId,productName)=>{
    this.setState({
      editProductId: productId,
      editProductName: productName,
      editProductType:"name",
    });
  }

  hanleSaveProductName=(event)=>{
    event.preventDefault();
    const { editProductId,editProductName } = this.state;
    const { token } = this.props.auth;
    this.props.updateCDKeyProduct(editProductId,editProductName,0,'',0,0, token);
  }

  handleChangeProducePrice=(event,discount)=>{
    const productPrice = event.target.value;
    const priceRegex = /^[0-9]*\.?[0-9]*$/;
    if(!productPrice||!priceRegex.test(productPrice)||isNaN(productPrice)||Number(productPrice)<0){
      this.setState({editProductPrice: ""});
    }else{
      //const salePrice = Number((Number(productPrice)*(100-discount)/100).toFixed(2));
      //if(salePrice<=0){
      //  this.setState({editProductPrice: ""});
     // }else{
        this.setState({editProductPrice: productPrice});
      //}
    }
  }

  handleEditProductPrice=(productId,productPrice)=>{
      this.setState({
        editProductId: productId,
        editProductPrice: productPrice,
        editProductType: "price",
      });
  }

  hanleSaveProductPrice=(event,discount)=>{
    event.preventDefault();
    const { editProductId,editProductPrice } = this.state;
    const { token } = this.props.auth;
    const salePrice = Number((editProductPrice*(100-discount)/100).toFixed(2));
    if(editProductPrice<=0||salePrice<=0){
      this.setState({editProductPrice: ""});
      simpleNotification({
        level: "error",
        title: "The price cannot be too small",
        message: "CDKEY product failed to modify!",
      });
    }else {
      this.props.updateCDKeyProduct(editProductId, '', 0, '', editProductPrice, 0, token);
    }
  }

  handleChangeProduceDiscount=(event,price)=>{
    const productDiscount = event.target.value;
    const priceRegex = /^[0-9]*\.?[0-9]*$/;
    if(!productDiscount||!priceRegex.test(productDiscount)||isNaN(productDiscount)||Number(productDiscount)<0||Number(productDiscount)>100){
      this.setState({editProductDiscount: ""});
    }else{
      const salePrice = Number((price*(100-productDiscount)/100).toFixed(2));
      //console.log(salePrice);
      if(salePrice<=0){
        this.setState({editProductDiscount: ""});
        simpleNotification({
          level: "error",
          title: "The discount cannot be too large",
          message: "CDKEY product failed to modify!",
        });
      }else{
        this.setState({editProductDiscount: productDiscount});
      }
    }
  }
  handleEditProductDiscount=(productId,productDiscount)=>{
    this.setState({
      editProductId: productId,
      editProductDiscount: productDiscount,
      editProductType: "discount",
    });
  }

  hanleSaveProductDiscount=(event,price)=>{
    event.preventDefault();
    const { editProductId,editProductDiscount } = this.state;
    const { token } = this.props.auth;
    const salePrice = Number((price*(100-editProductDiscount)/100).toFixed(2));
    if(salePrice<=0){
      this.setState({editProductDiscount: ""});
      simpleNotification({
        level: "error",
        title: "The discount cannot be too large",
        message: "CDKEY product failed to modify!",
      });
    }else {
      this.props.updateCDKeyProduct(editProductId, '', 0, '', 0, editProductDiscount, token);
    }
  }

  hanleChangeProductSaleStatus=(event,editProductId,status,editProductPlatformName,cdKeyCount,price)=>{
    event.preventDefault();
    const { token } = this.props.auth;
    if(status===2){
      this.setState({
        editProductId: editProductId
      });
      this.handleSaleoutOpen(event,editProductId,status,editProductPlatformName);
      return;
    }else{
      if(cdKeyCount<=0){
        simpleNotification({
          level: "error",
          title: 'sale failure',
          message: "The remaining quantity of CDK is 0",
        });
        return;
      }else{
        if(price<=0){
          simpleNotification({
            level: "error",
            title: 'sale failure',
            message: "The unit price cannot be 0",
          });
          return;
        }else{
          this.setState({
            editProductStatus: status
          });
          this.props.updateCDKeyProduct(editProductId,'',status,'',0,0, token);
        }
      }
    }

  }

  handleEditProductPlatform=(productId,productPlatform)=>{
    const { productPlatforms } = this.state;
    const initOption = productPlatforms?.find(option=>option.options?.find(itemOption=>itemOption.value===productPlatform))?.options.find(itemOption=>itemOption.value===productPlatform);
    //console.log(productPlatforms,'initOption',initOption);
    this.setState({
      editProductId: productId,
      editProductPlatform: initOption,
      editProductType:"platform",
    });
  }

  hanleSaveProductPlatform=(event)=>{
    event.preventDefault();
    const { editProductId,editProductPlatform } = this.state;
    const { token } = this.props.auth;
    this.props.updateCDKeyProduct(editProductId,'',0,editProductPlatform?.value,0,0, token);
  }

  uploadKeys() {
    const { token } = this.props.auth;
    const { uploadProductId } = this.state;
    const formData = new FormData();
    formData.append('file', this.state.file);
    // Upload the file
    this.props.uploadCDKeys(formData, uploadProductId,0, token);
  }

  onDragEnter() {
    this.setState({ dropzoneActive: true });
  }

  onDragLeave() {
    this.setState({ dropzoneActive: false });
  }

  onDrop(files, e) {
    const csv = files && files.length ? files[0] : e[0];
    this.setState({ dropzoneActive: false, file: csv });
  }

  handleSaleoutClose = () => {
    this.setState({ dialogProductOpen: false,saleoutProductPlatformName:""});
  };

  handleSaleoutOpen = (event,editProductId,status,editProductPlatformName) => {
    event.preventDefault();
    this.setState({ dialogProductOpen: true,saleoutProductPlatformName:editProductPlatformName});
  };

  handleSaleout = (event) => {
    event.preventDefault();
    const { token } = this.props.auth;
    const { editProductId } = this.state;
    this.setState({
      editProductStatus: 2
    });
    this.props.updateCDKeyProduct(editProductId,'',2,'',0,0, token);
    this.handleSaleoutClose();
  };

  handleCDKeysOpen=(cdKeys,productName,productPlatform)=>{
    this.setState({
      dialogCDKeysOpen:true,
      cdKeys:cdKeys,
      productNameTip:productName,
      productPlatformTip:productPlatform,
    });
  }

  handleCDKeysClose = () => {
    this.setState({ dialogCDKeysOpen: false,cdKeysSel: []});
    //this.countryList=[];
    //this.props.getUserWallet();
    this.doSearch(1);
  };

  onChangeCDKeysSel(cdKeyId) {
    const { cdKeysSel } = this.state;

    if (cdKeysSel.indexOf(cdKeyId) <= -1) {
      this.setState({
        cdKeysSel: [
          ...cdKeysSel,
          cdKeyId,
        ],
      });
    } else {
      this.setState({
        cdKeysSel: [
          ...without(cdKeysSel, cdKeyId),
        ],
      });
    }
  }

  selectAllKeys() {
    const { cdKeys } = this.state;
    this.setState({
      cdKeysSel: cdKeys.map(obj => obj.id),
    });
  }

  clearSelection = () => {
    this.setState({
      cdKeysSel: [],
    });
  };

  openDeleteConfirmationModal(productName,platformName) {
    this.setState({
      dialogCDKeysDelConfirmOpen: true,
      productNameTip:productName,
      productPlatformTip:platformName,
    });
  }

  handleDeleteConfirmationClose = () => {
    this.setState({ dialogCDKeysDelConfirmOpen: false,saleoutProductPlatformName:""});
  };

  handleSuccessConfirmationClose = () => {
    this.setState({ dialogSuccessOpen: false});
  };

  deleteCDKeys() {
    const { token } = this.props.auth;
    const { cdKeysSel } = this.state;
    this.props.deleteCDKeys(JSON.stringify(cdKeysSel), token);
    this.handleDeleteConfirmationClose();
  }

  handleConfirmationWarnClose = () => {
    this.setState({ dialogCDKeysRepeatConfirmOpen: false});
  };

  handleRepeatWarnClose = () => {
    this.setState({ dialogCDKeysRepeatOpen: false});
  };

  reUploadKeys = () => {
    const { token } = this.props.auth;
    const { uploadProductId } = this.state;
    const formData = new FormData();
    formData.append('file', this.state.file);
    // Upload the file
    this.props.uploadCDKeys(formData, uploadProductId,1, token);
    this.handleConfirmationWarnClose();
  };

  handleChangeNewProduceDiscount=(event)=>{
    const productDiscount = event.target.value;
    let { productPrice } = this.state;
    const priceRegex = /^[0-9]*\.?[0-9]*$/;
    if(!productPrice){
      productPrice=0;
    }
    if(!productDiscount||!priceRegex.test(productDiscount)||isNaN(productDiscount)||Number(productDiscount)<0||Number(productDiscount)>100){
      this.setState({productDiscount: "",productDiscountError: false});
    }else{
      const salePrice = Number((productPrice*(100-productDiscount)/100).toFixed(2));
      if(salePrice<=0){
        this.setState({productDiscount: "",productDiscountError: false,productDiscountPrice:productPrice});
        simpleNotification({
          level: "error",
          title: "The discount cannot be too large",
          message: "CDKEY product failed to modify!",
        });
      }else{
      this.setState({productDiscount: productDiscount,productDiscountPrice:salePrice,productDiscountError: false});
      }
    }
  }

  handleChangeNewProducePrice=(event)=>{
    const productPrice = event.target.value;
    let { productDiscount } = this.state;
    const priceRegex = /^[0-9]*\.?[0-9]*$/;
    if(!productDiscount){
      productDiscount=0;
    }
    if(!productPrice===undefined||!priceRegex.test(productPrice)||isNaN(productPrice)||Number(productPrice)<0){
      this.setState({productPrice: "",productPriceError: false});
    }else{
      //const salePrice = Number((productPrice*(100-productDiscount)/100).toFixed(2));
      //if(salePrice<=0){
      //  this.setState({productPrice: "",productPriceError: false});
      //  simpleNotification({
      //    level: "error",
      //    title: "The price cannot be too small",
       //   message: "CDKEY product failed to modify!",
       // });
     // }else{
      this.setState({productPrice: productPrice,productDiscountPrice:Number((productPrice*(100-productDiscount)/100).toFixed(2)),productPriceError: false});
      //}
    }
  }

  render() {
    const { data: records=[], isLoading } = this.props.commandCenter.CDKRecords||{};
    const { tab,dialogAddProduct,productNameError,productName,productPlatform,productPlatformError,productPrice,productPriceError,productDiscount,
      productDiscountError,productDiscountPrice,saleoutProductPlatformName,dialogProductOpen,dialogCDKeysOpen,dialogCDKeysDelConfirmOpen,dialogCDKeysRepeatConfirmOpen,dialogCDKeysRepeatOpen} = this.state;
    const { data: cdKeyProducts=[]} = this.props.commandCenter.cdKeyProducts||{};
    if (isLoading) {
      return (<i className="fa fa-spinner fa-spin fa-3x fa-fw" />);
    }
    const sum=cdKeyProducts.reduce((accumulator,product)=>{
      accumulator.availableNum+=product.availableNum;
      accumulator.pendingNum+=product.pendingNum;
      accumulator.soldNum+=product.soldNum;
      accumulator.totalCDKs+=product.soldNum+product.pendingNum+product.availableNum;
      accumulator.totalRevenue+=product.soldRevenue;
      return accumulator;
    },{availableNum:0,pendingNum:0,soldNum:0,totalCDKs:0,totalRevenue:0});
    //const today = moment().tz('America/Los_Angeles');
    //const hoursOffset = -today.utcOffset() / 60;

    //let activeQuests = filter(records, x => (moment(x.endDateTime).tz('America/Los_Angeles').add(hoursOffset, 'hours') > today));
    //let finishedQuests = filter(records, x => (moment(x.endDateTime).tz('America/Los_Angeles').add(hoursOffset, 'hours') <= today));

    //activeQuests = orderBy(activeQuests, ['endDateTime'], ['desc']);
    //finishedQuests = orderBy(finishedQuests, ['endDateTime'], ['desc']);

    if (tab === 1) {
      //showQuests = [...activeQuests];
    } else if (tab === 2) {
      //showQuests = [...finishedQuests];
    }

    return (

    <section className="CCCDKeys">

      <div className="card mb-4">
        <div className="card-header">
          <h5 className="mb-0">Sales Statistics</h5>
        </div>
        <div className="card-body">
          <div className="row g-3 mb-4">
            <div className="col-md-3">
              <div className="stats-card">
                <h6>Total CDKs</h6>
                <span id="totalCdk">{sum?.totalCDKs}</span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="stats-card">
                <h6>Sold</h6>
                <span id="soldCdk">{sum?.soldNum}</span>
              </div>
            </div>
            <div className="col-md-3">
              <div className="stats-card" w-tid="48">
                <h6 w-tid="49">Available</h6>
                <span id="remainingCdk" w-tid="50">{sum?.availableNum}</span>
              </div>
            </div>
            <div className="col-md-3" w-tid="51">
              <div className="stats-card" w-tid="52">
                <h6 w-tid="53">Total Revenue</h6>
                <span id="totalRevenue" w-tid="54">${getCurryFormattedString(sum?.totalRevenue)}</span>
              </div>
            </div>
          </div>

          <div className="table-responsive" w-tid="55">
            {cdKeyProducts&&cdKeyProducts.length>0&&(<Table className="table table-dark" id="platformStats">
              <TableHead w-tid="57">
              <TableRow w-tid="58">
                <TableHeaderCell w-tid="59">Product Name</TableHeaderCell>
                <TableHeaderCell w-tid="60">Platform</TableHeaderCell>
                <TableHeaderCell w-tid="61">OP($)</TableHeaderCell>
                <TableHeaderCell w-tid="61">Discount</TableHeaderCell>
                <TableHeaderCell w-tid="61">DP($)</TableHeaderCell>
                <TableHeaderCell w-tid="62">Sold</TableHeaderCell>
                <TableHeaderCell w-tid="63">Pending</TableHeaderCell>
                <TableHeaderCell w-tid="64">Available</TableHeaderCell>
                <TableHeaderCell w-tid="65">Revenue ($)</TableHeaderCell>
                <TableHeaderCell w-tid="66">Status</TableHeaderCell>
                <TableHeaderCell w-tid="67">Import CDK</TableHeaderCell>
                <TableHeaderCell w-tid="68">Actions</TableHeaderCell>
              </TableRow>
              </TableHead>
              <TableBody id="platformStatsList">
              {cdKeyProducts.map(product => (
              <TableRow>
                <TableCell>
                  {(this.state.editProductId!==product.id||this.state.editProductType!=="name")&&(<>
                  {product.name!==undefined ? product.name:'-'}
                  <button className="edit-btn" onClick={event =>this.handleEditProductName(product.id,product.name)}>
                    <i className="fa fa-pencil-square"></i>
                  </button></>)}
                  {this.state.editProductId===product.id&&this.state.editProductType==="name"&&(<><input type='text' value={this.state.editProductName} onChange={event => this.setState({editProductName: event.target.value,
                  })} style={{width:"80%"}}/><button className="edit-btn" onClick={event=>this.hanleSaveProductName(event)}> <i className="fa fa-save" /></button></>)}
                </TableCell>
                <TableCell>
                  {(this.state.editProductId!==product.id||this.state.editProductType!=="platform")&&(<>
                    {product.platform!==undefined ? product.platform.platform:'-'}
                    <button className="edit-btn" onClick={event =>this.handleEditProductPlatform(product.id,product.platform.id)}>
                      <i className="fa fa-pencil-square"></i>
                    </button></>)}
                  {this.state.editProductId===product.id&&this.state.editProductType==="platform"&&(
                      <><Select defaultValue={this.state.editProductPlatform} onChange={option=>{this.setState({editProductPlatform:option})}} options={this.state.productPlatforms} styles={arrayDropDownColors}>
                      </Select>
                        <button className="edit-btn" onClick={event=>this.hanleSaveProductPlatform(event)}> <i className="fa fa-save" /></button></>)}
                </TableCell>
                <TableCell>
                  {(this.state.editProductId!==product.id||this.state.editProductType!=="price")&&(<>
                    {product.price!==undefined ? getCurryFormattedString(product.price):'-'}
                    <button className="edit-btn" onClick={event =>this.handleEditProductPrice(product.id,product.price)}>
                      <i className="fa fa-pencil-square"></i>
                    </button></>)}
                  {this.state.editProductId===product.id&&this.state.editProductType==="price"&&(<><input type='text' value={this.state.editProductPrice} onChange={event => this.handleChangeProducePrice(event,product.discount)}
                  style={{width:"80%"}}/><button className="edit-btn" onClick={event=>this.hanleSaveProductPrice(event,product.discount)}> <i className="fa fa-save" /></button></>)}
                </TableCell>
                <TableCell>
                  {(this.state.editProductId!==product.id||this.state.editProductType!=="discount")&&(<>
                    {product.discount!==undefined ? product.discount+'%':'-'}
                    <button className="edit-btn" onClick={event =>this.handleEditProductDiscount(product.id,product.discount)}>
                      <i className="fa fa-pencil-square"></i>
                    </button></>)}
                  {this.state.editProductId===product.id&&this.state.editProductType==="discount"&&(<><input type='text' value={this.state.editProductDiscount} onChange={event => this.handleChangeProduceDiscount(event,product.price)}
                                                                                                          style={{width:"80%"}}/><button className="edit-btn" onClick={event=>this.hanleSaveProductDiscount(event,product.price)}> <i className="fa fa-save" /></button></>)}
                </TableCell>
                <TableCell>{product.discount!==undefined ? (product.price*(100-product.discount)/100).toFixed(2):product.price}</TableCell>
                <TableCell>{product.soldNum!==undefined ? product.soldNum:'-'}</TableCell>
                <TableCell>{product.pendingNum!==undefined ? product.pendingNum:'-'}</TableCell>
                <TableCell>
                  {product.availableNum!==undefined ? product.availableNum:'-'}
                  {product.availableNum>0&&<button className="edit-btn" onClick={event =>this.handleCDKeysOpen(product.cdKeys.filter(cdKey=>cdKey.status==='active'),product.name,product.platform.platform)}>
                    <i className="fa fa-pencil-square"></i>
                  </button>}
                </TableCell>
                <TableCell>
                  {product.soldRevenue!==undefined ? getCurryFormattedString(product.soldRevenue):'-'}
                  {product.priceGroup&&product.priceGroup.map(price=><div className="price-breakdowns">
                      <span style={{marginLeft:'10px',color:'#ffffff99',fontSize:'0.75rem'}}>${getCurryFormattedString(price[0])}:{price[1]}units(${getCurryFormattedString(price[0]*price[1])})</span>
                  </div>)}
                </TableCell>
                <TableCell>
                  {product.active===1&&<span className="platform-status active">
                    Active
                  </span>}
                  {product.active!==1&&<span className="platform-status inactive">
                    Inactive
                  </span>}
                </TableCell>
                <TableCell>
                  <div className="input-group input-group-sm">
                    <div className="fake-input"  onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.setState({uploadProductId:product.id,file:null});
                      this.dropzoneRef.open();
                    }} style={{color:this.state.file&&this.state.uploadProductId=== product.id?'#ffffff':'',fontSize:this.state.file&&this.state.uploadProductId=== product.id?'0.6rem':''}}>
                      { (this.state.file&&this.state.uploadProductId=== product.id) ? this.state.file.name : 'select file' }</div>

                    {/*<input type="file" className="form-control form-control-sm platform-import-file" accept=".xlsx,.xls"
                           data-platform="11"/>*/}
                    { (this.state.file&&this.state.uploadProductId=== product.id)&&
                        (<button className="btn btn-primary btn-sm" onClick={this.uploadKeys.bind(this)}>Import</button>)}
                  </div>
                </TableCell>
                <TableCell>
                  {product.active===1&&<button className="btn btn-sm btn-warning" onClick={event=>this.hanleChangeProductSaleStatus(event,product.id,2,product.platform?.platform,product.availableNum,product.price)}>
                    Deactivate
                  </button>}
                  {product.active!==1&&<button className="btn btn-sm btn-normal" onClick={event=>this.hanleChangeProductSaleStatus(event,product.id,1,product.platform?.platform,product.availableNum,product.price)}>
                    Activate
                  </button>}
                </TableCell>
              </TableRow>))}
              </TableBody>
            </Table>)}
            <div>
              <Dropzone
                  ref={(node) => { this.dropzoneRef = node; }}
                  disableClick={this.state.disableClick}
                  className="Dropzone"
                  style={{}}
                  onDrop={(e, event) => this.onDrop(e, event)}
                  onDragEnter={this.onDragEnter.bind(this)}
                  onDragLeave={this.onDragLeave.bind(this)}
                  disablePreview={false}
                  accept=".csv"
                  multiple={false}
                  name="file"
                  inputProps={{ id: 'file' }}
              >
              </Dropzone>
            </div>
            <div className="text-end mt-3" w-tid="70">
              <div style={{marginRight:'80px'}}>
              <p className="GameKeys__uploader--instructions">File must be a .CSV and the spreadsheet requires 'KEY' columns.</p>
              <a className="GameKeys__uploader--sample-file" target="_blank" href="/csv/CD_KEYS_EXAMPLE.csv">Download a sample template.</a>
              </div>
              <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addPlatformModal" onClick={event =>this.handleProductOpen()}>
                <i className="fa fa-plus" w-tid="72"></i> Add Product
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card" w-tid="73">
        <div className="card-header" w-tid="74">
          <h5 className="mb-0" w-tid="75">Sales Records</h5>
        </div>
        <div className="card-body" w-tid="76">
          <div className="row mb-3" w-tid="77">
            <div className="col-md-6" w-tid="78">
              <div className="gap-2" w-tid="79">
                <Select defaultValue={this.state.searchPlatform} onChange={option=>{this.setState(prevState => ({
                  filters: {
                    ...prevState.filters,
                    searchPlatform: option?.value
                  },searchPlatform:option
                }),()=>this.doSearchSailRecords(0))}} options={this.state.productPlatforms} styles={arrayDropDownColors} placeholder={"All platforms"} isClearable={true}>
                  <option>select</option>
                </Select>
                {/*<select id="platformFilter" className="form-select" w-tid="80">
                  <option value="">All platforms</option>
                  <option value="11">11</option>
                </select>*/}
              </div>
            </div>
            <div className="col-md-6" w-tid="82">
              <div className="search-wrapper" w-tid="83">
                <input type="text" id="cdkSearch" className="form-control" value={this.state.search}
                       placeholder="Search CDK, buyer account or order number..." style={{height:'50px'}}  onChange={e => this.setState({search:e.target.value})} onKeyDown={(e) => e.key === 'Enter' && this.doSearchSailRecords(0)}/>

              </div>
            </div>
          </div>
          <div className="table-responsive" w-tid="87">
            <table className="table table-dark" w-tid="88">
              <thead w-tid="89">
              <tr w-tid="90">
                <th w-tid="91">Order No.</th>
                <th w-tid="92">Status</th>
                <th w-tid="93">Product Name</th>
                <th w-tid="94">Platform</th>
                <th w-tid="95">CDK</th>
                <th w-tid="96">Price ($)</th>
                <th w-tid="97">Buyer</th>
                <th w-tid="98">Type</th>
                <th w-tid="99">Email</th>
                <th w-tid="100">Created</th>
                <th className="sortable" data-field="soldTime" w-tid="101">Paid/Cancel</th>
                <th className="sortable" data-field="viewTime" w-tid="102">Viewed</th>
              </tr>
              </thead>
              <tbody id="salesRecordList" w-tid="103">
              {records.length>0&&records.map(record => (
              <tr className="sales-record">
                <td>{record.orderNo}</td>
                <td>
                  {record.status==='w'&& <span className="order-status pending">Pending payment</span>}
                  {record.status==='c'&& <span className="order-status completed">Payment completed</span>}
                  {record.status==='q'&& <span className="order-status cancelled">Cancelled</span>}
                </td>
                <td>{record.productName}</td>
                <td>{record.platformName}</td>
                <td>

                            <span className="masked-cdk" onClick="window.uiManager.showFullCdk('S6342-FO7AA-65T85')">
                               {record.cdk}
                            </span>

                </td>
                <td>{record.price!==undefined ? getCurryFormattedString(record.salePrice):''}</td>
                <td>{record.userName}</td>
                <td>
                  {record.purchaseType==='g'&& <span className="purchase-type gift">Gift</span>}
                  {record.purchaseType==='b'&& <span className="purchase-type purchase">Purchase</span>}
                </td>
                <td>{record.sendEmail}</td>
                <td>{record.orderTime? moment(record.orderTime).format('MMMM DD, YYYY - hh:mm a') : ''}</td>
                <td>
                  {(record.status==='c'&&record.payTime)?moment(record.payTime).format('MMMM DD, YYYY - hh:mm a') : ''}
                  {(record.status==='q'&&record.cancelTime)?moment(record.cancelTime).format('MMMM DD, YYYY - hh:mm a') : ''}
                </td>
                <td>{record.cdkViewTime? moment(record.cdkViewTime).format('MMMM DD, YYYY - hh:mm a') : ''}</td>
              </tr>))}
              {records.length<=0&&<tr className="sales-record"><td colSpan='15'>No Records</td></tr>}
              </tbody>
            </table>
          </div>
          <nav w-tid="104">
            <ul className="pagination justify-content-center" id="pagination" w-tid="105"></ul>
          </nav>
        </div>
      </div>

      <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={!!dialogAddProduct}
      >
        <form onSubmit={this.addProduct.bind(this)} style={{width:'100%'}}>
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" style={{color:'#ff0000',alignSelf:'flex-start',fontWeight:'400',backgroundColor:'#161616',borderColor:'#161616'}}>
            Add New Product
          </DialogTitle>
          <DialogContent dividers style={{backgroundColor:'#212529'}}>
            <div style={{display:'flex',justifyContent:'left'}}>
              {}<Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',color:'#ffffff'}}>Product Name</Typography>
            </div>
            <div style={{display:'flex',justifyContent:'left'}}>
              <input type="text" id="product-name-bar-input" className={productNameError?'input_error':'input'} label="RegionBarInput" value={productName} placeholder="Enter product name" onChange={event => this.setState({ productName: event.target.value,productNameError: false})}/>
            </div>
            <div style={{display:'flex',justifyContent:'left',marginTop:'20px'}}>
              <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',color:'#ffffff'}}>Platform</Typography>
            </div>
            <div style={{display:'flex',justifyContent:'left'}} className={productPlatformError?'select_error':'select'}>
              <Select defaultValue={productPlatform?.value} onChange={option=>{this.setState({productPlatform:{name:option.label,value:option.value},productPlatformError: false})}} options={this.state.productPlatforms} styles={arrayDropDownColors}>
              </Select>
            </div>
            <div style={{display:'flex',justifyContent:'left',marginTop:'20px'}}>
              <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',color:'#ffffff'}}>OP</Typography>
            </div>
            <div style={{display:'flex',justifyContent:'left'}}>
              <input type="text" id="product-price-bar-input" className={productPriceError?'input_error':'input'} label="RegionBarInput" value={productPrice} placeholder="Enter product price" onChange={event => this.handleChangeNewProducePrice(event)}/>
            </div>
            <div style={{display:'flex',justifyContent:'left',marginTop:'20px'}}>
              <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',color:'#ffffff'}}>Discount</Typography>
            </div>
            <div style={{display:'flex',justifyContent:'left'}}>
              <input type="text" id="product-price-bar-input" className={productDiscountError?'input_error':'input'} label="RegionBarInput" value={productDiscount} placeholder="Enter product price" onChange={event => this.handleChangeNewProduceDiscount(event)}/>
            </div>
            <div style={{display:'flex',justifyContent:'left',marginTop:'20px'}}>
              <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',color:'#ffffff'}}>DP</Typography>
            </div>
            <div style={{display:'flex',justifyContent:'left'}}>
              <input type="text" id="product-price-bar-input" className='input_readonly' label="RegionBarInput" value={productDiscountPrice} readOnly/>
            </div>
          </DialogContent>
          <DialogActions style={{alignSelf:'flex-end',backgroundColor:'#161616'}}>
            <Button autoFocus style={{background:'#ffffff',color:'#000000'}} onClick={this.handleProductClose.bind(this)}>
              Cancel
            </Button>
            <Button style={{background:'#4a90e2',color:'#ffffff'}} onClick={this.addProduct.bind(this)}>
              Confirm
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>

      <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={!!dialogProductOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Are you sure to stop selling CDK on <p style={{color:'#E7F328',display:'inline'}}>{saleoutProductPlatformName}</p>?
        </DialogTitle>
        <DialogContent dividers>
          <div style={{display:'flex',justifyContent:'center'}}>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus style={{fontSize:'16px',fontWeight:'800',backgroundColor:'#CAC1C4',color:'#fff',borderRadius:'8px',padding:'10px 50px',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.handleSaleoutClose.bind(this)}>
            Cancel
          </Button>
          <Button style={{marginLeft:'20px',fontSize:'16px',fontWeight:'800',backgroundColor:'#76b452',color:'#fff',borderRadius:'8px',padding:'10px 50px',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.handleSaleout.bind(this)}>
            Confirm
          </Button>
        </DialogActions>
      </BootstrapDialog>

      {<BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={!!dialogCDKeysOpen}
          PaperProps={{
            style: {
              width: '600px',
              maxWidth: 'none',
              maxHeight: '80%',
              height: 'auto',
            },
          }}
      >

        <DialogTitle  style={{width:'100%'}} id="customized-dialog-title">
          <Box display="flex" alignItems="left" justifyContent="space-between">
            <Typography variant="h6"  style={{flex:'4'}}>{this.state.productPlatformTip} CDKs of {this.state.productNameTip}</Typography>
            <IconButton
                edge="end"
                aria-label="close"
                onClick={this.handleCDKeysClose.bind(this)}
                style={{flex:'1'}}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {this.state.cdKeys.map(cdKey=>(
              <div style={{display:'flex',justifyContent:'left',borderBottom:'solid 1px #3d3d3d'}}>
                <Typography variant='body1' component="div"  gutterBottom style={{marginTop:'10px',minWidth:'150px'}}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start',color:'#4a48b7' }}>
                    <input
                        name={`${cdKey.id}`}
                        id={`${cdKey.id}`}
                        customLabel={`${cdKey.key}`}
                        type="checkbox"
                        style={{ width: '30px' }}
                        onClick={this.onChangeCDKeysSel.bind(this,cdKey.id)}
                        checked={(this.state.cdKeysSel.indexOf(cdKey.id) !== -1)}
                    />
                    <span className={this.state.formEditable ? '' : 'disabled'}>{cdKey.key}</span>
                  </div>
                </Typography>
              </div>))}
        </DialogContent>
        <DialogActions>
          <Button autoFocus style={{fontSize:'14px',fontWeight:'800',backgroundColor:'#CAC1C4',color:'#fff',borderRadius:'8px',whiteSpace:'nowrap',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.selectAllKeys.bind(this)}>
            Select All
          </Button>

          {this.state.cdKeysSel.length>0&&(  <><Button autoFocus style={{fontSize:'14px',fontWeight:'800',backgroundColor:'#CAC1C4',color:'#fff',borderRadius:'8px',whiteSpace:'nowrap',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.clearSelection.bind(this)}>
            Clear Selection
          </Button>

          <Button autoFocus style={{fontSize:'14px',fontWeight:'800',backgroundColor:'#76b452',color:'#fff',borderRadius:'8px',whiteSpace:'nowrap',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.openDeleteConfirmationModal.bind(this,this.state.productPlatformTip,this.state.productNameTip)}>
            Delete ({this.state.cdKeysSel.length}) Keys
          </Button></>)}
        </DialogActions>
      </BootstrapDialog>}

      <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={!!dialogCDKeysDelConfirmOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Are you sure to delete the selected CDKs of <p style={{color:'#E7F328',display:'inline'}}>{this.state.productNameTip} on {this.state.productPlatformTip}</p>?
        </DialogTitle>
        <DialogContent dividers>
          <div style={{display:'flex',justifyContent:'center'}}>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus style={{fontSize:'16px',fontWeight:'800',backgroundColor:'#CAC1C4',color:'#fff',borderRadius:'8px',padding:'10px 50px',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.handleDeleteConfirmationClose.bind(this)}>
            Cancel
          </Button>
          <Button style={{marginLeft:'20px',fontSize:'16px',fontWeight:'800',backgroundColor:'#76b452',color:'#fff',borderRadius:'8px',padding:'10px 50px',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.deleteCDKeys.bind(this)}>
            Confirm
          </Button>
        </DialogActions>
      </BootstrapDialog>
      {this.state.dialogSuccessOpen&&<PromptDialog dialogOpen={this.state.dialogSuccessOpen} level="success" title="Import Successfu!" content={'Platform:'+this.state.uploadSuccess.platform+' Enter quantity:'+this.state.uploadSuccess.enter} cancelAction={this.handleSuccessConfirmationClose}/>}
      <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={!!dialogCDKeysRepeatConfirmOpen}
      >
        <div className="block_5 flex-row justify-between">
          <div className="text-wrapper_7 flex-col">
            <span className="text_11">!</span>
          </div>
          <div className="text-wrapper_8 flex-col justify-between">
            <span className="text_12">WARNING</span>
            <span className="paragraph_1">
                     The following CDK format does not conform to the usual format of Steam Store platform (ABCD1-EFGH2-IJKL3), please check and confirm the import.
                    </span>
          </div>
        </div>


        <DialogContent dividers>
          <div className="block_6 flex-row">
            <div className="text-wrapper_9 flex-col">

                      {this.state.invalidKeys.map(key=>(<span className="paragraph_2">{key}</span>))}

            </div>
          </div>
        </DialogContent>
        <DialogActions style={{width:'100%',justifyContent:'space-evenly'}}>
          <div className="block_7 flex-row" >
            <div className="text-wrapper_10 flex-col" style={{backgroundColor:'rgb(118, 180, 82)'}} onClick={this.reUploadKeys.bind(this)}>
              <span className="text_13" >Continue importing</span>
            </div>
          </div>
          <div className="block_7 flex-row">
            <div className="text-wrapper_10 flex-col" onClick={this.handleConfirmationWarnClose.bind(this)}>
              <span className="text_13">Cancel</span>
            </div>
          </div>
        </DialogActions>
      </BootstrapDialog>
      <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={!!dialogCDKeysRepeatOpen}
      >
        <div className="block_5 flex-row justify-between">
          <div className="text-wrapper_7 flex-col">
            <span className="text_11">!</span>
          </div>
          <div className="text-wrapper_8 flex-col justify-between">
            <span className="text_12">WARNING</span>
            <span className="paragraph_1">
                      The following CDKs are the same as those already entered in the {this.state.repeatPlatform}. Please check and confirm the import.
                    </span>
          </div>
        </div>


        <DialogContent dividers>
          <div className="block_6 flex-row">
            <div className="text-wrapper_9 flex-col">

              {this.state.repeatKeys.map(key=>(<span className="paragraph_2">{key}</span>))}

            </div>
          </div>
        </DialogContent>
        <DialogActions style={{width:'100%',justifyContent:'space-evenly'}}>
          <div className="block_7 flex-row">
            <div className="text-wrapper_10 flex-col" onClick={this.handleRepeatWarnClose.bind(this)}>
              <span className="text_13">Close</span>
            </div>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </section>


    );
  }
}

CDKeys.propTypes = {

};

export default CDKeys;
