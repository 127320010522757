import React from 'react';
// import { Link } from 'react-router';
// import IconParticles from '../icon-particles/IconParticles';
import ContactForm from '../brand-service/slides/ContactForm';
import BrandLoginForm from '../brand-service/slides/BrandLoginForm';
import bellwright from './assets/study-bellwright.png';
import ark from './assets/study-ark.png';
import './Onboard.scss';

const Onboard = () => {
  return (
    <section className="Onboard">
      <section className="Onboard__container">
        <section className="MainOnboard">
          <BrandLoginForm />
          <ContactForm />
        </section>
        <section className="studies">
          <h2>RECENT CASE STUDIES</h2>
          <ul>
            <li>
              <a href="https://drive.google.com/file/d/1i_OxjqrWrDKYxy5vWFCeQUQUeKZydbx-/view?usp=drive_link">
                <img src={bellwright} alt="" />
              </a>
            </li>
            <li>
              <a href="https://drive.google.com/file/d/1GdAxLHKsbAwcDKZF31W8mmog4L6gvXGO/view?usp=sharing">
                <img src={ark} alt="" />
              </a>
            </li>
          </ul>
        </section>
      </section>
    </section>);
};

export default Onboard;
