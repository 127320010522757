import React from 'react';
import { Link } from 'react-router';
import classNames from 'classnames';
import './Welcome.scss';
import controller from "./icon-controller-white.png"
import camera from "./icon-camera-white.png"
import Logo from '../logo/Logo';
class ConnectAccounts extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      gamersActive: false,
      streamersActive: false,
    };
  }

  render() {
    return (
      <section className="Welcome">
        <section className="ModalMaster">
          <section className={classNames('Modal Modal__welcome active')}>
            <Link
              to="/"
              href="/"
              className="Modal__layer"
              role="button"
              aria-hidden
            />

            <section className="Modal__wrapper">
              {/* <div> */}
              <Link
                className="Modal__close-btn"
                to="/"
                href="/"
                role="button"
                aria-hidden
              />
              <div className='Modal__left_welcome'>
                <Logo />
              </div>
              {/* </div> */}
              <div className="Welcome__header">
                <div className='Welcome__header__title'>Welcome</div>
                <div className="Welcome__header__description">
                  Noiz is a community platform where streamers and gamers <br /> can explore all kinds of opportunities including:
                </div>
              </div>
              <div className="Welcome__body">
                <div className="Welcome__gamers">
                  <div className="Welcome__gamers__image">
                    <img src={controller} alt="Gamers Sizer" className="image-sizer image-sizer-gamers" />
                  </div>
                  <div className="Welcome__gamers__title">
                    Gamers
                  </div>
                  <div>
                    <div className="Welcome__gamers__row">
                      Alpha Testing + Feedback
                    </div>
                    <div className="Welcome__gamers__row">
                      Direct access to game dev teams
                    </div>
                    <div className="Welcome__gamers__row">
                      Raffles + Freebies
                    </div>
                    <div className="Welcome__gamers__row">
                      Competitions + Tournaments
                    </div>
                  </div>
                </div>
                <div className="Welcome__streamers">
                  <div className="Welcome__streamers__image">
                    <img src={camera} alt="Streamers Sizer" className="image-sizer image-sizer-streamers" />
                  </div>
                  <div className="Welcome__streamers__title">
                    Streamers
                  </div>
                  <div>
                    <div className="Welcome__streamers__row">
                      Paid opportunities
                    </div>
                    <div className="Welcome__streamers__row">
                      All opportunities opened to gamers
                    </div>
                    <div className="Welcome__streamers__row">
                      Exclusive Early Access
                    </div>
                    <div className="Welcome__streamers__row">
                      Other streaming partnerships
                    </div>
                  </div>
                </div>
              </div>
              <div className="Welcome__footer">
                <div className="Welcome__footer__button">
                  <Link to="/" href="/" >Continue</Link>
                </div>
              </div>
              <div className="blurb">
                Designed by game developers and gamers, for game developers and gamers
              </div>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

ConnectAccounts.propTypes = {
};

export default ConnectAccounts;
