import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import './GameTooltip.scss';

function GameTooltip({
    isRightEdge,
    coverPortrait,
    cover,
    errorCover,
    name,
    imageGallery,
    currentImageIndex,
    description,
    categories,
    releaseDate,
}) {
    const truncateText = (text, maxLength) => {
        if (!text) return "";
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    

    return (
        <div className={`GameItem__tooltip ${isRightEdge ? 'tooltip-left' : ''}`}>
            <div
                className='GameItem__tooltip__bg'
                style={{
                    backgroundImage: `url(${coverPortrait || cover || errorCover})`,
                }}
            />
            <div className="game-title">{name}</div>
            <div className="game-info">Release Date: {releaseDate}</div>
            <div className="container">
                {imageGallery.length > 0 ? (
                    <img
                        src={imageGallery[currentImageIndex].url}
                        alt={`Gallery ${currentImageIndex + 1}`}
                        className="gallery-image"
                    />
                ) : description ? (
                    <div className='rating'>
                        {ReactHtmlParser(truncateText(description, 200))}
                    </div>
                ) : (
                    <div className="no-content">
                        暂无描述
                    </div>
                )}
            </div>
            <div className="game-tags">
                {categories && categories.length > 0 &&
                    categories.map(category => (
                        <span key={category.id || category.name} className="tag">
                            {category.name}
                        </span>
                    ))
                }
            </div>
        </div>
    );
}

export default GameTooltip;