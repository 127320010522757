/* eslint max-len: ["error", { "code": 800 }] */
import React from 'react';
// import PropTypes from 'prop-types';
import './CareersPost.scss';
// import IconParticles from '../icon-particles/IconParticles';

class CommunityManager extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  UNSAFE_componentWillMount() { }

  componentDidMount() { }

  render() {
    return (
      <section className="CareersPost">
        <section className="CareersPost__container">
          <section className="CareersPostSummary">
            <section className="CareersPostSummary__content">

            </section>
          </section>
          <section className="CareersPostDetails">
            <section className="CareersPostDetails__content">
              <section className="PostingItem">
                <a className="PostingItem__apply-btn" href="mailto:angie@noiz.gg,sandy@noiz.gg">
                  Apply for this job <i className="fa fa-envelope" />
                </a>
                <section className="PostingItem__wrapper">
                  <h4 className="PostingItem__title">Community Manager</h4>
                  <section className="PostingItem__categories">
                    <span className="PostingItem__categories--location">Beverly Hills, CA</span>
                  </section>
                </section>
              </section>
              <div>
                <p><span style={{ fontWeight: 400 }}>Noiz is a creative agency and full-service marketing platform that connects brands with influencers in gaming and entertainment. Our client is a yet-to-be-unveiled, highly innovative EV startup that offers an amazing opportunity to establish and grow a brand from the ground floor.&nbsp;</span></p>
                <p><span style={{ fontWeight: 400 }}>We are looking for a qualified Community manager to join our team. If you are a tech-savvy professional, experienced in social media, PR and promotional events, we would like to meet you.</span></p>
                <p><span style={{ fontWeight: 400 }}>Our ideal candidate has exceptional oral and written communication skills and is able to develop engaging content. You should be a ‘people person’ with great customer service skills and the ability to moderate online and offline conversations with our community.</span></p>
                <p><span style={{ fontWeight: 400 }}>Ultimately, you should be able to act as the face and voice of our brand and manage all community communications.</span></p>
                <p><h4>Responsibilities</h4></p>
                <ul>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Set and implement social media and communication campaigns to align with marketing strategies</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Provide engaging text, image and video content for social media accounts</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Respond to comments and customer queries in a timely manner</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Monitor and report on feedback and online reviews</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Organize and participate in events to build community and boost brand awareness</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Coordinate with Marketing, PR and Communications teams to ensure brand consistency</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Liaise with Development and Sales departments to stay updated on new products and features</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Build relationships with customers, potential customers, industry professionals and journalists</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Stay up-to-date with digital technology trends</span></li>
                </ul>
                <p><h4>Requirements</h4></p>
                <ul>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Proven work experience as a community manager across social media platforms including Discord, Twitter, Instagram, TikTok, etc.,&nbsp;</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Experience launching community initiatives (e.g. building an online forum, launching an ambassador program, creating an event series and writing an email newsletter)</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Interest in automotive and electric vehicles</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Ability to identify and track relevant community metrics (e.g. repeat attendance at events)</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Excellent verbal communication skills</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Excellent writing skills</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Hands on experience with social media management for brands</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Ability to interpret website traffic and online customer engagement metrics</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Knowledge of online marketing and marketing channels</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Attention to detail and ability to multitask</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>BSc degree in Marketing or relevant field</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Experience with Club House a plus&nbsp;</span></li>
                </ul>
                <p><em>NOIZ.gg is an Equal Opportunity Employer. We welcome and encourage applicants from all backgrounds, and do not discriminate based on race, sex, age, disability, sexual orientation, national origin, religion, color, gender identity/expression, marital status, veteran status, or any other characteristics protected by applicable laws.</em></p>
              </div>
              <p className="CareersPostDetails__content--cta">Email your resume to <a href="mailto:angie@noiz.gg">angie@noiz.gg</a></p>

            </section>
          </section>
        </section>
      </section>
    );
  }
}

// CareersPostFullStackEngineer.propTypes = {
//
// };

export default CommunityManager;
