import React, { useEffect } from 'react';
import { ParallaxLayer } from '../../../custom_modules/react-spring/src/renderprops/addons/index';
import useOnScreen from '../useOnScreen';
import dataVis from '../assetsV2/diagonal.png';
import bestInClassIndex from '../assetsV2/iconography/bestInClassIndex';
import anime from 'animejs';

import './BrandServiceSlides.scss';

const InfluencerMarketing = (props) => {
  const {
    pOffset, layoutFactor = 1, offsetAdjust = 0,
  } = props;
  const ref = React.createRef();
  const onScreen = useOnScreen(ref);

  const [debounce, setDebounce] = React.useState(false);

  useEffect(() => {
    if (!debounce && onScreen) {
      anime({
        targets: '.influencer-fade',
        opacity: [1, 0],
        translateY: 100,
        duration: 800,
        direction: 'reverse',
        easing: 'linear',
        delay: anime.stagger(60, { direction: 'reverse' }),
      });

      setDebounce(true);
    }
  }, [onScreen, debounce]);
  return (
    <>
      <ParallaxLayer
        offset={pOffset + offsetAdjust}
        factor={layoutFactor}
        className="background-i"
        speed={0}
        style={{
          background: `url("${dataVis}")`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat' // 明确禁用重复 
        }}
      />
      <ParallaxLayer offset={pOffset + offsetAdjust} factor={layoutFactor} speed={0} className="slide-i-container" >
        <div ref={ref} />
        <div className="influencer-fade brand-i-title cascade-fade-i ">Our Best in Class Influencer<br/> Marketing Solutions
        </div>

        <div className="brand-i-grid">

          {
            bestInClassIndex.map((item, i) => (<div key={i} className="brand-text-stack">
              <div className="influencer-fade row-flex cascade-fade-i">
                <div className="image-round">
                  <img src={item.icon} alt={item.title} />
                </div>
                <div className="title">
                    {item.title}
                  </div>
                  <div className="body">
                    {item.text}
                  </div>
              </div>
            </div>))
          }
        </div>
      </ParallaxLayer>
    </>
  );
};

export default InfluencerMarketing;
