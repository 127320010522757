/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link } from 'react-router';
import { forEach, find } from 'lodash';
import languages from 'languages';
// import classNames from 'classnames';
import { preloadRequirement } from '../../../../utils/functions';
import { simpleNotification } from '../../../../utils/notifications';
import QuestForm from '../../../forms/QuestForm';

import './QuestEdit.scss';

class QuestEdit extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      game: null,
    };
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    const { questId } = this.props.router.params;
    if (questId) {
      this.props.getCCQuest(questId, token);
    }
  }

  componentDidMount() {
    const { questId } = this.props.router.params;

    if (!questId) {
      this.props.assignQuestDefaultInfo();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { gameId } = nextProps.router.params;

    // Find & save game
    if (nextProps.commandCenter.games.data.length > 0) {
      const game = find(nextProps.commandCenter.games.data, game => game.id === gameId);
      if (game) {
        this.setState({
          game,
        });
      }
    }

    if (this.props.commandCenter.quest.isLoading === true && nextProps.commandCenter.quest.isLoading === false &&
        this.props.commandCenter.quest.isUpdating === false && nextProps.commandCenter.quest.isUpdating === false &&
        this.props.commandCenter.quest.isCreating === false && nextProps.commandCenter.quest.isCreating === false) {
      if (!nextProps.commandCenter.quest.errors.hasError) {
        const { data: quest } = nextProps.commandCenter.quest;
        const { requirements } = quest;
        console.log('quest', quest); // eslint-disable-line no-console
        
        // 不再对日期进行格式转换，让组件内部处理时区转换
        // 只打印日志以便于调试
        if (quest.startDateTime) {
          console.log('接收到的 startDateTime:', quest.startDateTime);
        }
        if (quest.endDateTime) {
          console.log('接收到的 endDateTime:', quest.endDateTime);
        }
        
        let platformToStream;
        if (quest.twitchAccountRequired === true) {
          platformToStream = 'twitch';
        } else if (quest.youtubeAccountRequired === true) {
          platformToStream = 'youtube';
        }

        if (quest.campaigns && quest.campaigns.length > 0) {
          const campaigns = [];
          forEach(quest.campaigns, (obj) => {
            campaigns.push({
              value: obj.id,
              label:
              (
                <span style={{ zIndex: '1000' }}>{obj.name}</span>
              ),
            });
          });
          quest.campaigns = campaigns;
        }

        if (quest.countries && quest.countries.length > 0) {
          quest.countries = quest.countries.map(c => ({
            value: c,
            label: <span style={{ zIndex: '1000' }}>{c}</span>,
          }));
        }

        if (quest.languages && quest.languages.length > 0) {
          quest.languages = quest.languages.map((l) => {
            if (l === 'any') {
              return ({
                value: l,
                label: <span style={{ zIndex: '1000' }}>Any Language</span>,
              });
            }
            const langInfo = languages.getLanguageInfo(l);
            return ({
              value: l,
              label: <span style={{ zIndex: '1000' }}>{langInfo.name}</span>,
            });
          });
        }

        if (requirements) {
          if (requirements.beforeStream) {
            for (let i = 0; i < requirements.beforeStream.length; i += 1) {
              if (!requirements.beforeStream[i].title || requirements.beforeStream[i].title === '') {
                const { type, subtype } = requirements.beforeStream[i];
                requirements.beforeStream[i].title = preloadRequirement(type, subtype, quest, quest.game).title;
              }

              requirements.beforeStream[i].value = requirements.beforeStream[i].subtype ? [requirements.beforeStream[i].type, requirements.beforeStream[i].subtype].join('-') : requirements.beforeStream[i].type;
            }
          }

          if (requirements.duringStream) {
            for (let i = 0; i < requirements.duringStream.length; i += 1) {
              if (!requirements.duringStream[i].title || requirements.duringStream[i].title === '') {
                const { type, subtype } = requirements.duringStream[i];
                requirements.duringStream[i].title = preloadRequirement(type, subtype, quest, quest.game).title;
              }

              requirements.duringStream[i].value = requirements.duringStream[i].subtype ? [requirements.duringStream[i].type, requirements.duringStream[i].subtype].join('-') : requirements.duringStream[i].type;
            }
          }

          if (requirements.afterStream) {
            for (let i = 0; i < requirements.afterStream.length; i += 1) {
              if (!requirements.afterStream[i].title || requirements.afterStream[i].title === '') {
                const { type, subtype } = requirements.afterStream[i];
                requirements.afterStream[i].title = preloadRequirement(type, subtype, quest, quest.game).title;
              }

              requirements.afterStream[i].value = requirements.afterStream[i].subtype ? [requirements.afterStream[i].type, requirements.afterStream[i].subtype].join('-') : requirements.afterStream[i].type;
            }
          }

          quest.requirements = requirements;
        }

        this.props.assignQuestInfo((platformToStream ? { ...quest, platformToStream } : quest));
      }
    }
    
    if (this.props.commandCenter.quest.isCreating === true && nextProps.commandCenter.quest.isCreating === false) {
      if (nextProps.commandCenter.quest.errors.hasError) {
        if (nextProps.commandCenter.quest.errors.code === 'E_QUEST_SLUG_DUPLICATED') {
          simpleNotification({
            level: 'error',
            title: 'Quest Slug Duplicated.',
            message: nextProps.commandCenter.quest.errors.message,
          });
          this.props.assignQuestSlugError(nextProps.commandCenter.quest.errors.message);
        }
      } else {
        simpleNotification({
          level: 'success',
          title: 'Quest Created.',
          message: 'Your quest has been created successfully.',
        });
        this.props.router.push(`/admin/command-center/${gameId}/quests`);
      }
    }

    if (this.props.commandCenter.quest.isUpdating === true && nextProps.commandCenter.quest.isUpdating === false) {
      if (nextProps.commandCenter.quest.errors.hasError) {
        if (nextProps.commandCenter.quest.errors.code === 'E_QUEST_SLUG_DUPLICATED') {
          simpleNotification({
            level: 'error',
            title: 'Quest Slug Duplicated.',
            message: nextProps.commandCenter.quest.errors.message,
          });
          this.props.assignQuestSlugError(nextProps.commandCenter.quest.errors.message);
        }
      } else {
        simpleNotification({
          level: 'success',
          title: 'Quest Updated.',
          message: 'Your quest has been updated successfully.',
        });
        this.props.router.push(`/admin/command-center/${gameId}/quests`);
      }
    }
  }

  onSubmit = (questFormInfo) => {
    const {
      auth: { token },
      router: { params: { gameId, questId } },
    } = this.props;

    console.log('表单提交时间字段:', {
      startDateTime: questFormInfo.startDateTime,
      endDateTime: questFormInfo.endDateTime,
      openRegistrationDate: questFormInfo.openRegistrationDate,
      closeRegistrationDate: questFormInfo.closeRegistrationDate,
      expectedPayoutDate: questFormInfo.expectedPayoutDate
    });

    // 时间处理：确保将本地时间转换为UTC格式提交
    const preparedInfo = { ...questFormInfo, game: gameId };
    
    // 这些时间转换移动到了CustomDateTimePicker组件内部进行
    // 确保传递给后端的是UTC格式的ISO字符串
    // 在CustomDateTimePicker中，用户选择时间时已经处理了从本地时区到UTC的转换
    
    if (!questId) {
      this.props.createCCQuest(preparedInfo, token);
    } else {
      this.props.updateCCQuest(questId, preparedInfo, token);
    }
  }

  render() {
    const {
      auth,
      commandCenter: {
        adminActivityLogs,
        campaigns: { data: campaigns },
        games: { data: games },
        preSignedUrl,
        quest: {
          data: quest,
          isLoading,
        },
      },
      config: { rules: { data: configRules } },
      form: { questForm = {} },
      getQuest,
      removeIcon,
      requestUploadCCImageUrl,
      router: {
        location: { pathname },
        params: { gameId },
      },
      swapQuestStep,
      swapRequirements,
      titleSlugApply,
      updateCCQuestForm,
    } = this.props;
    const { questId } = this.props.router.params;
    const { game } = this.state;
    // 如果没有 questId，则将 quest 数据设置为 null
    const questData = questId ? quest : null;
    // console.log('questData', questData); // eslint-disable-line no-console
    return (
      <section className="CCQuestEdit" style={{ display: isLoading }}>
        <div className="CCQuestEdit_bar">
          <Link
            to={`/admin/command-center/${gameId}/quests`}
            activeClassName="active"
            href={`/admin/command-center/${gameId}/quests`}
          >
            <button className="CCQuests__back--btn CTAButton__purple">Back</button>
          </Link>
          { questData
            &&
            <h2>Quest Title: {questData.title}</h2>
          }
        </div>

        <div style={{ display: (isLoading) ? 'none' : 'block' }}>
          <QuestForm
            adminActivityLogs={adminActivityLogs}
            campaigns={campaigns}
            auth={auth}
            configRules={configRules}
            game={game || {}}
            games={games}
            getQuest={getQuest}
            isLoading={isLoading}
            onSubmit={this.onSubmit.bind(this)}
            pathname={pathname}
            preSignedUrl={preSignedUrl}
            quest={questData}
            questForm={questForm}
            removeIcon={removeIcon}
            requestUploadCCImageUrl={requestUploadCCImageUrl}
            swapQuestStep={swapQuestStep}
            swapRequirements={swapRequirements}
            titleSlugApply={titleSlugApply}
            updateCCQuestForm={updateCCQuestForm}
          />
        </div>
        <div className="QuestLoading" style={{ display: (isLoading) ? 'block' : 'none' }}><i className="fa fa-spinner fa-spin fa-3x fa-fw" /></div>
      </section>
    );
  }
}

QuestEdit.propTypes = {

};

export default QuestEdit;
