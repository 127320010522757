/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link } from 'react-router';
import { orderBy, filter } from 'lodash';
import moment from 'moment-timezone';
import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { simpleNotification } from '../../../../utils/notifications';
import './Quests.scss';
import Axios from 'axios';

const quickWidth = {};

class Quests extends React.Component {
  constructor(props) {
    super(props);
    this.idCopy = React.createRef();
    this.props = props;
    this.state = {
      copyModal: false,
      questCopyId: null,
      selectGame: null,
      tab: 1,
    };
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    const { gameId } = this.props.router.params;
    localStorage.setItem('questsPathname', this.props.router.location.pathname);
    this.setState({ selectGame: gameId });
    if (!this.props.commandCenter.quests.isLoading) {
      this.props.getAdminQuestListByGame(gameId, token);
    }
    this.props.getCCCampaignListByGame(gameId, token);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (localStorage.getItem('questsPathname') !== nextProps.router.location.pathname) {
      localStorage.setItem('questsPathname', nextProps.router.location.pathname);
      const { token } = this.props.auth;
      const { gameId } = this.props.router.params;
      this.setState({ selectGame: gameId });
      if (!this.props.commandCenter.quests.isLoading) {
        this.props.getAdminQuestListByGame(gameId, token);
      }
    }
  }

  copyText = (event) => {
    const text = event.target.innerHTML;
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(text);
      simpleNotification({
        level: 'success',
        title: 'Successfully Copied',
        message: `${text}`,
        autoDismiss: 2,
      });
    }
  }

  generateRowsHTML = (quests, gameId) => {
    // const today = moment().tz('America/Los_Angeles');
    // const hoursOffset = -today.utcOffset() / 60;
    const userTimezone = moment.tz.guess();
    console.log('用户当前时区:', userTimezone); // 便于调试
    
    if (quests.length >= 1) {
      return quests.map((quest, index) => (
        <div className="row" key={quest.id}>
          <div className="table-cell">{quest.title}</div>
          <div
            className="table-cell"
            ref={this.idCopy}
            style={{ cursor: 'pointer', ...quickWidth }}
            onClick={e => this.copyText(e)}
            aria-hidden
          >
            {quest.id}
          </div>
          {/* <div className="table-cell">{quest.startDateTime ? moment(quest.startDateTime).tz('America/Los_Angeles').add(hoursOffset, 'hours').format('MM-DD-YY hh:mm A') : '-'}</div>
          <div className="table-cell">{quest.endDateTime ? moment(quest.endDateTime).tz('America/Los_Angeles').add(hoursOffset, 'hours').format('MM-DD-YY hh:mm A') : '-'}</div> */}
          <div className="table-cell">
          {quest.startDateTime
            ? moment.utc(quest.startDateTime).tz(userTimezone).format('MM-DD-YY hh:mm A')
            : '-'}
        </div>
        <div className="table-cell">
          {quest.endDateTime
            ? moment.utc(quest.endDateTime).tz(userTimezone).format('MM-DD-YY hh:mm A')
            : '-'}
        </div>
          <div className="table-cell">
            {(quest.slug)
              &&
              <Link
                to={`/quests/${quest.slug}`}
                activeClassName="active"
                href={`/quests/${quest.slug}`}
                className="remove-key"
                target="_blank"
              >
                <i className="fa fa-external-link" />
              </Link>}
            {!(quest.slug)
              &&
              <span className="not-available">
                Not Available
              </span>
            }
          </div>
          <div className="table-cell">
            {quest.needsReview}
          </div>
          <div className="table-cell">
            <Tooltip title="Edit" placement="top">
              <Link
                to={`/admin/command-center/${gameId}/quests/${quest.id}/edit`}
                activeClassName="active"
                href={`/admin/command-center/${gameId}/quests/${quest.id}/edit`}
                className="remove-key"
              >
                <i className="fa fa-pencil" />
              </Link>
            </Tooltip>
            {(quest.requirePreapproval) &&
              <Tooltip title="Preapproval" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/preapproval`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/preapproval`}
                  className="remove-key"
                >
                  <i className="fa fa-check-square-o" />
                </Link>
              </Tooltip>
            }
            {(quest.type === 'paid-bounty' || quest.type === 'paid bounty') &&
              <Tooltip title="Details" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/activity`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/activity`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
            }
            {(quest.type === 'referral') &&
              <Tooltip title="Details" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/sales`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/sales`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
            }
            {(quest.type === 'timed') &&
              <Tooltip title="Details" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/timed`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/timed`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
            }
            {(quest.type === 'general' || quest.type === 'default' || quest.type === 'basic') &&
              <Tooltip title="Details" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/${'general'}`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/${'general'}`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
            }
            {(quest.type === 'affiliate') &&
              <Tooltip title="Details" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/affiliate`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/affiliate`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
            }
            {(((quest.type === 'tiered' || quest.type === 'tiered-one-time') && quest.frequency === 'one-time') || quest.type === 'tiered-auto'|| quest.type === 'secondary-creation') &&
              <Tooltip title="Details" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/${quest.type}`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/${quest.type}`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
            }
            {((quest.type === 'tiered' || quest.type === 'tiered-multi-days') && quest.frequency === 'daily') &&
              <Tooltip title="Details" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/tiered-multiple-days`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/tiered-multiple-days`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
            }
            {moment(quest.startDateTime).diff(moment(Date.now())) > 0 &&
              <Tooltip title="Delete" placement="top">
                <button className="remove-key" onClick={() => { this.deleteQuest(quest, index); }}>
                  <i className="fa fa-trash" />
                </button>
              </Tooltip>
            }
            <Tooltip title="Duplicate" placement="top">
              <button className="remove-key" onClick={() => { this.setState({ copyModal: true, questCopyId: quest.id, selectGame: gameId }); }}>
                <i className="fa fa-copy" />
              </button>
            </Tooltip>
          </div>
        </div>
      ));
    }
    return (<div className="NoQuests">No Quests for this game</div>);
  }

  deleteQuest(quest, index) {
    if (window.confirm('This will delete the selected Quest, proceed?')) {
      const { token } = this.props.auth;
      fetch(`/api/v1/admin/quest/${quest.id}`, {
        method: 'delete',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        this.props.commandCenter.quests.data.splice(index, 1);
        this.setState({ quests: this.props.commandCenter.quests.data });
      });
    }
  }

  copyCCQuest() {
    if (window.confirm('Are you sure about copy this Quest?')) {
      const { token } = this.props.auth;
      this.props.copyCCQuest(this.state.questCopyId, this.state.selectGame, token);
      this.setState({ copyModal: false });
    }
  }

  closeCopyModal = () => {
    this.setState({
      copyModal: false,
    });
  }

  renderCopyModal() {
    const { data: games } = this.props.commandCenter.games;
    // const { gameId } = this.props.router.params;
    let configGames = games.map(game => ({
      value: game.id,
      label: game.name,
    }));
    configGames = orderBy(configGames, ['label'], ['asc']);
    const renderSelectOptions = option => (<option key={option.value} value={option.value}>{option.label}</option>);
    return (
      <section className="ModalMaster active">
        <section className="Modal active">
          <button onClick={this.closeCopyModal.bind(this)} className="Modal__layer" aria-hidden />
          <section className="Modal__wrapper Copy">
            <div className="Modal__copy__header">
              Select the game
            </div>
            <div className="Modal__copy__body">
              <select
                onChange={event => this.setState({ selectGame: event.target.value })}
                className=""
                value={this.state.selectGame}
              >
                {configGames.map(renderSelectOptions)}
              </select>
            </div>
            <div className="Modal__copy__footer">
              <button
                onClick={this.closeCopyModal.bind(this)}
              >
                <i className="fa fa-times" /> Cancel
              </button>
              <button
                onClick={this.copyCCQuest.bind(this)}
              >
                <i className="fa fa-check" /> Copy
              </button>
            </div>
          </section>
        </section>
      </section>
    );
  }

   // 新增 syncRankings 方法
   async syncRankings() {
    const { token } = this.props.auth;
    // 根据实际情况填充 body 信息
    const body = { /* 根据需求构造请求体 */ };
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      }
    };
    const message = "排行榜同步";
    try {
      await Axios.post(`/api/v1/user/test-update-quest-rank`, body, config);
      simpleNotification({
        level: 'success',
        title: 'Task Update',
        message: `${message}成功`,
      });
    } catch (err) {
      simpleNotification({
        level: 'error',
        title: 'Task Update Failed',
        message: `${message}失败`,
      });
    }
  }

  render() {
    const { gameId } = this.props.router.params;
    const { data: quests, isLoading } = this.props.commandCenter.quests;
    const { tab } = this.state;

    if (isLoading) {
      return (<i className="fa fa-spinner fa-spin fa-3x fa-fw" />);
    }

    // 使用本地时区处理
    const userTimezone = moment.tz.guess();
    const today = moment();

    // 使用本地时区判断任务是否活跃
    let activeQuests = filter(quests, x => (
      moment.utc(x.endDateTime).tz(userTimezone) > today
    ));
    let finishedQuests = filter(quests, x => (
      moment.utc(x.endDateTime).tz(userTimezone) <= today
    ));

    activeQuests = orderBy(activeQuests, ['endDateTime'], ['desc']);
    finishedQuests = orderBy(finishedQuests, ['endDateTime'], ['desc']);

    let showQuests = [];

    if (tab === 1) {
      showQuests = [...activeQuests];
    } else if (tab === 2) {
      showQuests = [...finishedQuests];
    }

    

    return (
      <section className="CCQuests">
        <Link
          to={`/admin/command-center/${gameId}/quests/create`}
          activeClassName="active"
          href={`/admin/command-center/${gameId}/quests/create`}
        >
          <button className="CCQuests__create--btn CTAButton__purple">
            Create Quest
          </button>
        </Link>
        <button className="CCQuests__create--btn CTAButton__purple" onClick={() => this.syncRankings()}>
          同步排行榜
        </button>
        <div className="Global__tabs">
          <div className={classNames('Global__tab', { active: tab === 1 })} onClick={() => this.setState({ tab: 1 })} aria-hidden>
            Active ({activeQuests.length})
          </div>
          <div className={classNames('Global__tab', { active: tab === 2 })} onClick={() => this.setState({ tab: 2 })} aria-hidden>
            Finished ({finishedQuests.length})
          </div>
        </div>
        <section className="ToggleTable">
          <section className="ToggleTable__table-wrapper">
            <section className="ToggleTable__table flex-table">
              <div className="row">
                <div className="table-cell headline">Headline</div>
                <div className="table-cell headline" style={quickWidth}>Quest ID</div>
                <div className="table-cell headline">Start Date (Tracking)</div>
                <div className="table-cell headline">End Date (Tracking)</div>
                <div className="table-cell headline">Landing Page</div>
                <div className="table-cell headline">Pending Review</div>
                <div className="table-cell headline">Actions</div>
              </div>
              {this.generateRowsHTML(showQuests, gameId)}
            </section>
          </section>
        </section>
        {(this.state.copyModal) && this.renderCopyModal()}
      </section>
    );
  }
}

Quests.propTypes = {

};

export default Quests;
