import React, { useState } from 'react';
import axios from 'axios';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

const WithdrawalComponent = () => {
  // 状态：加载、错误信息
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // 保存支付数据
  const [paymentData] = useState({
    userId: '12345',
    transactionId: 'txn_98765',
    amount: 1000,
    currency: 'USD',
    payee: {
      email_address: 'sb-fk3pe34419627@personal.example.com' // 指定收款人PayPal账户（邮箱）
    },
    email: 'user@example.com',
    platform: 'web',
    ipAddress: '192.168.1.1', // 假设你已经获取了用户的IP
  });



  // 处理支付逻辑
  const processPayment = async () => {
    setLoading(true);  // 开始加载
    setError(null);    // 清除错误信息

    try {
      const response = await axios.post('/api/v1/paypal/withdrawal', paymentData,{headers: {
        'Content-Type': 'application/json',
      },
    });

      const { success, approvalLink } = response.data;

      if (success) {
        // 跳转到 PayPal 页面进行支付
        window.location.href = approvalLink;
      } else {
        throw new Error('Payment initiation failed.');
      }
    } catch (err) {
      setError(err.message || 'An error occurred while processing the payment.');
    } finally {
      setLoading(false); // 停止加载
    }
  };

  return (
    <div>
      <h1>Payment Page</h1>
      
      {/* 显示加载状态 */}
      {loading && <div>Processing payment, please wait...</div>}
      
      {/* 显示错误信息 */}
      {error && <div style={{ color: 'red' }}>{error}</div>}

      {/* 支付按钮 */}
      <button onClick={processPayment} disabled={loading} style={{
    padding: '10px 20px', // 增加按钮的内边距
    fontSize: '16px',      // 增加字体大小
    cursor: 'pointer',    // 鼠标悬停时显示手指形状
    backgroundColor: '#4CAF50', // 改变按钮背景颜色
    color: 'white',        // 设置字体颜色
    border: 'none',        // 去除边框
    borderRadius: '5px',   // 圆角按钮
    marginTop: '200px',  // 下移 200px
  }}>
        {loading ? 'Processing...' : 'Pay Now'}
      </button>
    </div>
  );
};

const PaymentComponent = () => {
  const initialOptions = {
    "client-id": "YOUR_CLIENT_ID", // 从 GitLab 或配置文件获取
    currency: "USD",
    intent: "CAPTURE",
  };

   // 保存支付数据
   const [paymentData] = useState({
    userId: '12345',
    transactionId: 'txn_98765',
    amount: 1000,
    currency: 'USD',
    payee: {
      email_address: 'sb-fk3pe34419627@personal.example.com' // 指定收款人PayPal账户（邮箱）
    },
    email: 'user@example.com',
    platform: 'web',
    ipAddress: '192.168.1.1', // 假设你已经获取了用户的IP
  });


  return (
    <PayPalScriptProvider options={initialOptions}>
      <PayPalButtons
        createOrder={async (data, actions) => {
          // 调用后端创建订单 API，返回订单 ID
          const orderResponse = await axios.post('/api/v1/paypal/withdrawal', paymentData,{headers: {
            'Content-Type': 'application/json',
          },
        });
          const orderData = await orderResponse.json();
          return orderData.id;
        }}
        onApprove={async (data, actions) => {
          // 调用后端捕获订单 API
          console.log(data);
          const captureResponse = await axios.post(`/api/v1/paypal/captureOrder?orderId=${data.orderID}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
          });
          const captureData = await captureResponse.json();
          if (captureData.status === 'success') {
            // 支付成功逻辑
          } else {
            // 支付失败处理
          }
        }}
      />
    </PayPalScriptProvider>
  );
};


const PayoutComponent = () => {
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const [failedItems, setFailedItems] = useState([]);

  const handlePayout = async () => {
    const payoutItems = [
      {
        recipient_type: 'EMAIL',
        amount: {
          value: '1000.00',
          currency: 'USD',
        },
        receiver: 'user1@example.com',
        note: 'e-wallet Withdrawal',
        userid:"12344",
        transactionId: 'item1',
        email: 'user@example.com',
        platform: 'web',
        ip:'127.0.0.1'
        
      },
      {
        recipient_type: 'EMAIL',
        amount: {
          value: '15.00',
          currency: 'USD',
        },
        receiver: 'user1@example.com',
        note: 'e-wallet Withdrawal',
        userid:"12344",
        transactionId: 'item2',
        email: 'user@example.com',
        platform: 'web',
        ip:'127.0.0.1'
      },
      {
        recipient_type: 'EMAIL',
        amount: {
          value: '15.00',
          currency: 'USD',
        },
        receiver: 'user1@example.com',
        note: 'e-wallet Withdrawal',
        userid:"12344",
        transactionId: 'item2',
        email: 'user@example.com',
        platform: 'web',
        ip:'127.0.0.1'
      },
      {
        recipient_type: 'EMAIL',
        amount: {
          value: '15.00',
          currency: 'USD',
        },
        receiver: 'user1@example.com',
        note: 'e-wallet Withdrawal',
        userid:"12344",
        transactionId: 'item2',
        email: 'user@example.com',
        platform: 'web',
        ip:'127.0.0.1'
      },
      {
        recipient_type: 'EMAIL',
        amount: {
          value: '15.00',
          currency: 'USD',
        },
        receiver: 'user1@example.com',
        note: 'e-wallet Withdrawal',
        userid:"12344",
        transactionId: 'item2',
        email: 'user@example.com',
        platform: 'web',
        ip:'127.0.0.1'
      },
      {
        recipient_type: 'EMAIL',
        amount: {
          value: '15.00',
          currency: 'USD',
        },
        receiver: 'user1@example.com',
        note: 'e-wallet Withdrawal',
        userid:"12344",
        transactionId: 'item2',
        email: 'user@example.com',
        platform: 'web',
        ip:'127.0.0.1'
      },
      {
        recipient_type: 'EMAIL',
        amount: {
          value: '15.00',
          currency: 'USD',
        },
        receiver: 'user1@example.com',
        note: 'e-wallet Withdrawal',
        userid:"12344",
        transactionId: 'item2',
        email: 'user@example.com',
        platform: 'web',
        ip:'127.0.0.1'
      },
      {
        recipient_type: 'EMAIL',
        amount: {
          value: '15.00',
          currency: 'USD',
        },
        receiver: 'user1@example.com',
        note: 'e-wallet Withdrawal',
        userid:"12344",
        transactionId: 'item2',
        email: 'user@example.com',
        platform: 'web',
        ip:'127.0.0.1'
      },
      {
        recipient_type: 'EMAIL',
        amount: {
          value: '15.00',
          currency: 'USD',
        },
        receiver: 'user1@example.com',
        note: 'e-wallet Withdrawal',
        userid:"12344",
        transactionId: 'item2',
        email: 'user@example.com',
        platform: 'web',
        ip:'127.0.0.1'
      },
      {
        recipient_type: 'EMAIL',
        amount: {
          value: '150.00',
          currency: 'USD',
        },
        receiver: 'user1@example.com',
        note: 'e-wallet Withdrawal',
        userid:"12344",
        transactionId: 'item2',
        email: 'user@example.com',
        platform: 'web',
        ip:'127.0.0.1'
      },
      {
        recipient_type: 'EMAIL',
        amount: {
          value: '150.00',
          currency: 'USD',
        },
        receiver: 'user1@example.com',
        note: 'e-wallet Withdrawal',
        userid:"12344",
        transactionId: 'item2',
        email: 'user@example.com',
        platform: 'web',
        ip:'127.0.0.1'
      },
      {
        recipient_type: 'EMAIL',
        amount: {
          value: '150.00',
          currency: 'USD',
        },
        receiver: 'user1@example.com',
        note: 'e-wallet Withdrawal',
        userid:"12344",
        transactionId: 'item2',
        email: 'user@example.com',
        platform: 'web',
        ip:'127.0.0.1'
      },
      {
        recipient_type: 'EMAIL',
        amount: {
          value: '1500.00',
          currency: 'USD',
        },
        receiver: 'user1@example.com',
        note: 'e-wallet Withdrawal',
        userid:"12344",
        transactionId: 'item2',
        email: 'user@example.com',
        platform: 'web',
        ip:'127.0.0.1'
      },
    ];

    try {
      const response = await axios.post('/api/paypal/create-payout', { items: payoutItems });
      setStatus(response.data.message);
      setError('');
      setFailedItems(response.data.data.failedItems || []);
    } catch (err) {
      setError(err.response?.data?.message || '请求支付失败');
      setStatus('');
      setFailedItems([]);
    }
  };

  return (
    <div>
      <h1>创建 PayPal 支付</h1>
      <button onClick={handlePayout} style={{
      padding: '10px 20px', // 增加按钮的内边距
      fontSize: '16px',      // 增加字体大小
      cursor: 'pointer',    // 鼠标悬停时显示手指形状
      backgroundColor: '#4CAF50', // 改变按钮背景颜色
      color: 'white',        // 设置字体颜色
      border: 'none',        // 去除边框
      borderRadius: '5px',   // 圆角按钮
      marginTop: '200px',  // 下移 200px
    }}>创建支付</button>

    {status && <p>Status: {status}</p>}
          {error && <p style={{ color: 'red' }}>Error: {error}</p>}

          {failedItems.length > 0 && (
            <div>
              <h2>失败的支付项</h2>
              <ul>
                {failedItems.map((item, index) => (
                  <li key={index}>{item.receiver} - {item.amount.value} {item.amount.currency} (失败原因: {item.error.message})</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      );
};

export  {WithdrawalComponent,PayoutComponent,PaymentComponent};
