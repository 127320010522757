import React from 'react';
import { find } from 'lodash';
import moment from 'moment-timezone';
import {
  Button,
  Checkbox,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';

import { LinkTabs } from '../../../tab-table/tableView';
import LinkTable from '../../../tab-table/table';
import RequirementModal from './RequirementModal';

import { simpleNotification } from '../../../../utils/notifications';
import { getQuestRequirements, linkify, parseLinks } from '../../../../utils/functions';
import { getContentMediaTypes } from '../../../../utils/apiv2';

import './QuestRequirementsTable.scss';

const notificationModule = (type) => {
  simpleNotification({
    level: 'warning',
    title: '',
    message: `Please select at least one item to ${type}.`,
  });
};

class QuestRequirementsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      checkboxes: new Array((props.quest.requirements ? getQuestRequirements(props.quest, true).length : 0) * props.userQuests.length).fill(false),
      currentView: [],
      selected: [],
      contentTypes: {},
    };

    this.tabList = [
      { name: 'Pending', data: 'warning' },
      { name: 'Approved', data: 'completed' },
      { name: 'Rejected', data: 'error' },
    ];

    this.tabSections = [
      'warning',
      'completed',
      'error',
    ];
  }

  componentDidMount() {
    const { quest } = this.props;

    getContentMediaTypes(quest.id).then((res) => {
      const contentTypes = {};

      res.data.contentMediaTypes.forEach((type) => {
        contentTypes[type.value] = type.label;
      });

      this.setState({ contentTypes });
    });
  }

  onCheck = (event, value, data) => {
    const { checkboxes } = this.state;
    let { selected } = this.state;

    if (value) selected.push(data);
    else selected = selected.filter(o => o.rowId !== data.rowId);

    checkboxes[data.rowId] = !checkboxes[data.rowId];

    this.setState({
      selected,
      checkboxes,
    });
  }

  setTab = (n) => {
    this.resetCheckBoxes();

    this.setState({
      tab: n,
      selected: [],
    });
  }

  resetCheckBoxes = () => {
    const { selected, checkboxes } = this.state;

    if (selected.length > 0) {
      selected.forEach((row) => { checkboxes[row.rowId] = false; });
    }

    this.setState({ selected: [], checkboxes });
  }

  selectAll = () => {
    const { currentView, checkboxes } = this.state;

    this.resetCheckBoxes();
    currentView.forEach((row) => { checkboxes[row.rowId] = true; });

    this.setState({ selected: currentView, checkboxes });
  }

  currentView = (rows) => {
    const { currentView } = this.state;

    if (currentView.length !== rows.length) {
      this.setState({ currentView: rows });
    } else {
      let same = true;

      const ids = rows.map(r => r.rowId);
      const currentIds = currentView.map(v => v.rowId);

      for (let i = 0; i < rows.length && same; i += 1) {
        if (!currentIds.includes(ids[i])) same = false;
      }

      if (!same) this.setState({ currentView: rows });
    }
  }

  isVOD = (requirement) => {
    const isVOD = !!((requirement['raw.type'] === 'submit-link' && requirement['raw.subtype'] === 'vod'));
    return isVOD;
  }

  rowMap = (tab, rows) => {
    const { selected, contentTypes } = this.state;
    const selectedUsernames = selected.length > 0 ? selected.map(s => s.username) : [];

    const newRows = rows.map((data) => {
      // Disable multi select across more than 1 user/1 VOD
      const notSelectedUser = !!((selectedUsernames.length > 0 && selectedUsernames[0] !== data.username));
      const anotherVODSelected = !!(this.isVOD(data) && (selected && selected.filter(s => (this.isVOD(s) && s['raw.id'] !== data['raw.id'])).length > 0));

      const disabled = anotherVODSelected || notSelectedUser;

      const checkbox = (
        <TableCell align="left">
          <Checkbox
            align="left"
            checked={this.state.checkboxes[data.rowId]}
            onChange={(e, value) => this.onCheck(e, value, data)}
            disabled={disabled}
          />
        </TableCell>
      );

      let display = null;
      const type = data['raw.type'];
      const { content } = data;

      if (content && (type === 'submit-tweet' || type === 'submit-link' || type === 'submit-content-media')) {
        display = linkify(content);
      } else if (content && (type === 'submit-text' || type === 'submit-text-optional')) {
        display = parseLinks(content);
      } else if (content) {
        display = content;
      }

      const reason = <TableCell align="left">{data.reason}</TableCell>;

      let submissionDate = '';
      if (data.submissionDate) {
        submissionDate = moment(data.submissionDate).tz('America/Los_Angeles').format('MMMM DD, YYYY - hh:mm a');
      }

      let rejectedDate = '';
      if (data.rejectedDate) {
        rejectedDate = moment(data.rejectedDate).tz('America/Los_Angeles').format('MMMM DD, YYYY - hh:mm a');
      }

      let completedDate = '';
      if (data.completedDate) {
        completedDate = moment(data.completedDate).tz('America/Los_Angeles').format('MMMM DD, YYYY - hh:mm a');
      }

      const rejectedDateCell = <TableCell align="left">{rejectedDate}</TableCell>;
      const completedDateCell = <TableCell align="left">{completedDate}</TableCell>;

      const title = type === 'submit-content-media' && !!data.contentType && !!contentTypes ? `[${data.title}] ${contentTypes[data.contentType]}` : data.title;

      return (
        <TableRow key={data.rowId}>
          {tab === 0 ? checkbox : null}
          <TableCell align="left">{data.username}</TableCell>
          <TableCell align="left">{data.platformName}</TableCell>
          <TableCell align="left">{title}{ }</TableCell>
          <TableCell align="left">{display}</TableCell>
          <TableCell align="left">{submissionDate}</TableCell>
          {tab === 1 ? completedDateCell : null}
          {tab === 2 ? rejectedDateCell : null}
          {tab === 2 ? reason : null}
        </TableRow>
      );
    });

    return newRows;
  }

  completeSelected = (reason) => {
    if (this.state.selected.length <= 0) notificationModule('approve');
    else this.processRequirements(reason, 'completed');
  }

  rejectSelected = (reason, link = null) => {
    if (this.state.selected.length <= 0) notificationModule('reject');
    else this.processRequirements(reason, 'rejected');
  }

  processRequirements = (reason, status) => {
    const { auth: { token }, postCCRequirementMultiple, quest: { id } } = this.props;
    const { selected } = this.state;

    const arrayToSend = [];

    for (let i = 0; i < selected.length; i += 1) {
      const select = selected[i];

      const newObj = {};

      newObj.userId = select.userId;
      newObj.content = select.content;
      newObj.id = select['raw.id'];
      newObj.note = reason || '';
      newObj.status = status;
      newObj.type = select['raw.type'];

      if (!newObj.status) {
        newObj.status = 'to-review';
      }

      const sendingObj = {
        ...newObj,
        resolution: !(reason),
        ...(reason ? { reason } : {}),
      };

      arrayToSend.push(sendingObj);
    }

    const uploadData = JSON.stringify(arrayToSend);

    postCCRequirementMultiple(uploadData, id, token);

    this.resetCheckBoxes();
  }

  render() {
    const { selected, tab, currentView } = this.state;
    const { userQuests, quest } = this.props;

    const requirements = getQuestRequirements(quest, true);

    const allUserReqs = [];
    let rowId = -1;

    for (let i = 0; i < userQuests.length; i += 1) {
      const uq = userQuests[i];

      if (uq.status === 'approved' || uq.status === 'normal') {
        const { user: { username, connectedAccounts, id } } = uq;
        const platformName = quest.twitchAccountRequired ? connectedAccounts.twitch.username : '';

        if (requirements && requirements.length > 0 && uq.requirements && uq.requirements.length > 0) {
          const userQuestRequirements = uq?.requirements || [];
          // eslint-disable-next-line no-loop-func
          requirements.forEach((r) => {
            rowId += 1;
            let status = null;

            const ur = find(userQuestRequirements, o => o.questReqId === r.id);

            if (ur) {
              if (ur.status === 'completed') status = 'completed';
              else if (ur.status === 'rejected') status = 'error';
              else status = 'warning';

              const {
                submissionDate,
                rejectedDate,
                completedDate,
                content,
                contentType,
              } = ur;

              let title = 'error in finding title';

              if (r.title) title = r.title;
              else if (r.type === 'submit-tweet') title = 'Twitter Link';
              else if (r.type === 'submit-link' && r.subtype === 'vod') title = 'VOD Link';
              else if (r.type === 'submit-link' && r.subtype === 'clip') title = 'Clip Link';

              if (status === null) status = 'warning';

              const newRow = {
                rowId,
                username,
                platformName,
                title,
                status,
                content: content || '',
                contentType: contentType || null,
                submissionDate,
                rejectedDate,
                completedDate,
                reason: (ur && ur.note ? ur.note : null),
                raw: r,
                userId: id,
              };

              allUserReqs.push(newRow);
            }
          });
        }
      }
    }

    const tabData = {
      warning: allUserReqs.filter(req => req.status === 'warning'),
      completed: allUserReqs.filter(req => req.status === 'completed'),
      error: allUserReqs.filter(req => req.status === 'error'),
    };

    const headers = [
      {
        id: 'username',
        numeric: false,
        label: 'NOIZ',
      },
      {
        id: 'platformName',
        numeric: false,
        label: (quest.twitchAccountRequired ? 'Twitch' : 'NA'),
      },
      {
        id: 'title',
        numeric: false,
        label: 'Requirement',
      },
      {
        id: 'content',
        numeric: false,
        label: 'Content',
      },
      {
        id: 'submissionDate',
        numeric: false,
        label: 'Submission Date',
      },
    ];

    const blank = [{
      id: 'checkbox',
      numeric: false,
      label: '',
    }];

    const tabHeaders = {
      warning: blank.concat(headers),
      completed: headers.concat([{ id: 'completedDate', numeric: false, label: 'Approved Date' }]),
      error: headers.concat([{ id: 'rejectedDate', numeric: false, label: 'Rejected Date' }, { id: 'reason', numeric: false, label: 'Reason' }]),
    };

    const tabRows = {
      warning: rows => this.rowMap(0, rows),
      completed: rows => this.rowMap(1, rows),
      error: rows => this.rowMap(2, rows),
    };

    const buttonProps = {
      variant: 'contained',
      size: 'large',
      ...(selected.length > 0 ? {} : { disabled: true }),
      ...(tab !== 0 ? { disabled: true } : {}),
    };

    return (
      <div>
        <div className="SubmissionButtons">
          <RequirementModal
            title="Approve"
            handleSubmit={this.completeSelected}
            selectedCheckboxesLength={selected.length}
            useMaterialUI={Object.assign({}, buttonProps, (tab === 2 ? { disabled: true } : {}))}
          />
          <RequirementModal
            title="Reject"
            handleSubmit={this.rejectSelected}
            selectedCheckboxesLength={selected.length}
            useMaterialUI={Object.assign({}, buttonProps, (tab === 2 ? { disabled: true } : {}))}
          />
          <Tooltip title="Unselect all currently selected" placement="top">
            <span>
              <Button {...buttonProps} onClick={this.resetCheckBoxes}>Clear Selected</Button>
            </span>
          </Tooltip>
          <Tooltip title="Select all requirements in the current viewing window" placement="top">
            <span>
              <Button {...buttonProps} disabled={tab !== 0 || currentView.length <= 0} onClick={this.selectAll}>Select All</Button>
            </span>
          </Tooltip>
        </div>
        <LinkTabs
          setTab={this.setTab}
          tabList={this.tabList}
          tableData={tabData}
          tableHeaders={this.tabList}
          headerTemplates={tabHeaders}
          rowTemplates={tabRows}
          Table={LinkTable}
          passUp={this.currentView}
        />
      </div>
    );
  }
}

export default QuestRequirementsTable;
