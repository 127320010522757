import React, { useEffect, useState } from 'react';

import CDKeyItem from './CDKeyItem';

import './CDKeyList.scss';
import { Link } from "react-router";

function CDKeyList(props) {
    const { auth: { token, user } ,searchKeyword} = props;
    const { data: cdkProducts = [] } = props.user?.cdkProducts || { isLoading: false };
    const [filterGames, setFilterGames] = useState([]);
    useEffect(() => {
        props.getCDKProducts(' ', 0, null, false, token, 0);
    }, [])
    useEffect(() => {
        if (cdkProducts) {
            const fps = Object.values(cdkProducts.reduce((acc, product) => {
                const exist = acc[product.game.id];
                if ((!exist || product.price < exist.price)&&(!searchKeyword||!searchKeyword.trim()||product.game.name.toLowerCase().indexOf(searchKeyword.toLowerCase())>=0)) {
                    acc[product.game.id] = product;
                }
                return acc;
            }, {}));
            setFilterGames(fps);
        }
    }, [cdkProducts])
    return (
        <div className="GQL__Wrapper_CDKey">
            {cdkProducts && cdkProducts.length > 0 &&
                <div className="game-cdkey-list">
                    <div className="cdkey-grid">
                        {filterGames.map((product, index) => (
                            <CDKeyItem
                                key={product.id}
                                quest={product.game}
                                product={product}
                                user={user}
                                isRightEdge={((index + 1) % 4 === 0)}
                            />
                        ))}
                    </div>
                </div>}
            {(!cdkProducts || cdkProducts.length <= 0) &&
                <div className='no-quests'>
                    There are currently no active cdkeys, please watch your email for announcements and join
                    us on{" "}
                    <Link rel='noopener noreferrer' target='_blank' to='https://discord.gg/Hwb2Rrg'>
                        Discord
                    </Link>
                    .
                </div>}
        </div>
    );
};

export default CDKeyList;
