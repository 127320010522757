import React from "react";

import { ActionButton, CampaignGameButton, CampaignActionButton } from "./QuestCampaignButtons";
import { SetViewContext } from "../../PortalContext";
import { extractInstantQuestPayment } from "../../../../../utils/functions";
import { activateIntercom } from "../../../../../utils/functions";

import Icon from "../../../../../global/images/icons/Icon";
import errorCover from "../../../../images/errorCover.jpg";
import searchIcon from './search.png'
import "./QuestsDisplay.scss";
import "./CampaignDisplay.scss";

export const BlankCampaign = () => (
  <div className='QuestDisplay__Blank'>
    {Icon({ color: "#302f34", name: "loudspeaker" })}
    <div className='QuestDisplay__Blank_Text'>Interested in a NOIZ Managed Campaign?  Get in touch!</div>
    <div className='QuestDisplay__Blank_Button' onClick={activateIntercom}>Yes, Let's Talk!</div>
  </div>
);

export const QuestCard = ({ quest, quest: { img, title, budget, startDate, endDate, createdAt, game, viewerTiers = [], id: questId } }) => {
  const rewardPayment = extractInstantQuestPayment(quest);
  const { cover = "", id: gameId } = game;
  return (
    <div className='QuestDisplay__Item'>
      <img className='QuestDisplay__Item_Img' src={cover} alt={title} />
      <div className='QuestDisplay__Item_Details'>
        <div className='QuestDisplay__Item_Title'>{title}</div>
        <div className='QuestDisplay__Item_Rewards'>
          <div className='QuestDisplay__Item_Rewards_Title'>Reward/Streamer</div>
          <div className='QuestDisplay__Item_Rewards_Details'>
            {rewardPayment > 0 &&
              <>
                <Icon name="moneybag" />
                <div className='QuestDisplay__Item_Rewards_Details_Text'>${(Math.round(rewardPayment * 100) / 100).toFixed(2)}</div>
              </>
            }
            {rewardPayment === -1 &&
              <>
                <Icon name='key' />
                <div className='QuestDisplay__Item_Rewards_Details_Text'>Free Key</div>
              </>
            }
            {rewardPayment === -2 &&
              <>
                <Icon name='moneybag' />
                <div className='QuestDisplay__Item_Rewards_Details_Text'>Points</div>
              </>
            }
          </div>
        </div>
        <div className='QuestDisplay__Item_Actions'>
          <ActionButton tooltip='Reports' icon='reports' url='/reports' args={{ component: "report", game: gameId, questId }} />
          <SetViewContext.Consumer>
            {({ setView, view }) => (
              <div
                className='QuestDisplay__Item_View_Details'
                onClick={() =>
                  setView({
                    ...view,
                    currentQuest: quest.id,
                    component: "QuestDetail",
                  })
                }>
                <img src={searchIcon} alt="searchIcon" /> <span>View Details</span>
              </div>
            )}
          </SetViewContext.Consumer>
        </div>

      </div>
    </div>
  );
};

export const CampaignCard = ({ campaign, campaign: { campaign: campaignId, img, name, budget, startDate, endDate, createdAt, game, totalQuests, hoursStreamed, hoursWatched } }) => {
  const { cover = "", id = "" } = game || {};
  return (
    <div className='CampaignDisplay__Item'>
      <img className='CampaignDisplay__Item_Img' src={cover} alt={name} onError={(e) => {
        e.target.src = errorCover;
      }} />
      <div className='CampaignDisplay__Item_Details'>
        <div className='CampaignDisplay__Item_Title'>{name}</div>

        <div className='CampaignDisplay__Item_Rewards'>
          <div>
            <div className='CampaignDisplay__Item_Rewards_Title'>Hours Watched</div>
            <div className='CampaignDisplay__Item_Rewards_Details'>{Math.round(hoursWatched, 2)}</div>
          </div>
          <div>
            <div className='CampaignDisplay__Item_Rewards_Title'>Total Quests</div>
            <div className='CampaignDisplay__Item_Rewards_Details'>{totalQuests}</div>

          </div>
          <div>
            <div className='CampaignDisplay__Item_Rewards_Title'>Budget</div>
            <div className='CampaignDisplay__Item_Rewards_Details'>
              {Icon({ color: "white", name: "moneybag" })}
              <div className='CampaignDisplay__Item_Rewards_Details_Text'>${(Math.round(budget * 100) / 100).toFixed(2)}</div>
            </div>
          </div>
        </div>
        <div className='CampaignDisplay__Item_Actions'>
          <CampaignActionButton style={{ width: "100px" }} tooltip='Reports' icon='graph' url='/reports' args={{ component: "report", game: id, campaignId }} />
          <CampaignGameButton game={game} />
        </div>
      </div>
    </div>
  );
};
