import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactGA from 'react-ga';

import {
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';

import {
  FormControl,
  TextField,
} from '@material-ui/core';

import { updateContact } from '../../../utils/apiv2';
import { isProduction } from '../../../utils/functions';
import { simpleNotification } from '../../../utils/notifications';

import './BrandServiceSlides.scss';

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!re.test(String(email).toLowerCase())) {
    return false;
  }

  if (email.includes('@gmail.com') || email.includes('@hotmail.com') || email.includes('@yahoo.com') || email.includes('@ymail.com')) {
    return false;
  }

  return true;
};
const isMobile = window.innerWidth < 700;

const CustomInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      color: 'white !important',
      fontWeight: 400,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 12,
      '&:hover fieldset': {
        borderColor: '#595261',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#595261',
      },
    },
  },
}))(TextField);

const useStyles = makeStyles(theme => ({
  root: {
    border: 0,
    borderRadius: 0,
    '& .MuiTextField-root': {
      borderRadius: 0,
      fontSize: 16,
      color: 'white !important',
      width: isMobile ? '80vw !important' : '360px !important',
      maxWidth: '80vw',
      marginTop: isMobile ? theme.spacing(1.5) : theme.spacing(3),
      marginBottom: isMobile ? theme.spacing(3) : theme.spacing(3),

      //   paddingTop: 0,
      //   paddingBottom: 0,
    },
    '& .MuiInputBase-input': {
      borderRadius: 12,
      border: 0,
      padding: 10,
      fontSize: 16,
    },
    '& .MuiSelect-root': {
      maxHeight: 58,
      border: 0,
      color: 'white !important',
    },
  },
}));

export default function CustomizedInputs() {
  useEffect(() => {
    ReactGA.initialize('UA-116227928-1');
  }, []);

  const classes = useStyles({
    formLabel: {
      color: '#000',
      '&.Mui-focused': {
        color: '#000',
      },
    },
  });

  const [company, setCompany] = useState('');
  const [product, setProduct] = useState('');
  const [email, setEmail] = useState('');
  const [campaignGoal, setCampaignGoal] = useState('');
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [promoCode, setPromoCode] = useState('');

  const [steps, setSteps] = useState(0);
  const [errors, setErrors] = useState({});

  const [hasInteracted, setHasInteracted] = useState(false);

  const clearForm = () => {
    setCompany('');
    setProduct('');
    setEmail('');
    setCampaignGoal('');
    setAdditionalNotes('');
    setPromoCode('');
    setSteps(0);
  };

  const sendInteractionToGoogle = () => {
    if (!hasInteracted && isProduction()) {
      ReactGA.event({
        category: 'form interactions',
        action: 'touched_form',
        label: 'touched form',
      });
      setHasInteracted(true);
    }
  };

  const sendConversionToGoogle = () => {
    if (isProduction()) {
      ReactGA.event({
        category: 'form interactions',
        action: 'form_submitted',
        label: 'form submitted',
      });
    }
  };

  const verifyFields = () => {
    const errors = {};

    if (steps === 0) {
      if (!company || !company.trim()) {
        errors.company = true;
      }

      if (!product || !product.trim()) {
        errors.product = true;
      }

      if ((!email || !validateEmail(email)) && !validateEmail(email)) {
        errors.email = true;
      }
    }

    if (steps === 1) {
      if (!campaignGoal || !campaignGoal.trim()) {
        errors.campaignGoal = true;
      }

      if (!additionalNotes || !additionalNotes.trim()) {
        errors.additionalNotes = true;
      }
    }

    setErrors(errors);
  };

  const verifyField = field => {
    if (field === 'email') {
      if (!validateEmail(email)) {
        setErrors({ ...errors, email: true });
      } else {
        const { email, ...rest } = errors;
        setErrors(rest);
      }
    }

    if (field === 'company') {
      if (!company || !company.trim()) {
        setErrors({ ...errors, company: true });
      } else {
        const { company, ...rest } = errors;
        setErrors(rest);
      }
    }

    if (field === 'product') {
      if (!product || !product.trim()) {
        setErrors({ ...errors, product: true });
      } else {
        const { product, ...rest } = errors;
        setErrors(rest);
      }
    }

    if (field === 'campaignGoal') {
      if (!campaignGoal || !campaignGoal.trim()) {
        setErrors({ ...errors, campaignGoal: true });
      } else {
        const { campaignGoal, ...rest } = errors;
        setErrors(rest);
      }
    }

    if (field === 'additionalNotes') {
      if (!additionalNotes || !additionalNotes.trim()) {
        setErrors({ ...errors, additionalNotes: true });
      } else {
        const { additionalNotes, ...rest } = errors;
        setErrors(rest);
      }
    }
  };

  const handleSubmit = () => {
    verifyFields();

    if (steps === 0 && email && company && product) {
      if (Object.keys(errors).length === 0) {
        axios.post('/api/v4/guest/initial-contact', {
          company,
          product,
          email,
        });

        setSteps(1);
      }
    } else {
      submit();
    }
  };

  const submit = async () => {
    if (email && !validateEmail(email)) {
      simpleNotification({
        level: 'error',
        title: 'Valid Email Required',
        autoDismiss: 5,
        position: 'bl',
        message: 'Please enter a valid business email',
      });
      return null;
    }

    if (email) {
      let response;
      try {
        response = await updateContact({
          email,
          campaignGoal,
          additionalNotes,
          promoCode,
        });
      } catch (err) {
        simpleNotification({
          level: 'error',
          title: 'Something Went Wrong',
          autoDismiss: 10,
          position: 'bl',
          message: "We're sorry something went wrong!  Please try the chat widget on your right!",
        });
      }
      sendConversionToGoogle();
      if (response && response.status === 200) {
        simpleNotification({
          level: 'success',
          title: 'Got it!',
          position: 'bl',
          autoDismiss: 10,
          message: 'One of our team members will be in touch right away!',
        });
        clearForm();
      }
    } else {
      simpleNotification({
        level: 'error',
        title: 'Name and Email Required',
        autoDismiss: 5,
        position: 'bl',
        message: "We'd love to connect! Please add at least a name and email",
      });
    }
  };

  const handleChange = (field, event) => {
    sendInteractionToGoogle();
    switch (field) {
      case 'company':
        setCompany(event.target.value);
        break;
      case 'product':
        setProduct(event.target.value);
        break;
      case 'email':
        setEmail(event.target.value);
        break;
      case 'campaignGoal':
        setCampaignGoal(event.target.value);
        break;
      case 'additionalNotes':
        setAdditionalNotes(event.target.value);
        break;
      case 'promoCode':
        setPromoCode(event.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <div className="brand-form-control brand-form-control-contactForm">
      <h2>GET IN TOUCH</h2>
      <form className={classes.root} style={{ display: 'flex', flexDirection: 'column' }}>
        {steps === 0 && <>
          <FormControl className={classes.margin}>
            <CustomInput
              required
              label="Company name"
              variant="outlined"
              error={errors.company}
              value={company}
              onBlur={() => verifyField('company')}
              onChange={e => handleChange('company', e)}
            />
          </FormControl>
          <FormControl className={classes.margin}>
            <CustomInput
              required
              label="Email"
              variant="outlined"
              error={errors.email}
              value={email}
              onBlur={() => verifyField('email')}
              onChange={e => handleChange('email', e)}
            />
          </FormControl>
          <FormControl className={classes.margin}>
            <CustomInput
              required
              label="Product name"
              variant="outlined"
              error={errors.product}
              value={product}
              onBlur={() => verifyField('product')}
              onChange={e => handleChange('product', e)}
            />
          </FormControl>
        </>}
        {steps === 1 && <>
          <FormControl className={classes.margin}>
            <CustomInput
              required
              label="Campaign Goal"
              variant="outlined"
              error={errors.campaignGoal}
              value={campaignGoal}
              onBlur={() => verifyField('campaignGoal')}
              onChange={e => handleChange('campaignGoal', e)}
            />
          </FormControl>
          <FormControl className={classes.margin}>
            <CustomInput
              required
              label="Additional notes"
              variant="outlined"
              error={errors.additionalNotes}
              value={additionalNotes}
              onBlur={() => verifyField('additionalNotes')}
              onChange={e => handleChange('additionalNotes', e)}
            />
          </FormControl>
          <FormControl className={classes.margin}>
            <CustomInput
              label="Promo Code"
              variant="outlined"
              value={promoCode}
              onChange={e => handleChange('promoCode', e)}
            />
          </FormControl>
        </>}
      </form>
      <div >
        <button disabled={Object.keys(errors).length > 0} onClick={handleSubmit} className="brand-rec-button">
          {steps === 0 && 'CONTINUE'}
          {steps === 1 && 'Submit'}
        </button>
      </div>
    </div>
  );
}
