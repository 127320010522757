/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/label-has-for */

import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import RichText from '../RichText';

// const rteDefaultConfig = {
//   skin: 'dark',
//   plugins: 'link lists advlist media image textcolor',
//   toolbar: 'formatselect | fontselect | fontsizeselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent  | removeformat',
//   content_style: '#tinymce{background-color:#f4f4f9 !important;color:#000 !important; font-family:Helvetica;}',
// };

class CustomInput extends Component {
  shouldComponentUpdate(nextProps) {
    const { input, meta } = this.props;
    return (
      input.value !== nextProps.input.value ||
      meta.touched !== nextProps.meta.touched ||
      meta.error !== nextProps.meta.error
    );
  }

  handleInputChange = (event) => {
    const { input, ...custom } = this.props;
    const { value } = event.target;

    const isValidInput = (
      (custom.onlyNumbers === 'true' && /^\d*$/.test(value)) ||
      (custom.onlyCurrency === 'true' && /^(?:\d+(?:\.\d{1,2})?|\.\d{1,2}|\d+\.|)$/.test(value)) ||
      (custom.onlyNumbers !== 'true' && custom.onlyCurrency !== 'true')
    );

    if (isValidInput) {
      if (custom.handleChange) {
        const newValue = custom.handleChange(event);
        if (newValue) {
          event.target.value = newValue;
        }
      }
      input.onChange(event);
    }
  }

  renderInput() {
    const { input, meta: { touched, error }, ...custom } = this.props;
    const hasError = touched && error !== undefined;
    const { copyUrl } = custom;

    const commonProps = {
      autoComplete: "off",
      id: custom.id,
      placeholder: custom.placeholder,
      className: classNames({
        touched,
        error: hasError,
        ok: !hasError,
        empty: !input.value,
        'not-empty': input.value,
        copyUrl
      })
    };

    if (custom.type === 'textarea') {
      return (
        <textarea
          {...commonProps}
          {...input}
          rows={custom.rows}
          type={custom.type}
          onChange={(value) => input.onChange(value)}
        />
      );
    }

    if (custom.type === 'richtext') {
      return (
        <RichText
          initialValue={input.value}
          callback={custom.handleChange}
        />
      );
    }

    return (
      <input
        {...commonProps}
        {...input}
        type={custom.type}
        onChange={this.handleInputChange}
        readOnly={custom.readOnly}
      />
    );
  }

  render() {
    const { input, meta: { touched, error }, ...custom } = this.props;
    const hasError = touched && error !== undefined;
    return (
      <div className="input-container" style={{ flexBasis: custom.flexBasis }}>
        {custom.customLabel}
        <div className={classNames('input-wrapper', { error: hasError })}>
          {custom.prefixIcon &&
            <img
              src={custom.prefixIcon}
              className="prefix-icon"
              alt="prefix"
            />
          }
          {this.renderInput()}
          {!hasError && touched && (
            <div className="ok-icon">
              <i className="fa fa-check" />
            </div>
          )}
        </div>
        {custom.customLabel2}
        <div className="error-message" style={{ opacity: hasError ? '1' : '0' }}>{error}</div>
      </div>
    );
  }
}

CustomInput.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,

  placeholder: PropTypes.string,
};

CustomInput.defaultProps = {
  placeholder: '',
};

export default CustomInput;
