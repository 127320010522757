import React from 'react';

import GalleryModal from './GalleryModal';

const GameGallery = (props) => {
  const { gallery, isGameNew } = props;
  const galleryLimited = [...gallery];
  // galleryLimited.length = isGameNew? galleryLimited.length:4;

  const galleryHtml = galleryLimited.map((item, i) => (
    <GalleryModal
      img={item.url}
      isImage
      items={gallery.map(g => g.url)}
      index={i}
      key={i}
    />
  ));

  // const galleryBarHtml = (
  //   <GalleryModal
  //     img=""
  //     isImage={false}
  //     items={gallery.map(g => g.url)}
  //   />
  // );

  return (
    <>
      <div className={`GameGallery ${isGameNew ? 'game-new-gallery' : ''}`}>
        {galleryHtml}
      </div>
      {/* <div className="gameGalleryBar">
        {' '}
        {galleryBarHtml}
      </div> */}
    </>
  );
};

export default GameGallery;
