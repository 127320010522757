import React from 'react';
import { ParallaxLayer } from '../../../custom_modules/react-spring/src/renderprops/addons/index';
import './BrandServiceSlides.scss';
import anime from 'animejs';
import throttle from '../throttle';
import CylinderScroll from './CylinderScroll';
import scrollMouse from '../../home/assets/v4/scroll.svg';


const AFullService = (props) => {
  const {
    pOffset, layoutFactor = 1, offsetAdjust = 0,
  } = props;
  const ref = React.createRef();

  let isIn = true;

  const toggleMenu = (scrollObj) => {
    const scrollPos = Math.abs(scrollObj.translate);
    if (scrollPos >= 50 && isIn) {
      anime({
        targets: '.Main__Header__wrapper',
        opacity: 0,
        duration: 100,
        easing: 'linear',
      });
      const fixedContactDealy = document.querySelector('.fixed-contact-dealy');
      if (fixedContactDealy) {
        fixedContactDealy.style.display = 'flex';
      }
      anime({
        targets: '.fixed-contact-dealy',
        opacity: 1,
        duration: 100,
        easing: 'linear',
      });
      isIn = false;
    }

    if (scrollPos <= 49 && !isIn) {
      anime({
        targets: '.Main__Header__wrapper',
        opacity: 1,
        duration: 80,
        easing: 'linear',
      });
      anime({
        targets: '.fixed-contact-dealy',
        opacity: 0,
        duration: 80,
        easing: 'linear',
      });
      document.querySelector('.fixed-contact-dealy').style.display = 'none';
      isIn = true;
    }
  };

  const toggleMenuThrottle = throttle(toggleMenu, 100);


  return (
    <>
      <ParallaxLayer
        className="brand-service-slide-a"
        factor={layoutFactor}
        offset={pOffset + offsetAdjust}
        speed={0}
      >
        <CylinderScroll />
      </ParallaxLayer>
      <ParallaxLayer
        className="brand-service-slide-a-text"
        offset={pOffset + offsetAdjust}
        onFrame={toggleMenuThrottle}
        space={0}
        speed={0.5}
      >
        <div className="brand-service-slide-a-text-content">
          Noiz is a full service marketing platform that connects brands with influencers <br /> in  gaming and entertainment
        </div>
        <div ref={ref} />
      </ParallaxLayer>
      <ParallaxLayer
        className="scroll-for-more-brand"
        fontSize="large"
        offset={pOffset + offsetAdjust}
        space={0}
        speed={0.8}
      >
        <div className="scroll-notice-flex">
          <img src={scrollMouse} alt="scroll mouse" />
          <div>scroll for more</div>
        </div>
      </ParallaxLayer>
    </>
  );
};

export default AFullService;
