import React from 'react';
import './Faq.scss';
import IconParticles from '../icon-particles/IconParticles';

class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    return (
      <section className="Faq">
        <section className="Faq__container">
          <section className="Hero">
            <IconParticles />
          </section>
          <section className="Questions">

            <section className="Questions__content">
              <div className='title_wrap'>
                <span className='Questions__content__title'>Frequently Asked Questions</span>
              </div>
              <section className="QuestionsContainer">
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        01
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      What is Noiz?
                    </section>
                    <section className="QuestionItem__answer">
                      Noiz is a platform for streamers and creators to find opportunities like paid sponsorships, early access to games, commission on sales and much more. We started as a team of gamers for gamers, and are advocates for the creator community!
                    </section>
                  </section>
                  {/*  */}
                </section>
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      {/* <div className="QuestionItem__decoration">
                        <div className="d1" />
                      </div> */}
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        02
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      What type of Quests are available?
                    </section>
                    <section className="QuestionItem__answer">
                      We aim to have Quests available where you will have opportunities to earn money, early access, free game offers and more.
                    </section>
                  </section>
                  {/*  */}
                </section>
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      {/* <div className="QuestionItem__decoration">
                        <div className="d1" />
                      </div> */}
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        03
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      How do I know if I'm eligible for a Quest?
                    </section>
                    <section className="QuestionItem__answer">
                      On the Quest details, we will show the qualifications and requirements of the Quest. When you try to join a Quest, our platform will also verify you meet the requirements.
                    </section>
                  </section>
                  
                </section>
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      {/* <div className="QuestionItem__decoration">
                        <div className="d1" />
                      </div> */}
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        04
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      Can I join multiple Quests?
                    </section>
                    <section className="QuestionItem__answer">
                      Yes, while some Quests may overlap, you are welcome to join as many Quests as you like. Be aware, we do keep track and if you consistently join Quests but do not fulfill the requirements, you may be banned from future Quests.
                    </section>
                  </section>
                  
                </section>
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      {/* <div className="QuestionItem__decoration">
                        <div className="d1" />
                      </div> */}
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        05
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      What happens after I join a Quest?
                    </section>
                    <section className="QuestionItem__answer">
                      After you have joined a Quest, you will receive an email notification when it goes live and ends.
                    </section>
                  </section>
                  
                </section>
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      {/* <div className="QuestionItem__decoration">
                        <div className="d1" />
                      </div> */}
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        06
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      Where do I find the Quest details after I join?
                    </section>
                    <section className="QuestionItem__answer">
                      Once you are signed up for a Quest, you have two options to find your Quest if you're logged in 1. You can go back and find the Quest details in your Quest Book or 2. In the dropdown menu under your username and in My Quests.
                    </section>
                  </section>
                  
                </section>
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      {/* <div className="QuestionItem__decoration">
                        <div className="d1" />
                      </div> */}
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        07
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      The Quest tracking started, now what?
                    </section>
                    <section className="QuestionItem__answer">
                      Start streaming! Make sure you follow all the requirements in directions in your Quest.
                    </section>
                  </section>
                  
                </section>
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      {/* <div className="QuestionItem__decoration">
                        <div className="d1" />
                      </div> */}
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        08
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      How do I stream for a game I do not own?
                    </section>
                    <section className="QuestionItem__answer">
                      While some Quests require you to already have the game, others will offer a game key as well. Before a Quest goes live, we will send you a notification stating the game key is ready to redeem. When you receive the notification, come back to noiz and in the Quest book, you will be able to find your game key.
                    </section>
                  </section>
                  
                </section>
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      {/* <div className="QuestionItem__decoration">
                        <div className="d1" />
                      </div> */}
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        09
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      Can I stream longer to make more money?
                    </section>
                    <section className="QuestionItem__answer">
                      Quests have certain time limits and while you are welcome to stream longer, for paid Quests, you will only be paid for the live duration.
                    </section>
                  </section>
                  
                </section>
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      {/* <div className="QuestionItem__decoration">
                        <div className="d1" />
                      </div> */}
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        10
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      Something came up, can I leave a Quest?
                    </section>
                    <section className="QuestionItem__answer">
                      To leave a Quest, go to your Quest book located on the top of the site once logged in. Select the Quest and click on the leave Quest button. If you leave a Quest when live, you will lose all progress you have made and forfeit any gains you made.
                    </section>
                  </section>
                  
                </section>
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      {/* <div className="QuestionItem__decoration">
                        <div className="d1" />
                      </div> */}
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        11
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      How do I get paid?
                    </section>
                    <section className="QuestionItem__answer">
                      We use Paypal, if you do not have a Paypal account, please make one.
                    </section>
                  </section>
                  
                </section>
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      {/* <div className="QuestionItem__decoration">
                        <div className="d1" />
                      </div> */}
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        12
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      When do I get paid?
                    </section>
                    <section className="QuestionItem__answer">
                      Payment terms will be different for each Quest, but typically payment occur 45-60 days after the Quest ends depending on the campaign.
                    </section>
                  </section>
                  
                </section>
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      {/* <div className="QuestionItem__decoration">
                        <div className="d1" />
                      </div> */}
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        13
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      Do I need to follow all the requirements in the Quest?
                    </section>
                    <section className="QuestionItem__answer">
                      Yes, we require you to follow all the requirements in the Quest. If we find out at any point you do not have the proper information, you may not qualify for the payouts.
                    </section>
                  </section>
                  
                </section>
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        14
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      Why can't I join a Quest?
                    </section>
                    <section className="QuestionItem__answer">
                      Sometimes, Quests are invites only while some are based on requirements. You may not be part of a group that has been invited or do not meet the requirements.
                    </section>
                  </section>
                  
                </section>
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      {/* <div className="QuestionItem__decoration">
                        <div className="d1" />
                      </div> */}
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        15
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      How do you know I completed a Quest?
                    </section>
                    <section className="QuestionItem__answer">
                      Our platform verifies your stream times and tags, we may also verify you completed Quest by viewing your video upload.
                    </section>
                  </section>
                  
                </section>
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      {/* <div className="QuestionItem__decoration">
                        <div className="d1" />
                      </div> */}
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        16
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      How do you determine my pay rate?
                    </section>
                    <section className="QuestionItem__answer">
                      Pay rates will differ by Quests. Some may be based on average viewers, sometimes a flat rate. Rates will be displayed on the Quest details.
                    </section>
                  </section>
                  
                </section>
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      {/* <div className="QuestionItem__decoration">
                        <div className="d1" />
                      </div> */}
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        17
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      What is a Quest Book?
                    </section>
                    <section className="QuestionItem__answer">
                      The Quest Book is your history of all the Quests you have participated in or have signed up for. This is also where you will need to go to retrieve game keys when available.
                    </section>
                  </section>
                  
                </section>
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      {/* <div className="QuestionItem__decoration">
                        <div className="d1" />
                      </div> */}
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        18
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      How do I connect my Twitch Account?
                    </section>
                    <section className="QuestionItem__answer">
                      Click on your name and go to connect accounts, there you will see the connections available.
                    </section>
                  </section>
                  
                </section>
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      {/* <div className="QuestionItem__decoration">
                        <div className="d1" />
                      </div> */}
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        19
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      I'm in a different Time Zone, when do I need to stream?
                    </section>
                    <section className="QuestionItem__answer">
                      Noiz works in Pacfic Time zone. Check the start time in your Quest and convert to your time zone from PST.
                    </section>
                  </section>
                  
                </section>
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      {/* <div className="QuestionItem__decoration">
                        <div className="d1" />
                      </div> */}
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        20
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      I completed my stream, it says failed what do I do?
                    </section>
                    <section className="QuestionItem__answer">
                      If you did not complete your stream in the Quest tracking time, you will fail the Quest. You also need to follow the requirements to complete the Quest. Reach out to support if you have issues.
                    </section>
                  </section>
                  
                </section>
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      {/* <div className="QuestionItem__decoration">
                        <div className="d1" />
                      </div> */}
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        21
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      I completed my stream, how do I see how much / when I will be paid?
                    </section>
                    <section className="QuestionItem__answer">
                      Go to your My Quests or Quest Book and you will see the payout and payout date. By default, payments will occur 45-60 days after the Quest has ended.
                    </section>
                  </section>
                  
                </section>
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      {/* <div className="QuestionItem__decoration">
                        <div className="d1" />
                      </div> */}
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        22
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      How to get my unique referral link?
                    </section>
                    <section className="QuestionItem__answer">
                      Your unique referral link will be in the referral Quest. You must have signed up to receive this unique link.
                    </section>
                  </section>
                  
                </section>
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      {/* <div className="QuestionItem__decoration">
                        <div className="d1" />
                      </div> */}
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        23
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      A Quest is showing me a lower payout than normal, why is this?
                    </section>
                    <section className="QuestionItem__answer">
                      The higher tiers spots are full and you can only sign up for this tier.
                    </section>
                  </section>
                  
                </section>
                <section className="QuestionItem">
                  <section className="QuestionItem__icon">
                    <div className="QuestionItem__icon-wrapper">
                      {/* <div className="QuestionItem__decoration">
                        <div className="d1" />
                      </div> */}
                      <div className="QuestionItem__background-layer" />
                      <div className="QuestionItem__number">
                        24
                      </div>
                    </div>
                  </section>
                  <section className="QuestionItem--right">
                    <section className="QuestionItem__question">
                      I have issues, how can I reach you?
                    </section>
                    <section className="QuestionItem__answer">
                      You can send us any Questions to <a href="mailto:support@noiz.gg">support@noiz.gg</a> or join our Discord channel: <a href="https://discord.gg/4nNpS3kdtp" target="_blank" rel="noopener noreferrer">https://discord.gg/4nNpS3kdtp</a>
                    </section>
                  </section>
                  
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

// AboutUs.propTypes = {
//
// };

export default FAQ;
