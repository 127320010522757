import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DatetimePickerTrigger } from 'rc-datetime-picker';
import moment from 'moment-timezone';
import classNames from 'classnames';

class CustomDateTimePicker extends Component {
  constructor(props) {
    super(props);
    this.date = React.createRef();

    // 记录最初的输入值，用于调试
    console.log(`初始化 ${props.input?.name || 'unknown'} 字段:`, props.input?.value);
  }

  render() {
    const {
      input,
      inputDisplay,
      placeholder,
      meta: { touched, error },
      timezone, // 可选的时区 prop，如未传入则自动检测
      ...custom
    } = this.props;

    const hasError = touched && error !== undefined;
    const showHour = custom.hideHour ? false : true;

    // 检测时区
    const userTimezone = timezone || moment.tz.guess();
    
    // 首先尝试使用ISO格式解析，如果失败再使用指定的dateStoreFormat
    let isValidDate = moment(input.value, moment.ISO_8601, true).isValid();
    
    // 如果ISO格式解析失败，并且提供了dateStoreFormat，则尝试使用它解析
    if (!isValidDate && custom.dateStoreFormat) {
      isValidDate = moment(input.value, custom.dateStoreFormat, true).isValid();
    }
    
    // 初始化日期对象 - 如果有有效日期则使用，否则使用当前日期
    let currentMoment;
    if (isValidDate) {
      // 首先尝试以ISO格式解析，确保从UTC转换到本地时区
      currentMoment = moment.utc(input.value).tz(userTimezone);
      console.log(`解析 ${custom.customLabel || input.name} ISO日期:`, {
        输入值: input.value,
        本地时区显示: currentMoment.format(custom.dateDisplayFormat),
        ISO格式: currentMoment.toISOString()
      });
    } else {
      currentMoment = moment().tz(userTimezone);
      console.log(`${custom.customLabel || input.name} 使用当前时间:`, currentMoment.format(custom.dateDisplayFormat));
    }

    return (
      <div className="input-container">
        <label htmlFor={input.label}>{custom.customLabel}</label>
        <div className="input-wrapper">
          <DatetimePickerTrigger
            moment={currentMoment}
            onChange={value => {
              // 关键修复：value 已经是本地时区的时间，我们需要正确转换为UTC
              // 首先创建一个本地时区的 moment 对象，设置为用户选择的日期和时间
              const localMoment = moment.tz(value.format('YYYY-MM-DD HH:mm:ss'), userTimezone);
              
              // 然后将其转换为UTC时间的ISO字符串
              const utcValue = localMoment.utc().toISOString();
              
              console.log(`${custom.customLabel} 日期选择器变更:`, {
                本地时间显示: value.format(custom.dateDisplayFormat),
                本地moment对象: localMoment.format(),
                UTC时间: utcValue,
                时区: userTimezone
              });
              
              // 保存为ISO格式的UTC时间
              input.onChange(utcValue);
              
              // 如果有 onChange 处理函数，调用它
              if (custom.onChange) {
                custom.onChange(null, utcValue);
              }
            }}
            showTimePicker={showHour}
            closeOnSelectDay
          >
            {custom.customLabel2}
            <input
              ref={this.date}
              type="text"
              {...inputDisplay}
              readOnly
              style={{ cursor: 'inherit' }}
              placeholder={placeholder || "Select Date"}
              value={
                isValidDate
                  ? currentMoment.format(custom.dateDisplayFormat)
                  : ''
              }
              className={classNames('date', {
                touched,
                error: hasError,
                ok: !hasError,
                empty: !input.value,
                'not-empty': input.value,
              })}
            />
            <input type="hidden" {...input} readOnly />
            {custom.customLabel2}
          </DatetimePickerTrigger>
          {touched && (hasError ? (
            <div className="error-icon">
              <i className="fa fa-exclamation-circle" />
            </div>
          ) : (
            <div className="ok-icon">
              <i className="fa fa-check" />
            </div>
          ))}
          {hasError && <div className="error-message">{error}</div>}
        </div>
        {this.props.enableClearDate && (
          <button
            type="button"
            style={{
              color: '#fff',
              border: '1px solid #fff',
              marginBottom: '10px',
              padding: '5px',
            }}
            onClick={() => {
              input.onChange('');
              input.value = '';
              this.date.current.value = '';
            }}
          >
            Clear Date
          </button>
        )}
      </div>
    );
  }
}

CustomDateTimePicker.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.any,
  }).isRequired,
  inputDisplay: PropTypes.object,
  placeholder: PropTypes.string,
  timezone: PropTypes.string, // 如 "America/New_York" 或 "Asia/Shanghai"，可选
  enableClearDate: PropTypes.bool,
};

CustomDateTimePicker.defaultProps = {
  placeholder: '',
  inputDisplay: {},
  timezone: '', // 默认使用自动检测
  enableClearDate: false,
};

export default CustomDateTimePicker;
