/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router';
import classNames from 'classnames';
import CustomInput from '../../utils/redux-forms-custom-fields/CustomInput2';
import { throttle } from '../../utils/functions';

import email from './img/91.png';
import password from './img/92.png';
import person from './img/93.png';

// const normalize = (phone) => {
//   // normalize string and remove all unnecessary characters
//   const newPhone = phone.replace(/[^\d]/g, '');

//   // check if number length equals to 10
//   if (newPhone.length == 10) {
//     // reformat and return phone number
//     return newPhone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
//   }

//   return null;
// };

class SignUpForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.throttledSubmit = throttle(this.props.onSubmit, 3000);
    this.classCountry = 'firstOptionSelected';
    this.countryError = '';
    this.countryOk = false;
    this.iAgree = false;
    this.state = {
      iAgreeError: false,
      loading: false,
    };
  }

  checkIAgree = () => {
    if (!this.iAgree) {
      this.setState({
        iAgreeError: true,
      });
    } else {
      this.setState({
        iAgreeError: false,
      });
    }
  }

  submit = (userFormInfo) => {
    // this.props.onSubmit(userFormInfo);
    const { role } = this.props;
    this.throttledSubmit({ ...userFormInfo, ...(role ? { role } : {}) });
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <form className="form-container signup" autoComplete="off" onSubmit={handleSubmit(this.submit.bind(this))}>
        <Field
          type="text"
          name="email"
          variant="outlined"
          id="email"
          placeholder="Email"
          prefixIcon={email}
          customLabel2={(<label data-label="Email"  />)}
          component={CustomInput}
        />
        <Field
          type="text"
          name="username"
          id="username"
          placeholder="Noiz Username"
          prefixIcon={person}
          customLabel2={(<label data-label="Username"  />)}
          component={CustomInput}
        />
        {this.props.manualTwitch &&
          (<Field
            type="text"
            name="twitch"
            id="twitch"
            placeholder="Twitch Username"
            customLabel2={(<label data-label="Twitch username" data-label-small="Your Twitch username here" />)}
            component={CustomInput}
          />)
        }
        {this.props.manualTwitch &&
          (<Field
            type="text"
            name="phone"
            id="phone"
            placeholder="(Optional) Mobile Number"
        
            component={CustomInput}
          />)
        }
        <Field
          type="password"
          name="password"
          id="password"
          placeholder="Password"
          prefixIcon={password}
          component={CustomInput}
        />
        <div
          className={
            classNames(
              'i_agree_wrap',
              { i_agree_error: this.state.iAgreeError },
            )
          }
        >
          <Field
            name="iAgree"
            id="iAgree"
            className="i_agree"
            component={CustomInput}
            type="checkbox"
            onChange={(evt) => { this.iAgree = evt.target.checked; }}
          />
          <div className='i_agree__content'>
            By registering, you agree to NOIZ's
            <Link
              href="/terms-of-use"
              to="/terms-of-use"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </Link>
            &nbsp;,&nbsp;
            <Link
              href="/privacy-policy"
              to="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>
            &nbsp; and &nbsp;
            <Link
              href="/privacy-policy"
              to="/privacy-policy-update"
              target="_blank"
              rel="noopener noreferrer"
            >
              updated terms
            </Link>
          </div>
        </div>

        <div className="form__footer">
          <div className="form__footer__left">
            {this.props.auth && this.props.auth.isLoading && (<i className="fa fa-spinner fa-spin fa-3x fa-fw" />)}
            {this.props.auth && !this.props.auth.isLoading && (<button className="cta-button" onClick={this.checkIAgree.bind(this)}>
              CONTINUE
            </button>)}
          </div>

        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const passCheck = (string) => {
    const checkSpecial = /[*@!#%&()^~{}$]+/.test(string);


    const checkUpper = /[A-Z]+/.test(string);


    const checkLower = /[a-z]+/.test(string);


    let r = false;

    if (checkUpper && checkLower && checkSpecial) {
      r = true;
    }

    return r;
  };
  const errors = {};

  if (!values.email || values.email.trim() === '') {
    errors.email = 'This field is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.username || values.username.trim() === '') {
    errors.username = 'This field is required';
  } else if (!values.username.match('^[a-zA-Z0-9_]*$')) {
    errors.username = 'Usernames can only contain numbers, letters, and underscores.';
  } else if (values.username.length > 25) {
    errors.username = 'Usernames must be less than 25 characters long.';
  }

  if (!values.password || values.password.trim() === '') {
    errors.password = 'This field is required';
  } else if (!values.password || values.password.length < 8) {
    errors.password = 'Passwords must have at least 8 characters';
  } else if (!values.password || values.password.length > 30) {
    errors.password = 'Passwords cannot be longer than 30 characters';
  } else if (!values.password || !passCheck(values.password)) {
    errors.password = 'Password must have at least one upper case letter and one of the following: !@#$%^&*()';
  }


  if (!values.iAgree) {
    errors.iAgree = "You will need to agree to NOIZ's Terms of Use and Privacy Policy";
  }

  if (!values.twitch) {
    errors.twitch = 'Please enter your Twitch username';
  }

  return errors;
};


SignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'signUpForm',
  validate,
})(SignUpForm);
