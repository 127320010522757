/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  styled,
} from '@material-ui/core';
import { getCurryFormattedString, roundingMoney } from "../../utils/functions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paperWidthSm': {
    backgroundColor: '#322d38',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    width: '600px',
    padding: '10px',
    overflowX:'hidden'
  },
  '& .MuiDialogContent-dividers': {
    borderTop: '0px',
    borderBottom: '0px'
  },
  '& .MuiTypography-h6': {
    textTransform: 'none'
  },
  '& .group_7': {
    width: '305px',
    height: '52px',
    margin: '32px 0 0 100px'
  },
  '& .flex-row': {
    display: 'flex',
    flexDirection: 'row'
  },
  '& .flex-col': {
    display: 'flex',
    flexDirection: 'column'
  },
  '& .text-wrapper_13': {
    width: '137px',
    height: '52px',
    overflowWrap: 'break-word',
    fontSize: '0',
    fontFamily: 'MicrosoftYaHei',
    fontWeight: 'NaN',
    textAlign: 'right',
    whiteSpace: 'nowrap',
    lineHeight: '34px'
  },
  '& .text_14': {
    width: '137px',
    height: '52px',
    overflowWrap: 'break-word',
    color: '#48EF37',
    fontSize: '36px',
    fontFamily: 'MicrosoftYaHei',
    fontWeight: 'NaN',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    lineHeight: '34px',
  },
  '& .text_15': {
    width: '137px',
    height: '52px',
    overflowWrap: 'break-word',
    color: '#48EF37',
    fontSize: '60px',
    fontFamily: 'MicrosoftYaHei',
    fontWeight: 'NaN',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    lineHeight: '34px'
  },
  '& .text-wrapper_14': {
    width: '305px',
    height: '18px',
    margin: '35px 0 0 0px'
  },
  '& .text_12': {
    width: '305px',
    height: '18px',
    overflowWrap: 'break-word',
    color: 'rgba(255, 255, 255, 1)',
    fontSize: '16px',
    fontFamily: 'MicrosoftYaHei-Bold',
    fontWeight: '700',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    lineHeight: '18px'
  },
  '& .block_6': {
    width: '542px',
    height: '204px',
    margin: '19px 0 0 0px'
  },
  '& .text-wrapper_9': {
    backgroundColor: 'rgba(33, 28, 39, 1)',
    borderRadius: '7px',
    height: '204px',
    width: '542px',
    overflowY:'auto',
    paddingTop:'10px'
  },
  '& .paragraph_2': {
    width: '186px',
    //height: '154px',
    overflowWrap: 'break-word',
    color: 'rgba(205, 204, 208, 1)',
    fontSize: '16px',
    fontFamily: 'MicrosoftYaHei',
    fontWeight: 'NaN',
    textAlign: 'left',
    lineHeight: '34px',
    margin: '0px 0 0 24px',
  },
  '& .paragraph_3': {
    width: '305px',
    //height: '151px',
    overflowWrap: 'break-word',
    color: 'rgba(217, 215, 215, 1)',
    fontSize: '16px',
    fontFamily: 'MicrosoftYaHei',
    fontWeight: 'NaN',
    textAlign: 'right',
    lineHeight: '34px',
    margin: '0px 42px 0 0px'
  },
  '& .text-wrapper_10': {
    height: '48px',
    backgroundSize: '100% 100%',
    width: '250px',
    backgroundColor:'#ff0000',
    borderRadius:'100px',
    cursor:'pointer',
    justifyContent:'center'
  },
  '& .text-wrapper_11': {
    height: '48px',
    backgroundSize: '100% 100%',
    width: '250px',
    backgroundColor:'#808280',
    borderRadius:'100px',
    cursor:'pointer',
    justifyContent:'center'
  },
  '& .text_13': {
    width: '100%',
    height: '24px',
    overflowWrap: 'break-word',
    color: 'rgba(255, 255, 255, 1)',
    fontSize: '18px',
    fontFamily: 'MicrosoftYaHei',
    fontWeight: 'NaN',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    lineHeight: '24px',
    margin: '0px 0 0 0px'
  },
  '& .block_7': {
    width: '250px',
    height: '48px',
    margin: '0px 0 0px 0px'
  },
}));
class OrderConfirmDialog extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      open:this.props.dialogOpen
    };
  }

  componentDidMount() {
    console.log('componentDidMount');
  }

  handlePayClose = () => {
    this.setState({ open: false });
  }

  render() {
    const {curOrder,button1Name,button1Action,button2Name,button2Action} = this.props;
    return (

       <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={true}
        >
          <div className="group_7 flex-row">
            <div className="text-wrapper_13">
              <span className="text_14">$</span>
              <span className="text_15">{getCurryFormattedString(curOrder?.balance ? roundingMoney(curOrder?.balance, 2) : '0.00')}</span>
            </div>
          </div>
          <div className="text-wrapper_14 flex-row">
                  <span className="text_12">
                    Confirmation&nbsp;of&nbsp;payment&nbsp;information
                  </span>
          </div>
          <DialogContent dividers>
            <div className="block_6 flex-row">
              <div className="text-wrapper_9 flex-col">

                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}><span className="paragraph_2">Product&nbsp;Type</span><span className="paragraph_3">{curOrder?.productName}-{curOrder?.platformName}</span></div>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}><span className="paragraph_2">Price</span><span className="paragraph_3">${getCurryFormattedString(curOrder?.salePrice ? roundingMoney(curOrder?.salePrice, 2) : '0.00')}</span></div>
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}><span className="paragraph_2">Amount</span><span className="paragraph_3">{curOrder?.amount}</span></div>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}><span className="paragraph_2">Total</span><span className="paragraph_3">${getCurryFormattedString(curOrder?.balance ? roundingMoney(curOrder?.balance, 2) : '0.00')}</span></div>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}><span className="paragraph_2">Method Of Payment</span><span className="paragraph_3">{curOrder?.payType}</span></div>
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{width:'100%',justifyContent:'space-evenly'}}>

              <div className="text-wrapper_10 flex-col" onClick={button1Action}>
                <span className="text_13">{button1Name}</span>
              </div>
              <div className="text-wrapper_10 flex-col" onClick={button2Action}>
                <span className="text_13">{button2Name}</span>
              </div>

          </DialogActions>
        </BootstrapDialog>
    );
  }
}

OrderConfirmDialog.propTypes = {

};

export default OrderConfirmDialog;
