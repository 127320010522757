import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import axios from 'axios';

const PaypalButton = (props) => {

  const { amount, currency, paypalEmail,transactionId,isAuth=false,resultHandle } = props;
  const initialOptions = {
    //这里改成真实的paypal账号id
    clientId: (process.env.NODE_ENV === 'production'||process.env.NODE_ENV === 'staging')?'ARXGPRCtSatdJCa9iIN2bqalnDVa7cOwwqYqlmpzhIgI930i0Ur7ELQXadkhiRkew7CdbPJYljEoAX-b'
        :'AUBaMIFpsLzqfsEr7dicoeOlX8tq4tSTFLGEor2T2N8YTVTU9rHorEPCvpnWGBQ2TTal_Oxwd7HnHU2W',
    components: "buttons,hosted-fields",
    intent: isAuth?"authorize":"capture"
  };

  const styles = {
     shape: "sharp",
    layout: "vertical",
    // layout: 'horizontal',
    color: 'blue',
    //shape: 'pill',
    label: 'pay',
    // label: 'checkout',
    tagline: 'false',
    size:'small',
    borderRadius:10,
  };
  const onCancel = (data) => {
    // Show a cancel page, or return to cart
    //window.location.assign("/");
  };

  const onError = (err) => {
    console.error('PayPal error:', err);
    //window.location.assign("/");
  }


// 前端 createOrder 方法，向后端请求订单 ID，并传递给 PayPal SDK 进行支付
const createOrder = async (data, actions) => {
  const orderData = {
    amount: amount, // 订单金额
    currency: currency, // 货币类型n
    payee: {
      email_address: paypalEmail // 指定收款人PayPal账户（邮箱）
    },
    userid:"12344",
    transactionId: transactionId,
    email: 'user@example.com',
    platform: 'web',
    ip:'127.0.0.1',
    description: '支付描述', // 支付描述,
    isAuth:isAuth,
  };
  try {
    // 向后端发请求，创建 PayPal 订单并获取订单 ID 和其他信息
    //const { data: orderResponse } = await axios.post('/paypal/create-order', orderData);
     //const { data: orderResponse } = await axios.post('/paypal/withdrawal', orderData);
    const { data: orderResponse,status:orderStatus,code,message,statusText } = await axios.post('/api/v1/paypal/withdrawalC', {orderData:orderData});
    if(!orderResponse){
      console.error('create order failure:',code,message);
      return;
    }
    if(orderStatus!==200){
      console.error('create order:',orderStatus,statusText);
      return;
    }

    // 使用 PayPal SDK 创建订单
    /*return actions.order.create({
      purchase_units: [{
        amount: {
          value: orderData.amount.toString(),  // 订单金额
          currency_code: orderData.currency,   // 货币类型
          description: orderData.description,     // 支付描述
        },
      }],
      // 这里使用从后端获得的订单 ID,用于其它 PayPal SDK 操作的关联
      orderID: orderResponse.data.orderId
    });*/
    return orderResponse.data.orderId;
  } catch (error) {
    console.error('Error creating order:', error);
    //return Promise.reject('Order creation failed');
  }
};

// 前端支付成功后的回调
const onApprove = async (data, actions) => {
  try {
    //const order = await actions.order.capture();
    const { data: orderResponse,status:orderStatus,code,message,statusText } = await axios.post('/withdrawal/executeC', {orderId:data.orderID,PayerID:"-",isAuth:isAuth});
    if(!orderResponse){
      console.error('create order failure:',code,message);
      resultHandle('failure');
      return;
    }
    if(orderStatus!==200){
      console.error('create order:',orderStatus,statusText);
      resultHandle('failure');
      return;
    }
    resultHandle('success');
  } catch (error) {
    console.error('Error capturing order:', error);
    resultHandle('failure');
  }

};

  return (
    <PayPalScriptProvider options={initialOptions} style={{borderRadius:'100px'}}>
      <PayPalButtons style={styles}
        createOrder={createOrder}
        onApprove={onApprove}
        onCancel={onCancel}
        onError={onError}
        fundingSource="paypal"  // 只允许显示 PayPal
        disableFunding="credit,card"  // 禁用 Credit 和 Card 支付方式（如果需要）
        />
    </PayPalScriptProvider>
  );
};

export default PaypalButton;




