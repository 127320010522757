//// filepath: /d:/workspace/1/branchs/noiz-frontend/src/components/quest/TwitchAuthModal.js
import React from 'react';
import './TwitchAuthModal.scss';

const toConnectAccounts = () => window.location.assign('/connect-accounts');

const TwitchAuthModal = ({ onClose }) => {
  return (
    <div className="twitch-auth-modal-overlay">
      <div className="twitch-auth-modal">
        <div className="modal-close" onClick={onClose}>
          <svg viewBox="0 0 24 24">
            <path d="M18 6L6 18M6 6l12 12" stroke="#99969C" strokeWidth="2" strokeLinecap="round" />
          </svg>
        </div>
        <div className="modal-header">
          <div className="red-circle">
            <span className="red-x">!</span>
          </div>
          <div className="auth-title">Authorize Again</div>
        </div>
        <div className="modal-body">
          You need to authorize twitch again
        </div>
        <div className="modal-footer">
          <button className="retry-button" onClick={toConnectAccounts}>Retry</button>
        </div>
      </div>
    </div>
  );
};

export default TwitchAuthModal;