import React from 'react';
import { Link } from 'react-router';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import ConnectPlatforms from '../connect-platforms/ConnectPlatforms';
import { simpleNotification } from '../../utils/notifications';
import Logo from '../../components/logo/Logo';
import './ConnectAccounts.scss';

class ConnectAccounts extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    // if (user.role === 'brand') {
    //   this.props.router.replace('/portal');
    // }
    if (
      this.props.router.location.pathname === '/connect-accounts/twitch' &&
      !isEmpty(this.props.router.location.query.code) &&
      !isEmpty(this.props.router.location.query.scope)
    ) {
      // Requesting a JWT token with the oauth callback code.
      const { code, scope } = this.props.router.location.query;
      this.props.loginWithTwitchOAuthCode(code, scope, token);
    }
    if (
      this.props.router.location.pathname === '/connect-accounts/twitter' &&
      !isEmpty(this.props.router.location.query.oauth_token) &&
      !isEmpty(this.props.router.location.query.oauth_verifier)
    ) {
      const { oauth_token, oauth_verifier } = this.props.router.location.query;
      this.props.loginWithTwitterOAuthCode(oauth_token, oauth_verifier, token);
    }
    if (
      this.props.router.location.pathname === '/connect-accounts/youtube' &&
      !isEmpty(this.props.router.location.query.code) &&
      !isEmpty(this.props.router.location.query.scope)
    ) {
      const { code, scope } = this.props.router.location.query;
      this.props.loginWithYoutubeOAuthCode(code, scope, token);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.auth.youtube.isLoading === true && nextProps.auth.youtube.isLoading === false) {
      this.props.router.replace('/connect-accounts');
      if (nextProps.auth.youtube.errors.hasError) {
        simpleNotification({
          level: 'error',
          title: 'Youtube login error',
          message: nextProps.auth.youtube.errors.message,
          autoDismiss: 0,
          position: 'tl',
        });
        this.props.router.replace('/connect-accounts');
      }
    }
    if (this.props.auth.youtube.isDisconnecting === true && nextProps.auth.youtube.isDisconnecting === false) {
      if (nextProps.auth.youtube.errors.hasError) {
        simpleNotification({
          level: 'error',
          title: 'Youtube disconnect error',
          message: nextProps.auth.youtube.errors.message,
          autoDismiss: 0,
          position: 'tl',
        });
      } else {
        simpleNotification({
          level: 'warning',
          title: 'Disconnected',
          message: 'Youtube account disconnected',
          autoDismiss: 0,
          position: 'tl',
        });
      }
    }
    if (this.props.auth.twitter.isLoading === true && nextProps.auth.twitter.isLoading === false) {
      if (nextProps.auth.twitter.errors.hasError) {
        simpleNotification({
          level: 'error',
          title: 'Twitter login error',
          message: nextProps.auth.twitter.errors.message,
          autoDismiss: 0,
          position: 'tl',
        });
      }
    }

    // Updating things when twitch finish the request loading.
    if (this.props.auth.twitch.isLoading === true && nextProps.auth.twitch.isLoading === false) {
      // When user successfully access.
      // const { user } = nextProps.auth;
      // const isBasicInfoCompleted = (user.firstName && user.lastName && user.birthDate && user.country);
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      this.props.validateUserHandshake(this.props.auth.token, '');
      if (nextProps.auth.twitch.errors.hasError) {
        simpleNotification({
          level: 'error',
          title: 'Twitch login error',
          message: nextProps.auth.twitch.errors.message,
          autoDismiss: 0,
          position: 'tl',
        });
        // Removing the query strings code and scope from url.
        if (localStorage.getItem('backToBooth') === 'true') {
          localStorage.removeItem('backToBooth');
          this.props.router.replace('/make-some-noiz');
        } else {
          this.props.router.replace('/connect-accounts');
        }
      } else if (localStorage.getItem('backToBooth') === 'true') {
        localStorage.removeItem('backToBooth');
        this.props.router.replace('/make-some-noiz?connected=true');
      } else {
        this.props.router.replace('/connect-accounts');
      }
    }
  }

  // goBack = () => {
  //   const pathArray = document.referrer.split('/');
  //   console.log('pathArray', pathArray);
  //   const host = pathArray[2];
  //   const canGoBack = host === 'noiz.gg' || host === 'localhost:3000' || host === 'staging.noiz.gg' || host === 'noiz.xyz';
  //   const fullReferrer = document.referrer !== '';
  //   console.log('canGoBack', canGoBack, 'fullReferrer', fullReferrer, 'this.props.router.location.key', this.props.router.location.key);
  //   if (canGoBack && fullReferrer && this.props.router.location.key) {
  //     this.props.router.goBack();
  //   }

  //   this.props.router.replace('/');
  // }

  goBack = () => {
    // 优化返回逻辑，不再依赖 document.referrer
    try {
      // 检查是否有历史记录可以返回
      if (this.props.router && this.props.router.location && this.props.router.location.key) {
        // 如果有历史记录，尝试返回上一页
        console.log('Attempting to go back using router history');
        this.props.router.goBack();
        
        // 给返回操作设置一个超时，如果没有成功返回，则导航到首页
        setTimeout(() => {
          // 如果当前路径仍然是 connect-accounts 相关，说明返回失败，需要重定向到首页
          if (this.props.router.location.pathname.includes('connect-accounts')) {
            console.log('Go back failed or stayed on connect-accounts, redirecting to home');
            this.props.router.replace('/');
          }
        }, 100);
      } else {
        // 没有历史记录，直接返回首页
        console.log('No router history key found, redirecting to home');
        this.props.router.replace('/');
      }
    } catch (error) {
      // 出现任何错误，都返回首页
      console.error('Error during navigation:', error);
      this.props.router.replace('/');
    }
  }

  displayNotConnectedMessage = () => {
    simpleNotification({
      level: 'warning',
      title: '',
      message: 'You must connect at least one streaming platform in order to see available quests.',
    });
  }

  render() {
    const { user } = this.props.auth;

    return (
      <section className="ConnectAccounts">
        <section className="ModalMaster">
          <section className={classNames('Modal Modal__welcome active')}>
            <div
              className="Modal__layer"
              onClick={(e) => {
                if (!(user.connectedAccounts.twitch.id || user.connectedAccounts.twitch.username)) {
                  e.preventDefault();
                } else {
                  this.goBack();
                }
              }}
              role="button"
              aria-hidden
            />
            <section className="Modal__wrapper">
              <div className="Modal__icon">
                <Logo />
                {(user.connectedAccounts.twitch.id || user.connectedAccounts.twitch.username) &&
                  <button
                    className="Modal__close-btn"
                    onClick={this.goBack}
                    aria-hidden
                  />
                }
              </div>
              <div className="Welcome__header">
                Welcome
              </div>
              <ConnectPlatforms
                auth={this.props.auth}
                config={this.props.config}
                disconnectTwitch={this.props.disconnectTwitch}
                disconnectYoutube={this.props.disconnectYoutube}
              />
              <div className="Welcome__footer">
                <button onClick={this.goBack}>Close</button>
                <Link className="logout" to="/logout" href="/logout">Logout</Link>
              </div>
              <div className="blurb">
                Designed by game developers and gamers, for game developers and gamers
              </div>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

export default ConnectAccounts;
