import React, { useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import GallerySocials from './GallerySocials';
import NotFound from '../../../../not-found/NotFound';
import Icon from '../../../../../global/images/icons/Icon';
import Quests from './ProductQuests';
import web from "../../assets/web.png";


import { htmlToPlainText, textTruncate } from '../../../../../utils/functions';

import './ProductDetail.scss';

const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };

const ProductDetail = (props) => {
  const {
    currentProduct,
    brandUser: {
      games: {
        data: { games: products },
        isLoading,
      },
    },
    getBrandGame,
    tab,
    token,
  } = props;

  const [product, setProduct] = useState({});

  const getProduct = () => {
    if (currentProduct) {
      const foundProduct = products?.find(p => p.id === currentProduct);

      if (foundProduct) {
        setProduct(foundProduct);
      }
    }
  };

  useEffect(() => {
    if (currentProduct) {
      const foundProduct = products?.find(p => p.id === currentProduct);

      if (!foundProduct) {
        getBrandGame({ gameId: currentProduct }, token);
      }
    }
  }, []);

  useEffect(() => {
    getProduct();
  }, [currentProduct]);

  useEffect(() => {
    getProduct();
  }, [isLoading]);

  const {
    id,
    cover,
    coverPortrait,
    description,
    developer,
    developerLogo,
    gallery,
    gamePageURL,
    logo,
    name,
    quests,
    releaseDate,
    socialNetworks,
  } = product;

  const warnings = [];
  if (!cover) warnings.push('cover');
  if (!coverPortrait) warnings.push('cover portrait');
  if (!developerLogo) warnings.push('developer logo');
  if (!logo) warnings.push('logo');

  const devStyle = (developerLogo) ? {} : { marginLeft: 0 };
  const theReleaseDate = new Date(releaseDate || '').toLocaleDateString('en-US', dateOptions);
  const toDisplayDate = theReleaseDate && theReleaseDate !== 'Invalid Date' ? theReleaseDate : '';
  const ProductDetailMasthead = () => (
    <div className="ProductDetailMasthead">
      <img className="ProductDetailMasthead__Cover" src={cover} alt={name} />
      {
        !!warnings.length &&
        <Tooltip title={`Missing ${warnings.join(', ')} image${warnings.length > 1 ? 's' : ''}. Please contact an admin to provide the missing image${warnings.length > 1 ? 's' : ''}.`} placement='top'>
          <div className='ProductDetailMasthead__Warning'>
            <Icon name='warning' color="#FFDBBB" />
          </div>
        </Tooltip>
      }
      <div className='ProductDetailMasthead__Fade' />
      <div className="ProductDetailMasthead__Details">
       
        <div className="ProductDetailMasthead__Details__Right">
          <div className="ProductDetailMasthead__Details__Right__Name">{name}</div>
          <div className="ProductDetailMasthead__Details__Right__ReleaseDate">{toDisplayDate} </div>
          <div className="ProductDetailMasthead__Details__Right__Description">{textTruncate(htmlToPlainText(description), 150)}</div>
          <div className='ProductDetailMasthead__Details__Right__Developer'>
            {developerLogo && <img className='ProductDetailMasthead__Details__Right__Developer__Logo' src={developerLogo} alt={developer} />}
            <div className='ProductDetailMasthead__Details__Right__Developer__Name' style={devStyle}>{developer}</div>
          </div>
        </div>
        <div className="ProductDetailMasthead__Details__Left">
          <img className="ProductDetailMasthead__Details__Left__Portrait" src={web} alt={name} />
          <div className="ProductDetailMasthead__Details__Left__Website" onClick={() => window.open(gamePageURL, '_blank')}>
           Visit Website
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="ProductDetail__Wrapper">
      {!id && <NotFound home={`/portal?component=default&tab=${tab}`} buttonText={'Back'} />}
      {id && (
        <>
          <ProductDetailMasthead />
          <Quests quests={quests || []} productImg={coverPortrait || cover} />
          <GallerySocials gallery={gallery || []} socialNetworks={socialNetworks || []} />
        </>
      )}
    </div>
  );
};

export default ProductDetail;
