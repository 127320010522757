import React, { useEffect } from 'react';
import { ParallaxLayer } from '../../../custom_modules/react-spring/src/renderprops/addons/index';
import useOnScreen from '../useOnScreen';
import './BrandServiceSlides.scss';
import anime from 'animejs';
import bestInClassIndex from '../assetsV2/iconography/managedCampaignIndex';
import JManagedCampaignBg from '../assetsV2/JManagedCampaign-bg.png';


const JManagedCampaign = (props) => {
  const {
    pOffset, layoutFactor = 1, offsetAdjust = 0, isDesktop = false,
  } = props;
  const ref = React.createRef();
  const onScreen = useOnScreen(ref);
  const [debounce, setDebounce] = React.useState(true);
  const lineLength = isDesktop ? 575 : 787;

  useEffect(() => {
    if (onScreen && debounce) {
      anime({
        targets: '.isolated-j-fade',
        easing: 'linear',
        opacity: [0, 1],
        duration: 800,
        delay: anime.stagger(200),
      });
      anime({
        targets: '.wee-little-circle',
        easing: 'easeOutQuad',
        delay: (e, i) => i * 400,
        opacity: 1,
        duration: 200,
        r: [0, 8],
      });
      anime({
        targets: '.big-line-down',
        easing: 'easeOutQuad',
        opacity: 1,
        duration: 3000,
        y2: [0, lineLength],
      });
      setDebounce(false);
    }
  }, [onScreen, debounce, lineLength]);

  // const spaceIntervalCirc = isDesktop ? 115 : 155;
  // const spaceIntervalText = isDesktop ? 115 : 155;
  // const circleOffset = isDesktop ? 250 : 297.5;
  // const textBoxOffset = isDesktop ? 183 : 230;


  return (
    < >
      <ParallaxLayer
        offset={pOffset + offsetAdjust}
        factor={layoutFactor}
        speed={0}
        className="slide-j-container hide-brand-scroll"
        style={{
          backgroundImage: `url(${JManagedCampaignBg})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat' // 明确禁用重复  
        }}
      >

        <div className="brand-j-title">End-to-End Managed Campaign Process</div>
        <div className="brand-j-fixed-stack">
          {/* <img src={BgPic} alt="jbg" /> */}
          <div className='brand-text-stack'>
            {bestInClassIndex.map((item, i) => {
              return (
                <div
                  className="text-flex isolated-j-fade"
                  key={i}
                  style={{
                    opacity: 0, // 初始状态设置为透明
                  }}>
                  <div className="title">
                    {item.title}
                  </div>
                  <div className="body">
                    {item.text}
                  </div>
                </div>
              )
            })}
          </div>

        </div>
        <div ref={ref} />
      </ParallaxLayer>
    </>
  );
};


export default JManagedCampaign;

