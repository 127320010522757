/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link } from 'react-router';
// import classNames from 'classnames';
import moment from 'moment-timezone';
import ReactTooltip from 'react-tooltip';
import {
  forEach,
} from 'lodash';
import { textTruncate } from '../../../../utils/functions';
import { simpleNotification } from '../../../../utils/notifications';
import CampaignForm from '../../../forms/CampaignForm';
import './CampaignEdit.scss';

class CampaignEdit extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      // 初始化时保存传入的游戏数据
      gameList: props.games || [],
      currentPage: 1,
      isLoadingMore: false,
      hasMore: true,
    };
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    const { campaignId, gameId } = this.props.router.params;
    this.props.getCCCampaign(gameId, campaignId, token);
  }

  componentDidMount() {
    const { campaignId } = this.props.router.params;
    if (!campaignId) {
      this.props.assignCampaignDefaultInfo();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { gameId } = nextProps.router.params;

    if (this.props.commandCenter.campaign.isCreating === true && nextProps.commandCenter.campaign.isCreating === false) {
      if (nextProps.commandCenter.campaign.errors.hasError) {
        if (nextProps.commandCenter.campaign.errors.code === 'E_CAMPAIGN_SLUG_DUPLICATED') {
          simpleNotification({
            level: 'error',
            title: 'Campaign Slug Duplicated.',
            message: nextProps.commandCenter.campaign.errors.message,
          });
          this.props.assignCampaignSlugError(nextProps.commandCenter.campaign.errors.message);
        }
      } else {
        simpleNotification({
          level: 'success',
          title: 'Campaign Created.',
          message: 'Your campaign has been created successfully.',
        });
        this.props.router.push(`/admin/command-center/${gameId}/campaigns`);
      }
    }

    if (this.props.commandCenter.campaign.isUpdating === true && nextProps.commandCenter.campaign.isUpdating === false) {
      if (nextProps.commandCenter.campaign.errors.hasError) {
        if (nextProps.commandCenter.campaign.errors.code === 'E_CAMPAIGN_SLUG_DUPLICATED') {
          simpleNotification({
            level: 'error',
            title: 'Campaign Slug Duplicated.',
            message: nextProps.commandCenter.campaign.errors.message,
          });
          this.props.assignCampaignSlugError(nextProps.commandCenter.campaign.errors.message);
        }
      } else {
        simpleNotification({
          level: 'success',
          title: 'Campaign Updated.',
          message: 'Your campaign has been updated successfully.',
        });
        this.props.router.push(`/admin/command-center/${gameId}/campaigns`);
      }
    }

    if (this.props.commandCenter.campaign.isLoading === true && nextProps.commandCenter.campaign.isLoading === false &&
      this.props.commandCenter.campaign.isUpdating === false && nextProps.commandCenter.campaign.isUpdating === false &&
      this.props.commandCenter.campaign.isCreating === false && nextProps.commandCenter.campaign.isCreating === false) {
      if (!nextProps.commandCenter.campaign.errors.hasError) {
        const { data: campaign } = nextProps.commandCenter.campaign;
        if (campaign.quests && campaign.quests.length > 0) {
          const quests = [];
          forEach(campaign.quests, (obj) => {
            quests.push({
              value: obj.id,
              label:
                (
                  <React.Fragment>
                    <span data-tip={obj.title} style={{ zIndex: '1000' }}>{textTruncate(obj.title, 25, '...')} {moment(obj.startDateTime).format('MM/DD')} - {moment(obj.endDateTime).format('MM/DD')}</span>
                    <ReactTooltip className="yellowTip" effect="solid" place="top" multiline html />
                  </React.Fragment>
                ),
            });
          });
          campaign.quests = quests;
        }

        // Game permissions HERE - MICHAEL
        if (campaign.brandUsers && campaign.brandUsers.length > 0) {
          const brandUsers = [];
          forEach(campaign.brandUsers, (obj) => {
            brandUsers.push({
              value: obj.id,
              label: `${obj.email} (${obj.firstName} ${obj.lastName} - ${obj.company})`,
            });
          });
          campaign.brandUsers = brandUsers;
        }
        this.props.assignCampaignInfoForm(campaign);
      }
    }
  }

  onSubmit = (campaignFormInfo) => {
    const { token } = this.props.auth;
    const { gameId, campaignId } = this.props.router.params;
    const preparedInfo = { ...campaignFormInfo, game: gameId };
    if (!campaignId) {
      this.props.createCCCampaign(preparedInfo, token);
    } else {
      this.props.updateCCCampaign(campaignId, preparedInfo, token);
    }
  }

  render() {
    const { gameId } = this.props.router.params;
    const { isLoading } = this.props.commandCenter.campaign;
    return (
      <section className="CCCampaignEdit" style={{ display: isLoading }}>
        <Link
          to={`/admin/command-center/${gameId}/campaigns`}
          activeClassName="active"
          href={`/admin/command-center/${gameId}/campaigns`}
        >
          <button className="CCCampaigns__back--btn">Back</button>
        </Link>
        <div style={{ display: (isLoading) ? 'none' : 'block' }}>
          <CampaignForm
            onSubmit={this.onSubmit.bind(this)}
            campaignForm={this.props.form.campaignForm}
            campaign={this.props.commandCenter.campaign}
            quests={this.props.commandCenter.quests}
            brandUsers={this.props.commandCenter.brandUsers}
            adminActivityLogs={this.props.commandCenter.adminActivityLogs}
          />
        </div>
        <div className="CampaignLoading" style={{ display: (isLoading) ? 'block' : 'none' }}><i className="fa fa-spinner fa-spin fa-3x fa-fw" /></div>
      </section>
    );
  }
}

CampaignEdit.propTypes = {

};

export default CampaignEdit;
