/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/label-has-for */

import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isBoolean } from 'lodash';
import Toggle from 'react-toggle';
import Tooltip from '@material-ui/core/Tooltip';

class CustomInputCheckbox extends Component {
  render() {
    const { input, meta: { touched, error }, ...custom } = this.props;
    const hasError = touched && error !== undefined;
    const { defaultChecked } = this.props;

    return (
      <div className="input-container">
        <div className="input-wrapper">
          <Toggle
            checked={typeof input.value === 'boolean' ? input.value : Boolean(defaultChecked)}
            onChange={input.onChange}
            onBlur={() => input.onBlur(input.value)}
            icons={false}
            className={classNames({
              touched, error: hasError, ok: !hasError, empty: !isBoolean(input.value), 'not-empty': !isBoolean(input.value),
            })}
          />
          <div>
            {custom.customLabel ? custom.customLabel : ''} &nbsp;
            {!!custom.tooltip && (
              <Tooltip title={custom.tooltip} placement="top">
                <i className="fa fa-info-circle" />
              </Tooltip>
            )}
          </div>


          {(hasError && touched) &&
          <div className="error-icon">
            <i className="fa fa-exclamation-circle" />
          </div>
          }
          {(!hasError && touched) &&
          <div className="ok-icon">
            <i className="fa fa-check" />
          </div>
          }
          {hasError &&
          <div className="error-message" style={{ marginTop: '10px' }}>{error}</div>
          }
        </div>
      </div>
    );
  }
}


CustomInputCheckbox.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  // meta: PropTypes.shape({
  //   touched: PropTypes.bool,
  //   error: PropTypes.bool,
  // }),
  placeholder: PropTypes.string,
};

CustomInputCheckbox.defaultProps = {
  placeholder: '',
  // meta: {
  //   error: false,
  //   touched: false,
  // },
};

export default CustomInputCheckbox;
