import React from 'react';
import './CdkeyBuyModal.scss';

const CdkeyBuyModal = ({ isVisible, onClose, children }) => {
  if (!isVisible) return null;

  return (
    <div className="custom-modal-overlay">
      <div className="custom-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="custom-modal-close" onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default CdkeyBuyModal;