/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import classnames from 'classnames';

import Icon from '../../images/icons/Icon';

import './BrowserBack.scss';

const BrowserBack = ({ color = '#fff', height = '28px', hover = true, margin = {}, router, text = 'Back' }) => (
  <span
    className={classnames('BrowserBack', hover && 'Hover')}
    // eslint-disable-next-line no-restricted-globals
    onClick={() => history.back()}
    style={{ height, ...margin }}
  >
    
    {text ? <span className="text">{text}</span> : null}
    <Icon name='back' color={color} />
  </span>
);

export default BrowserBack;
