// Components (external)
import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { Checkbox } from "@material-ui/core";

// Styles
import "./QuestModal.scss";

// Components (internal)
import { fade } from "../../../utils/transitionIndex";
import PrivacyPolicyUpdate from "../../static-pages/PrivacyPolicyUpdate";


const UpdateModal = (props) => {
    const {
        closeModal,
        open,
        onSubmit,
        accepted,
        handleAcceptedChange,
    } = props;



    const leftSideButton = {
        fn: closeModal,
        text: "Cancel"
    };
    const rightSideButton = {
        disabled: !accepted,
        fn: onSubmit,
        text: "Submit" ,
    };

    const modalButtons = ({ disabled = false, fn, text }) => (
        <button
            className={`${disabled ? "CTAButton__red" : "CTAButton__lightGrey"}`}
            disabled={disabled}
            onClick={fn}
        >
            {disabled ? 'Required' : text}
        </button>
    );
   

    return (
        <TransitionGroup>
            {open && (
                <CSSTransition {...fade()}>
                    <div className="QuestModal">
                        <i className="fa fa-times close_icon" onClick={closeModal} />
                        <div>
                            <h3 className="quest-agreement-title">Service Agreement Update Announcement</h3>
                            <div className="agreementCopy">
                                <PrivacyPolicyUpdate />
                            </div>
                            <div className="checkbox-container-questmodal">
                                <Checkbox
                                    checked={accepted}
                                    onChange={() => handleAcceptedChange(!accepted)}
                                />
                                <div>I agree to the above terms and conditions</div>
                            </div>
                        </div>
                        <div className="buttonsBar">
                            {modalButtons(leftSideButton)}
                            {modalButtons(rightSideButton)}
                        </div>
                    </div>
                </CSSTransition>
            )}
        </TransitionGroup>
    );
};

export default UpdateModal;
