/* eslint-disable react/no-did-update-set-state */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import Axios from "axios";
import './LogContent.scss';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
  Grid,
  Divider,
  Paper
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'

// Utils
import {
  stableSort,
  getSorting,
  truncateText,
  getLocalTimeString
} from '../../../utils/functions';

import Icon from '../../../global/images/icons/Icon';

import {
  FormControl,
  Select,
  MenuItem,
  Typography
} from '@material-ui/core';

const TableHeaderCell = withStyles(theme => ({
  head: {
    color: '#9b9b9b',
    fontWeight: '400',
    letterSpacing: '1.2px',
    fontSize: '14px',
  },
}))(TableCell);

// Add this helper function
const findObjectDifferences = (oldObj, newObj) => {
  // Handle null/undefined cases
  if (!oldObj) oldObj = {};
  if (!newObj) newObj = {};

  const differences = {};
  const allKeys = [...new Set([...Object.keys(oldObj), ...Object.keys(newObj)])];

  allKeys.forEach(key => {
    const oldVal = oldObj[key];
    const newVal = newObj[key];

    // Skip if values are exactly the same
    if (oldVal === newVal) return;

    // Handle null/undefined equivalence
    if ((oldVal === null && newVal === undefined) ||
      (oldVal === undefined && newVal === null)) return;

    // Handle different types
    if (typeof oldVal !== typeof newVal) {
      differences[key] = { old: oldVal, new: newVal };
      return;
    }

    // Handle objects (including arrays)
    if (typeof oldVal === 'object' && oldVal !== null &&
      typeof newVal === 'object' && newVal !== null) {

      // Handle dates
      if (oldVal instanceof Date || newVal instanceof Date) {
        const oldTime = oldVal instanceof Date ? oldVal.getTime() : null;
        const newTime = newVal instanceof Date ? newVal.getTime() : null;
        if (oldTime !== newTime) {
          differences[key] = { old: oldVal, new: newVal };
        }
        return;
      }

      // Handle arrays
      if (Array.isArray(oldVal) && Array.isArray(newVal)) {
        if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
          differences[key] = { old: oldVal, new: newVal };
        }
        return;
      }

      // Handle nested objects
      const nestedDiffs = findObjectDifferences(oldVal, newVal);
      if (Object.keys(nestedDiffs).length > 0) {
        differences[key] = nestedDiffs;
      }
      return;
    }

    // Handle primitive values
    if (oldVal !== newVal) {
      differences[key] = { old: oldVal, new: newVal };
    }
  });

  return differences;
};

// Helper component for comparison rows
const ComparisonRow = ({ label, oldValue, newValue }) => {
  const differences = findObjectDifferences(oldValue, newValue);

  return (
    <Box p={2}>
      <Typography variant="h6" gutterBottom>
        {label}
      </Typography>
      {Object.keys(differences).length === 0 ? (
        <Box
          mb={2}
          style={{
            backgroundColor: '#323f51',
            padding: '12px',
            borderRadius: '4px'
          }}
        >
          <Typography variant="body2" style={{ color: 'white' }}>
            No differences found
          </Typography>
        </Box>
      ) : (Object.entries(differences).map(([key, value], index) => (
        <Box
          key={key}
          mb={2}
          style={{
            backgroundColor: index % 2 === 0 ? '#323f51' : '#323f51',
            padding: '12px',
            borderRadius: '4px'
          }}
        >
          <Typography variant="subtitle1" color="white">
            Value:    {key}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper
                elevation={0}
                style={{
                  backgroundColor: 'rgba(66, 66, 66, 0.5)',
                  padding: '8px',
                  borderRadius: '4px'
                }}
              >
                <Typography variant="body2" style={{ color: 'white' }}>
                  Old: {value.old === undefined ? 'N/A' : JSON.stringify(value.old)}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper
                elevation={0}
                style={{
                  backgroundColor: 'rgba(60, 122, 238, 0.1)',
                  padding: '8px',
                  borderRadius: '4px'
                }}
              >
                <Typography variant="body2" style={{ color: 'white' }}>
                  New: {value.new === undefined ? 'N/A' : JSON.stringify(value.new)}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      )))}
    </Box>
  );
};

class LogContent extends React.Component {
  constructor(props) {
    super(props);

    const today = new Date()
    const sevenDaysBefore = new Date()
    sevenDaysBefore.setDate(today.getDate() - 7)
    const formatDate = (date) => {
      if (!date) return ''
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    }

    this.props = props;
    this.state = {
      downloadModal: false,
      orderBy: false,
      order: 'desc',
      numPage: 1,
      filters: {
        searchAll: true,
        search: '',
        startDate: sevenDaysBefore.toISOString(),
        endDate: today.toISOString(),
      },
      search: "",
      dateStart: formatDate(sevenDaysBefore),
      dateEnd: formatDate(today),
      pagination: {
        total: 0,
        pageNumber: 1,
        totalPages: 1
      },
      sorting: {
        order: 'desc',
        orderBy: false,
      },
      records: [],
      localIP: '',
      optionValue: "All",
      options: ["All"],
      optionLevelValue: "All",
      optionsLevel: ["All"],
      currentTab: 0,
      isLoading: false,
      detailModal: false,
      cur_payment: null
    };
  }

  handleChange = (event) => {
    const { target: { value } } = event;
    if (value) {
      this.setState({
        optionValue: value
      }, () => {
        this.refreshRecords()
      })
    }
  };

  handleLevelChange = (event) => {
    const { target: { value } } = event;
    if (value) {
      this.setState((prevState) => ({
        optionLevelValue: value,
        filters: {
          ...prevState.filters,
          level: value
        },
      }), () => {
        this.refreshRecords()
      })
    }
  };

  async componentDidMount() {
    // Existing componentDidMount code
  }

  handleStartDateChange = (event) => {
    const { target: { value } } = event;
    if (!value) {
      this.setState({
        dateStart: ''
      })
      return
    }

    const recordsDate = new Date(value).toISOString()
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        startDate: recordsDate
      },
      dateStart: value,
      dateEnd: prevState.dateEnd && value > prevState.dateEnd ? '' : prevState.dateEnd,
    }))
  }

  handleEndDateChange = (event) => {
    const { target: { value } } = event;
    if (!value) {
      this.setState({
        dateEnd: ''
      })
      return
    }
    //change date to iso string
    const recordsDate = new Date(value).toISOString()
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        endDate: recordsDate
      },
      dateEnd: value
    }))
    this.refreshRecords()
  }

  //根据当前设置的搜索数据
  refreshRecords() {
    // 打印当前的搜索条件
    // 调用接口
    // if(this.state.optionValue === "All"){
    //   this.props.getQuestLog(1, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), false, token, "");
    // }else{
    //   this.props.getQuestLog(1, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), false, token, this.state.optionValue);
    // }

    this.fetchRecords()
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    if (!this.props.admin.logQuest.isLoading) {
      if (this.state.optionValue === "All") {
        this.props.getQuestLog(1, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), false, token, "");
      } else {
        this.props.getQuestLog(1, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), false, token, this.state.optionValue);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { tab } = this.props

    if (this.state.sorting !== prevState.sorting || this.state.currentTab !== tab) {
      this.setState((prevState) => ({
        numPage: 1,
        currentTab: tab,
        optionValue: "All",
        optionLevelValue: "All",
        filters: {
          ...prevState.filters,
          level: "All"
        },
      }), () => {
        this.fetchRecords()
      })

    }
  }

  //根据当前的tab进行数据获取
  fetchRecords = () => {
    const { token } = this.props.auth;
    if (this.state.currentTab === 0) {
      //根据当前的tab进行数据获取
      if (!this.props.admin.logQuest.isLoading) {
        if (this.state.optionValue === 'All') {
          this.props.getQuestLog(this.state.numPage, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), false, token, "");
        } else {
          this.props.getQuestLog(this.state.numPage, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), false, token, this.state.optionValue);
        }
      }
    } else if (this.state.currentTab === 1) {
      if (!this.props.admin.logProfile.isLoading) {
        if (this.state.optionValue === 'All') {
          this.props.getProfileLog(this.state.numPage, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), false, token, "");
        } else {
          this.props.getProfileLog(this.state.numPage, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), false, token, this.state.optionValue);
        }
      }

    } else if (this.state.currentTab === 2) {
      if (!this.props.admin.logWallet.isLoading) {
        if (this.state.optionValue === 'All') {
          this.props.getWalletLog(this.state.numPage, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), false, token, "");
        } else {
          this.props.getWalletLog(this.state.numPage, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), false, token, this.state.optionValue);
        }
      }
    } else if (this.state.currentTab === 3) {
      if (!this.props.admin.logCdkey.isLoading) {
        if (this.state.optionValue === 'All') {
          this.props.getCDKeyLog(this.state.numPage, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), false, token, "");
        } else {
          this.props.getCDKeyLog(this.state.numPage, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), false, token, this.state.optionValue);
        }
      }
    } else if (this.state.currentTab === 4) {
      if (!this.props.admin.logDailyTask.isLoading) {
        if (this.state.optionValue === 'All') {
          this.props.getDailyTaskLog(this.state.numPage, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), false, token, "");
        } else {
          this.props.getDailyTaskLog(this.state.numPage, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), false, token, this.state.optionValue);
        }
      }
    } else if (this.state.currentTab === 5) {
      if (!this.props.admin.logChannel.isLoading) {
        if (this.state.optionValue === 'All') {
          this.props.getChannelLog(this.state.numPage, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), false, token, "");
        } else {
          this.props.getChannelLog(this.state.numPage, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), false, token, this.state.optionValue);
        }
      }
    } else if (this.state.currentTab === 6) {
      if (!this.props.admin.logOther.isLoading) {
        if (this.state.optionValue === 'All') {
          this.props.getOtherLog(this.state.numPage, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), false, token, "");
        } else {
          this.props.getOtherLog(this.state.numPage, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), false, token, this.state.optionValue);
        }
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    if (this.state.currentTab === 0) {
      if (this.props.admin.logQuest.isLoading === true && nextProps.admin.logQuest.isLoading === false) {
        const { data: records_new, pagination } = nextProps.admin.logQuest;
        if (pagination) {
          this.setState({
            pagination: pagination,
            records: records_new,
          })
        }
      }
    } else if (this.state.currentTab === 1) {
      if (this.props.admin.logProfile.isLoading === true && nextProps.admin.logProfile.isLoading === false) {
        const { data: records_new, pagination } = nextProps.admin.logProfile;
        if (pagination) {
          this.setState({
            pagination: pagination,
            records: records_new,
          })
        }
      }
    } else if (this.state.currentTab === 2) {
      if (this.props.admin.logWallet.isLoading === true && nextProps.admin.logWallet.isLoading === false) {
        const { data: records_new, pagination } = nextProps.admin.logWallet;
        if (pagination) {
          this.setState({
            pagination: pagination,
            records: records_new,
          })
        }
      }
    } else if (this.state.currentTab === 3) {
      if (this.props.admin.logCdkey.isLoading === true && nextProps.admin.logCdkey.isLoading === false) {
        const { data: records_new, pagination } = nextProps.admin.logCdkey;
        if (pagination) {
          this.setState({
            pagination: pagination,
            records: records_new,
          })
        }
      }
    } else if (this.state.currentTab === 4) {
      if (this.props.admin.logDailyTask.isLoading === true && nextProps.admin.logDailyTask.isLoading === false) {
        const { data: records_new, pagination } = nextProps.admin.logDailyTask;
        if (pagination) {
          this.setState({
            pagination: pagination,
            records: records_new,
          })
        }
      }
    } else if (this.state.currentTab === 5) {
      if (this.props.admin.logChannel.isLoading === true && nextProps.admin.logChannel.isLoading === false) {
        const { data: records_new, pagination } = nextProps.admin.logChannel;
        if (pagination) {
          this.setState({
            pagination: pagination,
            records: records_new,
          })
        }
      }
    } else if (this.state.currentTab === 6) {
      if (this.props.admin.logOther.isLoading === true && nextProps.admin.logOther.isLoading === false) {
        const { data: records_new, pagination } = nextProps.admin.logOther;
        if (pagination) {
          this.setState({
            pagination: pagination,
            records: records_new,
          })
        }
      }
    }
  }

  paginationRight = () => {
    // const { token } = this.props.auth;
    const { pagination } = this.state;
    if (pagination.totalPages < this.state.numPage + 1) {
      return;
    }

    this.setState({
      numPage: this.state.numPage + 1,
    }, () => {
      this.fetchRecords()
    });
  }

  paginationLeft = () => {
    // const { token } = this.props.auth;
    if (this.state.numPage === 1) {
      return;
    }

    this.setState({
      numPage: this.state.numPage - 1,
    }, () => {
      this.fetchRecords()
    });
  }

  // Determine sort direction for property; set state.order and state.orderBy
  handleRequestSort = (event, property) => {
    if (!property) {
      return;
    }
    const desc = this.state.order === 'desc' && this.state.orderBy === property;
    this.setState({
      order: desc ? 'asc' : 'desc',
      orderBy: property,
      sorting: {
        order: desc ? 'asc' : 'desc',
        orderBy: property,
      },
    });
  }

  // Sort & define payment rows; return elements to display
  getPaymentRows = (payments) => {
    if (!payments.length) return null;

    const sortedPayments = stableSort(payments, getSorting(this.state.order, this.state.orderBy));

    // 根据当前tab类型调用对应的渲染方法
    switch (this.state.currentTab) {
      case 0:
        return this.renderQuestRows(sortedPayments);
      case 1:
        return this.renderProfileRows(sortedPayments);
      case 2:
        return this.renderWalletRows(sortedPayments);
      case 3:
        return this.renderCDKeyRows(sortedPayments);
      case 4:
        return this.renderDailyTaskRows(sortedPayments);
      case 5:
        return this.renderChannelRows(sortedPayments);
      case 6:
        return this.renderOtherRows(sortedPayments);
      default:
        return null;
    }
  }

  // Quest Log (Tab 0)
  renderQuestRows = (payments) => {
    return payments.map((payment, index) => {
      const backgroundColor = index % 2 === 0 ? '#181717' : '#323f51';
      return (
        <TableRow hover tabIndex={-1} key={payment.id || index} style={{ backgroundColor }}>
          <TableCell align="center">{payment.questName || '-'}</TableCell>
          <TableCell align="center">{payment.userName || '-'}</TableCell>
          <TableCell align="center">{payment.level || '-'}</TableCell>
          <TableCell align="center">{payment.type || '-'}</TableCell>
          <TableCell align="center">{payment.date ? getLocalTimeString(payment.date) : '-'}</TableCell>
          <TableCell align="center">{payment.adminName || '-'}</TableCell>
          <TableCell align="center">
            <button onClick={() => this.openDetailModal(payment)} style={{ borderRadius: '5px', borderWidth: '2px', borderStyle: 'solid', backgroundColor: '#3C7AEE' }}>detail</button>
          </TableCell>
        </TableRow>
      );
    });
  }

  // Profile Log (Tab 1)
  renderProfileRows = (payments) => {
    return payments.map((payment, index) => {
      const backgroundColor = index % 2 === 0 ? '#181717' : '#323f51';
      return (
        <TableRow hover tabIndex={-1} key={payment.id || index} style={{ backgroundColor }}>
          <TableCell align="center">{payment.userName || '-'}</TableCell>
          <TableCell align="center">{payment.level || '-'}</TableCell>
          <TableCell align="center">{payment.type || '-'}</TableCell>
          <TableCell align="center">{payment.date ? getLocalTimeString(payment.date) : '-'}</TableCell>
          <TableCell align="center">{truncateText(payment.info)}</TableCell>
          <TableCell align="center">
            <button onClick={() => this.openDetailModal(payment)} style={{ borderRadius: '5px', borderWidth: '2px', borderStyle: 'solid', backgroundColor: '#3C7AEE' }}>detail</button>
          </TableCell>
        </TableRow>
      );
    });
  }

  // Wallet Log (Tab 2)
  renderWalletRows = (payments) => {
    return payments.map((payment, index) => {
      const backgroundColor = index % 2 === 0 ? '#181717' : '#323f51';
      return (
        <TableRow hover tabIndex={-1} key={payment.id || index} style={{ backgroundColor }}>
          <TableCell align="center">{payment.userName || '-'}</TableCell>
          <TableCell align="center">{payment.level || '-'}</TableCell>
          <TableCell align="center">{payment.type || '-'}</TableCell>
          <TableCell align="center">{payment.amount || '-'}</TableCell>
          <TableCell align="center">{payment.date ? getLocalTimeString(payment.date) : '-'}</TableCell>
          <TableCell align="center">{payment.platform || '-'}</TableCell>
          <TableCell align="center">{truncateText(payment.info)}</TableCell>
          <TableCell align="center">
            <button onClick={() => this.openDetailModal(payment)} style={{ borderRadius: '5px', borderWidth: '2px', borderStyle: 'solid', backgroundColor: '#3C7AEE' }}>detail</button>
          </TableCell>
        </TableRow>
      );
    });
  }

  // CDKey Log (Tab 3)
  renderCDKeyRows = (payments) => {
    return payments.map((payment, index) => {
      const backgroundColor = index % 2 === 0 ? '#181717' : '#323f51';
      return (
        <TableRow hover tabIndex={-1} key={payment.id || index} style={{ backgroundColor }}>
          <TableCell align="center">{payment.userName || '-'}</TableCell>
          <TableCell align="center">{payment.action || '-'}</TableCell>
          <TableCell align="center">{payment.level || '-'}</TableCell>
          <TableCell align="center">{payment.type || '-'}</TableCell>
          <TableCell align="center">{payment.amount || '-'}</TableCell>
          <TableCell align="center">{payment.price || '-'}</TableCell>
          <TableCell align="center">{payment.date ? getLocalTimeString(payment.date) : '-'}</TableCell>
          <TableCell align="center">{payment.ip || '-'}</TableCell>
          <TableCell align="center">{payment.platform || '-'}</TableCell>
          <TableCell align="center">{truncateText(payment.info)}</TableCell>
          <TableCell align="center">
            <button onClick={() => this.openDetailModal(payment)} style={{ borderRadius: '5px', borderWidth: '2px', borderStyle: 'solid', backgroundColor: '#3C7AEE' }}>detail</button>
          </TableCell>
        </TableRow>
      );
    });
  }

  // DailyTask Log (Tab 4)
  renderDailyTaskRows = (payments) => {
    return payments.map((payment, index) => {
      const backgroundColor = index % 2 === 0 ? '#181717' : '#323f51';
      return (
        <TableRow hover tabIndex={-1} key={payment.id || index} style={{ backgroundColor }}>
          <TableCell align="center">{payment.name || '-'}</TableCell>
          <TableCell align="center">{payment.level || '-'}</TableCell>
          <TableCell align="center">{payment.type || '-'}</TableCell>
          <TableCell align="center">{payment.startTime ? getLocalTimeString(payment.startTime) : '-'}</TableCell>
          <TableCell align="center">{payment.endTime ? getLocalTimeString(payment.endTime) : '-'}</TableCell>
          <TableCell align="center">{payment.date ? getLocalTimeString(payment.date) : '-'}</TableCell>
          <TableCell align="center">{truncateText(payment.message)}</TableCell>
          <TableCell align="center">
            <button onClick={() => this.openDetailModal(payment)} style={{ borderRadius: '5px', borderWidth: '2px', borderStyle: 'solid', backgroundColor: '#3C7AEE' }}>detail</button>
          </TableCell>
        </TableRow>
      );
    });
  }

  // Channel Log (Tab 5)
  renderChannelRows = (payments) => {
    return payments.map((payment, index) => {
      const backgroundColor = index % 2 === 0 ? '#181717' : '#323f51';
      return (
        <TableRow hover tabIndex={-1} key={payment.id || index} style={{ backgroundColor }}>
          <TableCell align="center">{payment.externalId || '-'}</TableCell>
          <TableCell align="center">{payment.action || '-'}</TableCell>
          <TableCell align="center">{payment.level || '-'}</TableCell>
          <TableCell align="center">{payment.type || '-'}</TableCell>
          <TableCell align="center">{payment.status || '-'}</TableCell>
          <TableCell align="center">{payment.date ? getLocalTimeString(payment.date) : '-'}</TableCell>
          <TableCell align="center">{truncateText(payment.info)}</TableCell>
          <TableCell align="center">
            <button onClick={() => this.openDetailModal(payment)} style={{ borderRadius: '5px', borderWidth: '2px', borderStyle: 'solid', backgroundColor: '#3C7AEE' }}>detail</button>
          </TableCell>
        </TableRow>
      );
    });
  }

  // Other Log (Tab 6)
  renderOtherRows = (payments) => {
    return payments.map((payment, index) => {
      const backgroundColor = index % 2 === 0 ? '#181717' : '#323f51';
      return (
        <TableRow hover tabIndex={-1} key={payment.id || index} style={{ backgroundColor }}>
          <TableCell align="center">{payment.level || '-'}</TableCell>
          <TableCell align="center">{payment.type || '-'}</TableCell>
          <TableCell align="center">{payment.date ? getLocalTimeString(payment.date) : '-'}</TableCell>
          <TableCell align="center">{truncateText(payment.info)}</TableCell>
          <TableCell align="center">
            <button onClick={() => this.openDetailModal(payment)} style={{ borderRadius: '5px', borderWidth: '2px', borderStyle: 'solid', backgroundColor: '#3C7AEE' }}>detail</button>
          </TableCell>
        </TableRow>
      );
    });
  }

  // Sort & define payment rows; return elements to display
  getTypeSelection = (payments) => {
    if (payments.length) {
      const rows = payments.map((item, index) => {
        return (
          <MenuItem className='date_input_head_style' key={index} value={item}>{item}</MenuItem>
        );
      });
      return rows;
    }
    return null;
  }

  handleSearchChange(event) {
    const { target: { value } } = event;
    this.setState({
      search: value,
    });

    // this.refreshRecords()
  }

  handleSearch = (event) => {
    this.refreshRecords()
  }

  // Define table headers; return array of headers
  getTableHeaders = () => {
    let tableHeaders = null
    if (this.state.currentTab === 0) {
      tableHeaders = [
        // {
        //   id: 'questId', numeric: false, disablePadding: true, label: 'Quest ID',
        // },
        {
          id: 'questName', numeric: false, disablePadding: true, label: 'Quest Name',
        },
        {
          id: 'username', numeric: false, disablePadding: true, label: 'UserName',
        },
        {
          id: 'level', numeric: false, disablePadding: true, label: 'Level',
        },
        {
          id: 'type', numeric: false, disablePadding: true, label: 'Action',
        },
        {
          id: 'date', numeric: false, disablePadding: true, label: 'Date',
        },
        {
          id: 'operateName', numeric: false, disablePadding: true, label: 'OperateName',
        },
        {
          id: 'action', numeric: false, disablePadding: true, label: 'Detail',
        }
      ];
    } else if (this.state.currentTab === 1) {
      tableHeaders = [
        {
          id: 'username', numeric: false, disablePadding: true, label: 'UserName',
        },
        {
          id: 'level', numeric: false, disablePadding: true, label: 'Level',
        },
        {
          id: 'type', numeric: false, disablePadding: true, label: 'Action',
        },
        {
          id: 'date', numeric: false, disablePadding: true, label: 'Date',
        },
        {
          id: 'info', numeric: false, disablePadding: true, label: 'Message',
        },
        {
          id: 'action', numeric: false, disablePadding: true, label: 'Detail',
        }
      ];
    } else if (this.state.currentTab === 2) {
      tableHeaders = [
        {
          id: 'username', numeric: false, disablePadding: true, label: 'UserName',
        },
        {
          id: 'level', numeric: false, disablePadding: true, label: 'Level',
        },
        {
          id: 'type', numeric: false, disablePadding: true, label: 'Action',
        },
        {
          id: 'amount', numeric: false, disablePadding: true, label: 'Amount',
        },
        {
          id: 'date', numeric: false, disablePadding: true, label: 'Date',
        },
        {
          id: 'platform', numeric: false, disablePadding: true, label: 'Platform',
        },
        {
          id: 'info', numeric: false, disablePadding: true, label: 'Message',
        },
        {
          id: 'action', numeric: false, disablePadding: true, label: 'Detail',
        }
      ];
    } else if (this.state.currentTab === 3) {
      tableHeaders = [
        {
          id: 'username', numeric: false, disablePadding: true, label: 'UserName',
        },
        {
          id: 'actionType', numeric: false, disablePadding: true, label: 'Action',
        },
        {
          id: 'level', numeric: false, disablePadding: true, label: 'Level',
        },
        {
          id: 'type', numeric: false, disablePadding: true, label: 'Type',
        },
        {
          id: 'amount', numeric: false, disablePadding: true, label: 'Amount',
        },
        {
          id: 'price', numeric: false, disablePadding: true, label: 'Price',
        },
        {
          id: 'date', numeric: false, disablePadding: true, label: 'Date',
        },
        {
          id: 'ip', numeric: false, disablePadding: true, label: 'IP Address',
        },
        {
          id: 'platform', numeric: false, disablePadding: true, label: 'Platform',
        },
        {
          id: 'info', numeric: false, disablePadding: true, label: 'Message',
        },
        {
          id: 'action', numeric: false, disablePadding: true, label: 'Detail',
        }
      ];
    } else if (this.state.currentTab === 4) {
      tableHeaders = [
        {
          id: 'actionType', numeric: false, disablePadding: true, label: 'Action',
        },
        {
          id: 'level', numeric: false, disablePadding: true, label: 'Level',
        },
        {
          id: 'type', numeric: false, disablePadding: true, label: 'Type',
        },
        {
          id: 'start', numeric: false, disablePadding: true, label: 'Start Time',
        },
        {
          id: 'end', numeric: false, disablePadding: true, label: 'End Time',
        },
        {
          id: 'date', numeric: false, disablePadding: true, label: 'Date',
        },
        {
          id: 'info', numeric: false, disablePadding: true, label: 'Message',
        },
        {
          id: 'action', numeric: false, disablePadding: true, label: 'Detail',
        }
      ];
    } else if (this.state.currentTab === 5) {
      tableHeaders = [
        {
          id: 'channelId', numeric: false, disablePadding: true, label: 'ChannelId',
        },
        {
          id: 'actionType', numeric: false, disablePadding: true, label: 'Action',
        },
        {
          id: 'level', numeric: false, disablePadding: true, label: 'Level',
        },
        {
          id: 'type', numeric: false, disablePadding: true, label: 'Type',
        },
        {
          id: 'status', numeric: false, disablePadding: true, label: 'Status',
        },
        {
          id: 'date', numeric: false, disablePadding: true, label: 'Date',
        },
        {
          id: 'info', numeric: false, disablePadding: true, label: 'Message',
        },
        {
          id: 'action', numeric: false, disablePadding: true, label: 'Detail',
        }
      ];
    } else if (this.state.currentTab === 6) {
      tableHeaders = [
        {
          id: 'level', numeric: false, disablePadding: true, label: 'Level',
        },
        {
          id: 'type', numeric: false, disablePadding: true, label: 'Type',
        },
        {
          id: 'date', numeric: false, disablePadding: true, label: 'Date',
        },
        {
          id: 'info', numeric: false, disablePadding: true, label: 'Message',
        },
        {
          id: 'action', numeric: false, disablePadding: true, label: 'Detail',
        }
      ];
    }
    return tableHeaders;
  }

  // Define data for csv download; return object with headers and data
  getCSV = (payments) => {
    const data = [];

    for (let i = 0; i < payments.length; i += 1) {
      const payment = payments[i];
      let dataRow = {}
      if (this.state.currentTab === 0) {
        dataRow = {
          questId: payment.questId ? payment.questId : '-',
          questName: payment.questName ? payment.questName : '-',
          type: payment.type ? payment.type : '-',
          date: payment.date ? moment(payment.date).format('YYYY-MM-DD HH:mm a') : '-',
          operatename: payment.adminName ? payment.adminName : '-',
          username: payment.userName ? payment.userName : '-',
          message: payment.info ? payment.info : '-',
        };
      } else if (this.state.currentTab === 1) {
        dataRow = {
          userId: payment.userId ? payment.userId : '-',
          username: payment.userName ? payment.userName : '-',
          type: payment.type ? payment.type : '-',
          date: payment.date ? moment(payment.date).format('YYYY-MM-DD HH:mm a') : '-',
          ip: payment.ip ? payment.ip : '-',
          platform: payment.platform ? payment.platform : '-',
          message: payment.info ? payment.info : '-',
        };
      }
      else if (this.state.currentTab === 2) {
        dataRow = {
          userId: payment.userId ? payment.userId : '-',
          username: payment.userName ? payment.userName : '-',
          type: payment.type ? payment.type : '-',
          date: payment.date ? moment(payment.date).format('YYYY-MM-DD HH:mm a') : '-',
          orderId: payment.orderId ? payment.orderId : '-',
          walletId: payment.walletId ? payment.walletId : '-',
          transactionId: payment.transactionId ? payment.transactionId : '-',
          ip: payment.ip ? payment.ip : '-',
          platform: payment.platform ? payment.platform : '-',
          message: payment.info ? payment.info : '-',
        };
      } else if (this.state.currentTab === 3) {
        dataRow = {
          userId: payment.userId ? payment.userId : '-',
          username: payment.userName ? payment.userName : '-',
          type: payment.type ? payment.type : '-',
          date: payment.date ? moment(payment.date).format('YYYY-MM-DD HH:mm a') : '-',
          orderId: payment.orderId ? payment.orderId : '-',
          walletId: payment.walletId ? payment.walletId : '-',
          transactionId: payment.transactionId ? payment.transactionId : '-',
          ip: payment.ip ? payment.ip : '-',
          platform: payment.platform ? payment.platform : '-',
          message: payment.info ? payment.info : '-',
        };
      }
      data.push(dataRow);
    }

    // Standard CSV headers
    let headers = [];
    if (this.state.currentTab === 0) {
      headers = [
        { label: 'Quest Id', key: 'questId' },
        { label: 'Quest Name', key: 'questName' },
        { label: 'Log Action', key: 'type' },
        { label: 'Transaction Date', key: 'date' },
        { label: 'Operate Name', key: 'operatename' },
        { label: 'User Name', key: 'username' },
        { label: 'Message', key: 'message' },
      ];
    } else if (this.state.currentTab === 1) {
      headers = [
        { label: 'User Id', key: 'userId' },
        { label: 'User Name', key: 'username' },
        { label: 'Log Action', key: 'type' },
        { label: 'Transaction Date', key: 'date' },
        { label: 'IP Address', key: 'ip' },
        { label: 'Platform', key: 'platform' },
        { label: 'Message', key: 'message' },
      ];
    } else if (this.state.currentTab === 2) {
      headers = [
        { label: 'User Id', key: 'userId' },
        { label: 'User Name', key: 'username' },
        { label: 'Log Action', key: 'type' },
        { label: 'Transaction Date', key: 'date' },
        { label: 'Order ID', key: 'orderId' },
        { label: 'Wallet ID', key: 'walletId' },
        { label: 'Transcation ID', key: 'transactionId' },
        { label: 'IP Address', key: 'ip' },
        { label: 'Platform', key: 'platform' },
        { label: 'Message', key: 'message' },
      ];
    } else if (this.state.currentTab === 3) {
      headers = [
        { label: 'User Id', key: 'userId' },
        { label: 'User Name', key: 'username' },
        { label: 'Log Action', key: 'type' },
        { label: 'Transaction Date', key: 'date' },
        { label: 'Order ID', key: 'orderId' },
        { label: 'Wallet ID', key: 'walletId' },
        { label: 'Transcation ID', key: 'transactionId' },
        { label: 'IP Address', key: 'ip' },
        { label: 'Platform', key: 'platform' },
        { label: 'Message', key: 'message' },
      ];
    }

    return { headers, data };
  }

  // Call api to pull down paypal payments.

  openDownloadModal = () => {
    const { token, user } = this.props.auth;
    const isAdmin = user.role === 'admin';
    const isHelper = user.role === 'helper';

    if (isAdmin || isHelper) {
      if (this.state.currentTab === 0) {
        if (this.state.optionValue === "All") {
          this.props.getQuestLogCsv(0, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token, "");
        } else {
          this.props.getQuestLogCsv(0, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token, this.state.optionValue);
        }
      } else if (this.state.currentTab === 1) {
        if (this.state.optionValue === "All") {
          this.props.getProfileLogCsv(0, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token, "");
        } else {
          this.props.getProfileLogCsv(0, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token, this.state.optionValue);
        }
      } else if (this.state.currentTab === 2) {
        if (this.state.optionValue === "All") {
          this.props.getWalletLogCsv(0, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token, "");
        } else {
          this.props.getWalletLogCsv(0, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token, this.state.optionValue);
        }
      } else if (this.state.currentTab === 3) {
        if (this.state.optionValue === "All") {
          this.props.getCDKeyLogCsv(0, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token, "");
        } else {
          this.props.getCDKeyLogCsv(0, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token, this.state.optionValue);
        }
      }

      this.setState({
        downloadModal: true,
      });
    }
  }

  closeDownloadModal = () => {
    this.setState({
      downloadModal: false,
    });
  }

  getDetailModalTitle = () => {
    if (this.state.currentTab === 0) {
      return "Quest Detail";
    } else if (this.state.currentTab === 1) {
      return "Profile Detail";
    } else if (this.state.currentTab === 2) {
      return "Wallet Detail";
    } else if (this.state.currentTab === 3) {
      return "Cdkey Detail";
    } else if (this.state.currentTab === 4) {
      return "Daily Task Detail";
    } else if (this.state.currentTab === 5) {
      return "Channel Detail";
    } else if (this.state.currentTab === 6) {
      return "Other Detail";
    }
    return "Detail";
  }

  openDetailModal = (payment) => {
      this.setState(
        {
          cur_payment: payment
        }, () => {
          this.setState({
            detailModal: true,
          });
        }
      )
  }

  closeDetailModal = () => {
    this.setState({
      detailModal: false,
    });
  }

  getHeadFirstPlaceholders = () => {
    if (this.state.currentTab === 0) {
      return "Please enter Quset ID,Multiple lines can be separated by commas";
    } else if (this.state.currentTab === 1) {
      return "Please enter User ID,Multiple lines can be separated by commas";
    } else if (this.state.currentTab === 2) {
      return "Please enter User ID,Multiple lines can be separated by commas";
    }
    return "Please";
  }

  getCSVTitle = () => {
    let type = ''
    if (this.state.currentTab === 0) {
      type = 'Quest'
    } else if (this.state.currentTab === 1) {
      type = 'Profile'
    } else if (this.state.currentTab === 2) {
      type = 'Wallet'
    } else if (this.state.currentTab === 3) {
      type = 'CDKey'
    }
    return `Records-Log-${type}-${this.renderFiltersString(true)}.csv`
  }

  renderFiltersString = (forFileName) => {
    const { filters, search } = this.state;
    const filterArray = [];

    if (search !== "") {
      filterArray.push(`Order No: ${search}`);
    }

    if (filters.startDate && filters.endDate) {
      filterArray.push(`Transaction Date: ${moment(filters.startDate).format('MMMM DD, YYYY')} - ${moment(filters.endDate).format('MMMM DD, YYYY')}`);
    } else if (filters.startDate) {
      filterArray.push(`Transaction Date: After ${moment(filters.startDate).format('MMMM DD, YYYY')}`);
    } else if (filters.endDate) {
      filterArray.push(`Transaction Date: Before ${moment(filters.endDate).format('MMMM DD, YYYY')}`);
    }

    if (filterArray.length === 0) {
      return 'None';
    }

    // Determine delimiter
    if (forFileName) {
      const filterStrings = filterArray.map(filter => filter.replace(/[^a-z0-9+]+/gi, '-'));
      return filterStrings.join('_');
    }
    return filterArray.join(', ');
  }

  // 将 renderDetailModal 重构为更简洁的分发函数
  renderDetailModal() {
    const { detailModal, currentTab, cur_payment } = this.state;

    return (
      <Dialog
        open={detailModal}
        onClose={this.closeDetailModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">{this.getDetailModalTitle()}</Typography>
            <IconButton
              edge="end"
              onClick={this.closeDetailModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          {/* 根据不同的 tab 类型调用对应的内容渲染函数 */}
          {this.renderDetailContent(currentTab, cur_payment)}

          {/* 比较数据变化部分 - 如果需要的话显示 */}
          {this.shouldShowComparison(cur_payment) && this.renderComparisonContent(cur_payment, currentTab)}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={this.closeDetailModal}
            color="primary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  // 中央分发函数 - 根据 tab 类型返回对应的内容
  renderDetailContent(tabType, payment) {
    if (!payment) return null;

    switch (tabType) {
      case 0:
        return this.renderQuestDetailContent(payment);
      case 1:
        return this.renderProfileDetailContent(payment);
      case 2:
        return this.renderWalletDetailContent(payment);
      case 3:
        return this.renderCDKeyDetailContent(payment);
      case 4:
        return this.renderDailyTaskDetailContent(payment);
      case 5:
        return this.renderChannelDetailContent(payment);
      case 6:
        return this.renderOtherDetailContent(payment);
      // 可以在这里添加更多的 case 分支来扩展 tab 4,5,6,7 等
      default:
        return <Typography>No detail view available for this type</Typography>;
    }
  }

  // 判断是否需要显示比较内容
  shouldShowComparison(payment) {
    return payment && (
      payment.type === 'update' ||
      payment.type === 'audit' ||
      payment.type === 'profile'
    );
  }

  // 渲染比较内容
  renderComparisonContent(payment, tabType) {
    let oldData = {}, newData = {};

    // 根据不同 tab 类型准备比较数据
    if (tabType === 0) {
      oldData = payment?.data_before || {};
      newData = payment?.data_after || {};
    } else {
      const data = payment?.data || {};
      if (data && payment.type !== 'login') {
        for (const [key, value] of Object.entries(data)) {
          oldData[key] = value.from;
          newData[key] = value.to;
        }
      }
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ComparisonRow
            label={this.getDifferenceLabel()}
            oldValue={oldData}
            newValue={newData}
          />
        </Grid>
      </Grid>
    );
  }

  // Quest 详情内容 (Tab 0)
  renderQuestDetailContent(payment) {
    return (
      <Box p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                Quest Name:
              </Typography>
              <Typography variant="body1">
                {payment?.questName || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                Quest Type:
              </Typography>
              <Typography variant="body1">
                {payment?.data?.type || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                Quest ID:
              </Typography>
              <Typography variant="body1">
                {payment?.questId || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                Creator:
              </Typography>
              <Typography variant="body1">
                {payment?.adminName || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          {payment?.date && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Date:
                </Typography>
                <Typography variant="body1">
                  {getLocalTimeString(payment.date)}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                Message:
              </Typography>
              <Typography variant="body1">
                {payment?.info || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          {payment?.details && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="flex-start" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Details:
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    maxWidth: 'calc(100% - 150px)'
                  }}
                >
                  {payment?.details || '-'}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}
        </Grid>
      </Box>
    );
  }

  // Profile 详情内容 (Tab 1)
  renderProfileDetailContent(payment) {
    return (
      <Box p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                User Name:
              </Typography>
              <Typography variant="body1">
                {payment?.userName || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                User ID:
              </Typography>
              <Typography variant="body1">
                {payment?.userId || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                IP Address:
              </Typography>
              <Typography variant="body1">
                {payment?.ip || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          {payment?.type !== 'login' && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Platform:
                </Typography>
                <Typography variant="body1">
                  {payment?.platform || '-'}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}

          {payment?.type === 'login' && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Browser:
                </Typography>
                <Typography variant="body1">
                  {payment?.data?.browser?.name + " " + payment?.data?.browser?.version || '-'}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}

          {payment?.date && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Date:
                </Typography>
                <Typography variant="body1">
                  {getLocalTimeString(payment.date)}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                Message:
              </Typography>
              <Typography variant="body1">
                {payment?.info || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>
        </Grid>
      </Box>
    );
  }

  // Wallet 详情内容 (Tab 2)
  renderWalletDetailContent(payment) {
    return (
      <Box p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                User Name:
              </Typography>
              <Typography variant="body1">
                {payment?.userName || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                User ID:
              </Typography>
              <Typography variant="body1">
                {payment?.userId || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                Order ID:
              </Typography>
              <Typography variant="body1">
                {payment?.orderId || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                Wallet ID:
              </Typography>
              <Typography variant="body1">
                {payment?.walletId || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          {payment?.type === 'withdrawal' && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Transaction ID:
                </Typography>
                <Typography variant="body1">
                  {payment?.transactionId || '-'}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}

          {payment?.type === 'rollIn' && payment?.data?.questName && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Quest Name:
                </Typography>
                <Typography variant="body1">
                  {payment?.data?.questName || '-'}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}

          {payment?.type === 'rollIn' && payment?.data?.questId && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Quest ID:
                </Typography>
                <Typography variant="body1">
                  {payment?.data?.questId || '-'}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}

          {payment?.type === 'rollIn' && (typeof payment?.data?.amount_before === 'number') && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Wallet Before:
                </Typography>
                <Typography variant="body1">
                  {payment?.data?.amount_before?.toString() || '-'}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}

          {payment?.type === 'rollIn' && payment?.data?.amount_after && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Wallet After:
                </Typography>
                <Typography variant="body1">
                  {payment?.data?.amount_after || '-'}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                IP Address:
              </Typography>
              <Typography variant="body1">
                {payment?.ip || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          {payment?.date && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Date:
                </Typography>
                <Typography variant="body1">
                  {getLocalTimeString(payment.date)}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                Message:
              </Typography>
              <Typography variant="body1">
                {payment?.info || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>
        </Grid>
      </Box>
    );
  }

  // CDKey 详情内容 (Tab 3)
  renderCDKeyDetailContent(payment) {
    return (
      <Box p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                User Name:
              </Typography>
              <Typography variant="body1">
                {payment?.userName || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                Action:
              </Typography>
              <Typography variant="body1">
                {payment?.action || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                Amount:
              </Typography>
              <Typography variant="body1">
                {payment?.amount || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                Price:
              </Typography>
              <Typography variant="body1">
                {payment?.price || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          {payment?.date && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Date:
                </Typography>
                <Typography variant="body1">
                  {getLocalTimeString(payment.date)}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                IP Address:
              </Typography>
              <Typography variant="body1">
                {payment?.ip || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                Platform:
              </Typography>
              <Typography variant="body1">
                {payment?.platform || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                Message:
              </Typography>
              <Typography variant="body1">
                {payment?.info || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          {payment?.data?.exception && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Exception:
                </Typography>
                <Typography variant="body1">
                  {payment?.data?.exception}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}
        </Grid>
      </Box>
    );
  }

  // 每日任务详情内容 (Tab 4)
  renderDailyTaskDetailContent(payment) {
    return (
      <Box p={2}>
        <Grid container spacing={3}>

          {payment?.name && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Log Name:
                </Typography>
                <Typography variant="body1">
                  {payment?.name}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}

          {payment?.externalId && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Channel ID:
                </Typography>
                <Typography variant="body1">
                  {payment?.externalId}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}

          {payment?.level && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Action:
                </Typography>
                <Typography variant="body1">
                  {payment?.level}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}

          {payment?.userId && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  User ID:
                </Typography>
                <Typography variant="body1">
                  {payment?.userId}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}

          {payment?.date && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Record Time:
                </Typography>
                <Typography variant="body1">
                  {getLocalTimeString(payment?.date)}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}

          {payment?.startTime && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Start Time:
                </Typography>
                <Typography variant="body1">
                  {getLocalTimeString(payment?.startTime)}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}

          {payment?.endTime && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  End Time:
                </Typography>
                <Typography variant="body1">
                  {getLocalTimeString(payment?.endTime)}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                Message:
              </Typography>
              <Typography variant="body1">
                {payment?.message || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          {payment?.error?.stack && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="flex-start" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Exception:
                </Typography>
                <Typography variant="body1">
                  {payment?.error?.stack}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}

          {payment?.details && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="flex-start" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Details:
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    maxWidth: 'calc(100% - 150px)'
                  }}
                >
                  {payment?.details || '-'}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}
        </Grid>
      </Box>
    );
  }

  // Channel 详情内容 (Tab 5)
  renderChannelDetailContent(payment) {
    return (
      <Box p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                Channel Name:
              </Typography>
              <Typography variant="body1">
                {payment?.externalUsername || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                Channel ID:
              </Typography>
              <Typography variant="body1">
                {payment?.externalId || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                Action:
              </Typography>
              <Typography variant="body1">
                {payment?.action || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                Bussiness Type:
              </Typography>
              <Typography variant="body1">
                {payment?.businessType || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                User ID:
              </Typography>
              <Typography variant="body1">
                {payment?.userId || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          {payment?.date && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Date:
                </Typography>
                <Typography variant="body1">
                  {getLocalTimeString(payment.date)}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                IP Address:
              </Typography>
              <Typography variant="body1">
                {payment?.ip || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                Channel Type:
              </Typography>
              <Typography variant="body1">
                {payment?.type || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                Message:
              </Typography>
              <Typography variant="body1">
                {payment?.info || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          {payment?.error?.stack && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Exception:
                </Typography>
                <Typography variant="body1">
                  {payment?.error?.stack || '-'}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}

          {payment?.details && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="flex-start" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Details:
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    maxWidth: 'calc(100% - 150px)'
                  }}
                >
                  {payment?.details || '-'}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}
        </Grid>
      </Box>
    );
  }

  // other 其他内容 (Tab 6)
  renderOtherDetailContent(payment) {
    return (
      <Box p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                User ID:
              </Typography>
              <Typography variant="body1">
                {payment?.userId || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          {payment?.date && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                  Date:
                </Typography>
                <Typography variant="body1">
                  {getLocalTimeString(payment.date)}
                </Typography>
              </Box>
              <Divider />
            </Grid>
          )}

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                IP Address:
              </Typography>
              <Typography variant="body1">
                {payment?.ip || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                Level:
              </Typography>
              <Typography variant="body1">
                {payment?.level || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="subtitle1" style={{ width: '150px', fontWeight: 'bold' }}>
                Message:
              </Typography>
              <Typography variant="body1">
                {payment?.info || '-'}
              </Typography>
            </Box>
            <Divider />
          </Grid>


        </Grid>
      </Box>
    );
  }

  getDifferenceLabel = () => {
    if (this.state.currentTab === 0) {
      return "Difference in Quest";
    } else if (this.state.currentTab === 1) {
      return "Difference in Profile";
    } else if (this.state.currentTab === 2) {
      return "Difference in Wallet";
    } else if (this.state.currentTab === 3) {
      return "Difference in CDKey";
    } else if (this.state.currentTab === 4) {
      return "Difference in Daily Task";
    } else if (this.state.currentTab === 5) {
      return "Difference in Channel";
    } else if (this.state.currentTab === 6) {
      return "Difference in Common";
    }
    return "Difference";
  }

  renderDownloadModal() {
    let isLoading = false
    let payments = []
    if (this.state.currentTab === 0) {
      isLoading = this.props.admin.logQuestCsv.isLoading;
      payments = this.props.admin.logQuestCsv.data;
    } else if (this.state.currentTab === 1) {
      isLoading = this.props.admin.logProfileCsv.isLoading;
      payments = this.props.admin.logProfileCsv.data;
    } else if (this.state.currentTab === 2) {
      isLoading = this.props.admin.logWalletCsv.isLoading;
      payments = this.props.admin.logWalletCsv.data;
    } else if (this.state.currentTab === 3) {
      isLoading = this.props.admin.logCdkeyCsv.isLoading;
      payments = this.props.admin.logCdkeyCsv.data;
    }
    const csv = this.getCSV(payments);

    return (
      <section className="ModalMaster active">
        <section className="Modal active">
          <button onClick={this.closeDownloadModal.bind(this)} className="Modal__layer" aria-hidden />
          <section className="Modal__wrapper Modal__download">
            {
              (isLoading) &&
              <div className="Modal__download--spinner">
                <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
              </div>
            }
            {
              (!isLoading) &&
              <div className="Modal__download--content">
                <div className="Modal__download--header">
                  Download
                </div>
                <div className="Modal__download--body">
                  <div className="Modal__download--row">
                    <CSVLink data={csv.data} headers={csv.headers} filename={this.getCSVTitle()}>
                      <i className="fa fa-download" />&nbsp;{`Search Results (${payments.length})`}
                    </CSVLink>
                  </div>
                </div>
                <div className="Modal__download--footer">
                  <button
                    onClick={this.closeDownloadModal.bind(this)}
                    className="Modal__download--cta">
                    <i className="fa fa-times" />&nbsp;Close
                  </button>
                </div>
              </div>
            }
          </section>
        </section>
      </section>
    );
  }

  onDrop = (file) => {

    if (file?.target?.files[0]) {
      /* this.setState({
        uploading: req.id,
      }); */

      this.uploadFile(file?.target?.files[0]);
    }
  }

  uploadFile = (file) => {
    const { token } = this.props.auth;

    const formData = new FormData();
    formData.append("file", file);

    Axios({
      method: 'POST',
      url: '/api/v1/admin/user/update-tickets',
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    }).then(res => {

      alert('success');
    })
      .catch(err => {
        alert('upload failed');
      });
  };

  /**
   *
   * @param {
          {isAdmin && (<div><h2 className="APaymentList__title"> RECORDS:  <span>{pagination.totalUsers || 0} (Page {pagination.pageNumber || 0} of {pagination.totalPages || 0})</span></h2></div>)}} isAdmin
   * @returns
   */
  renderRecordsSelection() {
    const { pagination } = this.state;
    return (
      <section>
        <div>
          <div className="APaymentList__title" style={{ fontSize: '30px', fontWeight: '400' }}>
            RECORDS: <span>{pagination.total} (Page {pagination.pageNumber} of {pagination.totalPages})</span>
          </div>
        </div>
      </section>
    );
  }

  render() {
    const { summary } = this.props;
    // const { isLoading} = this.props.admin.logQuest;
    const { search, dateStart, dateEnd, pagination, records, numPage, optionValue, optionLevelValue, currentTab } = this.state;
    const { role } = this.props.auth.user;
    const isAdmin = role === 'admin';
    const isHelper = role === 'helper';

    let options = [];
    let optionsLevel = []
    let isLoading = false
    if (currentTab === 0) {
      options = Array.from(summary.types_quest ? summary.types_quest : []);
      optionsLevel = Array.from(summary.levels_quest ? summary.levels_quest : []);
      isLoading = this.props.admin.logQuest.isLoading;
    } else if (currentTab === 1) {
      options = Array.from(summary.types_profile ? summary.types_profile : []);
      optionsLevel = Array.from(summary.levels_profile ? summary.levels_profile : []);
      isLoading = this.props.admin.logProfile.isLoading;
    } else if (currentTab === 2) {
      options = Array.from(summary.types_wallet ? summary.types_wallet : []);
      optionsLevel = Array.from(summary.levels_wallet ? summary.levels_wallet : []);
      isLoading = this.props.admin.logWallet.isLoading;
    } else if (currentTab === 3) {
      options = Array.from(summary.types_cdk ? summary.types_cdk : []);
      optionsLevel = Array.from(summary.levels_cdk ? summary.levels_cdk : []);
      isLoading = this.props.admin.logCdkey.isLoading;
    } else if (currentTab === 4) {
      options = Array.from(summary.types_daily_task ? summary.types_daily_task : []);
      optionsLevel = Array.from(summary.levels_daily_task ? summary.levels_daily_task : []);
      isLoading = this.props.admin.logDailyTask.isLoading;
    } else if (currentTab === 5) {
      options = Array.from(summary.types_channel ? summary.types_channel : []);
      optionsLevel = Array.from(summary.levels_channel ? summary.levels_channel : []);
      isLoading = this.props.admin.logChannel.isLoading;
    } else if (currentTab === 6) {
      options = Array.from(summary.types_log ? summary.types_log : []);
      optionsLevel = Array.from(summary.levels_log ? summary.levels_log : []);
      isLoading = this.props.admin.logOther.isLoading;
    }
    options.unshift("All");
    optionsLevel.unshift("All");

    const createSortHandler = property => (event) => {
      this.handleRequestSort(event, property);
    };

    return (
      <section className="APaymentList">
        <section className="APaymentList__wrapper">
          {isAdmin && this.renderRecordsSelection()}
          <section className="ToggleTable">
            <section className="ToggleTable__header">
              <div className="ToggleTable__header__left">
                <div className="Search_Input">
                  <Icon color='#bfbfbf' name='search' />
                  <input
                    type="text"
                    id="search-bar-input"
                    label="SearchBarInput"
                    placeholder={this.getHeadFirstPlaceholders()}
                    value={search}
                    onChange={e => this.handleSearchChange(e)}
                    onKeyDown={(e) => e.key === 'Enter' && this.handleSearch()}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} className="input-container" >

                  <Typography className='date_input_head_style' style={{ marginLeft: '5px', }}>
                    Select Log Level
                  </Typography>
                  <FormControl style={{ marginLeft: '10px', }} className='input-wrapper'>
                    <Select
                      className='input-wrapper'
                      id="dropdown"
                      value={optionLevelValue}
                      onChange={this.handleLevelChange}>
                      {this.getTypeSelection(optionsLevel)}
                    </Select>
                  </FormControl>
                </div>
                <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} className="input-container" >

                  <Typography className='date_input_head_style' style={{ marginLeft: '5px', }}>
                    Select Log Type
                  </Typography>
                  <FormControl style={{ marginLeft: '10px', }} className='input-wrapper'>
                    <Select
                      className='input-wrapper'
                      id="dropdown"
                      value={optionValue}
                      onChange={this.handleChange}>
                      {this.getTypeSelection(options)}
                    </Select>
                  </FormControl>
                </div>

                <button style={{ marginLeft: '25px' }} onClick={this.refreshRecords.bind(this)}>
                  Search
                  {!isLoading ? (
                    <i style={{ marginLeft: '5px' }} className="fa fa-check green" />
                  ) : null}
                </button>
                <Typography className="date_input_head_style">Log
                  time</Typography>
                {/* <label> */}
                <input type='date' placeholder="Select Start Date" className='date_input_style' value={dateStart} onChange={this.handleStartDateChange} />
                {/* </label> */}

                <input type='date' placeholder="Select End Date" className='date_input_style' value={dateEnd} onChange={this.handleEndDateChange} min={dateStart} />
                {(isAdmin || isHelper) && (
                  <button
                    style={{ marginLeft: '10px' }}
                    onClick={this.openDownloadModal.bind(this)}>
                    <i className="fa fa-download" />
                  </button>

                )}
              </div>
              <div className="ToggleTable__header__right">
                {(numPage !== 1) ?
                  <button className="regular" onClick={this.paginationLeft.bind(this)}>
                    <i className="fa fa-arrow-left" />
                  </button>
                  : null}

                {numPage !== pagination.totalPages && pagination.totalPages > 0 ?
                  <button className="regular" onClick={this.paginationRight.bind(this)}>
                    <i className="fa fa-arrow-right" />
                  </button> : null}
              </div>
            </section>
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                {/* <div className="row">
                  <div
                    className="table-cell headline"
                  >
                    Filters: {this.renderFiltersString(false)}
                  </div>
                </div> */}
                <div style={{ width: '100%', overflowX: 'auto', whiteSpace: 'nowrap' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {this.getTableHeaders().map(headCell => (
                          <TableHeaderCell
                            style={{ width: 'auto', backgroundColor: '#3C7AEE', color: 'white', fontSize: '14px', fontWeight: 'bold', paddingTop: '10px', paddingBottom: '10px' }}
                            key={headCell.id}
                            align="center"
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection="asc"
                          >
                            <TableSortLabel
                              active={this.state.orderBy === headCell.id}
                              direction={this.state.order}
                              onClick={createSortHandler(headCell.id)}
                              IconComponent={ArrowDropDownIcon}
                              style={{ marginLeft: '30px' }}
                            >
                              {headCell.label}
                              {this.state.orderBy === headCell.id ? (
                                <span style={{ display: 'none' }}>
                                  {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                              ) : null}
                            </TableSortLabel>
                          </TableHeaderCell>))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(!isLoading && records) &&
                        this.getPaymentRows(records)
                      }
                    </TableBody>
                  </Table>
                </div>
                <section>
                  <div className="NoPayments">
                    {isLoading &&
                      <div className="PaymentList__loading">
                        <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
                      </div>
                    }
                    {(!isLoading && !records.length) &&
                      <span> No Records Found </span>
                    }
                  </div>
                </section>
              </section>
            </section>
            <section className="ToggleTable__footer">
              {(numPage !== 1) ?
                <section className="ToggleTable__footer__left">
                  <button onClick={this.paginationLeft.bind(this)}>
                    <i className="fa fa-caret-left" />
                  </button>
                </section>
                : null}

              {numPage !== pagination.totalPages && pagination.totalPages > 0 ?
                <section className="ToggleTable__footer__right">
                  <button onClick={this.paginationRight.bind(this)}>
                    <i className="fa fa-caret-right" />
                  </button></section> : null}
            </section>
          </section>
        </section>
        {(this.state.downloadModal) && this.renderDownloadModal()}
        {(this.state.detailModal) && this.renderDetailModal()}
        {/* <LogQusetDialogModal open={this.state.detailModal} onClose={this.closeDetailModal.bind(this)} /> */}
      </section>
    );
  }
}

LogContent.propTypes = {

};

export default LogContent;
