import React from "react";

import { SetViewContext } from "../PortalContext";
import { htmlToPlainText, textTruncate,isNewTypeQuestStart,isQuestOver } from "../../../../utils/functions";

import Icon from "../../../../global/images/icons/Icon";
import IntercomPortal from "../mvp/IntercomPortal";
import Loading from "../../../../global/components/loading/Loading";
import quest from '../assets/quest.png';
import "./Masthead.scss";

const MastheadBlank = () => (
  <div className='Masthead__Blank'>
    <div className='Masthead__Blank_Title'>Start Your Quest</div>
    <div className='Masthead__Blank_Description'>
      You don’t have any pinned quests live at the moment. Click on the link below to launch your
      quest!
    </div>
    <IntercomPortal
      children={
        <div className='Masthead__Blank_Create_Quest'><a href="https://calendly.com/HARPERNOIZ" target="_blank" rel="noreferrer">
          Create Quest
        </a></div>
      }
    />
  </div>
);

const QuestActive = <div className='Masthead__QuestActive'>ACTIVE</div>;

const StreamerIcon = (user, index) => (
  <div
    className='Masthead__StreamerIcons_Icon'
    style={{
      zIndex: 100 - index,
      transform: `translateX(${index * -5}px)`,
    }}>
    <a href={`https://twitch.tv/${user.username}`} target='_blank' rel='noreferrer'>
      <img
        className='Masthead__StreamerIcons_Icon_Img'
        src={user.profile_img_url}
        alt={user.displayName}
      />
    </a>
  </div>
);

const CurrentlyStreaming = ({ users }) => {
  if (users.length === 0) {
    return null;
  }

  const streamerIcons = [];

  const remainingStreamers = [...users];

  for (let i = 0; i < 3; i++) {
    if (remainingStreamers[0]) {
      streamerIcons.push(StreamerIcon(remainingStreamers.shift(), i));
    }
  }

  const plural = users.length > 1;

  return (
    <div className='Masthead__CurrentlyStreaming'>
      {streamerIcons?.length > 0 && <div className='Masthead__StreamerIcons'>{streamerIcons}</div>}{" "}
      &nbsp;{remainingStreamers.length > 0 && `+ ${remainingStreamers.length} MORE`}{" "}
      {plural ? "ARE" : "IS"} STREAMING
    </div>
  );
};

const QuestDetails = ({ title, description, keyRequired }) => (
  <div className='Masthead__QuestDetails'>
    <div className='Masthead__QuestDetails_TitleBar'>
      <span className='Masthead__QuestDetails_Title'>{textTruncate(title, 40)}</span>
      {/* {keyRequired && (
        <div className='Masthead__KeyIcon'>
          <Icon color='black' name='key' />
        </div>
      )} */}
      <div className='Masthead__QuestIcon'>
        <Icon color='black' name='loudspeaker' />
      </div>
    </div>
    <div className='Masthead__QuestDetails_Description'>
      {textTruncate(htmlToPlainText(description), 200)}
    </div>
  </div>
);

const QuestLinkButton = ({ icon, text, fn }) => (
  <div className='Masthead__Links_Link' onClick={fn}>
    <img className='Masthead__Links_Link_Icon' src={quest} alt="quest" />
    <span className='Masthead__Links_Link_Text'>&nbsp;{text}</span>
  </div>
);

const QuestLinks = ({ questId }) => (
  <SetViewContext.Consumer>
    {({ setView, view }) => (
      <div className='Masthead__Links'>
        <QuestLinkButton
          icon='scroll'
          text='View Quest'
          fn={() =>
            setView({
              tab: view.tab,
              component: "QuestDetail",
              currentQuest: questId,
            })
          }
        />
      </div>
    )}
  </SetViewContext.Consumer>
);

const Masthead = props => {
  if (props.questsLoading)
    return (
      <div className='Masthead__Loading'>
        <Loading isBrand />
      </div>
    );

  if (!props?.quest?.id) return <MastheadBlank />;

  const {
    quest: {
      // gameId,
      id: questId,
      cover,
      coverPortrait,
      game: { cover: gameCover, coverPortrait: gameCoverPortrait },
      title,
      currentlyStreaming = [],
      description,
      keyRequired,
    },
  } = props;

  // const today = new Date();
  const isQuestActive = isNewTypeQuestStart(this.props.quest) && !isQuestOver(this.props.quest);

  const img = cover || coverPortrait || gameCover || gameCoverPortrait;

  return (
    <div className='Masthead'>
      <img className='Masthead__Background' src={img} alt={title} />
      <div className='Masthead__Title'>
        {isQuestActive && QuestActive}
        {!!currentlyStreaming?.length && <CurrentlyStreaming users={currentlyStreaming} />}
      </div>
      <div className='Masthead__Bottom'>
        <QuestDetails title={title} description={description} keyRequired={keyRequired} />
        <QuestLinks questId={questId} />
      </div>
    </div>
  );
};

export default Masthead;
