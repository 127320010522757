import React from "react";
import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { adminGetUserCounts } from "../../../../utils/apiv2";

import "./NewUsersCounts.scss";
import { number } from "prop-types";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const NewUsersCounts = () => {
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [userCounts, setUserCounts] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let date = new Date();
    date.setDate(date.getDate() - 7);
    setStartDate(date);

    fetchUserCounts(date);
  }, []);

  const fetchUserCounts = (date = startDate, date2 = endDate) => {
    setLoading(true);
    const fetchData = async () => {
      const res = await adminGetUserCounts(date, date2);
      console.log("fetchUserCounts res data is:",res?.data?.count)
      let count = 0 
      const countData = res?.data?.count
      if(typeof countData === number && countData > 0){
        count = countData
      }
      setUserCounts(count || '');
      setLoading(false);
    };
    fetchData();
  }

  const setDate = (type, e) => {
    const { target: { value } } = e;
    if(!value){
      return
    }
    const date = new Date(value);
    if (type === 'startDate') {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handleClickOpen = type => {
    setOpen(type);
  };

  const handleSave = () => {
    setOpen(false);
    fetchUserCounts();
  };

  return (
    <div className='NewUsersCounts_MasterContainer'>
      {loading && <div className='NewUsersCounts_Loading'>
        <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
      </div>}
      {!loading && (
        <div className='NewUsersCounts_WorkingContainer'>
          <div className='NewUsersCounts_Title'>
            <b>New Users</b>
          </div>
          <Tooltip title="Start Date" placement="top">
            <Button variant="outlined" onClick={e => handleClickOpen('startDate')}>
              {startDate.toISOString().split('T')[0]}
            </Button>
          </Tooltip>
          <Tooltip title="End Date">
            <Button variant="outlined" onClick={e => handleClickOpen('endDate')}>
              {endDate.toISOString().split('T')[0]}
            </Button>
          </Tooltip>
          <div className='NewUsersCounts_Count'>
            {userCounts}
          </div>
        </div>
      )}

      <BootstrapDialog
        onClose={handleSave}
        aria-labelledby="customized-dialog-title"
        open={!!open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Select {open === 'startDate' ? 'Start' : 'End'} Date
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <input
              type="date"
              value={open === 'startDate' ? startDate.toISOString().split('T')[0] : endDate.toISOString().split('T')[0]}
              onChange={(e) => setDate(open, e)}
            />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSave}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default NewUsersCounts;
