/* eslint-disable */
import React from 'react';
// import PropTypes from 'prop-types'
import './static.css';

class CDKInfo extends React.Component {

    componentDidMount() {
        const { orderId } = this.props.router.params;
        if (orderId) {
            this.props.getCDK(orderId);
        }
    }

    getCDKRows = (cdks) => {
        if (cdks&&cdks.length) {
                return cdks.map(cdk=>(
                    <>
                    {cdk.key}
                    <br/>
                    </>
        ));
        }
    }

    render() {
      const { data,errors} = this.props.auth||{};
    return (
      <section className="PrivacyPolicy static">



      <h1 dir="ltr">

          {(!errors.hasError&&data&&data.cdks)&&(<>The CDK you purchased is :<br/>
          { this.getCDKRows(data.cdks)}
          </>)}
          {(errors.hasError||!data||!data.cdks)&&(<>There are no available CDKEYs</>)}

</h1>
<br/>
<br/>

<p dir="ltr" style={{textAlign:'center'}}>
    Please keep it properly to prevent leakage.
</p>

      </section>
    );
  }
}

export default CDKInfo;
