import React from 'react';
import { Link } from 'react-router';
import { htmlToPlainText, getPlatformImages } from '../../utils/functions';
import './GameCardNew.scss';

const maxChar = 72;
const truncate = str => `${str.slice(0, maxChar) + (str.length > maxChar ? '...' : '')}`;

function GameCard({
  data: {
    cover, developer, developerLogo, id, name, headline = '', platforms = []
  },
}) {
  const headlineText = htmlToPlainText(headline);
  const truncated = headlineText.length > maxChar;
  const images = getPlatformImages(platforms);
  return (
    <div className="game-card">
      <div className="image-container">
        <div
          className="image"
          style={{ backgroundImage: `url(${cover})` }}
        >
          <div className="image-overlay" />
        </div>
      </div>
      <div className="info">
        <div className="dev">
          <img
            alt="logo"
            className="logo"
            src={developerLogo}
          />
          <div className="names">
            <span className="dev-name">
              {developer}
            </span>
            <span className="game-name">
              {name}
            </span>
          </div>
        </div>
        <div
          className="game-description"
          data-tip={truncated ? headlineText : undefined}
        >
          {truncate(headline)}
          <div className="image-list">
            {images.map((image, index) => (
              <img alt={`Platform icon ${index + 1}`} key={index} src={image} className="game-image" />
            ))}
          </div>
        </div>
        <Link
          className="view-quests-button"
          to={`/game/${id}`}
        >
          View More
        </Link>
      </div>
    </div>
  );
}

export default GameCard;
