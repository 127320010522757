import React, { useState, useEffect } from 'react';
import './Counter.scss';

const Counter = ({ initialCount = 0, onCountChange,min=1,max=10000,value }) => {
    const [count, setCount] = useState(initialCount);

    useEffect(() => {
        if (onCountChange) {
          onCountChange(count);
        }
      }, [count]);

    useEffect(() => {
        if(value>max){
            setCount(max);
        }else if(value<min){
            setCount(min);
        }else{
            setCount(value);
        }
    }, [value]);

    const handleIncrement = () => {
        if (count < max) {
            setCount(count + 1);
        }
    };

    const handleDecrement = () => {
        if (count > min) {
            setCount(count - 1);
          }
    };

    return (
        <div className="counter">
            <button className="counter-button left" onClick={handleDecrement}>-</button>
            <span className="counter-value">{count}</span>
            <button className="counter-button right" onClick={handleIncrement}>+</button>
        </div>
    );
};

export default Counter;
