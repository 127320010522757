import React from 'react';
import { ParallaxLayer } from '../../../custom_modules/react-spring/src/renderprops/addons/index';
import ContactForm from './ContactForm';
import LconcatBg from '../assetsV2/Lconcat-bg.png';

// Little helpers ...

import './BrandServiceSlides.scss';

const ContactUs = (props) => {
  const { pOffset, layoutFactor = 1, offsetAdjust = 0 } = props;

  return (
    <>
      <ParallaxLayer
        offset={pOffset + offsetAdjust} factor={layoutFactor}
        speed={0}
        className="contact-us-container"
        id="contact-us-container"
        style={{
          backgroundImage: `url(${LconcatBg})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat' // 明确禁用重复 
        }}
      >
        <div className="cascade-fade-l fade-in-prep">
          <div className="brand-title">Schedule Your Next<br /> Campaign Today</div>
          <div className='brand-description'>
            Do you have a game or product you want to promote? <br /> Awesome, we can help! To start, fill out this form and we’ll be in touch soon.
          </div>
        </div>
        <ContactForm />
      </ParallaxLayer>
      {/* <ParallaxLayer offset={pOffset + offsetAdjust} factor={layoutFactor} speed={0.8} className="brand-angle-svg" /> */}
    </>
  );
};

export default ContactUs;
