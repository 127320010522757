import { map, filter } from 'lodash';

const config = (state = {}, action) => {
  switch (action.type) {
    case 'GET_INITIAL_INFO_PENDING':
      return {
        ...state,
        featuredGames: {
          ...state.featuredGames,
          isLoading: true,
          errors: {
            ...state.featuredGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        rules: {
          ...state.rules,
          isLoading: true,
          errors: {
            ...state.rules.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        games: {
          ...state.games,
          isLoading: true,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        myGameKeys: {
          ...state.myGameKeys,
          isLoading: true,
          errors: {
            ...state.myGameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        myPreApprovedGameKeys: {
          ...state.myPreApprovedGameKeys,
          isLoading: true,
          errors: {
            ...state.myPreApprovedGameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_INITIAL_INFO_ERROR':
      return {
        ...state,
        featuredGames: {
          ...state.featuredGames,
          isLoading: false,
          errors: {
            ...state.featuredGames.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        rules: {
          ...state.rules,
          isLoading: false,
          errors: {
            ...state.rules.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        games: {
          ...state.games,
          isLoading: false,
          errors: {
            ...state.games.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        myGameKeys: {
          ...state.myGameKeys,
          isLoading: false,
          errors: {
            ...state.myGameKeys.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        myPreApprovedGameKeys: {
          ...state.myPreApprovedGameKeys,
          isLoading: false,
          errors: {
            ...state.myPreApprovedGameKeys.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_INITIAL_INFO_ABORTED':
      return {
        ...state,
        featuredGames: {
          ...state.featuredGames,
          isLoading: false,
          errors: {
            ...state.featuredGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        rules: {
          ...state.rules,
          isLoading: false,
          errors: {
            ...state.rules.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        games: {
          ...state.games,
          isLoading: false,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        myGameKeys: {
          ...state.myGameKeys,
          isLoading: false,
          errors: {
            ...state.myGameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        myPreApprovedGameKeys: {
          ...state.myPreApprovedGameKeys,
          isLoading: false,
          errors: {
            ...state.myPreApprovedGameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_INITIAL_INFO_SUCCESS':
      return {
        ...state,
        featuredGames: {
          ...state.featuredGames,
          data: action.payload.response.data.featuredGames,
          isLoading: false,
          errors: {
            ...state.featuredGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        rules: {
          ...state.rules,
          isLoading: true,
          data: action.payload.response.data.rules,
          errors: {
            ...state.rules.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        games: {
          ...state.games,
          isLoading: false,
          data: action.payload.response.data.games,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        myGameKeys: {
          ...state.myGameKeys,
          isLoading: false,
          data: action.payload.response.data.myGameKeys,
          errors: {
            ...state.myGameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        myPreApprovedGameKeys: {
          ...state.myPreApprovedGameKeys,
          isLoading: false,
          data: action.payload.response.data.myPreApprovedGameKeys,
          errors: {
            ...state.myPreApprovedGameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_GAME_LIST_PENDING':
      return {
        ...state,
        games: {
          ...state.games,
          isLoading: true,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_GAME_LIST_ERROR':
      return {
        ...state,
        games: {
          ...state.games,
          isLoading: false,
          errors: {
            ...state.games.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_GAME_LIST_ABORTED':
      return {
        ...state,
        games: {
          ...state.games,
          isLoading: false,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_GAME_LIST_SUCCESS':
      return {
        ...state,
        games: {
          ...state.games,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    //===============新增usergamelist开始================
    case 'GET_USER_GAME_LIST_PENDING':
      return {
        ...state,
        userGames: {
          ...state.userGames,
          isLoading: true,
          errors: {
            ...state.userGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_USER_GAME_LIST_ERROR':
      return {
        ...state,
        userGames: {
          ...state.userGames,
          isLoading: false,
          errors: {
            ...state.userGames.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_USER_GAME_LIST_ABORTED':
      return {
        ...state,
        userGames: {
          ...state.userGames,
          isLoading: false,
          errors: {
            ...state.userGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_USER_GAME_LIST_SUCCESS':
      return {
        ...state,
        userGames: {
          ...state.userGames,
          featured: action.payload.response.data.games_featured,
          past: action.payload.response.data.games_past,
          progress: action.payload.response.data.games_progress,
          current: action.payload.response.pagination.pageNumber,
          total: action.payload.response.pagination.totalPages,
          isLoading: false,
          errors: {
            ...state.userGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    //===============新增usergamelist结束================
    case 'GET_GAME_PENDING':
      return {
        ...state,
        game: {
          ...state.game,
          data: {},
          isLoading: true,
          errors: {
            ...state.game.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_GAME_ERROR':
      return {
        ...state,
        game: {
          ...state.game,
          data: {},
          isLoading: false,
          errors: {
            ...state.game.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_GAME_ABORTED':
      return {
        ...state,
        game: {
          ...state.game,
          isLoading: false,
          data: {},
          errors: {
            ...state.game.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_GAME_SUCCESS':
      return {
        ...state,
        game: {
          ...state.game,
          data: action.payload.response.game,
          quests: action.payload.response.game.quests
            .slice().sort((a, b) => {
              const A = a.comingSoon;
              const B = b.comingSoon;
              if ((!A && !B) || (A && B)) {
                return 0;
              } else if (A && !B) {
                return -1;
              } else if (!A && B) {
                return 1;
              }
              return 0;
            })
            .slice()
            .sort((a, b) => {
              const A = a.endDateTime;
              const B = b.endDateTime;
              if ((A === B)) {
                return 0;
              } else if (A > B) {
                return -1;
              } else if (A < B) {
                return 1;
              }
              return 0;
            }),
          isLoading: false,
          errors: {
            ...state.game.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'CLOSE_QUEST':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: {},
          isLoading: false,
          isShowing: false,
          errors: {
            hasError: false,
            code: '',
            message: '',
          },
        },
        userQuestReferral: {
          ...state.userQuestReferral,
          data: {},
          errors: {
            ...state.userQuestReferral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_STORE_GAMES_PENDING':
      return {
        ...state,
        storeGames: {
          ...state.storeGames,
          data: {},
          isLoading: true,
          isShowing: true,
          errors: {
            ...state.storeGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_STORE_GAMES_ERROR':
      return {
        ...state,
        storeGames: {
          ...state.storeGames,
          data: {},
          isLoading: false,
          isShowing: true,
          errors: {
            ...state.storeGames.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_STORE_GAMES_ABORTED':
      return {
        ...state,
        storeGames: {
          ...state.storeGames,
          isLoading: false,
          isShowing: true,
          data: {},
          errors: {
            ...state.storeGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_STORE_GAMES_SUCCESS':
      return {
        ...state,
        storeGames: {
          ...state.storeGames,
          data: action.payload.response.data,
          isLoading: false,
          isShowing: true,
          errors: {
            ...state.storeGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_QUEST_PENDING':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: {},
          isLoading: true,
          isShowing: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        userQuestReferral: {
          ...state.userQuestReferral,
          data: {},
          errors: {
            ...state.userQuestReferral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_QUEST_ERROR':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: {},
          isLoading: false,
          isShowing: false,
          errors: {
            ...state.quest.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        userQuestReferral: {
          ...state.userQuestReferral,
          data: {},
          errors: {
            ...state.userQuestReferral.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_QUEST_ABORTED':
      return {
        ...state,
        quest: {
          ...state.quest,
          isLoading: false,
          isShowing: false,
          data: {},
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        userQuestReferral: {
          ...state.userQuestReferral,
          data: {},
          errors: {
            ...state.userQuestReferral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_QUEST_SUCCESS':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: action.payload.response.quest,
          isLoading: false,
          isShowing: action.payload.response.openModal,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        userQuestReferral: {
          ...state.userQuestReferral,
          data: action.payload.response.userQuestReferral,
          isLoading: false,
          isShowing: false,
          errors: {
            ...state.userQuestReferral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'JOIN_QUEST_PENDING':
      return {
        ...state,
        quest: {
          ...state.quest,
          isLoading: true,
          isJoiningToAQuest: true,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        userQuestReferral: {
          ...state.userQuestReferral,
          data: {},
          errors: {
            ...state.userQuestReferral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'JOIN_QUEST_ERROR':
      return {
        ...state,
        quest: {
          ...state.quest,
          isLoading: false,
          isJoiningToAQuest: false,
          errors: {
            ...state.quest.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        userQuestReferral: {
          ...state.userQuestReferral,
          data: {},
          errors: {
            ...state.userQuestReferral.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'JOIN_QUEST_ABORTED':
      return {
        ...state,
        quest: {
          ...state.quest,
          isLoading: false,
          isJoiningToAQuest: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        userQuestReferral: {
          ...state.userQuestReferral,
          data: {},
          errors: {
            ...state.userQuestReferral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'JOIN_QUEST_SUCCESS':
      return {
        ...state,
        quest: {
          ...state.quest,
          isLoading: false,
          isJoiningToAQuest: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        userQuestReferral: {
          ...state.userQuestReferral,
          data: action.payload.response.userQuest,
          isLoading: false,
          isShowing: true,
          errors: {
            ...state.userQuestReferral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        quests: {
          ...state.quests,
          isLoading: false,
          data: map(state.quests.data, (quest) => {
            if (quest.id === action.payload.response.quest.id) {
              return action.payload.response.quest;
            }
            return quest;
          }),
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        game: {
          ...state.game,
          data: {
            ...state.game.data,
            quests: map(state.game.data.quests, (quest) => {
              if (quest.id === action.payload.response.quest.id) {
                return action.payload.response.quest;
              }
              return quest;
            }),
          },
          isLoading: false,
          errors: {
            ...state.game.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        myGameKeys: {
          ...state.myGameKeys,
          isLoading: false,
          data: [
            ...(
              action.payload.response.myGameKey ?
                [
                  ...(filter(state.myGameKeys.data, myGameKey => myGameKey.id !== action.payload.response.myGameKey.id)),
                  action.payload.response.myGameKey,
                ] :
                state.myGameKeys.data
            ),
          ],
          errors: {
            ...state.myGameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'JOIN_QUEST_OFFPLATFORM_PENDING':
      return {
        ...state,
        quest: {
          ...state.quest,
          isLoading: true,
          isJoiningToAQuest: true,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        userQuestReferral: {
          ...state.userQuestReferral,
          data: {},
          errors: {
            ...state.userQuestReferral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'JOIN_QUEST_OFFPLATFORM_ERROR':
      return {
        ...state,
        quest: {
          ...state.quest,
          isLoading: false,
          isJoiningToAQuest: false,
          errors: {
            ...state.quest.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        userQuestReferral: {
          ...state.userQuestReferral,
          data: {},
          errors: {
            ...state.userQuestReferral.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'JOIN_QUEST_OFFPLATFORM_ABORTED':
      return {
        ...state,
        quest: {
          ...state.quest,
          isLoading: false,
          isJoiningToAQuest: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        userQuestReferral: {
          ...state.userQuestReferral,
          data: {},
          errors: {
            ...state.userQuestReferral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'JOIN_QUEST_OFFPLATFORM_SUCCESS':
      return {
        ...state,
        quest: {
          ...state.quest,
          isLoading: false,
          isJoiningToAQuest: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        userQuestReferral: {
          ...state.userQuestReferral,
          data: action.payload.response.userQuest,
          isLoading: false,
          isShowing: true,
          errors: {
            ...state.userQuestReferral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        quests: {
          ...state.quests,
          isLoading: false,
          data: map(state.quests.data, (quest) => {
            if (quest.id === action.payload.response.quest.id) {
              return action.payload.response.quest;
            }
            return quest;
          }),
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        game: {
          ...state.game,
          data: {
            ...state.game.data,
            quests: map(state.game.data.quests, (quest) => {
              if (quest.id === action.payload.response.quest.id) {
                return action.payload.response.quest;
              }
              return quest;
            }),
          },
          isLoading: false,
          errors: {
            ...state.game.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        myGameKeys: {
          ...state.myGameKeys,
          isLoading: false,
          data: [
            ...(
              action.payload.response.myGameKey ?
                [
                  ...(filter(state.myGameKeys.data, myGameKey => myGameKey.id !== action.payload.response.myGameKey.id)),
                  action.payload.response.myGameKey,
                ] :
                state.myGameKeys.data
            ),
          ],
          errors: {
            ...state.myGameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'LEAVE_QUEST_PENDING':
      return {
        ...state,
        quest: {
          ...state.quest,
          isLoading: true,
          isLeavingAQuest: true,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'LEAVE_QUEST_ERROR':
      return {
        ...state,
        quest: {
          ...state.quest,
          isLoading: false,
          isLeavingAQuest: false,
          errors: {
            ...state.quest.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'LEAVE_QUEST_ABORTED':
      return {
        ...state,
        quest: {
          ...state.quest,
          isLoading: false,
          isLeavingAQuest: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'LEAVE_QUEST_SUCCESS':
      return {
        ...state,
        quest: {
          ...state.quest,
          isLoading: false,
          isLeavingAQuest: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        quests: {
          ...state.quests,
          isLoading: false,
          data: map(state.quests.data, (quest) => {
            if (quest.id === action.payload.response.quest.id) {
              return action.payload.response.quest;
            }
            return quest;
          }),
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        game: {
          ...state.game,
          data: {
            ...state.game.data,
            quests: map(state.game.data.quests, (quest) => {
              if (quest.id === action.payload.response.quest.id) {
                return action.payload.response.quest;
              }
              return quest;
            }),
          },
          isLoading: false,
          errors: {
            ...state.game.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'REQUEST_GAME_KEY_BY_QUEST_PENDING':
      return {
        ...state,
        myGameKeys: {
          ...state.myGameKeys,
          isLoading: true,
          errors: {
            ...state.myGameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        myPreApprovedGameKeys: {
          ...state.myPreApprovedGameKeys,
          isLoading: true,
          isRequestingAGameKey: true,
          errors: {
            ...state.myPreApprovedGameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'REQUEST_GAME_KEY_BY_QUEST_ERROR':
      return {
        ...state,
        myGameKeys: {
          ...state.myGameKeys,
          isLoading: false,
          errors: {
            ...state.myGameKeys.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        myPreApprovedGameKeys: {
          ...state.myPreApprovedGameKeys,
          isLoading: false,
          isRequestingAGameKey: false,
          errors: {
            ...state.myPreApprovedGameKeys.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'REQUEST_GAME_KEY_BY_QUEST_ABORTED':
      return {
        ...state,
        myGameKeys: {
          ...state.myGameKeys,
          isLoading: false,
          errors: {
            ...state.myGameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        myPreApprovedGameKeys: {
          ...state.myPreApprovedGameKeys,
          isLoading: false,
          isRequestingAGameKey: false,
          errors: {
            ...state.myPreApprovedGameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'REQUEST_GAME_KEY_BY_QUEST_SUCCESS':
      return {
        ...state,
        myGameKeys: {
          ...state.myGameKeys,
          isLoading: false,
          data: [
            ...(
              action.payload.response.myGameKey ?
                [
                  ...(filter(state.myGameKeys.data, myGameKey => myGameKey.id !== action.payload.response.myGameKey.id)),
                  action.payload.response.myGameKey,
                ] :
                state.myGameKeys.data
            ),
          ],
          errors: {
            ...state.myGameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        myPreApprovedGameKeys: {
          ...state.myPreApprovedGameKeys,
          isLoading: false,
          isRequestingAGameKey: false,
          data: [
            ...(
              action.payload.response.myPreApprovedGameKey ?
                [
                  ...(filter(state.myPreApprovedGameKeys.data, myPreApprovedGameKey =>
                    myPreApprovedGameKey.id !== action.payload.response.myPreApprovedGameKey.id),
                    action.payload.response.myPreApprovedGameKey),
                ] :
                state.myPreApprovedGameKeys.data),
          ],
          errors: {
            ...state.myPreApprovedGameKeys.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_SF_GAME_PENDING':
      return {
        ...state,
        sfgame: {
          ...state.sfgame,
          isLoading: true,
          errors: {
            ...state.sfgame.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_SF_GAME_ERROR':
      return {
        ...state,
        sfgame: {
          ...state.sfgame,
          isLoading: false,
          errors: {
            ...state.sfgame.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_SF_GAME_ABORTED':
      return {
        ...state,
        sfgame: {
          ...state.sfgame,
          isLoading: false,
          errors: {
            ...state.sfgame.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_SF_GAME_SUCCESS':
      return {
        ...state,
        sfgame: {
          ...state.sfgame,
          data: action.payload.response.game,
          isLoading: false,
          errors: {
            ...state.sfgame.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_SF_GAME_BY_SLUG_PENDING':
      return {
        ...state,
        sfgame: {
          ...state.sfgame,
          isLoading: true,
          errors: {
            ...state.sfgame.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_SF_GAME_BY_SLUG_ERROR':
      return {
        ...state,
        sfgame: {
          ...state.sfgame,
          isLoading: false,
          errors: {
            ...state.sfgame.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_SF_GAME_BY_SLUG_ABORTED':
      return {
        ...state,
        sfgame: {
          ...state.sfgame,
          isLoading: false,
          errors: {
            ...state.sfgame.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_SF_GAME_BY_SLUG_SUCCESS':
      return {
        ...state,
        sfgame: {
          ...state.sfgame,
          data: action.payload.response.game,
          isLoading: false,
          errors: {
            ...state.sfgame.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'POST_GAME_PAYMENT_PENDING':
      return {
        ...state,
        gamePayment: {
          ...state.gamePayment,
          isLoading: true,
          errors: {
            ...state.gamePayment.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'POST_GAME_PAYMENT_ERROR':
      return {
        ...state,
        gamePayment: {
          ...state.gamePayment,
          isLoading: false,
          errors: {
            ...state.gamePayment.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'POST_GAME_PAYMENT_ABORTED':
      return {
        ...state,
        gamePayment: {
          ...state.gamePayment,
          isLoading: false,
          errors: {
            ...state.gamePayment.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'POST_GAME_PAYMENT_SUCCESS':
      return {
        ...state,
        gamePayment: {
          ...state.gamePayment,
          data: action.payload.response.game,
          isLoading: false,
          errors: {
            ...state.gamePayment.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_QUEST_BY_IDENTIFIER_PENDING':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: {},
          isLoading: true,
          isShowing: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_QUEST_BY_IDENTIFIER_ERROR':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: {},
          isLoading: false,
          isShowing: false,
          errors: {
            ...state.quest.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_QUEST_BY_IDENTIFIER_ABORTED':
      return {
        ...state,
        quest: {
          ...state.quest,
          isLoading: false,
          isShowing: false,
          data: {},
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_QUEST_BY_IDENTIFIER_SUCCESS':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: action.payload.response.quest,
          isLoading: false,
          isShowing: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_QUEST_BY_SLUG_GUEST_PENDING':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: {},
          isLoading: true,
          isShowing: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_QUEST_BY_SLUG_GUEST_ERROR':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: {},
          isLoading: false,
          isShowing: false,
          errors: {
            ...state.quest.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_QUEST_BY_SLUG_GUEST_ABORTED':
      return {
        ...state,
        quest: {
          ...state.quest,
          isLoading: false,
          isShowing: false,
          data: {},
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_QUEST_BY_SLUG_GUEST_SUCCESS':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: action.payload.response.quest,
          isLoading: false,
          isShowing: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'REQUEST_REFERRAL_CODE_BY_QUEST_PENDING':
      return {
        ...state,
        userQuestReferral: {
          ...state.userQuestReferral,
          isLoading: true,
          errors: {
            ...state.userQuestReferral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'REQUEST_REFERRAL_CODE_BY_QUEST_ERROR':
      return {
        ...state,
        userQuestReferral: {
          ...state.userQuestReferral,
          isLoading: false,
          errors: {
            ...state.userQuestReferral.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'REQUEST_REFERRAL_CODE_BY_QUEST_ABORTED':
      return {
        ...state,
        userQuestReferral: {
          ...state.userQuestReferral,
          isLoading: false,
          errors: {
            ...state.userQuestReferral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'REQUEST_REFERRAL_CODE_BY_QUEST_SUCCESS':
      return {
        ...state,
        userQuestReferral: {
          ...state.userQuestReferral,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.userQuestReferral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'UPDATE_A_FEATURED_PENDING':
      return {
        ...state,
        featuredGames: {
          ...state.featuredGames,
          isLoading: true,
          errors: {
            ...state.featuredGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'UPDATE_A_FEATURED_ERROR':
      return {
        ...state,
        featuredGames: {
          ...state.featuredGames,
          isLoading: false,
          errors: {
            ...state.featuredGames.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'UPDATE_A_FEATURED_ABORTED':
      return {
        ...state,
        featuredGames: {
          ...state.featuredGames,
          isLoading: false,
          errors: {
            ...state.featuredGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'UPDATE_A_FEATURED_SUCCESS':
      return {
        ...state,
        featuredGames: {
          ...state.featuredGames,
          data: action.payload.response.data.featuredGames,
          isLoading: false,
          errors: {
            ...state.featuredGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'SUBMIT_AFFILIATE_DATA_PENDING':
      return {
        ...state,
        userQuestReferral: {
          ...state.userQuestReferral,
          isLoading: true,
          errors: {
            ...state.userQuestReferral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'SUBMIT_AFFILIATE_DATA_ERROR':
      return {
        ...state,
        userQuestReferral: {
          ...state.userQuestReferral,
          isLoading: false,
          errors: {
            ...state.userQuestReferral.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'SUBMIT_AFFILIATE_DATA_ABORTED':
      return {
        ...state,
        userQuestReferral: {
          ...state.userQuestReferral,
          isLoading: false,
          errors: {
            ...state.userQuestReferral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'SUBMIT_AFFILIATE_DATA_SUCCESS':
      return {
        ...state,
        userQuestReferral: {
          ...state.userQuestReferral,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.userQuestReferral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'SUBMIT_REQUIREMENT_DATA_PENDING':
      return {
        ...state,
        userQuestReferral: {
          ...state.userQuestReferral,
          isLoading: true,
          errors: {
            ...state.userQuestReferral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'SUBMIT_REQUIREMENT_DATA_ERROR':
      return {
        ...state,
        userQuestReferral: {
          ...state.userQuestReferral,
          isLoading: false,
          errors: {
            ...state.userQuestReferral.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'SUBMIT_REQUIREMENT_DATA_ABORTED':
      return {
        ...state,
        userQuestReferral: {
          ...state.userQuestReferral,
          isLoading: false,
          errors: {
            ...state.userQuestReferral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'SUBMIT_REQUIREMENT_DATA_SUCCESS':
      return {
        ...state,
        userQuestReferral: {
          ...state.userQuestReferral,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.userQuestReferral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'CHECK_REQUIREMENT_DATA_PENDING':
      return {
        ...state,
        userQuestReferral: {
          ...state.userQuestReferral,
          isLoading: true,
          errors: {
            ...state.userQuestReferral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'CHECK_REQUIREMENT_DATA_ERROR':
      return {
        ...state,
        userQuestReferral: {
          ...state.userQuestReferral,
          isLoading: false,
          errors: {
            ...state.userQuestReferral.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'CHECK_REQUIREMENT_DATA_ABORTED':
      return {
        ...state,
        userQuestReferral: {
          ...state.userQuestReferral,
          isLoading: false,
          errors: {
            ...state.userQuestReferral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'CHECK_REQUIREMENT_DATA_SUCCESS':
      return {
        ...state,
        userQuestReferral: {
          ...state.userQuestReferral,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.userQuestReferral.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_VERIFY_YOUTUBE_CHANNEL_STREAM_STATUS_PENDING':
      return {
        ...state,
        quest: {
          ...state.quest,
          isVerifyingYoutube: true,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_VERIFY_YOUTUBE_CHANNEL_STREAM_STATUS_ERROR':
      return {
        ...state,
        quest: {
          ...state.quest,
          isVerifyingYoutube: false,
          errors: {
            ...state.quest.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_VERIFY_YOUTUBE_CHANNEL_STREAM_STATUS_ABORTED':
      return {
        ...state,
        quest: {
          ...state.quest,
          isVerifyingYoutube: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_VERIFY_YOUTUBE_CHANNEL_STREAM_STATUS_SUCCESS':
      return {
        ...state,
        quest: {
          ...state.quest,
          data: {
            ...state.quest.data,
            youtube: {
              video: action.payload.response.video,
              readOnly: action.payload.response.readOnly,
            },
          },
          isVerifyingYoutube: false,
          errors: {
            ...state.quest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    default:
      return state;
  }
};

export default config;
