/* eslint max-len: ["error", { "code": 800 }] */
import React from 'react';
// import PropTypes from 'prop-types';
import './CareersPost.scss';
// import IconParticles from '../icon-particles/IconParticles';

class BizDevDirector extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
    };
  }

  UNSAFE_componentWillMount() {
  }

  componentDidMount() {
  }

  render() {
    return (
      <section className="CareersPost">
        <section className="CareersPost__container">
          <section className="CareersPostSummary">
            <section className="CareersPostSummary__content">

            </section>
          </section>
          <section className="CareersPostDetails">
            <section className="CareersPostDetails__content">
              <section className="PostingItem">
                <a className="PostingItem__apply-btn" href="mailto:angie@noiz.gg">Apply for this job <i className="fa fa-envelope" /></a>
                <section className="PostingItem__wrapper">
                  <h4 className="PostingItem__title">Sales/Business Development Director</h4>
                  <section className="PostingItem__categories">
                    <span className="PostingItem__categories--location">Beverly Hills, CA</span>
                  </section>
                </section>
              </section>
              <div>
                <p><span style={{ fontWeight: 400 }}>Noiz is a full service marketing platform that connects brands with influencers in gaming and entertainment. We are experts at cultivating authentic, meaningful partnerships across Twitch, Facebook, Youtube, and social.</span><span style={{ fontWeight: 400 }}> Noiz creates insight-driven experiences rooted in cultural context to convert consumer attention into action, sales, and absolute brand loyalty. With offices in Beverly Hills and New York, Noiz works with some iconic game publishers and brands through its core in-house departments.</span></p>
                <p><strong>The Role.</strong></p>
                <p><span style={{ fontWeight: 400 }}>Noiz is looking for a Sales/Business Development Director. You will be part of a committed group of gamers and professionals who develop ideas and are looking to create a unique platform in the video game industry.</span></p>
                <p><strong>As our Sales/Business Development Director, you will be responsible for:</strong></p>
                <ul>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Sourcing new sales opportunities through inbound lead follow up, outbound cold calls and emails. Understanding customer needs and requirements</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Negotiate and help build a content strategy to tell our story</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Drive initiatives with game publishers, video game streamers, and influencer management teams in authentic and positive ways</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Build relationships with decision makers across the video game industry</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Research industry trends and uncover new leads</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Attend virtual events</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Responsible for full sales cycle from lead to close</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Communicating our story, promotions, and partnerships across various channels</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Engage daily with game publishers, gaming influencers, and influencer management teams</span></li>
                </ul>
                <p><strong>About you:</strong></p>
                <ul>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>You are collaborative, humble, proactive, adventurous, and naturally curious. You thrive in a fast-paced environment where deadlines, priorities, and initiatives change frequently.</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>You have deep experience in passion for gaming and social media&nbsp;</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>You have the leadership skills to indirectly inspire a high-performing happy team to do the best work of their careers.&nbsp; You are deeply empathetic to the needs of our customers.</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>You communicate needs, concerns, and feedback directly with colleagues.&nbsp;</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>You embody a growth mindset. You take constructive feedback well and actively seek feedback from colleagues. You model resilience after critiques for and with your team.&nbsp;</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>You&nbsp; build and cultivate relationships with non-endemic brands and publishers in gaming</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>You have a strong understanding of live streaming platforms such as Twitch, YouTube Live, FB Live</span></li>
                </ul>
                <p><strong>When applying, please ensure you meet the following requirements:</strong></p>
                <ul>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Bachelor’s degree or equivalent experience</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>5+ years experience in sales and/or marketing (Bonus experience: SaaS, Adtech, Inside Sales, Digital Marketing, Agency, Video Games)</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>3+ years of experience in gaming or esports vertical</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Deep understanding of gaming industry and how to build effective relationships</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>The ability to analyze competitor strategies and innovate on best practice</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Strong organizational and program management skills</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Self Starter with an entrepreneurial mindset.</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Experience working with and managing external agencies</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Ability to use quantitative data and metrics to drive strategic decisions and marketing activities</span></li>
                  <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Self-motivated, passionate and high energy level</span></li>
                </ul>
                <p><span style={{ fontWeight: 400 }}>Noiz is committed to creating an inclusive employee experience for all. Regardless of race, gender, religion, sexual orientation, age, disability, we firmly believe that our work is at its best when everyone feels free to be themselves.</span></p>
                <p><span style={{ fontWeight: 400 }}>We pay competitive salaries &amp; benefits including (but not limited to) medical, dental and vision health insurance.</span></p>

                <p><strong>Seniority Level</strong></p>
                <p><span style={{ fontWeight: 400 }}>Mid-Senior level</span></p>
                <p><strong>Employment Type</strong></p>
                <p><span style={{ fontWeight: 400 }}>Full-time</span></p>
              </div>

              <p className="CareersPostDetails__content--cta">Email your resume to <a href="mailto:angie@noiz.gg">angie@noiz.gg</a></p>
            </section>
          </section>
        </section>
      </section>);
  }
}

// CareersPostFullStackEngineer.propTypes = {
//
// };

export default BizDevDirector;
