
const admin = (state = {}, action) => {
  switch (action.type) {
    case 'GET_GAME_BOUNTIES_DATA_PENDING':
      return {
        ...state,
        reports: {
          ...state.reports,
          bounties: {
            ...state.reports.bounties,
            isLoading: true,
            errors: {
              ...state.reports.bounties.errors,
              hasError: false,
              code: '',
              message: '',
            },
          },
        },
      };

    case 'GET_GAME_BOUNTIES_DATA_ERROR':
      return {
        ...state,
        reports: {
          ...state.reports,
          bounties: {
            ...state.reports.bounties,
            isLoading: false,
            errors: {
              ...state.reports.bounties.errors,
              hasError: action.payload.error,
              code: action.payload.code,
              message: action.payload.message,
              all: action.payload.errors,
            },
          },
        },
      };

    case 'GET_GAME_BOUNTIES_DATA_ABORTED':
      return {
        ...state,
        reports: {
          ...state.reports,
          bounties: {
            ...state.reports.bounties,
            isLoading: false,
            errors: {
              ...state.reports.bounties.errors,
              hasError: false,
              code: '',
              message: '',
            },
          },
        },
      };

    case 'GET_GAME_BOUNTIES_DATA_SUCCESS':
      return {
        ...state,
        reports: {
          ...state.reports,
          bounties: {
            ...state.reports.bounties,
            data: action.payload.response.data,
            isLoading: false,
            errors: {
              ...state.reports.bounties.errors,
              hasError: false,
              code: '',
              message: '',
            },
          },
        },
      };

    case 'GET_GAMES_LIVE_STREAMS_PENDING':
      return {
        ...state,
        reports: {
          ...state.reports,
          liveStreams: {
            ...state.reports.liveStreams,
            game: {},
            isLoading: true,
            errors: {
              ...state.reports.liveStreams.errors,
              hasError: false,
              code: '',
              message: '',
            },
          },
        },
      };

    case 'GET_GAMES_LIVE_STREAMS_ERROR':
      return {
        ...state,
        reports: {
          ...state.reports,
          liveStreams: {
            ...state.reports.liveStreams,
            game: {},
            isLoading: false,
            errors: {
              ...state.reports.liveStreams.errors,
              hasError: action.payload.error,
              code: action.payload.code,
              message: action.payload.message,
              all: action.payload.errors,
            },
          },
        },
      };

    case 'GET_GAMES_LIVE_STREAMS_ABORTED':
      return {
        ...state,
        reports: {
          ...state.reports,
          liveStreams: {
            ...state.reports.liveStreams,
            game: {},
            isLoading: false,
            errors: {
              ...state.reports.liveStreams.errors,
              hasError: false,
              code: '',
              message: '',
            },
          },
        },
      };

    case 'GET_GAMES_LIVE_STREAMS_SUCCESS':
      return {
        ...state,
        reports: {
          ...state.reports,
          liveStreams: {
            ...state.reports.liveStreams,
            game: action.payload.response.game,
            data: action.payload.response.data,
            isLoading: false,
            errors: {
              ...state.reports.liveStreams.errors,
              hasError: false,
              code: '',
              message: '',
            },
          },
        },
      };

    case 'UPLOAD_CSV_PENDING':
      return {
        ...state,
        csvUpload: {
          ...state.csvUpload,
          isLoading: true,
          data: {
            processed: {},
            errors: {},
            creations: {},
          },
          errors: {
            ...state.csvUpload.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPLOAD_CSV_ERROR':
      return {
        ...state,
        csvUpload: {
          ...state.csvUpload,
          isLoading: false,
          data: {
            processed: {},
            errors: {},
            creations: {},
          },
          errors: {
            ...state.csvUpload.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'UPLOAD_CSV_ABORTED':
      return {
        ...state,
        csvUpload: {
          ...state.csvUpload,
          isLoading: false,
          data: {
            processed: {},
            errors: {},
            creations: {},
          },
          errors: {
            // We are placing here the csvUpload error because it's the only one this response
            ...state.csvUpload.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPLOAD_CSV_SUCCESS':
      return {
        ...state,
        csvUpload: {
          ...state.csvUpload,
          data: {
            processed: action.payload.response.processed,
            errors: action.payload.response.errors,
            creations: action.payload.response.creations,
          },
          isLoading: false,
          errors: {
            ...state.csvUpload.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_USER_LIST_PENDING':
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: true,
          errors: {
            ...state.users.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_USER_LIST_ERROR':
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: false,
          errors: {
            ...state.users.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_A_USER_LIST_ABORTED':
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: false,
          errors: {
            ...state.users.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_USER_LIST_SUCCESS':
      return {
        ...state,
        users: {
          ...state.users,
          data: action.payload.response.data.users,
          userQuests: action.payload.response.data.userQuests,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.users.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_USER_LIST_CSV_PENDING':
      return {
        ...state,
        usersCsv: {
          ...state.usersCsv,
          isLoading: true,
          errors: {
            ...state.usersCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_USER_LIST_CSV_ERROR':
      return {
        ...state,
        usersCsv: {
          ...state.usersCsv,
          isLoading: false,
          errors: {
            ...state.usersCsv.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_A_USER_LIST_CSV_ABORTED':
      return {
        ...state,
        usersCsv: {
          ...state.usersCsv,
          isLoading: false,
          errors: {
            ...state.usersCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_USER_LIST_CSV_SUCCESS':
      return {
        ...state,
        usersCsv: {
          ...state.usersCsv,
          data: action.payload.response.data.users,
          isLoading: false,
          errors: {
            ...state.usersCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_USER_PENDING':
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: true,
          errors: {
            ...state.user.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_USER_ERROR':
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: false,
          errors: {
            ...state.user.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_A_USER_ABORTED':
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: false,
          errors: {
            ...state.user.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_USER_SUCCESS':
      return {
        ...state,
        user: {
          ...state.user,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.user.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'CREATE_A_USER_BRAND_PENDING':
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: true,
          errors: {
            ...state.user.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'CREATE_A_USER_BRAND_ERROR':
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: false,
          errors: {
            ...state.user.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'CREATE_A_USER_BRAND_ABORTED':
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: false,
          errors: {
            ...state.user.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'CREATE_A_USER_BRAND_SUCCESS':
      return {
        ...state,
        user: {
          ...state.user,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.user.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'CREATE_A_USER_PENDING':
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: true,
          errors: {
            ...state.user.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'CREATE_A_USER_ERROR':
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: false,
          errors: {
            ...state.user.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'CREATE_A_USER_ABORTED':
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: false,
          errors: {
            ...state.user.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'CREATE_A_USER_SUCCESS':
      return {
        ...state,
        user: {
          ...state.user,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.user.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPDATE_A_USER_PENDING':
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: true,
          errors: {
            ...state.user.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPDATE_A_USER_ERROR':
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: false,
          errors: {
            ...state.user.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'UPDATE_A_USER_ABORTED':
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: false,
          errors: {
            ...state.user.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPDATE_A_USER_SUCCESS':
      return {
        ...state,
        user: {
          ...state.user,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.user.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_CONFIG_LIST_PENDING':
      return {
        ...state,
        configs: {
          ...state.configs,
          isLoading: true,
          errors: {
            ...state.configs.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_CONFIG_LIST_ERROR':
      return {
        ...state,
        configs: {
          ...state.configs,
          isLoading: false,
          errors: {
            ...state.configs.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_A_CONFIG_LIST_ABORTED':
      return {
        ...state,
        configs: {
          ...state.configs,
          isLoading: false,
          errors: {
            ...state.configs.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_CONFIG_LIST_SUCCESS':
      return {
        ...state,
        configs: {
          ...state.configs,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.configs.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_CONFIG_PENDING':
      return {
        ...state,
        config: {
          ...state.config,
          isLoading: true,
          errors: {
            ...state.config.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_CONFIG_ERROR':
      return {
        ...state,
        config: {
          ...state.config,
          isLoading: false,
          errors: {
            ...state.config.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_A_CONFIG_ABORTED':
      return {
        ...state,
        config: {
          ...state.config,
          isLoading: false,
          errors: {
            ...state.config.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_CONFIG_SUCCESS':
      return {
        ...state,
        config: {
          ...state.config,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.config.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'CREATE_A_CONFIG_PENDING':
      return {
        ...state,
        config: {
          ...state.config,
          isLoading: true,
          errors: {
            ...state.config.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'CREATE_A_CONFIG_ERROR':
      return {
        ...state,
        config: {
          ...state.config,
          isLoading: false,
          errors: {
            ...state.config.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'CREATE_A_CONFIG_ABORTED':
      return {
        ...state,
        config: {
          ...state.config,
          isLoading: false,
          errors: {
            ...state.config.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'CREATE_A_CONFIG_SUCCESS':
      return {
        ...state,
        config: {
          ...state.config,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.config.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPDATE_A_CONFIG_PENDING':
      return {
        ...state,
        config: {
          ...state.config,
          isLoading: true,
          errors: {
            ...state.config.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPDATE_A_CONFIG_ERROR':
      return {
        ...state,
        config: {
          ...state.config,
          isLoading: false,
          errors: {
            ...state.config.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'UPDATE_A_CONFIG_ABORTED':
      return {
        ...state,
        config: {
          ...state.config,
          isLoading: false,
          errors: {
            ...state.config.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPDATE_A_CONFIG_SUCCESS':
      return {
        ...state,
        config: {
          ...state.config,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.config.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_GAME_LIST_PENDING':
      return {
        ...state,
        games: {
          ...state.games,
          isLoading: true,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_GAME_LIST_ERROR':
      return {
        ...state,
        games: {
          ...state.games,
          isLoading: false,
          errors: {
            ...state.games.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_A_GAME_LIST_ABORTED':
      return {
        ...state,
        games: {
          ...state.games,
          isLoading: false,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_GAME_LIST_SUCCESS':
      return {
        ...state,
        games: {
          ...state.games,
          data: action.payload.response.data.games,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.games.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_TRACKED_GAMES_PENDING':
      return {
        ...state,
        trackedGames: {
          ...state.trackedGames,
          isLoading: true,
          errors: {
            ...state.trackedGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_TRACKED_GAMES_ERROR':
      return {
        ...state,
        trackedGames: {
          ...state.trackedGames,
          isLoading: false,
          errors: {
            ...state.trackedGames.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_A_TRACKED_GAMES_ABORTED':
      return {
        ...state,
        trackedGames: {
          ...state.trackedGames,
          isLoading: false,
          errors: {
            ...state.trackedGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_TRACKED_GAMES_SUCCESS':
      return {
        ...state,
        trackedGames: {
          ...state.trackedGames,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.trackedGames.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_GAME_PENDING':
      return {
        ...state,
        game: {
          ...state.game,
          isLoading: true,
          errors: {
            ...state.game.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_GAME_ERROR':
      return {
        ...state,
        game: {
          ...state.game,
          isLoading: false,
          errors: {
            ...state.game.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_A_GAME_ABORTED':
      return {
        ...state,
        game: {
          ...state.game,
          isLoading: false,
          errors: {
            ...state.game.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_GAME_SUCCESS':
      return {
        ...state,
        game: {
          ...state.game,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.game.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'CREATE_A_GAME_PENDING':
      return {
        ...state,
        game: {
          ...state.game,
          isCreating: true,
          isLoading: true,
          errors: {
            ...state.game.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'CREATE_A_GAME_ERROR':
      return {
        ...state,
        game: {
          ...state.game,
          isCreating: false,
          isLoading: false,
          errors: {
            ...state.game.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'CREATE_A_GAME_ABORTED':
      return {
        ...state,
        game: {
          ...state.game,
          isCreating: false,
          isLoading: false,
          errors: {
            ...state.game.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'CREATE_A_GAME_SUCCESS':
      return {
        ...state,
        game: {
          ...state.game,
          data: action.payload.response.game,
          isCreating: false,
          isLoading: false,
          errors: {
            ...state.game.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPDATE_A_GAME_PENDING':
      return {
        ...state,
        game: {
          ...state.game,
          isUpdating: true,
          isLoading: true,
          errors: {
            ...state.game.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPDATE_A_GAME_ERROR':
      return {
        ...state,
        game: {
          ...state.game,
          isUpdating: false,
          isLoading: false,
          errors: {
            ...state.game.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'UPDATE_A_GAME_ABORTED':
      return {
        ...state,
        game: {
          ...state.game,
          isUpdating: false,
          isLoading: false,
          errors: {
            ...state.game.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPDATE_A_GAME_SUCCESS':
      return {
        ...state,
        game: {
          ...state.game,
          data: action.payload.response.game,
          isUpdating: false,
          isLoading: false,
          errors: {
            ...state.game.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'CREATE_A_PLATFORM_PENDING':
      return {
        ...state,
        platforms: {
          ...state.platforms,
          isLoading: true,
          errors: {
            ...state.platforms.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'CREATE_A_PLATFORM_ERROR':
      return {
        ...state,
        platforms: {
          ...state.platforms,
          isLoading: false,
          errors: {
            ...state.platforms.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'CREATE_A_PLATFORM_ABORTED':
      return {
        ...state,
        platforms: {
          ...state.platforms,
          isLoading: false,
          errors: {
            ...state.platforms.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'CREATE_A_PLATFORM_SUCCESS':
      return {
        ...state,
        platforms: {
          ...state.platforms,
          data: [...state.platforms.data, action.payload.response.platform],
          isLoading: false,
          errors: {
            ...state.platforms.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_ALL_PLATFORMS_PENDING':
      return {
        ...state,
        platforms: {
          ...state.platforms,
          isLoading: true,
          errors: {
            ...state.platforms.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_ALL_PLATFORMS_ERROR':
      return {
        ...state,
        platforms: {
          ...state.platforms,
          isLoading: false,
          errors: {
            ...state.platforms.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_ALL_PLATFORMS_ABORTED':
      return {
        ...state,
        platforms: {
          ...state.platforms,
          isLoading: false,
          errors: {
            ...state.platforms.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_ALL_PLATFORMS_SUCCESS':
      return {
        ...state,
        platforms: {
          ...state.platforms,
          data: action.payload.response.platforms,
          isLoading: false,
          errors: {
            ...state.platforms.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'REQUEST_UPLOAD_IMAGE_URL_PENDING':
      return {
        ...state,
        preSignedUrl: {
          ...state.preSignedUrl,
          isLoading: true,
          errors: {
            ...state.preSignedUrl.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'REQUEST_UPLOAD_IMAGE_URL_ERROR':
      return {
        ...state,
        preSignedUrl: {
          ...state.preSignedUrl,
          isLoading: false,
          errors: {
            ...state.preSignedUrl.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'REQUEST_UPLOAD_IMAGE_URL_ABORTED':
      return {
        ...state,
        preSignedUrl: {
          ...state.preSignedUrl,
          isLoading: false,
          errors: {
            ...state.preSignedUrl.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'REQUEST_UPLOAD_IMAGE_URL_SUCCESS':
      return {
        ...state,
        preSignedUrl: {
          ...state.preSignedUrl,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.preSignedUrl.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_CAMPAIGN_QUESTS_REPORT_PENDING':
      return {
        ...state,
        tieredReports: {
          ...state.tieredReports,
          isLoading: true,
          errors: {
            ...state.tieredReports.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        referralReports: {
          ...state.referralReports,
          isLoading: true,
          errors: {
            ...state.referralReports.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_CAMPAIGN_QUESTS_REPORT_ERROR':
      return {
        ...state,
        tieredReports: {
          ...state.tieredReports,
          isLoading: false,
          errors: {
            ...state.tieredReports.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
        referralReports: {
          ...state.referralReports,
          isLoading: false,
          errors: {
            ...state.referralReports.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_A_CAMPAIGN_QUESTS_REPORT_ABORTED':
      return {
        ...state,
        tieredReports: {
          ...state.tieredReports,
          isLoading: false,
          errors: {
            ...state.tieredReports.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        referralReports: {
          ...state.referralReports,
          isLoading: false,
          errors: {
            ...state.referralReports.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_CAMPAIGN_QUESTS_REPORT_SUCCESS':
      return {
        ...state,
        tieredReports: {
          ...state.tieredReports,
          data: action.payload.response.tieredReports,
          isLoading: false,
          errors: {
            ...state.tieredReports.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
        referralReports: {
          ...state.referralReports,
          data: action.payload.response.referralReports,
          isLoading: false,
          errors: {
            ...state.referralReports.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_PAYMENT_LIST_PENDING':
      return {
        ...state,
        payments: {
          ...state.payments,
          isLoading: true,
          errors: {
            ...state.payments.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_PAYMENT_LIST_ERROR':
      return {
        ...state,
        payments: {
          ...state.payments,
          isLoading: false,
          errors: {
            ...state.payments.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_A_PAYMENT_LIST_ABORTED':
      return {
        ...state,
        payments: {
          ...state.payments,
          isLoading: false,
          errors: {
            ...state.payments.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_PAYMENT_LIST_SUCCESS':
      return {
        ...state,
        payments: {
          ...state.payments,
          data: action.payload.response.data.payments,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.payments.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_PAYMENT_LIST_CSV_PENDING':
      return {
        ...state,
        paymentsCsv: {
          ...state.paymentsCsv,
          isLoading: true,
          errors: {
            ...state.paymentsCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_PAYMENT_LIST_CSV_ERROR':
      return {
        ...state,
        paymentsCsv: {
          ...state.paymentsCsv,
          isLoading: false,
          errors: {
            ...state.paymentsCsv.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_A_PAYMENT_LIST_CSV_ABORTED':
      return {
        ...state,
        paymentsCsv: {
          ...state.paymentsCsv,
          isLoading: false,
          errors: {
            ...state.paymentsCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_PAYMENT_LIST_CSV_SUCCESS':
      return {
        ...state,
        paymentsCsv: {
          ...state.paymentsCsv,
          data: action.payload.response.data.payments,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.paymentsCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_PAYPAL_PAYMENTS_PENDING':
      return {
        ...state,
        paypalPayments: {
          ...state.paypalPayments,
          isLoading: true,
          errors: {
            ...state.paypalPayments.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_PAYPAL_PAYMENTS_ERROR':
      return {
        ...state,
        paypalPayments: {
          ...state.paypalPayments,
          isLoading: false,
          errors: {
            ...state.paypalPayments.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_PAYPAL_PAYMENTS_ABORTED':
      return {
        ...state,
        paypalPayments: {
          ...state.paypalPayments,
          isLoading: false,
          errors: {
            ...state.paypalPayments.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_PAYPAL_PAYMENTS_SUCCESS':
      return {
        ...state,
        paypalPayments: {
          ...state.paypalPayments,
          data: action.payload.response.data.paypalPayments,
          // pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.paypalPayments.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_QUEST_LIST_PENDING':
      return {
        ...state,
        quests: {
          ...state.quests,
          isLoading: true,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_QUEST_LIST_ERROR':
      return {
        ...state,
        quests: {
          ...state.quests,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_A_QUEST_LIST_ABORTED':
      return {
        ...state,
        quests: {
          ...state.quests,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_A_QUEST_LIST_SUCCESS':
      return {
        ...state,
        quests: {
          ...state.quests,
          data: action.payload.response.quests,
          isLoading: false,
          errors: {
            ...state.quests.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_INSIGHTS_DATA_PENDING':
      return {
        ...state,
        insightsData: {
          ...state.insightsData,
          isLoading: true,
          errors: {
            ...state.insightsData.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_INSIGHTS_DATA_ERROR':
      return {
        ...state,
        insightsData: {
          ...state.insightsData,
          isLoading: false,
          errors: {
            ...state.insightsData.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_INSIGHTS_DATA_ABORTED':
      return {
        ...state,
        insightsData: {
          ...state.insightsData,
          isLoading: false,
          errors: {
            ...state.insightsData.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_INSIGHTS_DATA_SUCCESS':
      return {
        ...state,
        insightsData: {
          ...state.insightsData,
          graphData: action.payload.response.insightsData.graphData,
          userData: action.payload.response.insightsData.userData,
          regionData: action.payload.response.insightsData.regionData,
          totalReach: action.payload.response.insightsData.totalReach,
          averageHistorical: action.payload.response.insightsData.averageHistorical,
          isLoading: false,
          errors: {
            ...state.insightsData.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_INSIGHTS_OPTIONS_PENDING':
      return {
        ...state,
        insightsOptions: {
          ...state.insightsOptions,
          isLoading: true,
          errors: {
            ...state.insightsOptions.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_INSIGHTS_OPTIONS_ERROR':
      return {
        ...state,
        insightsOptions: {
          ...state.insightsOptions,
          isLoading: false,
          errors: {
            ...state.insightsOptions.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_INSIGHTS_OPTIONS_ABORTED':
      return {
        ...state,
        insightsOptions: {
          ...state.insightsOptions,
          isLoading: false,
          errors: {
            ...state.insightsOptions.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_INSIGHTS_OPTIONS_SUCCESS':
      return {
        ...state,
        insightsOptions: {
          ...state.insightsOptions,
          options: action.payload.response.options,
          isLoading: false,
          errors: {
            ...state.insightsOptions.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'CREATE_OFF_PLATFORM_PENDING':
      return {
        ...state,
        offPlatform: {
          ...state.offPlatform,
          isLoading: true,
          user: {},
          userCreated: false,
          errors: {
            ...state.offPlatform.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'CREATE_OFF_PLATFORM_ERROR':
      return {
        ...state,
        offPlatform: {
          ...state.offPlatform,
          isLoading: false,
          user: {},
          userCreated: false,
          errors: {
            ...state.offPlatform.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'CREATE_OFF_PLATFORM_ABORTED':
      return {
        ...state,
        offPlatform: {
          ...state.offPlatform,
          isLoading: false,
          user: {},
          userCreated: false,
          errors: {
            ...state.offPlatform.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'CREATE_OFF_PLATFORM_SUCCESS':
      return {
        ...state,
        offPlatform: {
          ...state.offPlatform,
          user: action.payload.response.user,
          userCreated: action.payload.response.userCreated,
          isLoading: false,
          errors: {
            ...state.offPlatform.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_USER_ROLL_OUT_LIST_SUCCESS':
      return {
        ...state,
        rollOuts: {
          ...state.rollOuts,
          data: action.payload.response.data.records,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          startDate:action.payload.response.data.startDate,
          endDate:action.payload.response.data.endDate,
          errors: {
            ...state.rollOuts.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_USER_ROLL_OUT_LIST_PENDING':
      return {
        ...state,
        rollOuts: {
          ...state.rollOuts,
          isLoading: true,
          errors: {
            ...state.rollOuts.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_USER_ROLL_OUT_LIST_ERROR':
      return {
        ...state,
        rollOuts: {
          ...state.rollOuts,
          isLoading: false,
          errors: {
            ...state.rollOuts.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_USER_ROLL_OUT_LIST_ABORTED':
      return {
        ...state,
        rollOuts: {
          ...state.rollOuts,
          isLoading: false,
          errors: {
            ...state.rollOuts.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_USER_ROLL_OUT_CSV_PENDING':
      return {
        ...state,
        rollOutsCsv: {
          ...state.rollOutsCsv,
          isLoading: true,
          errors: {
            ...state.rollOutsCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_USER_ROLL_OUT_CSV_ERROR':
      return {
        ...state,
        rollOutsCsv: {
          ...state.rollOutsCsv,
          isLoading: false,
          errors: {
            ...state.rollOutsCsv.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_USER_ROLL_OUT_CSV_ABORTED':
      return {
        ...state,
        rollOutsCsv: {
          ...state.rollOutsCsv,
          isLoading: false,
          errors: {
            ...state.rollOutsCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_USER_ROLL_OUT_CSV_SUCCESS':
      return {
        ...state,
        rollOutsCsv: {
          ...state.rollOutsCsv,
          data: action.payload.response.data.records,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.rollOutsCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'APPROVE_ROLL_OUT_PENDING':
      return {
        ...state,
        approve: {
          ...state.approve,
          isLoading: true,
          errors: {
            ...state.approve.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'APPROVE_ROLL_OUT_ERROR':
      return {
        ...state,
        approve: {
          ...state.approve,
          isLoading: false,
          errors: {
            ...state.approve.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'APPROVE_ROLL_OUT_ABORTED':
      return {
        ...state,
        approve: {
          ...state.approve,
          isLoading: false,
          errors: {
            ...state.approve.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'APPROVE_ROLL_OUT_SUCCESS':
      return {
        ...state,
        approve: {
          ...state.approve,
          isSucees: true,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.approve.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_USER_ROLL_OUT_LIST_ADMIN_SUCCESS':
      return {
        ...state,
        rollOutAdmin: {
          ...state.rollOutAdmin,
          data: action.payload.response.data.records,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.rollOutAdmin.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_USER_ROLL_OUT_LIST_ADMIN_PENDING':
      return {
        ...state,
        rollOutAdmin: {
          ...state.rollOutAdmin,
          isLoading: true,
          errors: {
            ...state.rollOutAdmin.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_USER_ROLL_OUT_LIST_ADMIN_ERROR':
      return {
        ...state,
        rollOutAdmin: {
          ...state.rollOutAdmin,
          isLoading: false,
          errors: {
            ...state.rollOutAdmin.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_USER_ROLL_OUT_LIST_ADMIN_ABORTED':
      return {
        ...state,
        rollOutAdmin: {
          ...state.rollOutAdmin,
          isLoading: false,
          errors: {
            ...state.rollOutAdmin.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_USER_ROLL_IN_LIST_ADMIN_SUCCESS':
      return {
        ...state,
        rollInAdmin: {
          ...state.rollInAdmin,
          data: action.payload.response.data.records,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.rollInAdmin.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_USER_ROLL_IN_LIST_ADMIN_PENDING':
      return {
        ...state,
        rollInAdmin: {
          ...state.rollInAdmin,
          isLoading: true,
          errors: {
            ...state.rollInAdmin.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_USER_ROLL_IN_LIST_ADMIN_ERROR':
      return {
        ...state,
        rollInAdmin: {
          ...state.rollInAdmin,
          isLoading: false,
          errors: {
            ...state.rollInAdmin.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_USER_ROLL_IN_LIST_ADMIN_ABORTED':
      return {
        ...state,
        rollInAdmin: {
          ...state.rollInAdmin,
          isLoading: false,
          errors: {
            ...state.rollInAdmin.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_USER_ROLL_IN_CSV_SUCCESS':
      return {
        ...state,
        rollInsCsv: {
          ...state.rollInsCsv,
          data: action.payload.response.data.records,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.rollInsCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_USER_ROLL_IN_CSV_PENDING':
      return {
        ...state,
        rollInsCsv: {
          ...state.rollInsCsv,
          isLoading: true,
          errors: {
            ...state.rollInsCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_USER_ROLL_IN_CSV_ERROR':
      return {
        ...state,
        rollInsCsv: {
          ...state.rollInsCsv,
          isLoading: false,
          errors: {
            ...state.rollInsCsv.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_USER_ROLL_IN_CSV_ABORTED':
      return {
        ...state,
        rollInsCsv: {
          ...state.rollInsCsv,
          isLoading: false,
          errors: {
            ...state.rollInsCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_USER_ROLL_OUT_ALL_PENDING':
      return {
        ...state,
        rollOutsAll: {
          ...state.rollOutsAll,
          isLoading: true,
          errors: {
            ...state.rollOutsAll.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_USER_ROLL_OUT_ALL_ERROR':
      return {
        ...state,
        rollOutsAll: {
          ...state.rollOutsAll,
          isLoading: false,
          errors: {
            ...state.rollOutsAll.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_USER_ROLL_OUT_ALL_ABORTED':
      return {
        ...state,
        rollOutsAll: {
          ...state.rollOutsAll,
          isLoading: false,
          errors: {
            ...state.rollOutsAll.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_USER_ROLL_OUT_ALL_SUCCESS':
      return {
        ...state,
        rollOutsAll: {
          ...state.rollOutsAll,
          data: action.payload.response.data.records,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          startDate:action.payload.response.data.startDate,
          endDate:action.payload.response.data.endDate,
          errors: {
            ...state.rollOutsAll.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_ROLL_OUT_PAY_ORDER_PENDING':
      return {
        ...state,
        orderLink: {
          ...state.orderLink,
          isLoading: true,
          errors: {
            ...state.orderLink.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_ROLL_OUT_PAY_ORDER_ERROR':
      return {
        ...state,
        orderLink: {
          ...state.orderLink,
          isLoading: false,
          errors: {
            ...state.orderLink.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_ROLL_OUT_PAY_ORDER_ABORTED':
      return {
        ...state,
        orderLink: {
          ...state.orderLink,
          isLoading: false,
          errors: {
            ...state.orderLink.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_ROLL_OUT_PAY_ORDER_SUCCESS':
      return {
        ...state,
        orderLink: {
          ...state.orderLink,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.orderLink.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_QUEST_LOG_SUCCESS':
      return {
        ...state,
        logQuest: {
          ...state.logQuest,
          data: action.payload.response.data.records,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.logQuest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_QUEST_LOG_PENDING':
      return {
        ...state,
        logQuest: {
          ...state.logQuest,
          isLoading: true,
          errors: {
            ...state.logQuest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_QUEST_LOG_ERROR':
      return {
        ...state,
        logQuest: {
          ...state.logQuest,
          isLoading: false,
          errors: {
            ...state.logQuest.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_QUEST_LOG_ABORTED':
      return {
        ...state,
        logQuest: {
          ...state.logQuest,
          isLoading: false,
          errors: {
            ...state.logQuest.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_QUEST_LOG_CSV_SUCCESS':
      return {
        ...state,
        logQuestCsv: {
          ...state.logQuestCsv,
          data: action.payload.response.data.records,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.logQuestCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_QUEST_LOG_CSV_PENDING':
      return {
        ...state,
        logQuestCsv: {
          ...state.logQuestCsv,
          isLoading: true,
          errors: {
            ...state.logQuestCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_QUEST_LOG_CSV_ERROR':
      return {
        ...state,
        logQuestCsv: {
          ...state.logQuestCsv,
          isLoading: false,
          errors: {
            ...state.logQuestCsv.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_QUEST_LOG_CSV_ABORTED':
      return {
        ...state,
        logQuestCsv: {
          ...state.logQuestCsv,
          isLoading: false,
          errors: {
            ...state.logQuestCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_LOG_SUMMARY_SUCCESS':
      return {
        ...state,
        logSummary: {
          ...state.logSummary,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.logSummary.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_LOG_SUMMARY_PENDING':
      return {
        ...state,
        logSummary: {
          ...state.logSummary,
          isLoading: true,
          errors: {
            ...state.logSummary.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_LOG_SUMMARY_ERROR':
      return {
        ...state,
        logSummary: {
          ...state.logSummary,
          isLoading: false,
          errors: {
            ...state.logSummary.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_LOG_SUMMARY_ABORTED':
      return {
        ...state,
        logSummary: {
          ...state.logSummary,
          isLoading: false,
          errors: {
            ...state.logSummary.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_PROFILE_LOG_SUCCESS':
      return {
        ...state,
        logProfile: {
          ...state.logProfile,
          data: action.payload.response.data.records,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.logProfile.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_PROFILE_LOG_PENDING':
      return {
        ...state,
        logProfile: {
          ...state.logProfile,
          isLoading: true,
          errors: {
            ...state.logProfile.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_PROFILE_LOG_ERROR':
      return {
        ...state,
        logProfile: {
          ...state.logProfile,
          isLoading: false,
          errors: {
            ...state.logProfile.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_PROFILE_LOG_ABORTED':
      return {
        ...state,
        logProfile: {
          ...state.logProfile,
          isLoading: false,
          errors: {
            ...state.logProfile.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_PROFILE_LOG_CSV_SUCCESS':
      return {
        ...state,
        logProfileCsv: {
          ...state.logProfileCsv,
          data: action.payload.response.data.records,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.logProfileCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_PROFILE_LOG_CSV_PENDING':
      return {
        ...state,
        logProfileCsv: {
          ...state.logProfileCsv,
          isLoading: true,
          errors: {
            ...state.logProfileCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_PROFILE_LOG_CSV_ERROR':
      return {
        ...state,
        logProfileCsv: {
          ...state.logProfileCsv,
          isLoading: false,
          errors: {
            ...state.logProfileCsv.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_PROFILE_LOG_CSV_ABORTED':
      return {
        ...state,
        logProfileCsv: {
          ...state.logProfileCsv,
          isLoading: false,
          errors: {
            ...state.logProfileCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_WALLET_LOG_SUCCESS':
      return {
        ...state,
        logWallet: {
          ...state.logWallet,
          data: action.payload.response.data.records,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.logWallet.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_WALLET_LOG_PENDING':
      return {
        ...state,
        logWallet: {
          ...state.logWallet,
          isLoading: true,
          errors: {
            ...state.logWallet.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_WALLET_LOG_ERROR':
      return {
        ...state,
        logWallet: {
          ...state.logWallet,
          isLoading: false,
          errors: {
            ...state.logWallet.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_WALLET_LOG_ABORTED':
      return {
        ...state,
        logWallet: {
          ...state.logWallet,
          isLoading: false,
          errors: {
            ...state.logWallet.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_WALLET_LOG_CSV_SUCCESS':
      return {
        ...state,
        logWalletCsv: {
          ...state.logWalletCsv,
          data: action.payload.response.data.records,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.logWalletCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_WALLET_LOG_CSV_PENDING':
      return {
        ...state,
        logWalletCsv: {
          ...state.logWalletCsv,
          isLoading: true,
          errors: {
            ...state.logWalletCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_WALLET_LOG_CSV_ERROR':
      return {
        ...state,
        logWalletCsv: {
          ...state.logWalletCsv,
          isLoading: false,
          errors: {
            ...state.logWalletCsv.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_WALLET_LOG_CSV_ABORTED':
      return {
        ...state,
        logWalletCsv: {
          ...state.logWalletCsv,
          isLoading: false,
          errors: {
            ...state.logWalletCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_CDKEY_LOG_SUCCESS':
      return {
        ...state,
        logCdkey: {
          ...state.logCdkey,
          data: action.payload.response.data.records,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.logCdkey.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_CDKEY_LOG_PENDING':
      return {
        ...state,
        logCdkey: {
          ...state.logCdkey,
          isLoading: true,
          errors: {
            ...state.logCdkey.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_CDKEY_LOG_ERROR':
      return {
        ...state,
        logCdkey: {
          ...state.logCdkey,
          isLoading: false,
          errors: {
            ...state.logCdkey.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_CDKEY_LOG_ABORTED':
      return {
        ...state,
        logCdkey: {
          ...state.logCdkey,
          isLoading: false,
          errors: {
            ...state.logCdkey.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CDKEY_LOG_CSV_SUCCESS':
      return {
        ...state,
        logCdkeyCsv: {
          ...state.logCdkeyCsv,
          data: action.payload.response.data.records,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.logCdkeyCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_CDKEY_LOG_CSV_PENDING':
      return {
        ...state,
        logCdkeyCsv: {
          ...state.logCdkeyCsv,
          isLoading: true,
          errors: {
            ...state.logCdkeyCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_CDKEY_LOG_CSV_ERROR':
      return {
        ...state,
        logCdkeyCsv: {
          ...state.logCdkeyCsv,
          isLoading: false,
          errors: {
            ...state.logCdkeyCsv.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_CDKEY_LOG_CSV_ABORTED':
      return {
        ...state,
        logCdkeyCsv: {
          ...state.logCdkeyCsv,
          isLoading: false,
          errors: {
            ...state.logCdkeyCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    //----------------------logdailytask start---------------------

    case 'GET_DAILY_TASK_LOG_SUCCESS':
      return {
        ...state,
        logDailyTask: {
          ...state.logDailyTask,
          data: action.payload.response.data.records,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.logDailyTask.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_DAILY_TASK_LOG_PENDING':
      return {
        ...state,
        logDailyTask: {
          ...state.logDailyTask,
          isLoading: true,
          errors: {
            ...state.logDailyTask.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_DAILY_TASK_LOG_ERROR':
      return {
        ...state,
        logDailyTask: {
          ...state.logDailyTask,
          isLoading: false,
          errors: {
            ...state.logDailyTask.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_DAILY_TASK_LOG_ABORTED':
      return {
        ...state,
        logDailyTask: {
          ...state.logDailyTask,
          isLoading: false,
          errors: {
            ...state.logDailyTask.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_DAILY_TASK_LOG_CSV_SUCCESS':
      return {
        ...state,
        logDailyTaskCsv: {
          ...state.logDailyTaskCsv,
          data: action.payload.response.data.records,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.logDailyTaskCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_DAILY_TASK_LOG_CSV_PENDING':
      return {
        ...state,
        logDailyTaskCsv: {
          ...state.logDailyTaskCsv,
          isLoading: true,
          errors: {
            ...state.logDailyTaskCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_DAILY_TASK_LOG_CSV_ERROR':
      return {
        ...state,
        logDailyTaskCsv: {
          ...state.logDailyTaskCsv,
          isLoading: false,
          errors: {
            ...state.logDailyTaskCsv.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_DAILY_TASK_LOG_CSV_ABORTED':
      return {
        ...state,
        logDailyTaskCsv: {
          ...state.logDailyTaskCsv,
          isLoading: false,
          errors: {
            ...state.logDailyTaskCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    //----------------------logdailytask end-----------------------

    //----------------------channel start--------------------------

    case 'GET_CHANNEL_LOG_SUCCESS':
      return {
        ...state,
        logChannel: {
          ...state.logChannel,
          data: action.payload.response.data.records,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.logChannel.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_CHANNEL_LOG_PENDING':
      return {
        ...state,
        logChannel: {
          ...state.logChannel,
          isLoading: true,
          errors: {
            ...state.logChannel.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_CHANNEL_LOG_ERROR':
      return {
        ...state,
        logChannel: {
          ...state.logChannel,
          isLoading: false,
          errors: {
            ...state.logChannel.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_CHANNEL_LOG_ABORTED':
      return {
        ...state,
        logChannel: {
          ...state.logChannel,
          isLoading: false,
          errors: {
            ...state.logChannel.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CHANNEL_LOG_CSV_SUCCESS':
      return {
        ...state,
        logChannelCsv: {
          ...state.logChannelCsv,
          data: action.payload.response.data.records,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.logChannelCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_CHANNEL_LOG_CSV_PENDING':
      return {
        ...state,
        logChannelCsv: {
          ...state.logChannelCsv,
          isLoading: true,
          errors: {
            ...state.logChannelCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_CHANNEL_LOG_CSV_ERROR':
      return {
        ...state,
        logChannelCsv: {
          ...state.logChannelCsv,
          isLoading: false,
          errors: {
            ...state.logChannelCsv.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_CHANNEL_LOG_CSV_ABORTED':
      return {
        ...state,
        logChannelCsv: {
          ...state.logChannelCsv,
          isLoading: false,
          errors: {
            ...state.logChannelCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    //----------------------channel end----------------------------

    //----------------------other start----------------------------

    case 'GET_OTHER_LOG_SUCCESS':
      return {
        ...state,
        logOther: {
          ...state.logOther,
          data: action.payload.response.data.records,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.logOther.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_OTHER_LOG_PENDING':
      return {
        ...state,
        logOther: {
          ...state.logOther,
          isLoading: true,
          errors: {
            ...state.logOther.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_OTHER_LOG_ERROR':
      return {
        ...state,
        logOther: {
          ...state.logOther,
          isLoading: false,
          errors: {
            ...state.logOther.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_OTHER_LOG_ABORTED':
      return {
        ...state,
        logOther: {
          ...state.logOther,
          isLoading: false,
          errors: {
            ...state.logOther.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_OTHER_LOG_CSV_SUCCESS':
      return {
        ...state,
        logOtherCsv: {
          ...state.logOtherCsv,
          data: action.payload.response.data.records,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.logOtherCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_OTHER_LOG_CSV_PENDING':
      return {
        ...state,
        logOtherCsv: {
          ...state.logOtherCsv,
          isLoading: true,
          errors: {
            ...state.logOtherCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_OTHER_LOG_CSV_ERROR':
      return {
        ...state,
        logOtherCsv: {
          ...state.logOtherCsv,
          isLoading: false,
          errors: {
            ...state.logOtherCsv.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_OTHER_LOG_CSV_ABORTED':
      return {
        ...state,
        logOtherCsv: {
          ...state.logOtherCsv,
          isLoading: false,
          errors: {
            ...state.logOtherCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    //----------------------other end------------------------------

    case 'CREATE_REGION_PENDING':
      return {
        ...state,
        region: {
          ...state.region,
          isLoading: true,
          errors: {
            ...state.region.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'CREATE_REGION_ERROR':
      return {
        ...state,
        region: {
          ...state.region,
          isLoading: false,
          errors: {
            ...state.region.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'CREATE_REGION_ABORTED':
      return {
        ...state,
        region: {
          ...state.region,
          isLoading: false,
          errors: {
            ...state.region.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'CREATE_REGION_SUCCESS':
      return {
        ...state,
        region: {
          ...state.region,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.region.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_REGIONS_SUCCESS':
      return {
        ...state,
        regions: {
          ...state.regions,
          data: action.payload.response.data.records,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.regions.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_REGIONS_PENDING':
      return {
        ...state,
        regions: {
          ...state.regions,
          isLoading: true,
          errors: {
            ...state.regions.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_REGIONS_ERROR':
      return {
        ...state,
        regions: {
          ...state.regions,
          isLoading: false,
          errors: {
            ...state.regions.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_REGIONS_ABORTED':
      return {
        ...state,
        regions: {
          ...state.regions,
          isLoading: false,
          errors: {
            ...state.regions.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'CREATE_REGIONS_PENDING':
      return {
        ...state,
        regionBatch: {
          ...state.regionBatch,
          isLoading: true,
          errors: {
            ...state.regionBatch.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'CREATE_REGIONS_ERROR':
      return {
        ...state,
        regionBatch: {
          ...state.regionBatch,
          isLoading: false,
          errors: {
            ...state.regionBatch.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'CREATE_REGIONS_ABORTED':
      return {
        ...state,
        regionBatch: {
          ...state.regionBatch,
          isLoading: false,
          errors: {
            ...state.regionBatch.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'CREATE_REGIONS_SUCCESS':
      return {
        ...state,
        regionBatch: {
          ...state.regionBatch,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.regionBatch.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPDATE_REGION_PENDING':
      return {
        ...state,
        region_m: {
          ...state.region_m,
          isLoading: true,
          errors: {
            ...state.region.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPDATE_REGION_ERROR':
      return {
        ...state,
        region_m: {
          ...state.region_m,
          isLoading: false,
          errors: {
            ...state.region.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'UPDATE_REGION_ABORTED':
      return {
        ...state,
        region_m: {
          ...state.region_m,
          isLoading: false,
          errors: {
            ...state.region.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPDATE_REGION_SUCCESS':
      return {
        ...state,
        region_m: {
          ...state.region_m,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.region.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPDATE_REGIONS_PENDING':
      return {
        ...state,
        region_ms: {
          ...state.region_ms,
          isLoading: true,
          errors: {
            ...state.region.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPDATE_REGIONS_ERROR':
      return {
        ...state,
        region_ms: {
          ...state.region_ms,
          isLoading: false,
          errors: {
            ...state.region.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'UPDATE_REGIONS_ABORTED':
      return {
        ...state,
        region_ms: {
          ...state.region_ms,
          isLoading: false,
          errors: {
            ...state.region.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPDATE_REGIONS_SUCCESS':
      return {
        ...state,
        region_ms: {
          ...state.region_ms,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.region.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CDK_ORDERS_LIST_SUCCESS':
      return {
        ...state,
        cdkOrders: {
          ...state.cdkOrders,
          data: action.payload.response.data.records,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.cdkOrders.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_CDK_ORDERS_LIST_PENDING':
      return {
        ...state,
        cdkOrders: {
          ...state.cdkOrders,
          isLoading: true,
          errors: {
            ...state.cdkOrders.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_CDK_ORDERS_LIST_ERROR':
      return {
        ...state,
        cdkOrders: {
          ...state.cdkOrders,
          isLoading: false,
          errors: {
            ...state.cdkOrders.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_CDK_ORDERS_LIST_ABORTED':
      return {
        ...state,
        cdkOrders: {
          ...state.cdkOrders,
          isLoading: false,
          errors: {
            ...state.cdkOrders.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_CDK_ORDERS_CSV_SUCCESS':
      return {
        ...state,
        cdkOrdersCsv: {
          ...state.cdkOrdersCsv,
          data: action.payload.response.data.records,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.cdkOrdersCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_CDK_ORDERS_CSV_PENDING':
      return {
        ...state,
        cdkOrdersCsv: {
          ...state.cdkOrdersCsv,
          isLoading: true,
          errors: {
            ...state.cdkOrdersCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_CDK_ORDERS_CSV_ERROR':
      return {
        ...state,
        cdkOrdersCsv: {
          ...state.cdkOrdersCsv,
          isLoading: false,
          errors: {
            ...state.cdkOrdersCsv.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_CDK_ORDERS_CSV_ABORTED':
      return {
        ...state,
        cdkOrdersCsv: {
          ...state.cdkOrdersCsv,
          isLoading: false,
          errors: {
            ...state.cdkOrdersCsv.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_PERMISSIONS_SUCCESS':
      return {
        ...state,
        permissions: {
          ...state.permissions,
          data: action.payload.response.data.records,
          permissions: action.payload.response.data.permissions,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.permissions.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_PERMISSIONS_PENDING':
      return {
        ...state,
        permissions: {
          ...state.permissions,
          isLoading: true,
          errors: {
            ...state.permissions.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_PERMISSIONS_ERROR':
      return {
        ...state,
        permissions: {
          ...state.permissions,
          isLoading: false,
          errors: {
            ...state.permissions.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_PERMISSIONS_ABORTED':
      return {
        ...state,
        permissions: {
          ...state.permissions,
          isLoading: false,
          errors: {
            ...state.permissions.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'UPDATE_PERMISSIONS_PENDING':
      return {
        ...state,
        permission_ms: {
          ...state.permission_ms,
          isLoading: true,
          errors: {
            ...state.permission_ms.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPDATE_PERMISSIONS_ERROR':
      return {
        ...state,
        permission_ms: {
          ...state.permission_ms,
          isLoading: false,
          errors: {
            ...state.permission_ms.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'UPDATE_PERMISSIONS_ABORTED':
      return {
        ...state,
        permission_ms: {
          ...state.permission_ms,
          isLoading: false,
          errors: {
            ...state.permission_ms.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPDATE_PERMISSIONS_SUCCESS':
      return {
        ...state,
        permission_ms: {
          ...state.permission_ms,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.permission_ms.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    case 'GET_ADMINS_SUCCESS':
      return {
        ...state,
        admins: {
          ...state.admins,
          data: action.payload.response.data.records,
          roles:action.payload.response.data.roles,
          regions:action.payload.response.data.regions,
          pagination: action.payload.response.data.pagination,
          isLoading: false,
          errors: {
            ...state.admins.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_ADMINS_PENDING':
      return {
        ...state,
        admins: {
          ...state.admins,
          isLoading: true,
          errors: {
            ...state.admins.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'GET_ADMINS_ERROR':
      return {
        ...state,
        admins: {
          ...state.admins,
          isLoading: false,
          errors: {
            ...state.admins.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'GET_ADMINS_ABORTED':
      return {
        ...state,
        admins: {
          ...state.admins,
          isLoading: false,
          errors: {
            ...state.admins.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPDATE_ADMINS_PENDING':
      return {
        ...state,
        admin_ms: {
          ...state.admin_ms,
          isLoading: true,
          errors: {
            ...state.admin_ms.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPDATE_ADMINS_ERROR':
      return {
        ...state,
        admin_ms: {
          ...state.admin_ms,
          isLoading: false,
          errors: {
            ...state.admin_ms.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };

    case 'UPDATE_ADMINS_ABORTED':
      return {
        ...state,
        admin_ms: {
          ...state.admin_ms,
          isLoading: false,
          errors: {
            ...state.admin_ms.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };

    case 'UPDATE_ADMINS_SUCCESS':
      return {
        ...state,
        admin_ms: {
          ...state.admin_ms,
          data: action.payload.response.data,
          isLoading: false,
          errors: {
            ...state.admin_ms.errors,
            hasError: false,
            code: '',
            message: '',
          },
        },
      };
    default:
      return state;
  }
};

export default admin;
