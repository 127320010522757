import React from 'react';

import './TiersTaskMode.scss'

export default function TiersTaskMode(props) {
  const {
    rankTiers,
    questType,
    setSubTab,
    subTab,
    splitTiers,
    followers
  } = props;

  if (!questType) {
    return <div className='payment-blocker'>
      <span>Select a Quest Type to access Payment Information.</span>
    </div>;
  }

  // const handleModeChange = (e, newMode) => {
  //   // Prevent default form submission behavior
  //   // e.preventDefault();
  //   setSubTab(newMode);
  // };

  return (
    <div className="Main__Background">

      <div className="platform-tabs">
        <div className="platform-tab active">
          <svg viewBox="0 0 24 24" fill="#9146FF"><path d="M11.571 4.714h1.715v5.143H11.57zm4.715 0H18v5.143h-1.714zM6 0L1.714 4.286v15.428h5.143V24l4.286-4.286h3.428L22.286 12V0zm14.571 11.143l-3.428 3.428h-3.429l-3 3v-3H6.857V1.714h13.714Z" /></svg>
          Twitch
        </div>
      </div>

      {followers()}

      {/* <div className="followers-requirement">
        <div className="requirement-label">Minimum Follower Requirement</div>
        <div className="input-group">
          <label>Enter minimum required followers</label>
          <input
            id="followers"
            type="number"
            value={followers}
            onChange={handleFollowersChange}
            placeholder="Enter minimum required followers"
          />
        </div>
      </div> */}

      <div className="mode-switch">
        <button type="button" className={`mode-btn ${subTab === 0 ? 'active' : ''}`}
          onClick={() => setSubTab(0)}>Split Mode</button>
        <button type="button" className={`mode-btn ${subTab === 1 ? 'active' : ''}`}
          onClick={() => setSubTab(1)}>Rank Mode</button>
      </div>
      <div style={{ display: subTab === 0 ? 'block' : 'none' }}>
        {splitTiers()}
      </div>
      <div style={{ display: subTab === 1 ? 'block' : 'none' }}>
        {rankTiers()}
      </div>

    </div>
  );
}
