import plan from './plan-icon.svg';
import analyze from './analyze-icon.svg';
import payProcess from './pay-process-icon.svg';
import execute from './execute-icon.svg';
import verify from './verify-icon.svg';
import optimize from './optimize-icon.svg';

const bestInClassIndex = [
    {
        icon: plan,
        title: 'Plan',
        text: 'We closely review your campaigns goals, target audience, and create quests.'
    },
     {
        icon: verify,
        title: 'Verify',
        text: 'After each stage in your campaign, we double-check participant deliverables, track streams, and only move forward when requirements are completed.'
    }, {
        icon: analyze,
        title: 'Analyze',
        text: 'We provide comprehensive reporting to measure success and share insights you can use to improve your next campaign.'
    }, {
        icon: execute,
        title: 'Execute',
        text: 'Next, we conduct targeted outreach, closely vet options, and distribute quest deliverables to influencers for launch.'
    },{
        icon: optimize,
        title: 'Optimize',
        text: 'We identify top performing talent, adapt schedules accordingly, and optimize for best results.'
    }, {
        icon: payProcess,
        title: 'Pay',
        text: 'Lastly, we facilitate all influencer payments within two weeks of receivng payment and address any pending needs.'
    }];

export default bestInClassIndex;
