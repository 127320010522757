import { combineEpics } from 'redux-observable';

import {
  getInitialInfo,
  getGameList,
  getUserGameList,
  getGame,
  requestGameKey,
  joinToBounty,
  leaveBounty,
  getUserPayments,
  getUserTodayActivity,
  getUserReferralsActivity,
  getUserTimedActivity,
  submitLink,
  submitAffiliateData,
  getMyQuestsPageData,
  getUserTickets,
  requestUpdateAvatarUrl,
  getBroadcast,
  submitRequirementData,
  checkRequirementData,
  getUserWallet,
  withdrawWallet,
  getWalletRollOutRecord,
  getWalletRollOutCsv,
  getWalletRollInRecord,
  getWalletRollInCsv,
  addRollInOrder,
  buyCDK,
  getCDKOrders,
  cancelCDKOrder,
    payCDKOrder,
  showCDKPay,
  getCDKOrderRecord,
  getCDKOrderRecordCsv,
  getCDKProducts,
} from './userEpic';

import {
  loginTwitch,
  loginWithTwitterOAuthCode,
  loginYoutube,
  updateUserInfo,
  updateInfoByUser,
  signInBefore,
  signIn,
  signUp,
  recover,
  recoverValidate,
  recoverChangePassword,
  disconnectTwitch,
  disconnectYoutube,
  updateProfileInfo,
  validateUserHandshake,
  sendVerificationCode,
  validateVerificationCode,
  getCDK,
} from './authEpic';

import {
  uploadCSV,
  getGameLiveStreams,
  getGameBountiesData,
  getAUserList,
  getAUserListCsv,
  createAUser,
  createAUserBrand,
  getAUser,
  updateAUser,
  getAConfigList,
  createAConfig,
  getAConfig,
  updateAConfig,
  getAGameList,
  getAdminQuestListByGame,
  getAGame,
  createAGame,
  updateAGame,
  createAPlatform,
  getAllPlatforms,
  updateAFeatured,
  requestUploadImageUrl,
  getATrackedGames,
  getACampaignList,
  getACampaign,
  getACampaignQuestsReport,
  getAPaymentList,
  getAPaymentListCsv,
  getPaypalPayments,
  getAQuestList,
  // getInsightsData,
  // getInsightsOptions,
  createOffPlatformUser,
  getRollOutRecord,
  getRollOutCsv,
  approveRollOut,
  getRollOutAll,
  getRollOutRecordAdmin,
  getRollInCsv,
  getRollInRecordAdmin,
  showRollOutPay,
  getQuestLog,
  getQuestLogCsv,
  getProfileLog,
  getProfileLogCsv,
  getWalletLog,
  getWalletLogCsv,
  getCDKeyLog,
  getCDKeyLogCsv,
  getDailyTaskLog,
  getDailyTaskLogCsv,
  getChannelLog,
  getChannelLogCsv,
  getOtherLog,
  getOtherLogCsv,
  getLogSummary,
  createRegion,
  getRegions,
  createRegions,
    updateRegion,
  updateRegions,
  getCDKOrderRecords,
  getCDKOrderRecordsCsv,
  getPermissions,
  updatePermissions,
  getAdmins,
  updateAdmins,
} from './adminEpic';

import {
  getCCGameList,
  uploadKeys,
  getGameKeys,
  deleteGameKeys,
  getBountyPredictions,
  getWhiteList,
  toggleUserInWhiteList,
  createUserAndWhiteListIt,
  findUsers,
  getGameActivity,
  distributeGameKeys,
  assignNewKeyPlatforms,
  getCCQuestList,
  createCCQuest,
  getCCQuest,
  copyCCQuest,
  updateCCQuest,
  getCCQuestActivity,
  getCCQuestSales,
  getCCQuestTimed,
  getCCQuestGeneral,
  getCCQuestGeneralCsv,
  getCCQuestAffiliate,
  postCCPreapproval,
  postCCRequirement,
  postCCRequirementMultiple,
  postCCAffiliateResolution,
  getCCQuestTiered,
  getCCQuestTieredMultipleDays,
  requestUploadCCImageUrl,
  getCCCampaignListByGame,
  getCCCampaign,
  createCCCampaign,
  updateCCCampaign,
  updateCCGamesPlatform,
  updateCCQuestsPlatform,
  addCCUserQuest,
  removeCCUserQuest,
  moveCCUserQuest,
  getCCActiveQuestsByGame,
  getLinksDashboard,
  uploadLinks,
  assignCustomLinks,
  assignMasterLinks,
  deleteLinks,
  postReputationRating,
  postApproveQuest,
    createCDKeyProduct,
    getCDKeyProducts,
    updateCDKeyProduct,
  uploadCDKeys,
  deleteCDKeys,
  getCDKRecords,
} from './commandCenterEpic';

import {
  getQuest,
  joinQuest,
  joinQuestOffplatform,
  leaveQuest,
  requestGameKeyByQuest,
  getSFGame,
  getSFGameBySlug,
  postGamePayment,
  getQuestByIdentifier,
  getQuestBySlugGuest,
  requestReferralCodeByQuest,
  verifyYoutubeChannelStreamStatus,
  getStoreGames,
} from './configEpic';

import {
  getOptimizedAllQuests,
  getAllGuestQuests,
  getOptimizedEndedQuests,
} from './homeEpic';

import {
  getBUCampaignList,
  getBUCampaign,
  getBrandGame,
  getBrandQuest,
  getBrandStreamer,
  getBrandUserQuest,
  brandUserUploadImageUrl,
  postBrandGame,
  postBrandPreapproval,
  postBrandQuest,
  postBrandRequirement,
  putBrandGame,
  putBrandQuest,
} from './brandUserEpic';

const rootEpic = combineEpics(
  loginTwitch,
  loginWithTwitterOAuthCode,
  loginYoutube,
  disconnectTwitch,
  disconnectYoutube,
  updateUserInfo,
  updateInfoByUser,
  uploadCSV,

  // <--------------- START NOIZ V2 EPICS --------------->
  getInitialInfo,
  getGameList,
  getUserGameList,
  getGame,
  requestGameKey,
  joinToBounty,
  leaveBounty,
  requestGameKeyByQuest,
  getGameLiveStreams,
  getGameBountiesData,
  getCCGameList,
  uploadKeys,
  getGameKeys,
  deleteGameKeys,
  getBountyPredictions,
  getWhiteList,
  toggleUserInWhiteList,
  createUserAndWhiteListIt,
  findUsers,
  getGameActivity,
  distributeGameKeys,
  assignNewKeyPlatforms,
  // <--------------- END NOIZ V2 EPICS --------------->

  // <--------------- START NOIZ V3 EPICS --------------->
  signInBefore,
  signIn,
  signUp,
  recover,
  recoverValidate,
  recoverChangePassword,
  getCCQuestList,
  getCCQuest,
  copyCCQuest,
  createCCQuest,
  updateCCQuest,
  getAUserList,
  getAUserListCsv,
  createAUser,
  createAUserBrand,
  getAUser,
  updateAUser,
  getAPaymentList,
  getAPaymentListCsv,
  getPaypalPayments,
  getAQuestList,
  getAConfigList,
  createAConfig,
  getAConfig,
  updateAConfig,
  getQuest,
  joinQuest,
  joinQuestOffplatform,
  leaveQuest,
  submitLink,
  submitAffiliateData,
  submitRequirementData,
  checkRequirementData,
  requestUpdateAvatarUrl,
  getSFGame,
  getSFGameBySlug,
  postGamePayment,
  getAdminQuestListByGame,
  getUserPayments,
  updateProfileInfo,
  validateUserHandshake,
    sendVerificationCode,
    validateVerificationCode,
  getCCCampaignListByGame,
  getCCCampaign,
  createCCCampaign,
  updateCCCampaign,
  updateCCGamesPlatform,
  updateCCQuestsPlatform,

  getUserTodayActivity,
  getUserReferralsActivity,
  getUserTimedActivity,
  getMyQuestsPageData,
  getUserTickets,
    getUserWallet,
    withdrawWallet,
    getWalletRollOutRecord,
    getWalletRollOutCsv,
    getWalletRollInRecord,
    getWalletRollInCsv,
    addRollInOrder,
    getRollOutRecord,
    getRollOutCsv,
    approveRollOut,
    getRollOutAll,
    showRollOutPay,
  getCCQuestActivity,
  getCCQuestSales,
  getCCQuestTimed,
  getCCQuestGeneral,
  getCCQuestGeneralCsv,
  getCCQuestAffiliate,
  postCCPreapproval,
  postCCRequirement,
  postCCRequirementMultiple,
  postCCAffiliateResolution,
  getCCQuestTiered,
  getCCQuestTieredMultipleDays,
  getQuestByIdentifier,
  getQuestBySlugGuest,
  requestReferralCodeByQuest,
  getAGameList,
  getAGame,
  createAGame,
  updateAGame,
  createAPlatform,
  getAllPlatforms,
  updateAFeatured,
  verifyYoutubeChannelStreamStatus,
  requestUploadImageUrl,
  // <--------------- END NOIZ V3 EPICS --------------->

  // <--------------- START NOIZ V4 EPICS --------------->
  getOptimizedAllQuests,
  getAllGuestQuests,
  getOptimizedEndedQuests,
  requestUploadCCImageUrl,
  getATrackedGames,
  getBUCampaignList,
  getBUCampaign,
  getACampaignList,
  getACampaign,
  getACampaignQuestsReport,
  getBroadcast,
  getStoreGames,
  addCCUserQuest,
  removeCCUserQuest,
  moveCCUserQuest,
  getCCActiveQuestsByGame,
  getLinksDashboard,
  uploadLinks,
  assignCustomLinks,
  assignMasterLinks,
  deleteLinks,
  postReputationRating,
  createOffPlatformUser,
  getBrandGame,
  getBrandQuest,
  getBrandStreamer,
  getBrandUserQuest,
  brandUserUploadImageUrl,
  postBrandGame,
  postBrandPreapproval,
  postBrandQuest,
  postBrandRequirement,
  putBrandGame,
  putBrandQuest,
  // <--------------- END NOIZ V4 EPICS --------------->

  // <--------------- START NOIZ V5 EPICS --------------->
  postApproveQuest,
  getRollOutRecordAdmin,
  getRollInCsv,
  getRollInRecordAdmin,
  getQuestLog,
  getQuestLogCsv,
  getProfileLog,
  getProfileLogCsv,
  getWalletLog,
  getWalletLogCsv,
  getCDKeyLog,
  getCDKeyLogCsv,
  getDailyTaskLog,
  getDailyTaskLogCsv,
  getChannelLog,
  getChannelLogCsv,
  getOtherLog,
  getOtherLogCsv,
  getLogSummary,
    createRegion,
  getRegions,
    createRegions,
  updateRegion,
    updateRegions,
    createCDKeyProduct,
    getCDKeyProducts,
    updateCDKeyProduct,
    uploadCDKeys,
    deleteCDKeys,
    buyCDK,
    getCDKOrders,
    cancelCDKOrder,
    payCDKOrder,
    getCDK,
    showCDKPay,
    getCDKOrderRecord,
    getCDKOrderRecordCsv,
    getCDKOrderRecords,
    getCDKOrderRecordsCsv,
    getCDKRecords,
    getCDKProducts,
    getPermissions,
    updatePermissions,
    getAdmins,
    updateAdmins,
  // <--------------- END NOIZ V5 EPICS --------------->
);

export default rootEpic;
