import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import { simpleNotification } from '../../../utils/notifications';
import RecoverForm from '../../forms/RecoverForm';
import RecoverPasswordForm from '../../forms/RecoverPasswordForm';
import Logo from '../../logo/Logo';
import '../login.scss';
import './Recover.scss';

function Recover({ auth, location, recover, recoverChangePassword, recoverValidate, router }) {
  const [authToken, setAuthToken] = useState(null);
  const [showingErrors, setShowingErrors] = useState(false);
  const [showingSuccess, setShowingSuccess] = useState(false);
  const recoveryToken = location.query['recovery-token'];

  // Handle error/success states
  useEffect(() => {
    if (auth.isLoading === false) {
      if (auth.errors.hasError) {
        if (showingSuccess) setShowingSuccess(false);
        if (!showingErrors) setShowingErrors(true);
      } else {
        if (showingErrors) setShowingErrors(false);
        if (auth.recoverSuccess && !showingSuccess) setShowingSuccess(true);
      }
    }
  }, [auth]);

  // Notify of successful password recovery and redirect
  useEffect(() => {
    if (authToken === null) {
      setAuthToken(auth.token);
      return;
    }

    if (authToken !== auth.token) {
      simpleNotification({
        level: 'success',
        title: 'Password Recovered.',
        message: 'Your password has been recovered successfully.',
      });
      router.replace('/');
      return;
    }

  }, [auth, authToken]);

  // Validate recovery token
  useEffect(() => {
    if (recoveryToken) recoverValidate(recoveryToken);
  }, [recoveryToken, recoverValidate]);

  return (
    <section className="Recover LogIn">
      <section className="ModalMaster">
        <section className="Modal active">
          <Link
            aria-hidden
            className="Modal__layer"
            role="button"
            to="/"
          />
          <section className="Modal__wrapper1">
            <Link
              className="Modal__close-btn"
              to="/"
              href="/"
              role="button"
              aria-hidden
            />
            <div className="Modal__left1">
              <Logo />
            </div>
            <div className="Modal__right">

              <div className="Modal__content">
                <ul className="Modal__tabs">
                  <li>
                    <Link
                      activeClassName="active"
                      aria-hidden
                      className="Modal__tab"
                      role="button"
                      to="/recover">
                      RECOVER
                    </Link>
                  </li>
                </ul>

                {recoveryToken &&
                  <RecoverPasswordForm
                    onSubmit={allFields => {
                      const { password, rePassword } = allFields;
                      recoverChangePassword(recoveryToken, password, rePassword);
                    }}
                  />
                }
                {!recoveryToken && <RecoverForm onSubmit={identity => recover(identity)} />}
                <section className={`errors-container ${showingErrors ? 'active' : ''}`}>
                  <div className="error-message">
                    {auth.errors.message}
                  </div>
                  <button
                    className="error-dismiss"
                    onClick={() => setShowingErrors(false)}
                    type="button"
                  />
                </section>
                <section className={`success-container ${(showingSuccess & !recoveryToken) ? 'active' : ''}`}>
                  <div className="success-message">
                    We sent you an email to retrieve your password. Sometimes this email may take a couple of minutes before it reaches your mailbox.
                  </div>
                  <button
                    className="success-dismiss"
                    onClick={() => setShowingSuccess(false)}
                    type="button"
                  />
                </section>
              </div>
            </div>
            <div className="blurb">
              Designed by game developers and gamers, for game developers and gamers
            </div>
          </section>
        </section>
      </section>
    </section>
  )
};

export default Recover;
