// FILE: src/components/LiveStreams.js
import React, { useState,useEffect } from 'react';
import { localeURI } from '../../utils/util';
import axios from 'axios';

import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

// 注册 Chart.js 组件
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TwitchFollowersCard = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [broadcaster, setbroadcasterId] = useState('');
  
    const handleButtonClick = async () => {
      setIsLoading(true);
      setMessage('');
      try {
        const response = await axios.get('/api/v1/twitch/followers', {
            params: { broadcaster }
        });
        if (response.status === 200 || response.data.success === true) {
          setMessage(`获取粉丝数成功：${response.data.followers}`);
        } else {
          setMessage('Unexpected response status: ' + response.status);
        }
      } catch (error) {
        if (error.response) {
          setMessage('Failed to fetch followers: ' + error.response.data.message);
        } else {
          setMessage('Network error: ' + error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };
  
    return (
      <div>
         <label htmlFor="follower_count" style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px', display: 'block' , marginTop: '200px'}}>
              featch broadcaster follower_count:
          </label>
          <input
              id="follower_count" // 与 label 的 htmlFor 对应
              type="text"
              value={broadcaster}
             // style={{ marginTop: '200px' }}
              onChange={(e) => setbroadcasterId(e.target.value)}
              placeholder="Enter broadcaster Id"
          />
        <button
          onClick={handleButtonClick}
          disabled={isLoading}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            cursor: 'pointer',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
          }}
        >
          {isLoading ? 'create...' : 'Fetch Broadcaster follower_count'}
        </button>
        {message && <p>{message}</p>}
      </div>
    );
  };
  
const SigSubscriptionUpdateButton = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [broadcasterId, setbroadcasterId] = useState('');
  
    const handleButtonClick = async () => {
      setIsLoading(true);
      setMessage('');
      try {
        const response = await axios.get('/api/v1/twitchEventsubsig', {
            params: { broadcasterId }
        });
        if (response.status === 200) {
          setMessage('Subscriptions create successfully.');
        } else {
          setMessage('Unexpected response status: ' + response.status);
        }
      } catch (error) {
        if (error.response) {
          setMessage('Failed to create subscriptions: ' + error.response.data.message);
        } else {
          setMessage('Network error: ' + error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };
  
    return (
      <div>
         <label htmlFor="Subscriptions" style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px', display: 'block' , marginTop: '200px'}}>
              create signature for subscriptions:
          </label>
          <input
              id="Subscriptions" // 与 label 的 htmlFor 对应
              type="text"
              value={broadcasterId}
             // style={{ marginTop: '200px' }}
              onChange={(e) => setbroadcasterId(e.target.value)}
              placeholder="Enter broadcaster Id"
          />
        <button
          onClick={handleButtonClick}
          disabled={isLoading}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            cursor: 'pointer',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
          }}
        >
          {isLoading ? 'create...' : 'Create Subscriptions'}
        </button>
        {message && <p>{message}</p>}
      </div>
    );
  };
  
  const SubscriptionRevButton = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [broadcasterId, setbroadcasterId] = useState('');
  
    const handleButtonClick = async () => {
      setIsLoading(true);
      setMessage('');
      try {
        const response = await axios.get('/api/v1/twitchEventsubsigRev', {
            params: { broadcasterId }
        });
        if (response.status === 200) {
          setMessage('Subscriptions Revoke successfully.');
        } else {
          setMessage('Unexpected response status: ' + response.status);
        }
      } catch (error) {
        if (error.response) {
          setMessage('Failed to Revoke subscriptions: ' + error.response.data.message);
        } else {
          setMessage('Network error: ' + error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };
  
    return (
      <div>
        <label htmlFor="RevSubscriptions" style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px', display: 'block' , marginTop: '200px'}}>
            revoke signature for subscriptions:
        </label>
        <input
            id="RevSubscriptions" // 与 label 的 htmlFor 对应
            type="text"
            value={broadcasterId}
            // style={{ marginTop: '200px' }}
            onChange={(e) => setbroadcasterId(e.target.value)}
            placeholder="Enter broadcaster Id"
        />
        <button
          onClick={handleButtonClick}
          disabled={isLoading}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            cursor: 'pointer',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
          }}
        >
          {isLoading ? 'Revoke...' : 'Revoke Subscription'}
        </button>
        {message && <p>{message}</p>}
      </div>
    );
  };
  
  const FetchSubscriptionButton = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [message, setMessage] = useState('');
  
    const handleButtonClick = async () => {
      setIsLoading(true);
      setMessage('');
      try {
        const response = await axios.get('/api/v1/fetchtwitchEventsub');
        // 假设返回的数据结构为：{ status: 'success', data: allSubscriptions }
        if (response.status === 200 && response.data.status === 'success') {
          setMessage('Fetched subscriptions successfully.');
          // 更新订阅数据状态
          setSubscriptions(response.data.data);
        } else {
          setMessage('Unexpected response status: ' + response.status);
        }
      } catch (error) {
        if (error.response) {
          setMessage('Failed to fetch subscriptions: ' + error.response.data.message);
        } else {
          setMessage('Network error: ' + error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };
  
    return (
      <div>
        <button
          onClick={handleButtonClick}
          disabled={isLoading}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            cursor: 'pointer',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            marginTop: '200px',
          }}
        >
          {isLoading ? 'Updating...' : 'Fetch Subscriptions'}
        </button>
        {message && <p>{message}</p>}
        {subscriptions.length > 0 && (
          <ul>
            {subscriptions.map((subscription, index) => (
              <li key={index}>
                {/* 根据你的订阅数据结构显示需要的字段 */}
                {subscription.condition.broadcaster_user_id}---
                {subscription.type}---
                {subscription.status}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };
  const SyncSubscriptionsButton = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
  
    const handleButtonClick = async () => {
      setIsLoading(true);
      setMessage('');
      try {
        const response = await axios.get('/api/v1/syncSubscriptions');
        if (response.status === 200) {
          setMessage('Subscriptions Sync successfully.');
        } else {
          setMessage('Unexpected response status: ' + response.status);
        }
      } catch (error) {
        if (error.response) {
          setMessage('Failed to Sync subscriptions: ' + error.response.data.message);
        } else {
          setMessage('Network error: ' + error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };
  
    return (
      <div>
         <label htmlFor="SyncSubscriptions" style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px', display: 'block' , marginTop: '200px'}}>
              SyncSubscriptions:
          </label>
        <button
          onClick={handleButtonClick}
          disabled={isLoading}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            cursor: 'pointer',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            // marginTop: '200px',
          }}
        >
          {isLoading ? 'SyncSubscriptions...' : 'Sync Subscriptions'}
        </button>
        {message && <p>{message}</p>}
      </div>
    );
  };

const LiveStreams = () => {
    const [gameName, setGameName] = useState('');
    const [streams, setStreams] = useState([]);

    const handleSearch = async () => {
        try {
          const response = await axios.get('/api/v1/twitch', {
            params: { gameName }
        });
          setStreams(response.data.data);
        } catch (error) {
            console.error('Error fetching live streams:', error);
        }
    };

    return (
      <div>
          <label htmlFor="gameSearch" style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px', display: 'block' , marginTop: '200px'}}>
              Search for  first 10 live streams by game name:
          </label>
          <input
              id="gameSearch" // 与 label 的 htmlFor 对应
              type="text"
              value={gameName}
             // style={{ marginTop: '200px' }}
              onChange={(e) => setGameName(e.target.value)}
              placeholder="Enter game name"
          />
          <button
              onClick={handleSearch}
              style={{
                  padding: '10px 20px', // 增加按钮的内边距
                  fontSize: '16px',      // 增加字体大小
                  cursor: 'pointer',    // 鼠标悬停时显示手指形状
                  backgroundColor: '#4CAF50', // 改变按钮背景颜色
                  color: 'white',        // 设置字体颜色
                  border: 'none',        // 去除边框
                  borderRadius: '5px',   // 圆角按钮
              }}
          >
              Search
          </button>
          <div>
            {streams.map((stream) => (
                <div
                    key={stream.id}
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        border: '1px solid #ccc',
                        borderRadius: '10px',
                        padding: '15px',
                        marginBottom: '20px',
                        maxWidth: '800px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#f9f9f9',
                    }}
                >
                    {/* 缩略图部分 */}
                    <div style={{ marginRight: '15px' }}>
                        <img
                            src={stream.thumbnail_url.replace('{width}', '200').replace('{height}', '150')}
                            alt={stream.title}
                            style={{
                                borderRadius: '10px',
                                width: '200px',
                                height: '150px',
                                objectFit: 'cover',
                            }}
                        />
                    </div>

                    {/* 信息部分 */}
                    <div style={{ flex: 1 }}>
                        {/* 用户名和游戏 */}
                        <div style={{ marginBottom: '10px' }}>
                            <h3 style={{ margin: '0', color: '#333' }}>{stream.user_name}</h3>
                            <span style={{ fontSize: '14px', color: '#666' }}>Playing: {stream.game_name}</span>
                        </div>

                        {/* 标题 */}
                        <p style={{ fontSize: '16px', margin: '10px 0', color: '#555' }}>{stream.title}</p>

                        {/* 观众数、语言和标签 */}
                        <div>
                            <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                                <strong>Viewers:</strong> {stream.viewer_count} | <strong>Language:</strong> {stream.language}
                            </p>

                            {/* 显示标签 */}
                            {stream.tags && stream.tags.length > 0 && (
                                <div style={{ marginTop: '10px' }}>
                                    <strong>Tags: </strong>
                                    {stream.tags.map((tag, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                backgroundColor: '#e0e0e0',
                                                padding: '5px 10px',
                                                borderRadius: '15px',
                                                fontSize: '12px',
                                                color: '#555',
                                                marginRight: '5px',
                                                display: 'inline-block',
                                            }}
                                        >
                                            {tag}
                                        </span>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>


      </div>
  );
};
const LiveStreamsUser = () => {
  const [gameName, setGameName] = useState('');
  const [streams, setStreams] = useState([]);
  const [userName, setUserName] = useState('');

  const handleSearch = async () => {
      try {
        const response = await axios.get('/api/v1/twitch/streams', {
          params: { gameName, userName  }
      });
        setStreams(response.data.data);
      } catch (error) {
          console.error('Error fetching live streams:', error);
      }
  };

  return (
    <div>
     <label htmlFor="gameSearch" style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px', display: 'block' , marginTop: '200px'}}>
            Search for live streams by game name and user name:
        </label>
    <input
        id="gameSearch" // 与 label 的 htmlFor 对应
        type="text"
        style={{marginRight: '25px'}}
        value={gameName}
        onChange={(e) => setGameName(e.target.value)}
        placeholder="Enter game name"
    />
    <input
        type="text"
        style={{ marginRight: '25px'}}
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
        placeholder="Enter user name"
    />
          <button onClick={handleSearch} style={{
  padding: '10px 20px', // 增加按钮的内边距
  fontSize: '16px',      // 增加字体大小
  cursor: 'pointer',    // 鼠标悬停时显示手指形状
  backgroundColor: '#4CAF50', // 改变按钮背景颜色
  color: 'white',        // 设置字体颜色
  border: 'none',        // 去除边框
  borderRadius: '5px',   // 圆角按钮
}}>Search</button>
          <div>
            {streams.map((stream) => (
                <div
                    key={stream.id}
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        border: '1px solid #ccc',
                        borderRadius: '10px',
                        padding: '15px',
                        marginBottom: '20px',
                        maxWidth: '800px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#f9f9f9',
                    }}
                >
                    {/* 缩略图部分 */}
                    <div style={{ marginRight: '15px' }}>
                        <img
                            src={stream.thumbnail_url.replace('{width}', '200').replace('{height}', '150')}
                            alt={stream.title}
                            style={{
                                borderRadius: '10px',
                                width: '200px',
                                height: '150px',
                                objectFit: 'cover',
                            }}
                        />
                    </div>

                    {/* 信息部分 */}
                    <div style={{ flex: 1 }}>
                        {/* 用户名和游戏 */}
                        <div style={{ marginBottom: '10px' }}>
                            <h3 style={{ margin: '0', color: '#333' }}>{stream.user_name}</h3>
                            <span style={{ fontSize: '14px', color: '#666' }}>Playing: {stream.game_name}</span>
                        </div>

                        {/* 标题 */}
                        <p style={{ fontSize: '16px', margin: '10px 0', color: '#555' }}>{stream.title}</p>

                        {/* 观众数、语言和标签 */}
                        <div>
                            <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                                <strong>Viewers:</strong> {stream.viewer_count} | <strong>Language:</strong> {stream.language}
                            </p>

                            {/* 显示标签 */}
                            {stream.tags && stream.tags.length > 0 && (
                                <div style={{ marginTop: '10px' }}>
                                    <strong>Tags: </strong>
                                    {stream.tags.map((tag, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                backgroundColor: '#e0e0e0',
                                                padding: '5px 10px',
                                                borderRadius: '15px',
                                                fontSize: '12px',
                                                color: '#555',
                                                marginRight: '5px',
                                                display: 'inline-block',
                                            }}
                                        >
                                            {tag}
                                        </span>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
      </div>
  );
};

const LiveStreamsData = () => {
  const [gameNameOrId, setGameNameOrId] = useState('');
  const [userNameOrId, setUserNameOrId] = useState('');
  const [streams, setStreams] = useState([]);
  const [recentStream, setRecentStream] = useState([]);

  const handleSearch = async () => {
      try {
        const response = await axios.get('/api/v1/twitch/streamsdata', {
          params: { gameNameOrId, userNameOrId }
        });
        console.log('API Response:', response.data); // 调试输出 API 返回值

        // 检查 response.data 是单个对象
        if (response.data && typeof response.data === 'object' && !Array.isArray(response.data)) {
            if (response.data.game_id) {
                // 是直播流数据，处理它
                setStreams([response.data]);  // 将其包装为数组
                setRecentStream([]);  // 清空最近视频流
            } else {
                // 是视频数据，处理它
                setStreams([]);
                setRecentStream([response.data]);  // 将其包装为数组
                console.log('recentStream:',recentStream[0]); // 调试输出 API 返回值
            }
        } else {
          console.log('1111111111111111111:'); // 调试输出 API 返回值
            // 没有任何数据
            setStreams([]);
            setRecentStream([]);
        }
    } catch (error) {
        console.error('Error fetching stream data:', error);
    }
  };

  return (
      <div>
         <label htmlFor="gameSearch" style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px', display: 'block' , marginTop: '200px'}}>
            Search for live streams or videos by game name and user name or ID:
        </label>
        <input
              id="gameSearch" // 与 label 的 htmlFor 对应
              type="text"
              style={{ marginRight: '25px'}}
              value={gameNameOrId}
              onChange={(e) => setGameNameOrId(e.target.value)}
              placeholder="Enter game name or ID"
          />
        
          <input
              type="text"
              style={{ marginRight: '25px'}}
              value={userNameOrId}
              onChange={(e) => setUserNameOrId(e.target.value)}
              placeholder="Enter user name or ID"
          />
          <button onClick={handleSearch} style={{
              padding: '10px 20px', // 增加按钮的内边距
              fontSize: '16px',      // 增加字体大小
              cursor: 'pointer',    // 鼠标悬停时显示手指形状
              backgroundColor: '#4CAF50', // 改变按钮背景颜色
              color: 'white',        // 设置字体颜色
              border: 'none',        // 去除边框
              borderRadius: '5px',   // 圆角按钮
            }}>Search
          </button>
          
         

            <div>
                {streams.length > 0 ? (
                    // 如果有 Live Streams 数据，显示 Live Streams 列表
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '20px',
                            justifyContent: 'center',
                        }}
                    >
                        {streams.map((stream) => (
                            <div
                                key={stream.id}
                                style={{
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                    padding: '15px',
                                    maxWidth: '300px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    backgroundColor: '#f9f9f9',
                                }}
                            >
                                <img
                                    src={stream.thumbnail_url?.replace('{width}', '320').replace('{height}', '180') || '/path/to/default-thumbnail.jpg'}
                                    alt={stream.title}
                                    style={{
                                        borderRadius: '10px',
                                        width: '100%',
                                        height: 'auto',
                                        objectFit: 'cover',
                                    }}
                                />
                                <h3 style={{ margin: '10px 0 5px', color: '#333' }}>{stream.user_name}</h3>
                                <p style={{ margin: '5px 0', color: '#555' }}>{stream.title}</p>
                                <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                                    Viewers: {stream.viewer_count}
                                </p>
                                <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                                    Started at: {new Date(stream.started_at).toLocaleString()}
                                </p>
                            </div>
                        ))}
                    </div>
                ): recentStream.length >0 ? (
                  // 显示 Recent Stream 数据
                  <div
                      style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          border: '1px solid #ccc',
                          borderRadius: '10px',
                          padding: '15px',
                          marginBottom: '20px',
                          maxWidth: '800px',
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                          backgroundColor: '#f9f9f9',
                      }}
                  >
                      <div style={{ marginRight: '15px' }}>
                          <img
                              src={recentStream[0].thumbnail_url?.replace('%{width}', '320').replace('%{height}', '180') || '/path/to/default-thumbnail.jpg'}
                              alt={recentStream[0].title}
                              style={{
                                  borderRadius: '10px',
                                  width: '320px',
                                  height: '180px',
                                  objectFit: 'cover',
                              }}
                          />
                      </div>
                      <div style={{ flex: 1 }}>
                          <h3 style={{ margin: '0', color: '#333' }}>{recentStream[0].user_name}</h3>
                          <p style={{ fontSize: '16px', margin: '10px 0', color: '#555' }}>{recentStream[0].title}</p>
                          <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                              <strong>Description:</strong> {recentStream[0].description || 'No description available.'}
                          </p>
                          <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                              <strong>Created At:</strong> {new Date(recentStream[0].created_at).toLocaleString()}
                          </p>
                          <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                              <strong>Published At:</strong> {new Date(recentStream[0].published_at).toLocaleString()}
                          </p>
                          <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                              <strong>Duration:</strong> {recentStream[0].duration}
                          </p>
                          <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                              <strong>Views:</strong> {recentStream[0].view_count}
                          </p>
                          <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                              <strong>Language:</strong> {recentStream[0].language.toUpperCase()}
                          </p>
                          <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                              <strong>Type:</strong> {recentStream[0].type}
                          </p>
                          <a
                              href={recentStream[0].url}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                  display: 'inline-block',
                                  marginTop: '10px',
                                  padding: '10px 20px',
                                  backgroundColor: '#4CAF50',
                                  color: 'white',
                                  textDecoration: 'none',
                                  borderRadius: '5px',
                                  fontSize: '14px',
                              }}
                          >
                              Watch Video
                          </a>
                      </div>
                  </div>
                  ):(
                    // 如果没有 Live Streams 或 Recent Stream 数据
                    <p>No live streams or recent streams found.</p>
                )}
            </div>
 

      </div>
  );

  
};

const TaskVideo = () => {
  const [url, setTaskurl] = useState('');
  const [streams, setStreams] = useState([]);

  const handleSearch = async () => {
    if (!url) {
      console.error('URL cannot be empty');
      return;
    }
      try {
        const response = await axios.get('/api/v1/twitch/videoById', {
          params: { url }
      });
        setStreams(response.data.data);
      } catch (error) {
          console.error('Error fetching live streams:', error);
      }
  };

  return (
    <div>
        <label htmlFor="gameSearch" style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px', display: 'block' , marginTop: '200px'}}>
            Search for  video by task url:
        </label>
        <input
            id="gameSearch" // 与 label 的 htmlFor 对应
            type="text"
            value={url}
           
            onChange={(e) => setTaskurl(e.target.value)}
            placeholder="Enter twitch video url"
        />
        <button
            onClick={handleSearch}
            style={{
                padding: '10px 20px', // 增加按钮的内边距
                fontSize: '16px',      // 增加字体大小
                cursor: 'pointer',    // 鼠标悬停时显示手指形状
                backgroundColor: '#4CAF50', // 改变按钮背景颜色
                color: 'white',        // 设置字体颜色
                border: 'none',        // 去除边框
                borderRadius: '5px',   // 圆角按钮
            }}
        >
            Search
        </button>
        <div>
            {streams.map((stream) => (
                <div
                    key={stream.id}
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        border: '1px solid #ccc',
                        borderRadius: '10px',
                        padding: '15px',
                        marginBottom: '20px',
                        maxWidth: '800px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#f9f9f9',
                    }}
                >
                    {/* 缩略图部分 */}
                    <div style={{ marginRight: '15px' }}>
                        <img
                            src={stream.thumbnail_url?.replace('%{width}', '320').replace('%{height}', '180') || '/path/to/default-thumbnail.jpg'}
                            alt={stream.title}
                            style={{
                              borderRadius: '10px',
                              width: '320px',
                              height: '180px',
                              objectFit: 'cover',
                          }}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                    <h3 style={{ margin: '0', color: '#333' }}>{stream.user_name}</h3>
                    <p style={{ fontSize: '16px', margin: '10px 0', color: '#555' }}>{stream.title}</p>
                    <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                        <strong>Description:</strong> {stream.description || 'No description available.'}
                    </p>
                    <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                        <strong>Created At:</strong> {new Date(stream.created_at).toLocaleString()}
                    </p>
                    <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                        <strong>Published At:</strong> {new Date(stream.published_at).toLocaleString()}
                    </p>
                    <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                        <strong>Duration:</strong> {stream.duration}
                    </p>
                    <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                        <strong>Views:</strong> {stream.view_count}
                    </p>
                    <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                        <strong>Language:</strong> {stream.language.toUpperCase()}
                    </p>
                    <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                        <strong>Type:</strong> {stream.type}
                    </p>
                    <a
                        href={stream.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            display: 'inline-block',
                            marginTop: '10px',
                            padding: '10px 20px',
                            backgroundColor: '#4CAF50',
                            color: 'white',
                            textDecoration: 'none',
                            borderRadius: '5px',
                            fontSize: '14px',
                        }}
                    >
                        Watch Video
                    </a>
                </div>
              </div>
            ))}
          </div>
    </div>
  );
};

const TaskVideoByDescription = () => {
  const [description, setDescription] = useState('');
  const [url, setTaskurl] = useState('');
  const [userNameOrId, setUserNameOrId] = useState('');
  const [streams, setStreams] = useState([]);

  const handleSearch = async () => {
    if (!description) {
      console.error('description cannot be empty');
      return;
    }
      try {
        const response = await axios.get('/api/v1/twitch/taskVideo', {
          params: { userNameOrId,description,url }
      });
      console.log(response.data);
      setStreams(response.data);
      } catch (error) {
          console.error('Error fetching live streams:', error);
      }
  };

  return (
    <div>
        <label htmlFor="gameSearch" style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px', display: 'block' , marginTop: '200px'}}>
            Search for  video by task url:
        </label>
        <input
            id="gameSearch" // 与 label 的 htmlFor 对应
            type="text"
            value={userNameOrId}
            style={{ marginRight: '25px'}}
            onChange={(e) => setUserNameOrId(e.target.value)}
            placeholder="Enter user name or id"
        />
          <input
              type="text"
              style={{ marginRight: '25px'}}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter description"
          />
           <input
            type="text"
            value={url}
            style={{ marginRight: '25px'}}
            onChange={(e) => setTaskurl(e.target.value)}
            placeholder="Enter twitch video url"
        />
        <button
            onClick={handleSearch}
            style={{
                padding: '10px 20px', // 增加按钮的内边距
                fontSize: '16px',      // 增加字体大小
                cursor: 'pointer',    // 鼠标悬停时显示手指形状
                backgroundColor: '#4CAF50', // 改变按钮背景颜色
                color: 'white',        // 设置字体颜色
                border: 'none',        // 去除边框
                borderRadius: '5px',   // 圆角按钮
            }}
        >
            Search
        </button>
        <div>
            {streams.map((stream) => (
                <div
                    key={stream.id}
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        border: '1px solid #ccc',
                        borderRadius: '10px',
                        padding: '15px',
                        marginBottom: '20px',
                        maxWidth: '800px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#f9f9f9',
                    }}
                >
                    {/* 缩略图部分 */}
                    <div style={{ marginRight: '15px' }}>
                        <img
                            src={stream.thumbnail_url?.replace('%{width}', '320').replace('%{height}', '180') || '/path/to/default-thumbnail.jpg'}
                            alt={stream.title}
                            style={{
                              borderRadius: '10px',
                              width: '320px',
                              height: '180px',
                              objectFit: 'cover',
                          }}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                    <h3 style={{ margin: '0', color: '#333' }}>{stream.user_name}</h3>
                    <p style={{ fontSize: '16px', margin: '10px 0', color: '#555' }}>{stream.title}</p>
                    <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                        <strong>Description:</strong> {stream.description || 'No description available.'}
                    </p>
                    <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                        <strong>Created At:</strong> {new Date(stream.created_at).toLocaleString()}
                    </p>
                    <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                        <strong>Published At:</strong> {new Date(stream.published_at).toLocaleString()}
                    </p>
                    <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                        <strong>Duration:</strong> {stream.duration}
                    </p>
                    <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                        <strong>Views:</strong> {stream.view_count}
                    </p>
                    <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                        <strong>Language:</strong> {stream.language.toUpperCase()}
                    </p>
                    <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                        <strong>Type:</strong> {stream.type}
                    </p>
                    <a
                        href={stream.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            display: 'inline-block',
                            marginTop: '10px',
                            padding: '10px 20px',
                            backgroundColor: '#4CAF50',
                            color: 'white',
                            textDecoration: 'none',
                            borderRadius: '5px',
                            fontSize: '14px',
                        }}
                    >
                        Watch Video
                    </a>
                </div>
              </div>
            ))}
          </div>
    </div>
  );
};

const GetAllvideoByuser = () => {
  const [userNameOrId, setUserNameOrId] = useState('');
  const [streams, setStreams] = useState([]);

  const handleSearch = async () => {
      try {
        const response = await axios.get('/api/v1/twitch/getAllvideoByuser', {
          params: { userNameOrId }
      });
        setStreams(response.data);
      } catch (error) {
          console.error('Error fetching live streams:', error);
      }
  };

  return (
    <div>
        <label htmlFor="gameSearch" style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px', display: 'block' , marginTop: '200px'}}>
            Search all viodeos by user name:
        </label>
        <input
            id="gameSearch" // 与 label 的 htmlFor 对应
            type="text"
            value={userNameOrId}
            style={{ marginRight: '25px'}}
            onChange={(e) => setUserNameOrId(e.target.value)}
            placeholder="Enter game name"
        />
        <button
            onClick={handleSearch}
            style={{
                padding: '10px 20px', // 增加按钮的内边距
                fontSize: '16px',      // 增加字体大小
                cursor: 'pointer',    // 鼠标悬停时显示手指形状
                backgroundColor: '#4CAF50', // 改变按钮背景颜色
                color: 'white',        // 设置字体颜色
                border: 'none',        // 去除边框
                borderRadius: '5px',   // 圆角按钮
            }}
        >
            Search
        </button>
        <div>
            {streams.map((stream) => (
                <div
                    key={stream.id}
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        border: '1px solid #ccc',
                        borderRadius: '10px',
                        padding: '15px',
                        marginBottom: '20px',
                        maxWidth: '800px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#f9f9f9',
                    }}
                >
                    {/* 缩略图部分 */}
                    <div style={{ marginRight: '15px' }}>
                        <img
                            src={stream.thumbnail_url?.replace('%{width}', '320').replace('%{height}', '180') || '/path/to/default-thumbnail.jpg'}
                            alt={stream.title}
                            style={{
                              borderRadius: '10px',
                              width: '320px',
                              height: '180px',
                              objectFit: 'cover',
                          }}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                    <h3 style={{ margin: '0', color: '#333' }}>{stream.user_name}</h3>
                    <p style={{ fontSize: '16px', margin: '10px 0', color: '#555' }}>{stream.title}</p>
                    <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                        <strong>Description:</strong> {stream.description || 'No description available.'}
                    </p>
                    <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                        <strong>Created At:</strong> {new Date(stream.created_at).toLocaleString()}
                    </p>
                    <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                        <strong>Published At:</strong> {new Date(stream.published_at).toLocaleString()}
                    </p>
                    <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                        <strong>Duration:</strong> {stream.duration}
                    </p>
                    <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                        <strong>Views:</strong> {stream.view_count}
                    </p>
                    <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                        <strong>Language:</strong> {stream.language.toUpperCase()}
                    </p>
                    <p style={{ margin: '5px 0', fontSize: '14px', color: '#777' }}>
                        <strong>Type:</strong> {stream.type}
                    </p>
                    <a
                        href={stream.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            display: 'inline-block',
                            marginTop: '10px',
                            padding: '10px 20px',
                            backgroundColor: '#4CAF50',
                            color: 'white',
                            textDecoration: 'none',
                            borderRadius: '5px',
                            fontSize: '14px',
                        }}
                    >
                        Watch Video
                    </a>
                </div>
              </div>
            ))}
          </div>
    </div>
  );
};

// 聊天消息组件
const TwitchChatMessages = () => {
    const [messages, setMessages] = useState([]);
    const [userid, setUserid] = useState('');
    const [dateRange, setDateRange] = useState({ start: '', end: '' });

    const handleFetch = async () => {
        try {
            const response = await axios.get('/api/v1/twitch/chat/messages', {
                params: {
                    userid,
                    startDate: dateRange.start,
                    endDate: dateRange.end
                }
            });
            setMessages(response.data.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div style={{ margin: '20px' }}>
            <h2>聊天记录查询</h2>
            <div style={{ marginBottom: '15px' }}>
                <input
                    type="text"
                    value={userid}
                    onChange={(e) => setUserid(e.target.value)}
                    placeholder="输入用户名"
                    style={{
                        padding: '8px',
                        marginRight: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ddd'
                    }}
                />
                <input
                    type="date"
                    value={dateRange.start}
                    onChange={(e) => setDateRange({...dateRange, start: e.target.value})}
                    style={{
                        padding: '8px',
                        marginRight: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ddd'
                    }}
                />
                <input
                    type="date"
                    value={dateRange.end}
                    onChange={(e) => setDateRange({...dateRange, end: e.target.value})}
                    style={{
                        padding: '8px',
                        marginRight: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ddd'
                    }}
                />
                <button onClick={handleFetch} style={{
                    padding: '10px 20px',
                    backgroundColor: '#4CAF50',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer'
                }}>
                    查询
                </button>
            </div>
            <div style={{ marginTop: '20px' }}>
                {messages.map(message => (
                    <div key={message.id} style={{
                        padding: '15px',
                        margin: '10px 0',
                        border: '1px solid #ddd',
                        borderRadius: '8px'
                    }}>
                        <h3>{message.username}</h3>
                        <p>{message.content}</p>
                        <p>发送时间: {new Date(message.sent_at).toLocaleString()}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

// 频道分析组件
const TwitchChannelAnalytics = () => {
    const [analytics, setAnalytics] = useState([]);
    const [channelName, setChannelName] = useState('');
    const [dateRange, setDateRange] = useState({ start: '', end: '' });
    const [metricType, setMetricType] = useState('all'); // viewers, followers, all

    const handleFetch = async () => {
        try {
            const response = await axios.get('/api/v1/twitch/analytics/channels', {
                params: {
                    channelName,
                    startDate: dateRange.start,
                    endDate: dateRange.end,
                    metricType
                }
            });
            setAnalytics(response.data.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div style={{ margin: '20px' }}>
            <h2>频道数据分析</h2>
            <div style={{ marginBottom: '15px' }}>
                <input
                    type="text"
                    value={channelName}
                    onChange={(e) => setChannelName(e.target.value)}
                    placeholder="输入频道名称"
                    style={{
                        padding: '8px',
                        marginRight: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ddd'
                    }}
                />
                <select
                    value={metricType}
                    onChange={(e) => setMetricType(e.target.value)}
                    style={{
                        padding: '8px',
                        marginRight: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ddd'
                    }}
                >
                    <option value="all">所有指标</option>
                    <option value="viewers">观众数据</option>
                    <option value="followers">粉丝数据</option>
                </select>
                <input
                    type="date"
                    value={dateRange.start}
                    onChange={(e) => setDateRange({...dateRange, start: e.target.value})}
                    style={{
                        padding: '8px',
                        marginRight: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ddd'
                    }}
                />
                <input
                    type="date"
                    value={dateRange.end}
                    onChange={(e) => setDateRange({...dateRange, end: e.target.value})}
                    style={{
                        padding: '8px',
                        marginRight: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ddd'
                    }}
                />
                <button onClick={handleFetch} style={{
                    padding: '10px 20px',
                    backgroundColor: '#4CAF50',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer'
                }}>
                    查询
                </button>
            </div>
            <div style={{ marginTop: '20px' }}>
                {analytics.map(stat => (
                    <div key={stat.id} style={{
                        padding: '15px',
                        margin: '10px 0',
                        border: '1px solid #ddd',
                        borderRadius: '8px'
                    }}>
                        <h3>{stat.channel_name}</h3>
                        <p>观众数: {stat.viewer_count}</p>
                        <p>粉丝数: {stat.follower_count}</p>
                        <p>统计时间: {new Date(stat.date).toLocaleString()}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

// 慈善活动组件
const TwitchCharityCampaigns = () => {
    const [campaigns, setCampaigns] = useState([]);
    const [userid, setUserid] = useState('');
    const [status, setStatus] = useState('all'); // all, active, completed
    const [sortBy, setSortBy] = useState('date'); // date, amount

    const handleFetch = async () => {
        try {
            const response = await axios.get('/api/v1/twitch/charity/campaigns', {
                params: {
                    userid,
                    status,
                    sortBy
                }
            });
            setCampaigns(response.data.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div style={{ margin: '20px' }}>
            <h2>慈善活动查询</h2>
            <div style={{ marginBottom: '15px' }}>
                <input
                    type="text"
                    value={userid}
                    onChange={(e) => setUserid(e.target.value)}
                    placeholder="输入活动名称"
                    style={{
                        padding: '8px',
                        marginRight: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ddd'
                    }}
                />
                <select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    style={{
                        padding: '8px',
                        marginRight: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ddd'
                    }}
                >
                    <option value="all">所有状态</option>
                    <option value="active">进行中</option>
                    <option value="completed">已结束</option>
                </select>
                <select
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    style={{
                        padding: '8px',
                        marginRight: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ddd'
                    }}
                >
                    <option value="date">按日期排序</option>
                    <option value="amount">按金额排序</option>
                </select>
                <button onClick={handleFetch} style={{
                    padding: '10px 20px',
                    backgroundColor: '#4CAF50',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer'
                }}>
                    查询
                </button>
            </div>
            <div style={{ marginTop: '20px' }}>
                {campaigns.map(campaign => (
                    <div key={campaign.id} style={{
                        padding: '15px',
                        margin: '10px 0',
                        border: '1px solid #ddd',
                        borderRadius: '8px'
                    }}>
                        <h3>{campaign.name}</h3>
                        <p>目标金额: ${campaign.target_amount}</p>
                        <p>当前金额: ${campaign.current_amount}</p>
                        <p>状态: {campaign.status}</p>
                        <p>开始时间: {new Date(campaign.start_date).toLocaleString()}</p>
                        <p>结束时间: {new Date(campaign.end_date).toLocaleString()}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};
// 订阅查询组件
const TwitchSubscriptions = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [userid, setUserid] = useState('');
    const [dateRange, setDateRange] = useState({ start: '', end: '' });

    const handleSearch = async () => {
        try {
            const response = await axios.get('/api/v1/twitch/subscriptions', {
                params: {
                    userid,
                    startDate: dateRange.start,
                    endDate: dateRange.end
                }
            });
            setSubscriptions(response.data.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div style={{ margin: '20px' }}>
            <h2>订阅查询</h2>
            <div style={{ marginBottom: '15px' }}>
                <input
                    type="text"
                    value={userid}
                    onChange={(e) => setUserid(e.target.value)}
                    placeholder="输入用户名"
                    style={{
                        padding: '8px',
                        marginRight: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ddd',
                        width: '200px'
                    }}
                />
                <input
                    type="date"
                    value={dateRange.start}
                    onChange={(e) => setDateRange({...dateRange, start: e.target.value})}
                    style={{
                        padding: '8px',
                        marginRight: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ddd'
                    }}
                />
                <input
                    type="date"
                    value={dateRange.end}
                    onChange={(e) => setDateRange({...dateRange, end: e.target.value})}
                    style={{
                        padding: '8px',
                        marginRight: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ddd'
                    }}
                />
                <button onClick={handleSearch} style={{
                    padding: '10px 20px',
                    backgroundColor: '#4CAF50',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer'
                }}>
                    查询
                </button>
            </div>
            <div style={{ marginTop: '20px' }}>
                {subscriptions.map(sub => (
                    <div key={sub.id} style={{
                        padding: '15px',
                        margin: '10px 0',
                        border: '1px solid #ddd',
                        borderRadius: '8px'
                    }}>
                        <h3>{sub.broadcaster_name}</h3>
                        <p>订阅等级: {sub.tier}</p>
                        <p>订阅时间: {new Date(sub.created_at).toLocaleString()}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

// Bits排行组件
const TwitchBitsLeaderboard = () => {
    const [leaderboard, setLeaderboard] = useState([]);
    const [period, setPeriod] = useState('all');
    const [limit, setLimit] = useState(10);

    const handleSearch = async () => {
        try {
            const response = await axios.get('/api/v1/twitch/bits/leaderboard', {
                params: { period, limit }
            });
            setLeaderboard(response.data.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div style={{ margin: '20px' }}>
            <h2>Bits排行榜</h2>
            <div style={{ marginBottom: '15px' }}>
                <select 
                    value={period}
                    onChange={(e) => setPeriod(e.target.value)}
                    style={{
                        padding: '8px',
                        marginRight: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ddd'
                    }}
                >
                    <option value="all">全部时间</option>
                    <option value="week">本周</option>
                    <option value="month">本月</option>
                    <option value="year">本年</option>
                </select>
                <input
                    type="number"
                    value={limit}
                    onChange={(e) => setLimit(e.target.value)}
                    placeholder="显示数量"
                    min="1"
                    max="100"
                    style={{
                        padding: '8px',
                        marginRight: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ddd',
                        width: '100px'
                    }}
                />
                <button onClick={handleSearch} style={{
                    padding: '10px 20px',
                    backgroundColor: '#4CAF50',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer'
                }}>
                    查询
                </button>
            </div>
            <div style={{ marginTop: '20px' }}>
                {leaderboard.map((user, index) => (
                    <div key={user.id} style={{
                        padding: '15px',
                        margin: '10px 0',
                        border: '1px solid #ddd',
                        borderRadius: '8px'
                    }}>
                        <h3>#{index + 1} {user.user_name}</h3>
                        <p>打赏数量: {user.score}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

// 游戏分析组件
const TwitchGameAnalytics = () => {
    const [gameStats, setGameStats] = useState([]);
    const [gameName, setGameName] = useState('');
    const [dateRange, setDateRange] = useState({ start: '', end: '' });

    const handleSearch = async () => {
        try {
            const response = await axios.get('/api/v1/twitch/analytics/games', {
                params: {
                    gameName,
                    startDate: dateRange.start,
                    endDate: dateRange.end
                }
            });
            setGameStats(response.data.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div style={{ margin: '20px' }}>
            <h2>游戏数据分析</h2>
            <div style={{ marginBottom: '15px' }}>
                <input
                    type="text"
                    value={gameName}
                    onChange={(e) => setGameName(e.target.value)}
                    placeholder="输入游戏名称"
                    style={{
                        padding: '8px',
                        marginRight: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ddd',
                        width: '200px'
                    }}
                />
                <input
                    type="date"
                    value={dateRange.start}
                    onChange={(e) => setDateRange({...dateRange, start: e.target.value})}
                    style={{
                        padding: '8px',
                        marginRight: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ddd'
                    }}
                />
                <input
                    type="date"
                    value={dateRange.end}
                    onChange={(e) => setDateRange({...dateRange, end: e.target.value})}
                    style={{
                        padding: '8px',
                        marginRight: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ddd'
                    }}
                />
                <button onClick={handleSearch} style={{
                    padding: '10px 20px',
                    backgroundColor: '#4CAF50',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer'
                }}>
                    查询
                </button>
            </div>
            <div style={{ marginTop: '20px' }}>
                {gameStats.map(game => (
                    <div key={game.id} style={{
                        padding: '15px',
                        margin: '10px 0',
                        border: '1px solid #ddd',
                        borderRadius: '8px'
                    }}>
                        <h3>{game.game_name}</h3>
                        <p>观众数: {game.viewer_count}</p>
                        <p>主播数: {game.streamer_count}</p>
                        <p>统计时间: {new Date(game.date).toLocaleString()}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};


// 玩家权限检测
const TwitchCchecks = () => {
    const [campaigns, setCampaigns] = useState([]);
    const [userid, setUserid] = useState('');
    const handleFetch = async () => {
        try {
            const response = await axios.get('/api/v1/twitch/destroyUserTwitch', {
                params: {
                    userid
                }
            });
            setCampaigns(response.data.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div style={{ margin: '20px' }}>
            <h2>玩家权限检测</h2>
            <div style={{ marginBottom: '15px' }}>
                <input
                    type="text"
                    value={userid}
                    onChange={(e) => setUserid(e.target.value)}
                    placeholder="输入玩家ID"
                    style={{
                        padding: '8px',
                        marginRight: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ddd'
                    }}
                />
                <button onClick={handleFetch} style={{
                    padding: '10px 20px',
                    backgroundColor: '#4CAF50',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer'
                }}>
                    查询
                </button>
            </div>
            <div style={{ marginTop: '20px' }}>
                {campaigns.map(campaign => (
                    <div key={campaign.id} style={{
                        padding: '15px',
                        margin: '10px 0',
                        border: '1px solid #ddd',
                        borderRadius: '8px'
                    }}>
                        <h3>{campaign.name}</h3>
                        <p>目标金额: ${campaign.target_amount}</p>
                        <p>当前金额: ${campaign.current_amount}</p>
                        <p>状态: {campaign.status}</p>
                        <p>开始时间: {new Date(campaign.start_date).toLocaleString()}</p>
                        <p>结束时间: {new Date(campaign.end_date).toLocaleString()}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

const UpdateTwitchPermissions = () => {
    const [userId, setUserId] = useState("");
    const [permissions, setPermissions] = useState([]);
    const [manualPermissions, setManualPermissions] = useState("");
    const [status, setStatus] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const availablePermissions = [
        'channel:read:subscriptions',
        'channel:read:redemptions', //许访问频道自定义奖励（Channel Points Rewards）及其兑换记录。
        'channel:read:chatters',
        'bits:read',
        'user:read:email',
        'chat:read',
    ];

    const handlePermissionChange = (e) => {
        // 获取当前选中的选项值
        const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
        
        // 如果有手动输入的权限，将其添加到选中的权限中
        if (manualPermissions) {
            const manualArray = manualPermissions.split(',')
                .map(p => p.trim())
                .filter(p => p); // 过滤空字符串
            
            // 合并选择的权限和手动输入的权限，去重
            const allPermissions = [...new Set([...selectedOptions, ...manualArray])];
            setPermissions(allPermissions);
        } else {
            setPermissions(selectedOptions);
        }
    };
    
    const handleManualInputChange = (e) => {
        const newValue = e.target.value;
    const oldPermissions = manualPermissions.split(',').map(p => p.trim()).filter(p => p);
    const currentPermissions = newValue.split(',').map(p => p.trim()).filter(p => p);
    
    // 找出被删除的权限
    const removedPermissions = oldPermissions.filter(p => !currentPermissions.includes(p));
    
    setManualPermissions(newValue);
    
    if (removedPermissions.length > 0) {
        // 从权限列表中移除被删除的权限
        setPermissions(permissions.filter(p => !removedPermissions.includes(p)));
    }
    
    // 当输入逗号时添加新权限
    if (newValue.endsWith(',')) {
        const newPermissions = newValue.split(',')
            .map(p => p.trim())
            .filter(p => p);
        
        const uniquePermissions = [...new Set([...permissions, ...newPermissions])];
        setPermissions(uniquePermissions);
    }
    };
    
    // const handleUpdate = async () => {
    //     try {
    //         setStatus('正在更新权限...');
            
    //         if (!userId) {
    //             setStatus('请输入用户ID');
    //             return;
    //         }
    
    //         if (!permissions.length && !manualPermissions) {
    //             setStatus('请选择或输入至少一个权限');
    //             return;
    //         }
           
    //         // 使用 axios.get 检查用户令牌
    //         const tokenResponse = await axios.get('/api/v1/check-token', {
    //             params: { userId ,permissions: permissions.join(',') },
    //         }).catch(error => {
    //             if (error.response) {
    //                 // 服务器返回错误状态码
    //                 console.error('Response error:', error.response.data);
    //                 throw new Error(`服务器错误: ${error.response.status}`);
    //             } else if (error.request) {
    //                 // 请求发送失败
    //                 console.error('Request error:', error.request);
    //                 throw new Error('网络请求失败');
    //             } else {
    //                 // 请求配置错误
    //                 console.error('Error:', error.message);
    //                 throw new Error('请求配置错误');
    //             }
    //         });
        
    //         const tokenResult = tokenResponse.data;
            
            
    
    //         if (!tokenResult.success) {
    //             const code  = tokenResult.error;
    //             // 获取当前窗口URL，用作回调地址
    //             const callbackUrl = `${window.location.origin}/auth/twitch/callback`;
    //             console.log('Error code:', code);
    //             if (code === "USER_NOT_CONNECTED" || code === "REFRESH_FAILED"|| code === "SCOPES_NEED_UPDATE") {
    //                 setStatus("令牌无效或刷新失败，正在更新权限");
    
    //                 const updateResponse = await axios.get("/api/v1/auth/twitch", {
    //                     params: { 
    //                         userId,
    //                         permissions: permissions.join(','),
    //                         callbackURL: callbackUrl
    //                     }
    //                 }).catch(error => {
    //                     if (error.response) {
    //                         throw new Error(`更新失败: ${error.response.data.message || '未知错误'}`);
    //                     }
    //                     throw error;
    //                 });
    //                 // 如果返回授权URL，重定向用户
    //                 if (updateResponse.data.authUrl) {
    //                     // 保存当前状态到 sessionStorage
    //                     sessionStorage.setItem('twitchAuthState', JSON.stringify({
    //                         userId,
    //                         permissions: permissions.join(','),
    //                     }));
                        
    //                     // 重定向到Twitch授权页面
    //                     window.location.href = updateResponse.data.authUrl;
    //                 }
    
    //                 if (updateResponse.status === 200) {
    //                     setStatus("权限更新成功");
    //                 } else {
    //                     throw new Error('更新失败：服务器返回非预期状态');
    //                 }
    //             } else {
    //                 throw new Error(`令牌检查失败: ${code}`);
    //             }
    //         } else {
    //             setStatus("令牌有效，权限更新成功");
    //         }
    //     } catch (error) {
    //         console.error('Error details:', error);
    //         setStatus(error.message || "更新权限时发生错误，请稍后重试");
    //     }
    // };

    const handleUpdate = async () => {
        try {
            // 基础验证
            // if (!userId) {
            //     setStatus('请输入用户ID');
            //     return;
            // }
    
            if (!permissions.length && !manualPermissions) {
                setStatus('请选择或输入至少一个权限');
                return;
            }
    
            setIsLoading(true);
            setStatus('正在检查权限状态...');
    
            // 准备权限列表
            const finalPermissions = [...new Set([
                ...permissions,
                ...manualPermissions.split(',').map(p => p.trim()).filter(p => p)
            ])];

            const redirectUri = localeURI('/api/v1/auth/twitch', {
                force_verify: true,
                permissions: finalPermissions.join(',')
              });
              window.location.href = redirectUri;
    
        //     // 直接发起授权请求
        //     const authResponse = await axios.get("/api/v1/auth/twitch", {
        //         params: { 
        //             userId,
        //             permissions: finalPermissions.join(','),
        //             callbackURL: `${window.location.origin}/connect-accounts/twitch` // 使用与后端配置相匹配的回调URL
        //         },
        //         withCredentials: true,
        //           // 允许重定向
        //          maxRedirects: 5
        //     });
    
        //     // 处理授权响应
        //     if (authResponse.data.code === 'ALREADY_AUTHORIZED') {
        //         // 已有所需权限
        //         setStatus(`已拥有所需权限: ${authResponse.data.currentScopes.join(', ')}`);
        //         return;
        //     }
    
        //     // 如果需要授权，保存状态并重定向
        //     sessionStorage.setItem('twitchAuthState', JSON.stringify({
        //         userId,
        //         permissions: finalPermissions.join(','),
        //         returnPath: window.location.pathname,
        //         timestamp: Date.now()
        //     }));
    
           
        //    // 否则重定向到 Twitch 授权页面
        //     const redirectURL = `/api/v1/auth/twitch?${new URLSearchParams({
        //         userId,
        //         permissions: finalPermissions.join(","),
        //         callbackURL: `${window.location.origin}/connect-accounts/twitch`,
        //     }).toString()}`;
        //     window.location.href = redirectURL;
    
        } catch (error) {
            console.error('Error during permission update:', error);
            setStatus(error.response?.data?.message || '更新权限时发生错误');
        } finally {
            setIsLoading(false);
        }
    };
    
    // 添加回调处理
    // useEffect(() => {
    //     // 检查是否在回调页面
    //     if (window.location.pathname === '/connect-accounts/twitch') {
    //         const params = new URLSearchParams(window.location.search);
    //         const error = params.get('error');
    //         const errorDescription = params.get('error_description');
    
    //         if (error) {
    //             setStatus(`授权失败: ${errorDescription || error}`);
    //             return;
    //         }
    
    //         // 恢复之前的状态
    //         const savedState = JSON.parse(sessionStorage.getItem('twitchAuthState'));
    //         if (savedState) {
    //             // 清理状态
    //             sessionStorage.removeItem('twitchAuthState');
    //             // 返回原页面
    //             if (savedState.returnPath) {
    //                 window.location.href = savedState.returnPath;
    //             }
    //         }
    //     }
    // }, []);
    return (
        <div style={{ margin: "20px" }}>
          <h2>管理 Twitch 权限</h2>
          <div style={{ marginBottom: "15px" }}>
            <input
              type="text"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              placeholder="输入用户ID"
              style={{
                padding: "8px",
                marginRight: "10px",
                borderRadius: "4px",
                border: "1px solid #ddd",
                width: "200px",
              }}
            />
            <div style={{ margin: "10px 0" }}>
              <p>选择权限:</p>
              <select
                multiple
                value={permissions}
                onChange={handlePermissionChange}
                style={{
                  padding: "8px",
                  marginRight: "10px",
                  borderRadius: "4px",
                  border: "1px solid #ddd",
                  height: "200px",
                  width: "300px",
                }}
              >
                {availablePermissions.map((perm) => (
                  <option key={perm} value={perm}>
                    {perm}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ margin: "10px 0" }}>
              <p>或手动输入权限 (用逗号分隔):</p>
              <input
                type="text"
                value={manualPermissions}
                onChange={handleManualInputChange}
                placeholder="例如: channel:read:subscriptions, bits:read"
                style={{
                  padding: "8px",
                  marginRight: "10px",
                  borderRadius: "4px",
                  border: "1px solid #ddd",
                  width: "100%",
                }}
              />
            </div>
            <button
              onClick={handleUpdate}
              disabled={isLoading}
              style={{
                padding: "10px 20px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: isLoading ? "not-allowed" : "pointer",
                marginTop: "10px",
                opacity: isLoading ? 0.7 : 1
              }}
            >
              {isLoading ? "处理中..." : "检查并更新权限"}
            </button>
          </div>
          {status && (
            <div
              style={{
                padding: "10px",
                marginTop: "10px",
                backgroundColor: status.includes("成功") ? "#dff0d8" : "#f2dede",
                color: status.includes("成功") ? "#3c763d" : "#a94442",
                borderRadius: "4px",
              }}
            >
              {status}
            </div>
          )}
          <div style={{ marginTop: "20px" }}>
            <h3>当前选择的权限:</h3>
            <ul>
              {permissions.map((perm) => (
                <li key={perm}>{perm}</li>
              ))}
            </ul>
          </div>
        </div>
      );
    };

    const ViewerChart1 = () => {
        const [username, setUsername] = useState("");
        const [isSearching, setIsSearching] = useState(false);
        const [isTracking, setIsTracking] = useState(false);
        const [viewerData, setViewerData] = useState([]);
        const [timestamps, setTimestamps] = useState([]);
        const [totalWatchTime, setTotalWatchTime] = useState(0);
        const intervalTime = 60;
    
        const handleSearch = () => {
            if (!username) {
                return;
            }
            // 重置数据
            setViewerData([]);
            setTimestamps([]);
            setTotalWatchTime(0);
            setIsTracking(true);
        };
          // 图表数据配置
        const chartData = {
            labels: timestamps,
            datasets: [
                {
                    label: '观众人数',
                    data: viewerData,
                    fill: false,
                    borderColor: 'rgb(75, 192, 192)',
                    tension: 0.1
                }
            ]
        };
    
        useEffect(() => {
            let interval;
            
            if (isTracking && username) {
                const fetchViewerCount = async () => {
                    try {
                        setIsSearching(true);
                        const response = await axios.get('/api/v1/twitch/streamsbyusername', {
                            params: { username },
                        });
              
                        const viewerCount = response.data.viewer_count;
                        const currentTime = new Date().toLocaleTimeString();
              
                        setViewerData(prevData => [...prevData, viewerCount]);
                        setTimestamps(prevTimestamps => [...prevTimestamps, currentTime]);
                        setTotalWatchTime(prevTime => prevTime + viewerCount * intervalTime);
                    } catch (error) {
                        console.error('获取观众数量失败:', error);
                    } finally {
                        setIsSearching(false);
                    }
                };
          
                interval = setInterval(fetchViewerCount, intervalTime * 1000);
                fetchViewerCount(); // 初次获取
            }
        
            return () => {
                if (interval) {
                    clearInterval(interval);
                }
            };
        }, [username, isTracking, intervalTime]);
    
        return (
            <div style={{ margin: "20px" }}>
                <div style={{ marginBottom: "15px" }}>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="输入用户名"
                        style={{
                            padding: "8px",
                            marginRight: "10px",
                            borderRadius: "4px",
                            border: "1px solid #ddd",
                            width: "200px",
                        }}
                    />
                    <button
                        onClick={handleSearch}
                        disabled={isSearching || !username}
                        style={{
                            padding: "10px 20px",
                            backgroundColor: "#4CAF50",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            cursor: (isSearching || !username) ? "not-allowed" : "pointer",
                            opacity: (isSearching || !username) ? 0.7 : 1
                        }}
                    >
                        {isSearching ? "获取中..." : "开始追踪"}
                    </button>
                    {isTracking && (
                        <button
                            onClick={() => setIsTracking(false)}
                            style={{
                                padding: "10px 20px",
                                marginLeft: "10px",
                                backgroundColor: "#f44336",
                                color: "white",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer"
                            }}
                        >
                            停止追踪
                        </button>
                    )}
                </div>
                {viewerData.length > 0 && (
                    <div style={{ marginTop: "20px" }}>
                        <Line data={chartData} />
                        <p>累计观看时长: {(totalWatchTime / 3600).toFixed(2)} 小时</p>
                    </div>
                )}
            </div>
        );
    };
        
    
//分别把这个组件导出
//export  {LiveStreams,LiveStreamsUser,LiveStreamsData};
const ViewerChart2 = () => {
    const [username, setUsername] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [isTracking, setIsTracking] = useState(false);
    const [viewerData, setViewerData] = useState([]);
    const [timestamps, setTimestamps] = useState([]);
    const [totalWatchTime, setTotalWatchTime] = useState(0);
    const intervalTime = 35;

    const handleSearch = () => {
        if (!username) {
            return;
        }
        // 重置数据
        setViewerData([]);
        setTimestamps([]);
        setTotalWatchTime(0);
        setIsTracking(true);
    };
      // 图表数据配置
    const chartData = {
        labels: timestamps,
        datasets: [
            {
                label: '观众人数',
                data: viewerData,
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1
            }
        ]
    };

    useEffect(() => {
        let interval;
        
        if (isTracking && username) {
            const fetchViewerCount = async () => {
                try {
                    setIsSearching(true);
                    const response = await axios.get('/api/v1/twitch/streamsbyusername', {
                        params: { username },
                    });
          
                    const viewerCount = response.data.viewer_count;
                    const currentTime = new Date().toLocaleTimeString();
          
                    setViewerData(prevData => [...prevData, viewerCount]);
                    setTimestamps(prevTimestamps => [...prevTimestamps, currentTime]);
                    setTotalWatchTime(prevTime => prevTime + viewerCount * intervalTime);
                } catch (error) {
                    console.error('获取观众数量失败:', error);
                } finally {
                    setIsSearching(false);
                }
            };
      
            interval = setInterval(fetchViewerCount, intervalTime * 1000);
            fetchViewerCount(); // 初次获取
        }
    
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [username, isTracking, intervalTime]);

    return (
        <div style={{ margin: "20px" }}>
            <div style={{ marginBottom: "15px" }}>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="输入用户名"
                    style={{
                        padding: "8px",
                        marginRight: "10px",
                        borderRadius: "4px",
                        border: "1px solid #ddd",
                        width: "200px",
                    }}
                />
                <button
                    onClick={handleSearch}
                    disabled={isSearching || !username}
                    style={{
                        padding: "10px 20px",
                        backgroundColor: "#4CAF50",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        cursor: (isSearching || !username) ? "not-allowed" : "pointer",
                        opacity: (isSearching || !username) ? 0.7 : 1
                    }}
                >
                    {isSearching ? "获取中..." : "开始追踪"}
                </button>
                {isTracking && (
                    <button
                        onClick={() => setIsTracking(false)}
                        style={{
                            padding: "10px 20px",
                            marginLeft: "10px",
                            backgroundColor: "#f44336",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer"
                        }}
                    >
                        停止追踪
                    </button>
                )}
            </div>
            {viewerData.length > 0 && (
                <div style={{ marginTop: "20px" }}>
                    <Line data={chartData} />
                    <p>累计观看时长: {(totalWatchTime / 3600).toFixed(2)} 小时</p>
                </div>
            )}
        </div>
    );
};


const TwitchComponent = () => {
  return (
    <div>
        <TwitchFollowersCard/>
        <SigSubscriptionUpdateButton/>
        <SubscriptionRevButton />
        <FetchSubscriptionButton/>
        <SyncSubscriptionsButton />
        <LiveStreams />
        <LiveStreamsUser />
        <LiveStreamsData />
        <TaskVideo />
        <TaskVideoByDescription />
        <GetAllvideoByuser/>
        <TwitchSubscriptions />
        <TwitchBitsLeaderboard />
        <TwitchCharityCampaigns />
        <TwitchChannelAnalytics />
        <TwitchGameAnalytics />
        <TwitchChatMessages />
        <TwitchCchecks />
        <UpdateTwitchPermissions />
        <ViewerChart1 />
        <ViewerChart2 />
    </div>
  );
};

export default TwitchComponent;
