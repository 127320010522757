import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { fade, slide } from '../../utils/transitionIndex';
import './OpenBar.scss';

function OpenBar(props) {
  const { title, description } = props;
  const [open, setOpen] = React.useState(false);

  // 提取 fade/slide 返回的属性，并剥离 in 属性
  const fadeProps = fade(200, 200);
  const { in: fadeIn, ...restFadeProps } = fadeProps;
  const slideProps = slide(200, 200);
  const { in: slideIn, ...restSlideProps } = slideProps;

  return (
    <div className={`OpenBar ${open ? 'open' : ''}`}>
      <TransitionGroup>
        <div className="previewBar">
          <h3>{title}</h3>
          <CSSTransition in={fadeIn} {...restFadeProps}>
            <i
              className={`fa fa-chevron-${open ? 'up' : 'down enlarge-me'}`}
              onClick={() => setOpen(!open)}
            />
          </CSSTransition>
        </div>
        {open && (
          <CSSTransition in={slideIn} {...restSlideProps}>
            <div className="faqDesc">
              {ReactHtmlParser(description)}
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
}

export default OpenBar;