/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link } from 'react-router';
import {
  find,
} from 'lodash';
import moment from 'moment-timezone';
import { CSVLink } from 'react-csv';
import Countdown from 'react-countdown-now';
import classNames from 'classnames';
import Axios from 'axios';
import { simpleNotification } from '../../../../utils/notifications';

import { getCSVQuest as getCSV, getKeys } from '../../../../utils/downloadReports';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

// Utils
import {
  getSorting,
  stableSort,
  getCompletionStatus,
  getNumApprovedRequirements,
  getQuestRequirements,
  getQuestRequirementsForDisplay,
  getStreamTimeCompleted,
  keyDistributionStepExists,
  parseLinks,
  formatMinutesToHoursAndMinutes,
  formatValueWithUnit
} from '../../../../utils/functions';

// Custom Modal
import CustomLinkModal from './CustomLinkModal';

// Styles
import './QuestDetailsTableNew.scss';

const TableHeaderCell = withStyles(theme => ({
  head: {
    color: '#9b9b9b',
    fontWeight: '400',
    letterSpacing: '1.2px',
    fontSize: '14px',
  },
}))(TableCell);

class QuestDetailsTableNew extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      orderBy: 'dateAccepted',
      order: 'asc',
      showingElements: [],
      showingSplitTiers: [],
      usersTab: 0,
      currentUserQuests: this.props.userQuests
    };
  }

  // Determine sort direction for property; set state.order and state.orderBy
  handleRequestSort = (event, property) => {
    const desc = this.state.order === 'desc' && this.state.orderBy === property;
    this.setState({
      order: desc ? 'asc' : 'desc',
      orderBy: property,
    });
  }

  // Determine user's completion status; return element to display
  getCompletionElement = (user) => {
    const { quest } = this.props;
    const { currentUserQuests } = this.state;
    const userQuest = find(currentUserQuests, o => (o.user.id === user.id));
    const {
      timeCompleted, untilDateTime, status,
    } = getCompletionStatus(quest, userQuest, user.timePlaying);

    if (status === 'C') {
      return (
        <TableCell align="center"> <span className="success">C</span></TableCell>
      );
    } else if (status === 'P') {
      return (
        <TableCell align="center"><span className="info">P</span></TableCell>
      );
    } else if (!timeCompleted) {
      return (
        <TableCell align="center">
          <Countdown
            date={untilDateTime}
            daysInHours
            renderer={({
              hours,
              minutes,
              seconds,
              completed,
            }) => {
              if (completed) {
                return (
                  <span className="error">F</span>
                );
              }
              return <span>{hours}:{minutes}:{seconds} left to stream</span>;
            }}
          />
        </TableCell>
      );
    }
    return (
      <TableCell align="center"><span className="error">F</span></TableCell>
    );
  }

   // Add toggle method for split tiers
   toggleSplitTierInfo = (username) => {
    const { showingSplitTiers,showingElements } = this.state;
    this.setState({
      showingSplitTiers: showingSplitTiers.includes(username) 
        ? showingSplitTiers.filter(name => name !== username)
        : [...showingSplitTiers, username],
      showingElements: showingElements.filter(name => name !== username)
    });
  }

  // Open requirements table for user; set state.showingElements
  toggleElementInfo = (elementId) => {
    const { showingSplitTiers,showingElements } = this.state;
    this.setState({
      showingElements: showingElements.includes(elementId) 
        ? showingElements.filter(name => name !== elementId)
        : [...showingElements, elementId],
        showingSplitTiers: showingSplitTiers.filter(name => name !== elementId)
    });
  }

  handleComplete = (user, quest, requirement, content) => {
    const {
      id, title, type, subtype,
    } = requirement;

    const reqToUpdate = {
      userId: user.id,
      questId: quest.id,
      reqId: id,
      title,
      type,
      subtype,
      status: 'completed',
      override: true,
      overriddenByUser: this.props.auth.user.id,
    };

    if (content) reqToUpdate.content = content;
    else reqToUpdate.content = 'Admin Override';

    const data = JSON.stringify(reqToUpdate);

    this.props.postCCRequirement(data, this.props.auth.token);
  }

  async postUserquestData(userQuestId, isReject) {
    const { user, token } = this.props.auth;
    const { quest } = this.props;
    const { currentUserQuests } = this.state;
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };

    let body = { isReject: isReject, userId: user.id, questId: quest.id };
    try {
      const response = await Axios.post('/api/v1/quest/userquest', body, config);
      // Find index of userquest to update
      const updateIndex = currentUserQuests.findIndex(
        uq => uq.id === userQuestId
      );
      if (updateIndex !== -1) {
        // Create new array with updated data
        const newUserQuest = response.data.data.userQuest[0];
        const updatedUserQuests = currentUserQuests.map((uq, index) => {
          if (uq.id === userQuestId) {
            return {
              ...uq,
              isReject: newUserQuest.isReject // Only update isReject property
            };
          }
          return uq;
        });
        this.setState({ currentUserQuests: updatedUserQuests });
      }
      simpleNotification({
        level: 'success',
        title: 'User Successfully Updated',
        message: 'Chagne success!',
      });
    } catch (err) {
      simpleNotification({
        level: 'error',
        title: 'Something Went Wrong',
        message: 'If problem persists, please contact dev team',
      });
    }
  }

  //同意该用户进入最后的结算名单
  approve = (userquestId) => {
    this.postUserquestData(userquestId[0], false);
  }

  //拒绝该用户进入最后的结算名单
  decline = (userquestId) => {
    this.postUserquestData(userquestId[0], true);
  }

  reset = () => {
    this.setState({
      numPage: 1,
      selectedUsers: [],
      searchValue: '',
      updateModal: false,
    });
  }

  // Sort & define user rows based on quest type & sorting state; return elements to display
  getUserRows = (users, questType, platform, keyDistribute) => {
    const { quest,leaderboard } = this.props;
    const { currentUserQuests,showingSplitTiers } = this.state;
    const { order, orderBy, showingElements } = this.state;

    const sortedUsers = stableSort(users, getSorting(order, orderBy));
    let numberRequirements = getQuestRequirements(quest, true).length;
    
    if (questType === 'tiered-auto') {
      numberRequirements += 1;
      return sortedUsers.map((user) => {
        const matchingUsers = leaderboard.filter(o => o.user.displayName === user.user.displayName);
        const leaderboardUser = matchingUsers.length ? matchingUsers[0] : null;
        const showTiers = showingSplitTiers.indexOf(user.username) !== -1;
        const username = platform === 'twitch' ? user.twitchUsername : 'NA';
        const userQuest = find(currentUserQuests, o => (o.user.id === user.id));
        let sumCompletedRequirements = getNumApprovedRequirements(quest, currentUserQuests);
        const completedStreamTime = getStreamTimeCompleted(quest, user.timePlaying);
        if (completedStreamTime) sumCompletedRequirements += 1;
        const showUserRequirements = showingElements.indexOf(user.username) !== -1;

        return (
          <React.Fragment key={user.id}>
            <TableRow hover tabIndex={-1}>
              <TableCell align="center">{user.user ? user.user.username : '-'}</TableCell>
              <TableCell align="center">{username}</TableCell>
              <TableCell align="center" >{formatMinutesToHoursAndMinutes(userQuest?.viewingTime)}</TableCell>
              <TableCell align="center" >{formatValueWithUnit(userQuest?.rewardData,'$')}</TableCell>
              <TableCell align="center" >{formatValueWithUnit(userQuest?.liveStreamingDays,'day')}</TableCell>
              <TableCell align="center" >{leaderboardUser?.overallScore}</TableCell>
              <TableCell align="center" >{leaderboardUser?.rank}</TableCell>
              <TableCell align="center" >
                {userQuest?.splitAmount}
                {!showTiers?
                  <button className="small-btn" onClick={this.toggleSplitTierInfo.bind(this, user.username)} style={{marginLeft:'10px'}}>+</button>
                  :
                  <button className="small-btn open" onClick={this.toggleSplitTierInfo.bind(this, user.username)} style={{marginLeft:'10px'}}>-</button>
                }
              </TableCell>
              <TableCell align="center" > ${userQuest?.leaderboardAmount}</TableCell>
              <TableCell align="center">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                  
                  {numberRequirements > 0 &&
                    <>{sumCompletedRequirements}/{numberRequirements}&nbsp;</>
                  }
                  {!showUserRequirements ?
                    <button className="small-btn" onClick={this.toggleElementInfo.bind(this, user.username)}>+</button>
                    :
                    <button className="small-btn open" onClick={this.toggleElementInfo.bind(this, user.username)}>-</button>
                  }
                </div>
              </TableCell>
            </TableRow>
            {showUserRequirements &&
              this.getUserRequirements(user, userQuest)
            }
            {showTiers &&
              this.getUserSplitTiers()
            }
          </React.Fragment>
        );
      });
    }

    return sortedUsers.map(user => {
      const userQuest = find(currentUserQuests, o => (o.user.id === user.id));
      let sumCompletedRequirements = getNumApprovedRequirements(quest, userQuest);
      const showUserRequirements = showingElements.indexOf(user.username) !== -1;
      let tier = '(?)';
      if (user.tier === 'viewer') {
        tier = 'V';
      } else if (user.tier === 'member') {
        tier = 'M';
      }
      return (
        <>
          <TableRow hover tabIndex={-1} key={user.id}>
            <TableCell align="center">{user.user ? user.user.username : '-'}</TableCell>
            <TableCell align="center">{user.paymentAmount ? `$${user.paymentAmount}-${tier}` : '$0'}</TableCell>
            <TableCell align="center">{user.link ? <a href={user.link} target="_blank" rel="noopener noreferrer"> {user.link} </a> : '-'}</TableCell>
            {this.getCompletionElement(user)}
            <TableCell align="center">{user.dateAccepted ? moment(user.dateAccepted).format('MM/DD/YY - hh:mm a') : '-'}</TableCell>
            <TableCell align="center">
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                {sumCompletedRequirements >= numberRequirements &&
                  <Tooltip title="Edit reputation responses" placement="top">
                    <button className="small-btn" onClick={this.props.openCongenialityModal.bind(this, user.id)}>
                      <i className="fa fa-address-card" />
                    </button>
                  </Tooltip>
                }
                {numberRequirements > 0 &&
                  <React.Fragment>
                    {sumCompletedRequirements}/{numberRequirements}&nbsp;
                  </React.Fragment>
                }
                {!showUserRequirements ?
                  <button className="small-btn" onClick={this.toggleElementInfo.bind(this, user.username)}>+</button>
                  :
                  <button className="small-btn open" onClick={this.toggleElementInfo.bind(this, user.username)}>-</button>
                }
              </div>
            </TableCell>
          </TableRow>
          {showUserRequirements &&
            this.getUserRequirements(user, userQuest)
          }
        </>
      );
    });
  }

  // 创建等级展示列表
  getUserSplitTiers = () => {
    const { quest } = this.props;

    const spliterTiers = quest.splitTiers

    return (
      <>
        <TableRow>
          <TableCell colSpan="10">
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell align="left"> Level </TableHeaderCell>
                  <TableHeaderCell align="left"> Amount </TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {spliterTiers.map((item,index) => {
                    return (
                      <TableRow>
                        <TableCell><span>Tier {index + 1}</span></TableCell>
                        <TableCell><span className="success">{item.prizePool}</span></TableCell>
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      </>
    );
  }

  // Create user requirements table; return element to display
  getUserRequirements = (user, userQuest) => {
    const { quest } = this.props;

    const requirements = getQuestRequirementsForDisplay(quest, true);

    return (
      <>
        <TableRow>
          <TableCell colSpan="10">
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell align="left"> Mark Complete </TableHeaderCell>
                  <TableHeaderCell align="left"> Requirement </TableHeaderCell>
                  <TableHeaderCell align="left"> Reason </TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(requirements && requirements.length > 0) &&
                  requirements.map((r) => {
                    const { type, subtype, approvalRequired } = r;

                    // Find user quest
                    const userQuestRequirements = (userQuest && userQuest.requirements) ? userQuest.requirements : [];
                    const ur = find(userQuestRequirements, o => o.questReqId === r.id);
                    const views = ur && ur.views ? ur.views : 'n/a';

                    // Decide how to color code
                    const statusClass = classNames({
                      success: ur && ur.status === 'completed',
                      error: ur && ur.status === 'rejected',
                      warning: !ur || (ur && (ur.status !== 'completed' && ur.status !== 'rejected')),
                    });

                    // Format link-based submissions
                    let linkHTML = <React.Fragment />;

                    if (ur && (ur.type === 'submit-tweet' || ur.type === 'submit-link')) {
                      if (!ur.content.toLowerCase().startsWith('http')) {
                        linkHTML = <Link href={`https://${ur.content.toLowerCase()}`} target="_blank">{ur.content}</Link>;
                      } else if (ur.content) {
                        linkHTML = <Link href={`${ur.content.toLowerCase()}`} target="_blank">{ur.content}</Link>;
                      }
                    }

                    // Create table cell for submission
                    let text = '';

                    if (ur) {
                      if (type === 'submit-tweet') {
                        text = <TableCell><span className={statusClass}>Twitter Link:&nbsp;{linkHTML}</span></TableCell>;
                      } else if (type === 'submit-link' && (subtype && subtype === 'vod')) {
                        text = (
                          <TableCell>
                            <span className={statusClass}>{'VOD Link:'}&nbsp;{linkHTML}{' - Views: '}{views}</span>
                            {(ur.status === 'completed') ? (
                              <button className="small-btn" style={{ margin: '0 0 0 5px' }} onClick={this.props.openVODModals.bind(this, user.id, ur.questReqId)}>
                                <i className="fa fa-address-card" />
                              </button>
                            ) : null}
                          </TableCell>
                        );
                      } else if (type === 'submit-link') {
                        text = <TableCell><span className={statusClass}>{subtype === 'vod' ? 'VOD Link:' : 'Clip Link:'}&nbsp;{linkHTML}</span></TableCell>;
                      } else if (type === 'submit-text' || type === 'submit-text-optional') {
                        text = <TableCell><span className={statusClass}>{r.title}: {parseLinks(ur.content)}</span></TableCell>;
                      } else if (approvalRequired) {
                        text = <TableCell><span className={statusClass}>{`Checkbox submitted: ${r.title}`}</span></TableCell>;
                      }
                    } else if (type === 'submit-tweet') {
                      text = <TableCell><span className="warning"><i>Twitter Link Pending</i></span></TableCell>;
                    } else if (type === 'submit-link') {
                      text = <TableCell><span className="warning"><i>{subtype === 'vod' ? 'VOD Link Pending' : 'Clip Link Pending'}</i></span></TableCell>;
                    } else if (type === 'submit-text' || type === 'submit-text-optional') {
                      text = <TableCell><span className="warning"><i>Text Submission Pending</i></span></TableCell>;
                    } else if (approvalRequired) {
                      text = <TableCell><span className="warning">Checkbox pending</span></TableCell>;
                    }

                    // Create table cell for note
                    let noteHtml = <TableCell />;
                    if (ur && ur.note) {
                      noteHtml = <TableCell><span className={statusClass}><i>{ur.note}</i></span></TableCell>;
                    }

                    // Create table cell for admin override button (if applicable)
                    let completeButton = <TableCell />;
                    if (!ur || (ur && ur.status !== 'completed')) {
                      completeButton = (
                        <TableCell>
                          <CustomLinkModal
                            completeSelected={link => this.handleComplete(user, quest, r, link)}
                            selectedCheckboxesLength={1}
                            {...(ur && ur.link ? { existingLink: ur.link } : {})}
                          />
                        </TableCell>
                      );
                    }

                    return (
                      <TableRow>
                        {completeButton}
                        {text}
                        {noteHtml}
                      </TableRow>
                    );
                  })
                }

                {/* Promo link */}
                {(userQuest.promoLink && (
                  <Table >
                    <TableHead>
                      <TableRow>
                        <TableHeaderCell colSpan="8" align="left"> Promo Link </TableHeaderCell>
                        <TableHeaderCell colSpan="8" align="left"> Clicks</TableHeaderCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan="8"><a href={`http://${userQuest.promoLink}`} target="_blank" rel="noopener noreferrer">{userQuest.promoLink}</a></TableCell>
                        <TableCell colSpan="8">{userQuest.promoLinkClicks}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                ))}
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
        {userQuest.leftQuestReason &&
          <TableRow>
            <TableCell colSpan="8">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell align="left">Reason for Leaving</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow style={{ padding: '0.67em' }}>
                    <TableCell colSpan="8">
                      {userQuest.leftQuestReason}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        }
      </>
    );
  }

  // Define table headers based on quest type; return array of headers
  getTableHeaders = (questType, platform, keyDistribute) => {
    const { usersTab } = this.state;

    let tableHeaders = [];

    switch (questType) {
      case 'tiered':
      case 'tiered-one-time':
      case 'tiered-auto':
        tableHeaders = [
          {
            id: 'username', numeric: false, disablePadding: true, label: 'Noiz',
          },
          {
            id: `${platform}Username`, numeric: false, disablePadding: true, label: platform.charAt(0).toUpperCase() + platform.slice(1),
          },
          {
            id: 'validWatchTime', numeric: true, disablePadding: true, label: 'Valid Watch Time',
          },
          {
            id: 'revenue', numeric: true, disablePadding: true, label: 'Revenue',
          },
          {
            id: 'Valid Streaming Days', numeric: true, disablePadding: true, label: 'Valid Streaming Days',
          },
          {
            id: 'overallScore', numeric: false, disablePadding: true, label: 'Overall Score',
          },
          {
            id: 'rank', numeric: false, disablePadding: true, label: 'Rank',
          },
          {
            id: 'tierAmount', numeric: false, disablePadding: true, label: 'Tier Amount',
          },
          {
            id: 'rankAmount', numeric: false, disablePadding: true, label: 'Rank Amount',
          },
          {
            id: 'actions', numeric: false, disablePadding: false, label: 'Actions',
          }
        ];
        break;
      default:
        tableHeaders = [
          {
            id: 'username', numeric: false, disablePadding: false, label: 'Noiz',
          },
          {
            id: 'paymentAmount', numeric: true, disablePadding: false, label: 'Payment Amount',
          },
          {
            id: 'link', numeric: false, disablePadding: false, label: 'Link',
          },
          {
            id: 'completionStatus', numeric: false, disablePadding: false, label: 'Status',
          },
          {
            id: 'dateAccepted', numeric: false, disablePadding: false, label: 'Date Accepted',
          },
          {
            id: 'actions', numeric: false, disablePadding: false, label: 'Actions',
          },
        ];
        break;
    }

    if (usersTab === 0 && keyDistribute) {
      tableHeaders.push({
        id: 'gameKey', numeric: false, disablePadding: true, label: '',
      });
    }

    return tableHeaders;
  }

  // Define data for csv download; return object with headers and data

  renderFirstRow = () => {
    return (
      <div className="Global__subTabs" style={{ padding: '0px 30px 10px 30px' }}>
        <button className="platform-twitch-btn active" data-platform="twitch">
          <svg className="platform-twitch-btn-icon" viewBox="0 0 24 24">
            <path d="M11.571 4.714h1.715v5.143H11.57zm4.715 0H18v5.143h-1.714zM6 0L1.714 4.286v15.428h5.143V24l4.286-4.286h3.428L22.286 12V0zm14.571 11.143l-3.428 3.428h-3.429l-3 3v-3H6.857V1.714h13.714Z" />
          </svg>
          Twitch
        </button>
      </div>
    );
  }

  render() {
    const {
      questType, users, quest,
    } = this.props;
    const { currentUserQuests } = this.state;
    const { twitchAccountRequired } = quest;
    const { usersTab } = this.state;

    let platform = 'twitch';
    if (twitchAccountRequired) {
      platform = 'twitch';
    }

    users.forEach((user) => {
      const userQuest = find(currentUserQuests, o => (o.user.id === user.id));
      user.completionStatus = getCompletionStatus(quest, userQuest, user.timePlaying);
      if (userQuest.chatDetails) { user.chatDetails = userQuest.chatDetails; }
    });

    const approvedUsers = users.filter(user => user.status !== 'leftQuest');
    const failedUsers = approvedUsers.filter(user => user.completionStatus.status === 'F');
    const leftQuestUsers = users.filter(user => user.status === 'leftQuest');
    const currentTabUsers = usersTab === 0 ? approvedUsers : leftQuestUsers;

    const keyDistribute = quest.keyRequired && keyDistributionStepExists(quest);

    const csv = getCSV(currentTabUsers, platform, quest, currentUserQuests);
    const keysCSV = getKeys([].concat(failedUsers, leftQuestUsers), platform, quest);

    const createSortHandler = property => (event) => {
      this.handleRequestSort(event, property);
    };

    return (
      <section className="ToggleTable">
        {currentTabUsers.length ? (
          <React.Fragment>
            <section className="ToggleTable__header">
              <h3 className="ToggleTable__header-title">Users: <span>{currentTabUsers?.length}</span></h3>
              {(currentTabUsers?.length) ?
                (
                  <div className="ToggleTable__header-buttons">
                    <Tooltip title="Report CSV" placement="top">
                      <CSVLink
                        data={csv.dataCSV}
                        headers={csv.headers}
                        filename={(quest && quest.game) ? `${quest.game.name} - ${quest.title}.csv` : 'report.csv'}
                      >
                        <i className="fa fa-download" />
                      </CSVLink>
                    </Tooltip>
                    {quest.keyRequired &&
                      <Tooltip title="Keys to Revoke" placement="top">
                        <CSVLink
                          data={keysCSV.dataCSV}
                          headers={keysCSV.headers}
                          filename={quest && quest.game ? `${quest.game.name} - ${quest.title} - KeysToRevoke.csv` : 'keysToRevoke.csv'}
                        >
                          <i className="fa fa-key" />
                        </CSVLink>
                      </Tooltip>
                    }
                  </div>
                ) : null}
            </section>
            {this.renderFirstRow()}
            <Table>
              <TableHead>
                <TableRow>
                  {this.getTableHeaders(questType, platform, keyDistribute).map(headCell => (
                    <TableHeaderCell
                      key={headCell.id}
                      align="center"
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                      sortDirection="asc"
                    >
                      <TableSortLabel
                        active={this.state.orderBy === headCell.id}
                        direction={this.state.order}
                        onClick={createSortHandler(headCell.id)}
                        IconComponent={ArrowDropDownIcon}
                        style={{ marginLeft: '30px' }}
                      >
                        {headCell.label}
                        {this.state.orderBy === headCell.id ? (
                          <span style={{ display: 'none' }}>
                            {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableHeaderCell>))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.getUserRows(currentTabUsers, questType, platform, keyDistribute)}
              </TableBody>
            </Table>
          </React.Fragment>
        ) : (<div className="NoQuestTiered">No one has joined yet.</div>)}
      </section>
    );
  }
}

QuestDetailsTableNew.propTypes = {
};

export default QuestDetailsTableNew;
