import React from 'react';
import anime from 'animejs';
import { Link } from 'react-router';

import { Parallax } from '../../custom_modules/react-spring/src/renderprops/addons/index';
import { brandServices, mobilePages, desktopPages, tabletPages } from './slides/slideIndex';

import './BrandService.scss';

let timeout = false;
// props:
// pOffset

const ContactUs = (props) => {
  const { final = 0, handleLoad } = props;

  handleLoad(final);

  return (<div className="fixed-contact-dealy">
    <div> Schedule your next campaign today</div>
    <div className='inner-button'>
      <Link
        to='/onboard'
      >
        Onboard
      </Link>
    </div>
  </div>);
};

class BrandService extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.para = React.createRef();
    this.state = {
      offsetAdjustments: [],
      windowWidth: 500,
      windowHeight: 500,
      debounce: 1000,
    };

    this.handleContactUsLoad = this.handleContactUsLoad.bind(this);
  }

  componentDidMount() {
    window.Intercom('boot', {
      app_id: 'ito0ceux',
      hide_default_launcher: false,
    });
    window.addEventListener('resize', this.getDimensions.bind(this));
    this.getDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getDimensions.bind(this));
    anime({
      targets: '.Main__Header__wrapper',
      opacity: 1,
      duration: 0,
      easing: 'linear',
    });
    window.Intercom('shutdown');
  }

  navToOffset = (offset) => {
    if (this.para && this.para.current) {
      this.para.current.scrollTo(offset - 0.5);
    }
  }

  getDimensions = () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight }), this.state.debounce);
  }

  handleContactUsLoad(scrollLoc) {
    let finalOffset = scrollLoc;

    if (this.state.windowHeight < 1000) {
      finalOffset -= 1;
    }

    // Nav directly to form if contact-us in URL
    if (window.location.hash && window.location.hash.includes('contact-us')) {
      this.navToOffset(finalOffset);
    }
  }

  render() {
    const currOffset = this.parallax && this.parallax.offset ? this.parallax.offset : 0;
    const mobileBreakPoint = this.state.windowWidth < 700;
    const medBreakPoint = this.state.windowWidth < 1025 && this.state.windowWidth >= 700;
    const medAndUp = this.state.windowWidth >= 700;
    const deskBreakPoint = this.state.windowWidth >= 1025;
    const maxHeightPointer = 1000;
    const tallEnough = this.state.windowHeight >= maxHeightPointer;
    console.log("windowHeight",this.state.windowHeight)
    console.log("windowWidth",this.state.windowWidth)

    // const mobileBreakPoint = window.innerWidth < 700;
    // const medBreakPoint = window.innerWidth < 1025 && window.innerWidth >= 700;
    // const deskBreakPoint = window.innerWidth >= 1025;
    const pageCount = mobileBreakPoint ? mobilePages : medBreakPoint ? tabletPages : desktopPages;
    console.log('pageCount', pageCount);
    const maxHeightOverride = mobileBreakPoint ? 0 : medBreakPoint ? 0 : deskBreakPoint && tallEnough ? maxHeightPointer : 0;
    console.log('maxHeightOverride', maxHeightOverride)
    const heightIsGreater = !!(this.state.windowHeight >= maxHeightPointer && deskBreakPoint);
    console.log('heightIsGreater', heightIsGreater);
    const pagesMaxHeightOverride = (9.3 / (this.state.windowHeight / maxHeightPointer));
    const thePages = heightIsGreater ? pagesMaxHeightOverride : pageCount;

    console.log('pagesMaxHeightOverride', pagesMaxHeightOverride);
    console.log('thePages', thePages);

    // const firstPageTallestOffset = heightIsGreater ? (this.state.windowHeight / (2.5 * maxHeightPointer)) : 0;
    const firstPageTallestOffset = 0;
    return (
      <div className="brand-service-master-flextainer">
        <div className="brand-service-master">
          {/* { !mobileBreakPoint && <ContactUs navigate={this.navToOffset} final={thePages} /> } */}
          <ContactUs
            navigate={this.navToOffset}
            final={thePages}
            screenHeight={this.state.windowHeight}
            handleLoad={this.handleContactUsLoad.bind(this)}
          />

          <Parallax
            maxHeight={maxHeightOverride}
            onWheel={this.onMouseWheel}
            ref={this.para}
            pages={heightIsGreater ? pagesMaxHeightOverride : thePages}
          >
            {brandServices.map((slide, index) => {
              const {
                Slide, mobileOffset, tabletOffset, desktopOffset, mobileFactor, tabletFactor, desktopFactor,
              } = slide;

              const finalDeskAdjOffset = index !== 0 ? desktopOffset + firstPageTallestOffset : desktopOffset;
              const offsetAdj = mobileBreakPoint ? mobileOffset : medBreakPoint ? tabletOffset : finalDeskAdjOffset;
              const slideFactor = mobileBreakPoint ? mobileFactor : medBreakPoint ? tabletFactor : desktopFactor;

              return (<Slide
                firstPageTallestOffset={firstPageTallestOffset}
                contactNavIndex={pageCount}
                navToOffset={this.navToOffset.bind(this)}
                medAndUp={medAndUp}
                isMobile={mobileBreakPoint}
                isTablet={medBreakPoint}
                isDesktop={deskBreakPoint}
                key={index}
                pOffset={index}
                pLax={this.parallax}
                currOffset={currOffset}
                layoutFactor={slideFactor}
                offsetAdjust={offsetAdj}
              // style={{overflow: 'hidden'}}
              />);
            })}
          </Parallax>
        </div>
      </div>
    );
  }
}

export default BrandService;
