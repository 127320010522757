/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import AccountMenuNew from './AccountMenuNew';
import BasicMenu from './BasicMenu';
import LogoNew from '../logo/LogoNew';
import MobileMenu from './MobileMenu';
import NavMenuNew from './NavMenuNew';
import PortalNav from './PortalNav';
import ToggleMobileMenu from './ToggleMobileMenu';

import { isAuthenticated } from '../../utils/authChecker';

import './MainNavNew.scss';

function MainNavNew({
  auth,
  brandUser,
  isBrandUser,
  isAdmin,
  isClientPortal,
  pathname,
  router,
  subdomain,
  toggleMyQuestsMenu,
  user,
  visible,
}) {
  if (!visible) return null;
  const [fadingOut, setFadingOut] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [mobile, setMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const update = () => {
      if (mobile && (window.innerWidth >= 768)) {
        setMobile(false);
      } else if (!mobile && (window.innerWidth < 768)) {
        setMobile(true);
      }
    };

    window.addEventListener('resize', update);
    return () => window.removeEventListener('resize', update);
  }, [mobile]);

  const closeMenu = () => {
    if (window.innerWidth < 768) {
      setMenuOpen(false);
    }
  };

  const toggleMenu = () => setMenuOpen(!menuOpen);
  if (pathname === '/make-some-noiz') return null;
  const authenticated = isAuthenticated();
  const inAboutUs = pathname === '/about-us';
  const inAboutNOIZ3 = pathname === '/about-us-beta3';
  const inBrandService = pathname === '/';
  const inContact = pathname === '/contact';
  const inCareers = pathname.startsWith('/careers');
  const inOnboard = pathname === '/onboard';
  const inHatchway = pathname === '/brand/hatchway';
  const inPrivacyPolicy = pathname === '/privacy-policy';
  const inRoot = pathname === '/';
  const inTermsOfUse = pathname === '/terms-of-use';
  const isBrands = (subdomain === 'brands' || subdomain === 'brands.staging');
  const inStreamWithUs = pathname === '/stream-with-us';

  const showBasicMenu = ((inRoot || inAboutUs || inAboutNOIZ3|| inBrandService || (inContact && !authenticated) || inCareers || inOnboard || inHatchway || inTermsOfUse || inPrivacyPolicy || inStreamWithUs)
    && ['staging', '', '192.168'].includes(subdomain) && !isClientPortal && !mobile);

  const headerClassNames = classNames(
    'Main__Header',
    {
      Portal: isClientPortal,
      'basic-menu': showBasicMenu,
      white: isBrands,
    },
  );

  const wrapperClassNames = classNames(
    'Main__Header__wrapper',
    {
      borderBottom: inAboutUs || inRoot || pathname.startsWith('/quest'),
      forceFullWidth: pathname === '/admin',
    },
  );

  return (
    <header className={headerClassNames}>
      <section className={wrapperClassNames}>
        <LogoNew black={isBrands} />
        <PortalNav
          campaign={brandUser.campaign}
          campaigns={brandUser.campaigns}
          router={router}
          visible={isClientPortal}
        />
        <BasicMenu
          authenticated={authenticated}
          visible={showBasicMenu}
        />
        <MobileMenu
          authenticated={authenticated}
          closeMenu={closeMenu}
          fadingOut={fadingOut}
          isBrands={isBrands}
          isAdmin={isAdmin}
          isBrandUser={isBrandUser}
          isClientPortal={isClientPortal}
          visible={mobile && menuOpen}
        />
        <NavMenuNew
          auth={auth}
          brandUser={brandUser}
          isBrandUser={isBrandUser}
          isAdmin={isAdmin}
          isClientPortal={isClientPortal}
          router={router}
          toggleMyQuestsMenu={toggleMyQuestsMenu}
          user={user}
          visible={!mobile && !showBasicMenu && authenticated}
        />
        <AccountMenuNew
          auth={auth}
          isBrandUser={isBrandUser}
          visible={!mobile && authenticated && !showBasicMenu}
        />
        <ToggleMobileMenu
          fadingOut={fadingOut}
          open={menuOpen}
          setFadingOut={setFadingOut}
          toggleMenu={toggleMenu}
          visible={mobile && !(isClientPortal && !authenticated)}
        />
      </section>
    </header>
  );
}

export default MainNavNew;
