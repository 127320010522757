/* eslint max-len: ["error", { "code": 800 }] */
import React from 'react';
import './CareersPost.scss';

class CampaignManager extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    return (
      <section className="CareersPost">
        <section className="CareersPost__container">
          <section className="CareersPostSummary">
            <section className="CareersPostSummary__content">

            </section>
          </section>
          <section className="CareersPostDetails">
            <section className="CareersPostDetails__content">
              <section className="PostingItem">
                <a className="PostingItem__apply-btn" href="mailto:angie@noiz.gg">
                  Apply for this job <i className="fa fa-envelope" />
                </a>
                <section className="PostingItem__wrapper">
                  <h4 className="PostingItem__title">Campaign Manager</h4>
                  <section className="PostingItem__categories">
                    <span className="PostingItem__categories--location">Beverly Hills, CA</span>
                  </section>
                </section>
              </section>
              <div>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Noiz is a group of esports and video game industry veterans—bringing together a diverse community of
                    Twitch, YouTube creators, with established streamers across mediums and global franchises.
                    Founded in 2018, Noiz empowers micro and macro streamers and inspires expression. We pride ourselves
                    on providing real value to both streamers and brands worldwide. Our goal is to support streamers
                    through meaningful partnerships and brand building. Attracting thousands of views monthly with over
                    25,000 loyal subscribers globally, Noiz reaches audiences across all platforms.
                  </span>
                </p>

                <p>
                  <strong>Purpose of Position:</strong>
                </p>
                <ul>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      The Campaign Manager is accountable for the overall project implementation and will handhold
                      clients through the entire project process from ideation to execution.&nbsp;
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      As part of the Team, they will need to serve as the liaison with internal departments to accurately
                      and succinctly communicate all project objectives and deliverables to everyone involved and will be
                      responsible to flag any potential miscommunications between the teams.&nbsp;
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      As part of the team, they will need to be well versed in the influencer marketing space or strong
                      interest in the gaming industry.
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      We are looking for someone who is passionate, has high energy, and is results-oriented with a
                      creative and analytical mind.
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      You should have the ability to manage critical situations and assist with delivering practical
                      solutions as necessary.&nbsp;
                    </span>
                  </li>
                </ul>

                <p>
                  <strong>Responsibilities:</strong>
                </p>
                <ul>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Build positive professional relationships with clients, influencers and associates while
                      effectively leading the project to successful completion.&nbsp;
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Define project requirements, objectives, and assumptions necessary to successfully complete a
                      project.&nbsp;
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Budget, schedule, plan, and control project activities to fulfill objectives and satisfy project
                      requirements.&nbsp;
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Develop and drive integrated project plans, aligning project tactics with project strategy.&nbsp;
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Serve as the project, client and influencer advocate within the entire organization while
                      establishing, maintaining and leading high-performance project teams.&nbsp;
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Facilitate, coordinate, lead and motivate all associated project resources to gain alignment on
                      project goals and deliverables.&nbsp;
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Lead risk management within project teams, ensuring risks have appropriate mitigation and
                      contingency plans.&nbsp;
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Manage both micro and macro influencer campaigns, including contract negotiations, strategy
                      briefing, and content creation approvals.
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Manage projects within established scope, schedule, and budget while meeting or exceeding
                      established KPIs.&nbsp;
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Develop and document high-level strategies for accomplishing specific project objectives.&nbsp;
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Facilitate and lead effective project meetings, manage change and conflict, and develop resource
                      planning estimates to manage project workload and productivity.&nbsp;
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Support influencers to ensure the success of projects while maintaining customer service
                      focus.&nbsp;
                    </span>
                  </li>
                </ul>

                <p>
                  <strong>Basic Qualifications</strong>
                </p>
                <ul>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      5+ years of experience with social media campaigns, gaming, or influencer-based marketing and
                      client-facing initiatives.&nbsp;
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Comfortable working in a startup environment, creating organization and processes where none may
                      already exist.
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Knowledgeable of all major social platforms (Twitch, YouTube, Steam, Twitter, Instagram, Facebook,
                      TikTok).
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Stay up-to-date on the latest trends and innovations in the gaming realm.
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Exceptional interpersonal and leadership skills to effectively communicate and build relationships
                      with a broad spectrum of audiences at all organizational levels.&nbsp;
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Comfortable with data entry and Google Sheets/Microsoft Excel.
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Strong competencies in planning, project management, and organization with the ability to lead
                      multiple activities and resources while maintaining a focus on quality.&nbsp;
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Solid analytical and strategic capabilities and business acumen along with. demonstrated work
                      ethic, integrity, and professional conduct and appearance.&nbsp;
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      Ability to produce and present clear, concise, and professionally written communication.
                    </span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>Bachelor’s degree or equivalent experience.</span>
                  </li>
                </ul>

                <p>
                  <span style={{ fontWeight: 400 }}>
                    Noiz is committed to creating an inclusive employee experience for all. Regardless of race, gender,
                    religion, sexual orientation, age, disability, we firmly believe that our work is at its best when
                    everyone feels free to be themselves.
                  </span>

                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    We're based in Beverly Hills, but the world is our oyster and you can currently work remotely until
                    further notice.
                  </span>
                </p>

                <p>
                  <span style={{ fontWeight: 400 }}>
                    We pay competitive salaries &amp; benefits including (but not limited to) medical, dental and vision
                    health insurance.
                  </span>
                </p>

                <h3>
                  <strong>Seniority Level</strong>
                </h3>
                <p>
                  <span style={{ fontWeight: 400 }}>Associate</span>
                </p>
                <h3>
                  <strong>Industry</strong>
                </h3>
                <ul>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>Marketing &amp; Advertising&nbsp;</span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>&nbsp;Online Media&nbsp;</span>
                  </li>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>Communications</span>
                  </li>
                </ul>
                <h3>
                  <strong>Employment Type</strong>
                </h3>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    This is a full-time, salaried position with full corporate benefits
                  </span>
                </p>

                <h3>
                  <strong>Job Functions</strong>
                </h3>
                <ul>
                  <li style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>Marketing&nbsp;</span>
                  </li>
                </ul>

              </div>

              <p className="CareersPostDetails__content--cta">
                Email your resume to <a href="mailto:angie@noiz.gg">angie@noiz.gg</a>
              </p>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

// CareersPostFullStackEngineer.propTypes = {
//
// };

export default CampaignManager;
