/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link } from 'react-router';
import { orderBy } from 'lodash';
import moment from 'moment-timezone';
import { Tooltip } from '@material-ui/core';
//import classNames from 'classnames';
import { simpleNotification } from '../../utils/notifications';
// import { Field, FieldArray, reduxForm } from 'redux-form';
// import CustomDropDownArray from '../../../../utils/redux-forms-custom-fields/CustomDropDownArray';
import './Wallet.scss';
import { getCurryFormattedString, roundingMoney } from "../../utils/functions";
import walletIcon from './assets/wallet.png';
import frozenIcon from './assets/frozen.png';


const quickWidth = {};
// const quickWidth = {maxWidth: '10%'}

class Wallet extends React.Component {
  constructor(props) {
    super(props);
    //this.idCopy = React.createRef();
    this.props = props;
    this.state = {
      copyModal: false,
      questCopyId: null,
      selectGame: null,
      tab: 1,
    };
  }

  componentDidMount() {
    const { token } = this.props.auth;

    // Unify this four methods in one in the future
    this.props.getUserWallet(token);
  }

  copyText = (event) => {
    const text = event.target.innerHTML;
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(text);
      simpleNotification({
        level: 'success',
        title: 'Successfully Copied',
        message: `${text}`,
        autoDismiss: 2,
      });
    }
  }

  generateRowsHTML = (quests, gameId) => {
    const today = moment().tz('America/Los_Angeles');
    const hoursOffset = -today.utcOffset() / 60;

    if (quests.length >= 1) {
      return quests.map((quest, index) => (
        <div className="row" key={quest.id}>
          <div className="table-cell">{quest.title}</div>
          <div
            className="table-cell"
            ref={this.idCopy}
            style={{ cursor: 'pointer', ...quickWidth }}
            onClick={e => this.copyText(e)}
            aria-hidden
          >
            {quest.id}
          </div>
          <div className="table-cell">{quest.startDateTime ? moment(quest.startDateTime).tz('America/Los_Angeles').add(hoursOffset, 'hours').format('MM-DD-YY hh:mm A') : '-'}</div>
          <div className="table-cell">{quest.endDateTime ? moment(quest.endDateTime).tz('America/Los_Angeles').add(hoursOffset, 'hours').format('MM-DD-YY hh:mm A') : '-'}</div>
          <div className="table-cell">
            {(quest.slug)
              &&
              <Link
                to={`/quests/${quest.slug}`}
                activeClassName="active"
                href={`/quests/${quest.slug}`}
                className="remove-key"
                target="_blank"
              >
                <i className="fa fa-external-link" />
              </Link>}
            {!(quest.slug)
              &&
              <span className="not-available">
                Not Available
              </span>
            }
          </div>
          <div className="table-cell">
            {quest.needsReview}
          </div>
          <div className="table-cell">
            <Tooltip title="Edit" placement="top">
              <Link
                to={`/admin/command-center/${gameId}/quests/${quest.id}/edit`}
                activeClassName="active"
                href={`/admin/command-center/${gameId}/quests/${quest.id}/edit`}
                className="remove-key"
              >
                <i className="fa fa-pencil" />
              </Link>
            </Tooltip>
            {(quest.requirePreapproval) &&
              <Tooltip title="Preapproval" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/preapproval`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/preapproval`}
                  className="remove-key"
                >
                  <i className="fa fa-check-square-o" />
                </Link>
              </Tooltip>
            }
            {(quest.type === 'paid-bounty' || quest.type === 'paid bounty') &&
              <Tooltip title="Details" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/activity`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/activity`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
            }
            {(quest.type === 'referral') &&
              <Tooltip title="Details" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/sales`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/sales`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
            }
            {(quest.type === 'timed') &&
              <Tooltip title="Details" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/timed`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/timed`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
            }
            {(quest.type === 'general' || quest.type === 'default' || quest.type === 'basic') &&
              <Tooltip title="Details" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/${'general'}`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/${'general'}`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
            }
            {(quest.type === 'affiliate') &&
              <Tooltip title="Details" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/affiliate`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/affiliate`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
            }
            {((quest.type === 'tiered' || quest.type === 'tiered-one-time') && quest.frequency === 'one-time') &&
              <Tooltip title="Details" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/${quest.type}`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/${quest.type}`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
            }
            {((quest.type === 'tiered' || quest.type === 'tiered-multi-days') && quest.frequency === 'daily') &&
              <Tooltip title="Details" placement="top">
                <Link
                  to={`/admin/command-center/${gameId}/quests/${quest.id}/tiered-multiple-days`}
                  activeClassName="active"
                  href={`/admin/command-center/${gameId}/quests/${quest.id}/tiered-multiple-days`}
                  className="remove-key"
                >
                  <i className="fa fa-line-chart" />
                </Link>
              </Tooltip>
            }
            {moment(quest.startDateTime).diff(moment(Date.now())) > 0 &&
              <Tooltip title="Delete" placement="top">
                <button className="remove-key" onClick={() => { this.deleteQuest(quest, index); }}>
                  <i className="fa fa-trash" />
                </button>
              </Tooltip>
            }
            <Tooltip title="Duplicate" placement="top">
              <button className="remove-key" onClick={() => { this.setState({ copyModal: true, questCopyId: quest.id, selectGame: gameId }); }}>
                <i className="fa fa-copy" />
              </button>
            </Tooltip>
          </div>
        </div>
      ));
    }
    return (<div className="NoQuests">No Quests for this game</div>);
  }

  deleteQuest(quest, index) {
    if (window.confirm('This will delete the selected Quest, proceed?')) {
      const { token } = this.props.auth;
      fetch(`/api/v1/admin/quest/${quest.id}`, {
        method: 'delete',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        this.props.commandCenter.quests.data.splice(index, 1);
        this.setState({ quests: this.props.commandCenter.quests.data });
      });
    }
  }

  copyCCQuest() {
    if (window.confirm('Are you sure about copy this Quest?')) {
      const { token } = this.props.auth;
      this.props.copyCCQuest(this.state.questCopyId, this.state.selectGame, token);
      this.setState({ copyModal: false });
    }
  }

  closeCopyModal = () => {
    this.setState({
      copyModal: false,
    });
  }

  renderCopyModal() {
    const { data: games } = this.props.commandCenter.games;
    // const { gameId } = this.props.router.params;
    let configGames = games.map(game => ({
      value: game.id,
      label: game.name,
    }));
    configGames = orderBy(configGames, ['label'], ['asc']);
    const renderSelectOptions = option => (<option key={option.value} value={option.value}>{option.label}</option>);
    return (
      <section className="ModalMaster active">
        <section className="Modal active">
          <button onClick={this.closeCopyModal.bind(this)} className="Modal__layer" aria-hidden />
          <section className="Modal__wrapper Copy">
            <div className="Modal__copy__header">
              Select the game
            </div>
            <div className="Modal__copy__body">
              <select
                onChange={event => this.setState({ selectGame: event.target.value })}
                className=""
                value={this.state.selectGame}
              >
                {configGames.map(renderSelectOptions)}
              </select>
            </div>
            <div className="Modal__copy__footer">
              <button
                onClick={this.closeCopyModal.bind(this)}
              >
                <i className="fa fa-times" /> Cancel
              </button>
              <button
                onClick={this.copyCCQuest.bind(this)}
              >
                <i className="fa fa-check" /> Copy
              </button>
            </div>
          </section>
        </section>
      </section>
    );
  }

  render() {
    //const { gameId } = this.props.router.params;
    const { isLoading } = this.props.commandCenter.quests;
    const { tab } = this.state;
    const { wallet, frozen, withdraw } = this.props.user.wallet.data;

    if (isLoading) {
      //return (<i className="fa fa-spinner fa-spin fa-3x fa-fw" />);
    }

    //const today = moment().tz('America/Los_Angeles');
    //const hoursOffset = -today.utcOffset() / 60;

    //let activeQuests = filter(quests, x => (moment(x.endDateTime).tz('America/Los_Angeles').add(hoursOffset, 'hours') > today));
    //let finishedQuests = filter(quests, x => (moment(x.endDateTime).tz('America/Los_Angeles').add(hoursOffset, 'hours') <= today));

    //activeQuests = orderBy(activeQuests, ['endDateTime'], ['desc']);
    //finishedQuests = orderBy(finishedQuests, ['endDateTime'], ['desc']);

    //let showQuests = [];

    if (tab === 1) {
      //showQuests = [...activeQuests];
    } else if (tab === 2) {
      //showQuests = [...finishedQuests];
    }

    return (
      <section className="CCQuests1">
        <section className='wallet_box'>
          <div className='box'>
            <div className='title'>Withdrawable balance</div>
            <div className="money">
              <i className="fa fa-usd" aria-hidden="true" />
              <div className="balance_max">{getCurryFormattedString(withdraw ? roundingMoney(withdraw, 2) : '0.00')}</div>
            </div>
            <Link
              to={`/my-account-detail/withdraw`}
              activeClassName='active'>
              <button>Withdraw balance</button>
            </Link>
          </div>
          <div className='right'>
            <div className='balance'>
              <img className='icon' src={walletIcon} alt='walletIcon' />
              <div>
                <div className="title">Account blance</div>
                <div className="balance_min"> {getCurryFormattedString(wallet ? roundingMoney(wallet, 2) : '0.00')}</div>
              </div>
            </div>
            <div className='balance'>
              <img className='icon' src={frozenIcon} alt='frozenIcon' />
              <div>
                <div className="title"> Pending cash out amount</div>
                <div className="balance_min"> {getCurryFormattedString(frozen ? roundingMoney(frozen, 2) : '0.00')}</div>
              </div>
            </div>
          </div>
        </section>
        {(this.state.copyModal) && this.renderCopyModal()}
      </section>
    );
  }
}

Wallet.propTypes = {

};

export default Wallet;
