/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import CustomInput from '../../utils/redux-forms-custom-fields/CustomInput2';
import email from './img/91.png';
class RecoverPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  submit = (userFormInfo) => {
    const userInfo = {
      identity: userFormInfo.identity.toLowerCase(),
      password: userFormInfo.password,
    };
    this.props.onSubmit(userInfo);
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <form className="form-container signup" autoComplete="off" onSubmit={handleSubmit(this.submit.bind(this))}>
        <Field
          type="text"
          name="identity"
          id="identity"
          prefixIcon={email}
          placeholder="Email"
          component={CustomInput}
        />
        <div className="form__message">
          A message with your new password will be sent to your email.
        </div>
        <div className="form__footer">
          <div className="form__footer__left">
            <button className="cta-button">SEND</button>
          </div>
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.identity || values.identity.trim() === '') {
    errors.identity = 'This field is required';
  }
  return errors;
};


RecoverPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'recoverPasswordForm',
  validate,
})(RecoverPasswordForm);
