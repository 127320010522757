import snail from '../assetsV2/brandnew/121.png';          // 121
import linceworks from '../assetsV2/brandnew/122.png';     // 122
import stridepr from '../assetsV2/brandnew/123.png';       // 123
import bose from '../assetsV2/brandnew/124.png';          // 124
import sega from '../assetsV2/brandnew/125.png';           // 125
import nexon from '../assetsV2/brandnew/126.png';          // 126
import squareEnix from '../assetsV2/brandnew/127.png';       // 127
import pqube from '../assetsV2/brandnew/128.png';          // 128
import softGiving from '../assetsV2/brandnew/129.png';     // 129
import thatgamecompany from '../assetsV2/brandnew/130.png'; // 130
import redbullrecords from '../assetsV2/brandnew/131.png';  // 131
import donkey from '../assetsV2/brandnew/132.png';         // 132
import stunlock from '../assetsV2/brandnew/133.png';       // 133
import facebook from '../assetsV2/brandnew/134.png';       // 134
import unknowng from '../assetsV2/brandnew/135.png';        // 135
import xseed from '../assetsV2/brandnew/136.png';           // 136
import wildCard from '../assetsV2/brandnew/137.png';  

const brands = [
  snail,
  linceworks,
  stridepr,
  bose,
  sega,
  nexon,
  squareEnix,
  pqube,
  softGiving,
  thatgamecompany,
  redbullrecords,
  donkey,
  stunlock,
  facebook,
  unknowng,
  xseed,
  wildCard,
];

export default brands;
